import React from "react";


import Plans from "./Plans";
import Faq, { faqPlans } from "./Faq";


import { ContactFrame } from "../layout/Contact";


import DialogWithTabs from "../dialogs/DialogWithTabs";

import classNames from "classnames";



const Manage = ({ close }) => {

    const tabs = {
        'plans': {
            caption: 'Plans',
            comp: React.createElement(Plans, {}),
        },
        'faq': {
            caption: 'Faq',
            comp: React.createElement(Faq, {
                list: faqPlans,
            }),
        },
        'contact': {
            caption: 'Contact Us',
            comp: React.createElement(ContactFrame, {
                placeholder: "Want a plan that fits your business like a glove? Let's get in touch. Write here.",
            }),
        },
    }

    return <DialogWithTabs tabs={tabs} close={close} />

}


export default Manage;