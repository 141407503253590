
import { ACCENTS_SUBTITLE, ACCENTS_GLOW, ACCENTS_MOBILE, FOCUS_BORDER, FOCUS_INPUT_BG, ARROW_BG, TOUCH_BG, TEXT_BG, BUTTON_BG, TEXT_INITIAL } from "../utils/consts.js";

import {
    Layout100 as TemplateIntroLayout100,


} from "./TemplateIntro.js";


import {
    Layout100 as TemplateOutroLayout100,


} from "./TemplateOutro.js";


import {
    Layout100 as TemplateChapterLayout100,


} from "./TemplateChapter.js";



import {
    Layout100 as TemplateBgImageLayout100,
    Layout101 as TemplateBgImageLayout101,
    Layout102 as TemplateBgImageLayout102,
    Layout103 as TemplateBgImageLayout103,

    Layout200 as TemplateBgImageLayout200,
    Layout300 as TemplateBgImageLayout300,
    Layout400 as TemplateBgImageLayout400,

    Layout500 as TemplateBgImageLayout500,



} from "./TemplateBgImage.js";





const Templates = {

    'intro': {
        title: 'Intro',
        thumb: 'https://firebasestorage.googleapis.com/v0/b/demobuilder-d24ed.appspot.com/o/media%2Fintro.svg?alt=media&token=98945b56-7cff-4736-9dc7-df07c1f7789c',
        layouts: [
            { name: 'layout100', title: 'Minimalist', comp: TemplateIntroLayout100 },


        ],
        elems: [
            { name: 'text1', desc: 'Description', type: 'text', visible: true, wait: 0, transition: 'animate-fade-up', text: '', placeholder: 'type a captivating description here', size: 'text-xl', align: 'text-center' },
            { name: 'button1', desc: 'Continue', type: 'button', visible: true, wait: 1000, transition: 'animate-fade', caption: 'Start', placeholder: 'Start', size: 'text-lg', },

        ],
    },



    'outro': {
        title: 'Outro',
        thumb: 'https://firebasestorage.googleapis.com/v0/b/demobuilder-d24ed.appspot.com/o/media%2Foutro.svg?alt=media&token=b234b0f8-7055-40d0-b6a2-8cacd3bd2d1b',
        layouts: [
            { name: 'layout100', title: 'Minimalist', comp: TemplateOutroLayout100 },


        ],
        elems: [
            { name: 'text1', desc: 'Description', type: 'text', visible: true, wait: 0, transition: 'animate-fade-up', text: '', placeholder: 'Thank you for watching', size: 'text-2xl', align: 'text-center' },
            { name: 'button1', desc: 'Replay', type: 'button', visible: true, wait: 1000, transition: 'animate-fade', caption: 'Replay', placeholder: 'Replay', size: 'text-base', },
            { name: 'button2', desc: 'Go To URL', type: 'button', visible: false, wait: 1000, transition: 'animate-fade', caption: '', placeholder: 'or visit our website', size: 'text-base', url: 'demo-builder.com' },

        ],
    },



    'chapter': {
        title: 'Chapter',
        thumb: 'https://firebasestorage.googleapis.com/v0/b/demobuilder-d24ed.appspot.com/o/media%2Fchapter.svg?alt=media&token=f631ead6-6ead-422e-842c-2044497660e5',
        layouts: [
            { name: 'layout100', title: 'Minimalist', comp: TemplateChapterLayout100 },


        ],
        elems: [
            { name: 'text1', desc: 'Description', type: 'text', visible: true, wait: 0, transition: 'animate-fade', text: '', placeholder: 'type a captivating chapter description here', size: 'text-xl', align: 'text-center' },

        ],
    },



    'bg-image': {
        title: 'Image',
        layouts: [
            { name: 'layout100', title: 'Minimalist', comp: TemplateBgImageLayout100 },
            { name: 'layout101', title: 'Subtitle', accents: ACCENTS_SUBTITLE, adopt: ['text1', 'button1'], comp: TemplateBgImageLayout101 },
            { name: 'layout102', title: 'Glow', accents: ACCENTS_GLOW, comp: TemplateBgImageLayout102 },
            { name: 'layout103', title: 'Billboard', comp: TemplateBgImageLayout103 },

            { name: 'layout200', title: 'Mockup - Browser', comp: TemplateBgImageLayout200 },

            { name: 'layout300', title: 'Mockup - iMac', comp: TemplateBgImageLayout300 },

            { name: 'layout400', title: 'Mockup - MacBook', comp: TemplateBgImageLayout400 },

            { name: 'layout500', title: 'Mobile', accents: ACCENTS_MOBILE, adopt: ['text1', 'button1'], comp: TemplateBgImageLayout500 },


        ],
        elems: [
            { name: 'bgimage', desc: 'Background', type: 'bg-image', visible: true, wait: 1000, naturalWidth: 0, naturalHeight: 0, crop: { left: 0, top: 0, width: 0, height: 0 }, zoom: { enabled: true, value: 1, x: 0, y: 0 }, url: '' },



            { name: 'focus1', parent: 'bgimage', desc: 'Focus', type: 'focus', visible: false, wait: 1000, transition: 'outline', bounds: { pristine: true, left: 0, top: 0, width: 200, height: 50 }, border: FOCUS_BORDER[1], cover: { enabled: true, opacity: 0.2 }, pause: false, input: { wait: 2000, bg: FOCUS_INPUT_BG[0], align: 'text-left', text: '', password: false } },

            { name: 'text1', parent: 'bgimage', desc: 'Text', type: 'text', visible: false, wait: 1000, transition: 'animate-fade-up', bounds: { pristine: true, left: 0, top: 0, width: 400 }, text: TEXT_INITIAL, bg: TEXT_BG[3], size: 'text-2xl', align: 'text-center' },

            { name: 'arrow1', parent: 'bgimage', desc: 'Arrow', type: 'arrow', visible: false, wait: 1000, transition: 'point', bounds: { pristine: true, left: 0, top: 0 }, style: 'style3', bg: ARROW_BG[1], angle: 45, scale: 1.5 },



            { name: 'button1', parent: 'bgimage', desc: 'Continue', type: 'button', visible: false, transition: 'animate-fade-right', bounds: { pristine: true, left: 0, top: 0 }, caption: 'Continue', bg: BUTTON_BG[3], size: 'text-sm', replay: false },



            { name: 'cursor1', parent: 'bgimage', desc: 'Cursor', type: 'cursor', visible: false, point1: { pristine: true, x: 0, y: 0 }, point2: { pristine: true, x: 100, y: 100 }, style: 'style1', scale: 1, path: 'curve', hili: false, effect: 'click' },

            { name: 'touch1', parent: 'bgimage', desc: 'Touch', type: 'touch', visible: false, bounds: { pristine: true, left: 0, top: 0 }, bg: TOUCH_BG[3], scale: 1 },



        ],
    },



}

export default Templates;