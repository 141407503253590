import { useState } from "react";

import classNames from "classnames";


export const Dialog = ({ className = 'w-[20rem] h-[20rem]', close, children }) => {

    const handleClose = () => {
        if (window.prevent) return;
        close();
    }

    return (
        <>
            <div
                className="animate-mask animate-fill-forwards fixed left-0 top-0 right-0 bottom-0 bg-black z-[99]"
                onClick={() => handleClose()}>
            </div>

            <div
                className="fixed left-0 top-0 right-0 bottom-[10vh] z-[100] flex items-center justify-center"
                onClick={() => handleClose()}>

                <div
                    className={classNames('animate-fade-up animate-duration-150 relative bg-white px-4 py-4 shadow-light flex flex-col', className)}
                    onClick={(e) => e.stopPropagation()}>

                    {children}

                </div>

            </div>
        </>
    );

};


export const InputDialog = ({ params }) => {

    const [text, setText] = useState(params.value);
    const [isUpdating, setIsUpdating] = useState(false);

    const handleClick = async () => {

        if (text == params.value) {
            params.close();
            return;
        }

        if (!(text || params.allowEmpty)) return;



        let result = true;

        if (params.execute) {

            setIsUpdating(true);
            result = await params.execute(text);
            setIsUpdating(false);

        }

        if (result) params.close();

    }



    const handleClose = () => {
        if (isUpdating) return;
        params.close();
    }

    const handleKeyDown = (e) => {
        if (e.keyCode == 27) params.close();
        if (e.keyCode == 13 && (text || params.allowEmpty)) handleClick();
    }

    return (
        <Dialog close={handleClose}>

            <div className="flex-1 p-4 flex flex-col items-center">

                <p className="text-center">{params.label}</p>

                <input
                    className="appearance-none w-[90%] mt-8 px-2 py-1 bg-white text-sm border-2 border-gray-200 rounded focus:outline-none focus:border-blue-500 placeholder:italic placeholder:text-gray-400"
                    placeholder=""
                    type="text"
                    maxLength="60"
                    required={true}
                    // autocomplete="off"
                    spellCheck={false}
                    autoFocus={true}
                    value={text}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => setText(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)} />

            </div>

            <div className="py-2 flex flex-col items-center">

                <button
                    className={classNames('group px-12 py-1 bg-green-500 rounded opacity-90 transition-all select-none hover:opacity-100 flex flex-row items-center justify-center space-x-2', !text && !params.allowEmpty && '!bg-gray-400 cursor-auto pointer-events-none')}
                    onClick={() => handleClick()}>
                    {isUpdating && <span className="animate-spin w-2 h-2 bg-white"></span>}
                    <span className="font-light text-white group-active:translate-y-px">{params.button}</span>
                </button>

                {/* <button className="font-light text-sm text-gray-300 mt-4">Cancel</button> */}

            </div>

            {isUpdating && <div className="fixed left-0 top-0 right-0 bottom-0 z-[9999]"></div>}

        </Dialog>
    );

};



export const ConfirmDialog = ({ params }) => {

    const [isUpdating, setIsUpdating] = useState(false);

    const handleClick = async () => {

        let result = true;

        if (params.execute) {

            setIsUpdating(true);
            result = await params.execute();
            setIsUpdating(false);

        }

        if (result) params.close();

    }

    const handleClose = () => {

        if (isUpdating) return;

        params.close();
    }


    return (
        <Dialog close={handleClose}>

            <div className="flex-1 p-4 flex flex-col items-center">

                <p className="text-center">{params.label}</p>
                {params.warning && <p className="mt-4 text-sm text-gray-500">{params.warning}</p>}

            </div>

            <div className="py-2 flex flex-col items-center">

                <button
                    className="group px-12 py-1 bg-red-500 rounded opacity-90 transition-all select-none hover:opacity-100 flex flex-row items-center justify-center space-x-2"
                    onClick={() => handleClick()}>
                    {isUpdating && <span className="animate-spin w-2 h-2 bg-white"></span>}
                    <span className="font-light text-white group-active:translate-y-px">{params.button}</span>
                </button>

                {/* <button className="font-light text-sm text-gray-300 mt-4">Cancel</button> */}

            </div>

            {isUpdating && <div className="fixed left-0 top-0 right-0 bottom-0 z-[9999]"></div>}

        </Dialog>
    );

};
