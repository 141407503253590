import { useState } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import ToolbarThumbnails from "./ToolbarThumbnails.js";
import ToolbarScene from "./ToolbarScene.js";

import Thumbnails from './Thumbnails.js';
import { EditScene } from './EditScene.js';

import Preview from "./Preview.js";
import Share from "./Share.js";
import Export from "./Export.js";




import classNames from 'classnames';





const Editor = () => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, scene } = state;

    const [active, setActive] = useState(null);

    // useEffect(() => { if (!scene) setActive(null) }, [scene]);
    // useEffect(() => { if (active  == 'play') setActive(null) }, [file]);


    useEffect(() => setActive(null), [file?.id]);



    const handleBack = () => {

        dispatch({ type: 'scenes-select', ids: [scene.id] });
        dispatch({ type: 'scene-set', scene: null });

    }



    return (
        <div className="flex-1 ml-1 flex flex-col">

            {!scene && !active &&
                <>
                    <ToolbarThumbnails onPlay={() => setActive('play')} onShare={() => setActive('share')} onExport={() => setActive('export')} />
                    <Thumbnails onEditScene={(scene) => dispatch({ type: 'scene-set', scene: scene })} />
                </>
            }

            {active == 'play' && <Preview onBack={() => setActive(null)} />}
            {active == 'share' && <Share onBack={() => setActive(null)} />}
            {active == 'export' && <Export onBack={() => setActive(null)} />}

            {scene && !active &&
                <>
                    <ToolbarScene onBack={handleBack} />
                    <EditScene />
                </>
            }

        </div>
    );

};

export default Editor;
