

import { objectsEqual } from "../utils";
import compute from "./compute";

const equal = (target, source) => !target.url.includes('placeholder.svg') && target.naturalWidth == source.naturalWidth && target.naturalHeigh == source.naturalHeigh;




export const startFrom = (scenes, index) => scenes.findLastIndex((scene, i) => scene.template != scenes[index].template && i < index);


export const can = {
    apply: (target, source) => equal(target, source),
    crop: (target, source) => equal(target, source),
    zoom: (target, source) => equal(target, source) && objectsEqual(target.crop, source.crop),
    blur: (target, source) => equal(target, source),
    bounds: (target, source) => equal(target, source),

}



export const check = {
    pan: (newX, newY, rect, zoomValue) => {
        const r1 = { x1: newX, y1: newY, x2: newX + rect.width * zoomValue, y2: newY + rect.height * zoomValue };
        const r2 = { x1: 0, y1: 0, x2: rect.width, y2: rect.height };

        if (r1.x1 > r2.x1) newX = r2.x1;
        if (r1.y1 > r2.y1) newY = r2.y1;
        if (r1.x2 < r2.x2) newX = r2.x1 - ((r1.x2 - r1.x1) - (r2.x2 - r2.x1));
        if (r1.y2 < r2.y2) newY = r2.y1 - ((r1.y2 - r1.y1) - (r2.y2 - r2.y1));

        return { newX, newY };
    },

}

export const cursor = {
    prev: (file, scene) => {
        const bgimage = scene.elems.find(elem => elem.name == 'bgimage');

        const index = file.content.scenes.findIndex(l => l.id == scene.id);
        const prevIndex = file.content.scenes.findLastIndex((l, i) => l.template == scene.template && can.apply(bgimage, l.elems.find(elem => elem.name == 'bgimage')) && i < index);

        return prevIndex > -1 ? file.content.scenes[prevIndex].elems.find(elem => elem.type == 'cursor').point2 : null;
    },
    next: (file, scene) => {
        const bgimage = scene.elems.find(elem => elem.name == 'bgimage');

        const index = file.content.scenes.findIndex(l => l.id == scene.id);
        const nextIndex = file.content.scenes.findIndex((l, i) => l.template == scene.template && can.apply(bgimage, l.elems.find(elem => elem.name == 'bgimage')) && i > index);

        return nextIndex > -1 ? file.content.scenes[nextIndex].elems.find(elem => elem.type == 'cursor').point1 : null;
    },

}

export const zoom = {
    prev: (file, scene) => {
        if (!scene) return;

        const bgImage = scene.elems.find(elem => elem.name == 'bgimage');

        const index = file.content.scenes.findIndex(l => l.id == scene.id);
        const prev = file.content.scenes.findLast((l, i) => l.template == scene.template && l.visible && i < index);

        const prevBgImage = prev ? prev.elems.find(elem => elem.name == 'bgimage') : undefined;
        return prevBgImage ? can.zoom(prevBgImage, bgImage) && prevBgImage.zoom.enabled ? prevBgImage.zoom : undefined : undefined;
    },
    next: (file, scene) => {
        if (!scene) return;

        if (!scene) return undefined;//thumb css zoom renders when no scene is selected that's why we use '?'//remove when changing in thumb

        const bgImage = scene.elems.find(elem => elem.name == 'bgimage');

        const index = file.content.scenes.findIndex(l => l.id == scene.id);
        const next = file.content.scenes.find((l, i) => l.template == scene.template && l.visible && i > index);

        const nextBgImage = next ? next.elems.find(elem => elem.name == 'bgimage') : undefined;
        return nextBgImage ? can.zoom(nextBgImage, bgImage) && nextBgImage.zoom.enabled ? nextBgImage.zoom : undefined : undefined;
    },
}


export const focus = {
    prev: (file, scene) => {
        if (!scene) return;

        const index = file.content.scenes.findIndex(l => l.id == scene.id);
        const prev = file.content.scenes.findLast((l, i) => l.template == scene.template && l.visible && i < index);

        const prevFocus = prev ? prev.elems.find(elem => elem.visible && elem.name == 'focus1') : undefined;
        return prevFocus && !prevFocus.bounds.pristine ? prevFocus : undefined;
    },
    next: (file, scene) => {
        if (!scene) return;

        const index = file.content.scenes.findIndex(l => l.id == scene.id);
        const next = file.content.scenes.find((l, i) => l.template == scene.template && l.visible && i > index);

        const nextFocus = next ? next.elems.find(elem => elem.visible && elem.name == 'focus1') : undefined;
        return nextFocus && !nextFocus.bounds.pristine ? nextFocus : undefined;
    },
}



export const get = {

    textForDetect: (file) => {

        let arr = [];

        const scenes = file.content.scenes;

        scenes.forEach(scene => {

            arr.push(scene.name);
            arr.push(scene.speak.text);

            const elems = scene.elems.filter(elem => 'text' in elem).map(elem => elem.text);

            arr.push(...elems);
        })


        const unique = [...new Set(arr.filter(item => item.length))];

        return unique.join('\n');
    },

    textForTranslate: (file) => {

        let arr = ['Scene'];

        const scenes = file.content.scenes.filter(scene => scene.visible);

        scenes.forEach(scene => {

            arr.push(scene.name);
            if (scene.speak.enabled && scene.speak.text) arr.push(scene.speak.text);

            const elems = scene.elems.filter(elem => elem.visible && ('text' in elem || 'caption' in elem)).map(elem => 'text' in elem ? elem.text : elem.caption);

            arr.push(...elems);
        })


        const unique = [...new Set(arr.filter(item => item.length))];

        return unique;
    },

    duration: (file, code) => {
        const scenes = file.content.scenes.filter(scene => scene.visible).map(scene => ({ duration: compute.calc(file, scene, code).duration }));
        return scenes.reduce((a, c) => { c.start = a; return a + c.duration; }, 0);
    },



}