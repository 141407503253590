import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';


import { ElemStatic, ElemEditableStaticText } from "../editor/Elems";


import emitter from "../emmiter";

import classNames from "classnames";


export const Layout100 = ({ motion, elems }) => {

    const dispatch = useContext(DispatchContext);


    //tood: de analizat
    //vizibil
    //placeholder
    // const url = scenes.find(scene => scene.visible && scene.template == 'bg-image')?.elems.find(elem => elem.name == 'bgimage').url;
    // console.log('url: ', url);


    const handleContinue = () => {
        if (!motion) return;
        emitter.emit('interact.continue');
    }

    const handleMouseDown = () => {
        if (motion) return;

        dispatch({ type: 'elem-set', elem: null });
    }


    //https://www.toptal.com/designers/htmlarrows/arrows/

    return (
        <div
            className="relative flex-1 flex flex-col items-center"
            onMouseDown={() => handleMouseDown()}>

            {/* <img
                className="animate-fade absolute left-1/2 top-0 w-auto h-full scale-50 grayscale opacity-80 rounded"
                src={url}
            /> */}

            <div className={classNames('mt-[20vh] px-4 w-full md:w-[30rem] flex flex-col', elems.text1.align == 'text-center' && 'items-center', elems.text1.align == 'text-right' && 'items-end')}>

                {elems.text1.visible &&
                    <div className={classNames('my-4 w-12 h-2 bg-red-500 rounded-full', motion && elems.text1.align == 'text-center' && 'animate-scale animate-duration-300')}></div>
                }

                <ElemStatic className="w-full" props={elems.text1}>

                    <ElemEditableStaticText className="dark:text-white" motion={motion} line={false} props={elems.text1} />

                </ElemStatic>

                {elems.button1 &&
                    <ElemStatic props={elems.button1}>

                        <div
                            className={classNames('inline-block mt-12 px-6 py-2 bg-blue-500 rounded shadow-md', motion && 'cursor-pointer active:translate-y-px active:!shadow-none')}
                            data-ignore="outside"
                            onClick={() => handleContinue()}>

                            <div className="flex flex-row items-center space-x-2">
                                <ElemEditableStaticText className="text-white" motion={motion} line={true} autoWidth={true} props={elems.button1} />
                                <div className={classNames('text-white', elems.button1.size)}>&#10140;</div>
                            </div>

                        </div>

                    </ElemStatic>
                }

            </div>



        </div>
    )
}