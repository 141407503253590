import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useRef } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import Loading from "../ui/Loading.js";


import DropZone from "../ui/DropZone";

import { UploadBgImage } from './Upload.js';
import { CropBgImage, BlurBgImage } from "./ImageModifiers.js";


import { storageUploadFile } from '../firebase/storage.js';


import "../cache/cache-img";

import { getBase64 } from '../utils';
import { check, zoom } from "./utils.js";
import { ZOOM_VALUES } from "../utils/consts.js";

import { useRefObserveDimensions, useDrag } from "../hooks/index.js";

import classNames from "classnames";





const useBase64 = (img, rect) => useMemo(() => img ? getBase64(img, rect) : null, [img, rect])



const useBgImage = (url, rect) => {
    const [img, setImg] = useState(null);

    useEffect(() => {

        if (!url) return;
        if (!rect) return;

        const load = async () => {

            const img = url.img();

            if (typeof img?.then === 'function') {
                const result = await img;

                setImg(result);
            }
            // else setImg(img)//leave in comment

        };

        setImg(null);
        load();

    }, [url, rect]);

    return img;
};


export const ElemBgImage = (props) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { user, file, scene, runtime } = state;


    const ref = useRef(null);

    //todo: useLayoutEffect
    const dimensions = useRefObserveDimensions(ref);


    const load = useBgImage(props.url, props.crop);
    const img = props.url.img();

    const bgImage = (typeof img?.then === 'function') ? { base64: useBase64(load, props.crop) } : { base64: useBase64(img, props.crop) };





    const min = 0.01;
    const max = 1;
    const scaleBgImage = dimensions ? Math.min(Math.max(Math.min(dimensions.width / props.crop.width, dimensions.height / props.crop.height), min), max) : 1;




    //design mode options
    const [isPanning, setIsPanning] = useState(false);
    const [panInfo, setPanInfo] = useState(null);

    const [isUploading, setIsUploading] = useState(false);

    const [showUploadBgImage, setShowUploadBgImage] = useState(false);
    const [showCropBgImage, setShowCropBgImage] = useState(false);
    const [showBlurBgImage, setShowBlurBgImage] = useState(false);


    const isEmpty = props.url.includes('placeholder.svg');
    const prevZoom = zoom.prev(file, scene);
    // const nextZoom = zoom.next(file, scene);


    const options = [
        {
            name: isEmpty ? 'Upload' : 'Replace',
            action: () => setShowUploadBgImage(true),
            delimiter: true,

        },
        {
            name: 'Crop',
            action: () => setShowCropBgImage(true),
            disabled: isEmpty,

        },
        {
            name: 'Blur',
            action: () => setShowBlurBgImage(true),
            disabled: isEmpty,
            delimiter: true,

        },
        {
            name: 'Zoom',
            disabled: isEmpty,
            delimiter: true,
            children: [
                {
                    name: 'Copy from previous scene',
                    action: () => dispatch({ type: 'scene-bgimage-zoom', zoom: { ...prevZoom } }),
                    disabled: prevZoom == undefined,
                    delimiter: true,
                },
                {
                    name: 'Apply to previous scenes',
                    action: () => dispatch({ type: 'applytoall', target: 'zoom', param: 'prev' }),
                },
                {
                    name: 'Apply to next scenes',
                    action: () => dispatch({ type: 'applytoall', target: 'zoom', param: 'next' }),
                    delimiter: true,
                },
                {
                    name: 'Apply to all scenes',
                    action: () => dispatch({ type: 'applytoall', target: 'zoom' }),
                },
            ]

        },
        {
            name: 'Same elems across scenes',
            action: () => dispatch({ type: 'applytoall', target: 'elems' }),
        },

    ];



    const onDropFiles = async (files) => {
        const f = files[0];

        setIsUploading(true);

        const url = await storageUploadFile(`users/${user.uid}/${file.id}/${scene.id}.${f.ext}`, f.data);

        if (url) {
            delete f.data;
            dispatch({ type: 'scene-bgimage-url', ...f, url });
        };

        setIsUploading(false);

    }




    useDrag(isPanning,
        (x, y) => {
            const deltaX = x - panInfo.x;
            const deltaY = y - panInfo.y;

            const { newX, newY } = check.pan(panInfo.zoom.x + deltaX * 1 / scaleBgImage, panInfo.zoom.y + deltaY * 1 / scaleBgImage, props.crop, props.zoom.value);


            dispatch({ type: 'scene-bgimage-zoom', zoom: { ...props.zoom, x: newX, y: newY } });
        },
        () => {
            setIsPanning(false);

        });

    const handleMouseDown = (e) => {
        if (props.motion) return;

        dispatch({ type: 'elem-set', elem: { ...props } })

        if (props.zoom.enabled && props.zoom.value != 1 && runtime.pan) {
            setIsPanning(true);
            setPanInfo({ x: e.clientX, y: e.clientY, zoom: { ...props.zoom } })

        }

    }

    const handleWheel = (e) => {
        if (props.motion) return;

        if (isEmpty) return;

        if (!props.zoom.enabled) return;

        const v = Math.sign(e.deltaY);

        const index = ZOOM_VALUES.indexOf(props.zoom.value);

        let newIndex = index - v;
        newIndex = Math.max(newIndex, 0);
        newIndex = Math.min(newIndex, ZOOM_VALUES.length - 1);

        const newValue = ZOOM_VALUES[newIndex];
        if (props.zoom.value == newValue) return;

        const left = dimensions.left + (dimensions.width - props.crop.width * scaleBgImage) / 2;
        const top = dimensions.top + (dimensions.height - props.crop.height * scaleBgImage) / 2;

        let lX = e.clientX - left;
        let lY = e.clientY - top;

        lX = lX * 1 / scaleBgImage * 1 / props.zoom.value;
        lY = lY * 1 / scaleBgImage * 1 / props.zoom.value;

        lX = lX - props.zoom.x * 1 / props.zoom.value;
        lY = lY - props.zoom.y * 1 / props.zoom.value;

        const deltaX = lX * newValue - lX * props.zoom.value;
        const deltaY = lY * newValue - lY * props.zoom.value;

        const { newX, newY } = check.pan(props.zoom.x - deltaX, props.zoom.y - deltaY, props.crop, newValue);

        dispatch({ type: 'scene-bgimage-zoom', zoom: { ...props.zoom, value: newValue, x: newX, y: newY } });


        if (newValue == 1) {
            if (runtime.pan) dispatch({ type: 'runtime-pan-toggle' });
        }
        else {
            if (!runtime.pan) dispatch({ type: 'runtime-pan-toggle' });
        };


    }

    const handleKeyDown = (e) => {
        if (props.motion) return;

        switch (e.keyCode) {
            case 72:
                //H - hand tool

                if (props.zoom.enabled && props.zoom.value != 1 && !isPanning) dispatch({ type: 'runtime-pan-toggle' });

                break;

            case 27:
                if (isPanning) {
                    dispatch({ type: 'scene-bgimage-zoom', zoom: { ...panInfo.zoom } });
                    setIsPanning(false);
                };

                if (props.zoom.enabled && runtime.pan) dispatch({ type: 'runtime-pan-toggle' });

                break;
        }


    };


    const handleContextMenu = (e) => {
        if (props.motion) return;

        e.preventDefault();
        if (props.zoom.enabled && runtime.pan) dispatch({ type: 'runtime-pan-toggle' });
        props.contextmenu({ x: e.clientX, y: e.clientY, sender: null, options: options });

    }



    const styleContainer = dimensions ? {
        'left': `${(dimensions.width - props.crop.width * scaleBgImage) / 2}px`,
        'top': `${(dimensions.height - props.crop.height * scaleBgImage) / 2}px`,
        'transform': `scale(${scaleBgImage})`,
    } : null;

    const stylePlaceholder = { 'width': `${props.crop.width}px`, 'height': `${props.crop.height}px`, }

    const z = props.zoom.enabled && props.zoom.value != 1 ? { 'transform': `scale(${props.zoom.value})`, 'left': `${props.zoom.x}px`, 'top': `${props.zoom.y}px`, } : null;


    const styleBgImage = { 'width': `${props.crop.width}px`, 'height': `${props.crop.height}px`, ...z };






    const children = props.children.filter(elem => elem.visible).map(elem => elem.content);


    return (
        <>
            <div
                ref={ref}
                className="absolute left-0 top-0 right-0 bottom-0 select-none">


                {!bgImage.base64 && <Loading />}


                {bgImage.base64 && dimensions &&
                    
                    // props.motion && 'overflow-clip'

                    <div
                        className="absolute origin-top-left"
                        style={styleContainer}
                        onWheel={(e) => handleWheel(e)}
                        onContextMenu={(e) => handleContextMenu(e) }>


                        <div
                            className={classNames('relative overflow-clip focus:outline-none',
                                !props.visible && 'invisible',
                                !props.motion && props.zoom.enabled && props.zoom.value != 1 && runtime.pan && 'cursor-grab',
                                !props.motion && isPanning && '!cursor-grabbing')}
                            style={stylePlaceholder}
                            tabIndex={-1}
                            data-target="bgimage"
                            onMouseDown={(e) => handleMouseDown(e)}
                            onKeyDown={(e) => handleKeyDown(e)}>

                            <img
                                className="absolute origin-top-left max-w-none max-h-none pointer-events-none"
                                style={styleBgImage}
                                src={bgImage.base64} />

               

                            {!props.motion && <DropZone noClick={!isEmpty} canPaste={isEmpty} execute={onDropFiles} />}

                        </div>

                        {children}

                        {isUploading && <Loading opaque={true} />}

                    </div>

                }
            </div>

            {showUploadBgImage && <UploadBgImage close={() => setShowUploadBgImage(false)} />}
            {showCropBgImage && <CropBgImage close={() => setShowCropBgImage(false)} />}
            {showBlurBgImage && <BlurBgImage close={() => setShowBlurBgImage(false)} />}

            {/* contextmenu - avoid drop-shadow-md to parent */}
        </>


    );

};

export default ElemBgImage;
