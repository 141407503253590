import { useState } from "react";
import { useEffect } from "react";

import { ButtonFlatIcon, ButtonWithModal } from "./Buttons";

import classNames from "classnames";


const useSubmenu = (i) => {
    const [index, setIndex] = useState(null);

    useEffect(() => {

        const timeout = setTimeout(() => setIndex(i), 200);
        return () => clearTimeout(timeout);

    }, [i]);

    return index;
}


const MoreOptions = ({ className, light, place = 'br', options }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [index, setIndex] = useState(null);
    const submenu = useSubmenu(index);


    const handleClick = (option) => {

        if (option.disabled) return;
        if (option.children) return;

        option.action(option);
        setIsModalVisible(false);
        setIndex(null);

    }

    const handleMouseEnter = (parent, item, index) => {
        if (parent) return;

        if (item.children) setIndex(index); else setIndex(null);

    }

    //todo: key must be analyzed

    const listItems = (parent, items) => items.map((item, index) =>
        <li className={classNames('context-menu-item', item.disabled && 'disabled', item.delimiter && 'delimiter')}
            key={item.tag ? item.tag : index}
            onClick={(e) => { e.stopPropagation(); handleClick(item); }}
            onMouseEnter={() => handleMouseEnter(parent, item, index)}
            onContextMenu={(e) => e.preventDefault()}>

            <span className="flex-1 text-nowrap">{item.name}</span>

            {item.children &&
                <>
                    <svg
                        className={classNames('ml-2 w-3 h-3', item.disabled && 'opacity-50')}
                        width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1997 10.4919L13.2297 8.52188L10.0197 5.31188C9.33969 4.64188 8.17969 5.12188 8.17969 6.08188V12.3119V17.9219C8.17969 18.8819 9.33969 19.3619 10.0197 18.6819L15.1997 13.5019C16.0297 12.6819 16.0297 11.3219 15.1997 10.4919Z" fill="white" />
                    </svg>


                    <ul className={classNames('context-menu absolute z-10 right-0 top-0 translate-x-full hidden', submenu == index && 'animate-fade animate-duration-75 !block')}>
                        {listItems(item, item.children)}
                    </ul>
                </>
            }


        </li>
    );




    return (
        <ButtonWithModal className={classNames('h-full', className)} place={place} isModalVisible={isModalVisible} setIsModalVisible={(v) => { setIndex(null); setIsModalVisible(v) }}>

            <ButtonFlatIcon
                classNameGroup={classNames('!px-1', !light && isModalVisible && '!bg-slate-600', light && isModalVisible && '!bg-gray-300', light && 'hover:!bg-gray-300')}
                className={classNames('h-4', isModalVisible && 'translate-y-px', light && 'filter-black')}
                svg="more-options.svg" />

            <ul className="context-menu">
                {listItems(null, options)}
            </ul>

        </ButtonWithModal>
    );

};

export default MoreOptions;