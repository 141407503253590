import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';


import { forwardRef, useImperativeHandle } from "react";

import useWebSocket, { ReadyState } from "react-use-websocket"


import customProtocolCheck from "custom-protocol-check";

import { base64Compress, extract } from "./utils";
import { uniqueId, actions } from "../editor/actions.js";

import { storageUploadFiles } from "../firebase/storage.js";

import { Dialog } from "../dialogs";

import Loading from "../ui/Loading";

import classNames from "classnames";


const DBR_SETUP = 'dbrsetup.exe';
const DBR_LATEST_VERSION = '0.0.1.17';


const useCustomProtocol = (condition, wait = 1000) => {

    //wait, maybe it's already started

    const [protocol, setProtocol] = useState(undefined);

    useEffect(() => {
        if (!condition) return;

        const check = () => {

            customProtocolCheck(
                'dbr://',
                () => {
                    // console.log("Custom protocol not found.");
                    setProtocol(false);
                },
                () => {
                    // console.log("Custom protocol found and opened the file successfully.");
                    setProtocol(true);
                },
                500 //maybe it's already started
            );
        }

        const timer = setTimeout(() => check(), wait);

        return () => clearTimeout(timer);

    }, [condition])


    return protocol;

}

const useConnectTimeout = (condition) => {

    const [flag, setFlag] = useState(false);

    useEffect(() => {
        if (!condition) return;

        const timer = setTimeout(
            () => setFlag(true),
            10000//wait for connecting to recorder
        );

        return () => clearTimeout(timer);

    }, [condition])


    return flag;
}


const Row = ({ caption, value, button }) => {

    return (
        <div className="h-6 pb-2 border-b border-b-gray-100 flex flex-row items-center">
            <div className="w-52 text-sm">{caption}</div>
            <div className="w-24 text-sm">{value}</div>
            {button &&
                <button
                    className={classNames('group px-4 py-1 rounded flex flex-row items-center justify-center', button.className)}
                    onClick={() => button.action()}>
                    <span className="text-sm text-white group-active:translate-y-px">{button.caption}</span>
                </button>
            }
        </div>);
}



export const RecoderFrame = forwardRef(({ cancel, done }, ref) => {

    const WS_URL = 'ws://127.0.0.1:8047';
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        WS_URL,
        {
            share: false,
            shouldReconnect: () => true,
            reconnectAttempts: 1000,
            reconnectInterval: 1000,
        }
    )


    const [version, setVersion] = useState(null);
    const [status, setStatus] = useState('connecting');
    const [captures, setCaptures] = useState([]);

    const [name, setName] = useState('');


    const protocol = useCustomProtocol(readyState !== ReadyState.OPEN);
    const timeout = useConnectTimeout(protocol === true);

    useImperativeHandle(ref, () => ({
        getStatus: () => status,
        close: () => {
            //using this method when the user goes back
            //need to do with imperative handle, as on unmount websocket is closed
            if (readyState === ReadyState.OPEN) sendJsonMessage('close');
        },
    }));


    useEffect(() => {
        if (!lastJsonMessage) return;

        const [msg, data] = lastJsonMessage;

        switch (msg) {
            case 'welcome':
                const { version } = data;
                setVersion(version);
                setStatus('ready');
                break;

            case 'start':
                setStatus('recording');
                break;

            case 'capture':
                setCaptures(prev => [...prev, data]);
                break;

            case 'stop':
                setStatus('stopped');
                break;

            case 'resume':
                setStatus('recording');
                break;

            case 'restart':
                setStatus('ready');
                setCaptures([]);
                break;

            case 'cancel':
                setStatus('cancelled');
                setCaptures([]);

                cancel();
                break;

            case 'done':
                setStatus('done');

                done(name, captures);
                break;


            case 'closing':
                setStatus('');
                if (!captures.length) cancel();
                break;

            case 'foreground':
                const { title } = data;
                setName(title);
                break;


        }


    }, [lastJsonMessage])





    //todo: check why it does not install the latest version without uninstalling the old one
    const upgrade = {
        caption: 'Upgrade',
        className: 'bg-blue-500',
        action: () => window.open(DBR_SETUP, '_blank')
    }

    const stop = {
        caption: 'Stop',
        className: 'bg-red-500',
        action: () => {
            sendJsonMessage('close');
            done(name, captures);
        }
    }




    return (
        <div className="flex-1 mt-8 flex flex-col">

            {readyState != ReadyState.OPEN && protocol === undefined && <span className="animate-spin ml-1 w-2 h-2 bg-black"></span>}

            {readyState != ReadyState.OPEN && protocol === true && !timeout && <p className="animate-fade animate-duration-100 animate-delay-1000 text-sm">Click the 'Open recorder' button to allow the browser to launch the recorder.</p>}

            {readyState != ReadyState.OPEN && protocol === true && timeout && status != 'done' &&
                <>
                    <p className="text-sm">Demo Builder Recorder is encountering connectivity issues.<br />Here are some potential solutions:</p>

                    <ul className="ml-6 mt-4 list-disc">
                        <li className="text-sm">Disable your firewall</li>
                        <li className="text-sm">Add Recorder to your antivirus whitelist</li>
                        <li className="text-sm">Reinstall <a className="text-sm text-blue-500 underline-offset-2 decoration decoration-blue-500 hover:underline" href={DBR_SETUP}>Recorder</a></li>
                    </ul>

                </>
            }

            {readyState != ReadyState.OPEN && protocol === false &&

                <div>
                    <h1 className="text-sm">Install Demo Builder Recorder for Windows</h1>
                    {/* <p className="text-sm">To record your screen, you need to download and install Recorder on your computer.</p> */}
                    <a className="mt-8 text-sm text-blue-500 underline underline-offset-2 decoration decoration-blue-500" href={DBR_SETUP}>Download</a>

                    <p className="mt-6 text-xs text-gray-400">
                        When prompted, save the file to a convenient location on your computer.<br />
                        Open the file, then the setup program will guide you through the rest of the process.
                    </p>

                </div>
            }


            {readyState === ReadyState.OPEN &&

                <>
                    <div className="flex flex-col items-start space-y-2">

                        <Row key="recorder" caption="Recorder" value={version ? `v${version}` : '-'} button={version && version != DBR_LATEST_VERSION ? upgrade : null} />
                        <Row key="status" caption="Status" value={status} />
                        <Row key="session" caption="Screenshots" value={captures.length} button={status == 'recording' ? stop : null} />

                    </div>
                </>
            }




        </div >
    )
})


export const addScenesFromCaptures = async (preferences, dispatch, uid, fileId, captures, index = undefined) => {

    const promises = captures.map(({ image }) => base64Compress(image));
    const files = await Promise.all(promises);

    const arr = files.map(f => { const id = uniqueId(); return { ...f, id, path: `users/${uid}/${fileId}/${id}.${f.ext}` } });

    const result = await storageUploadFiles(arr);
    if (!result) return;

    const extracted = extract(captures);

    const fs = result.map(({ name, id, naturalWidth, naturalHeight, url }, i) => ({ name, id, naturalWidth, naturalHeight, url, ...extracted[i] }));
    actions.addScenesFromImages(preferences, dispatch, index, fs);

    return true;
}


export const Record = ({ index = undefined, close }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { preferences, user, file } = state;


    const [isUpdating, setIsUpdating] = useState(false);


    const refRecorder = useRef(null);

    const addScenesFromRecording = async (name, captures) => {

        setIsUpdating(true);

        const result = await addScenesFromCaptures(preferences, dispatch, user.uid, file.id, captures, index);

        if (result) close();

        setIsUpdating(false);

    }


    const handleClose = () => {

        if (refRecorder.current.getStatus() == 'recording') return;

        if (isUpdating) return;


        refRecorder.current.close();

        close();
    }

    return (
        <Dialog
            className="w-[36rem] h-[28rem] !p-6 select-none flex flex-col"
            close={handleClose}>

            <h1 className="font-semibold">Record the screen</h1>

            {!isUpdating && <RecoderFrame cancel={handleClose} done={(name, captures) => addScenesFromRecording(name, captures)} ref={refRecorder} />}

            {isUpdating && <Loading />}

        </Dialog>
    )

}
