
import { useState } from "react";
import { useEffect } from "react";

import classNames from "classnames";


//tooltip requires parent to have 'group' and 'relative'

const Tooltip = ({ children }) => {

    //group-focus:!invisible

    if (!children) return;

    return (
        <div className="absolute z-[9999] left-0 bottom-0 px-2 py-1 bg-yellow-300 text-xs text-nowrap rounded shadow pointer-events-none translate-y-[calc(100%+0.2rem)] invisible group-hover:!visible group-hover:animate-fade group-hover:animate-duration-75 group-hover:animate-delay-700">

            {children}

        </div>
    );

};

export default Tooltip;
