import { useState } from "react";

import classNames from "classnames";


export const faqPlans = [

    {
        q: 'What is the difference between the STANDBY and CREATOR plans?',
        a: 'When you begin creating videos, you\'ll need to convert text to audio. Once the text is converted to audio, you won\'t need as much credit per month for text-to-audio because it\'s already been converted. So, after you create your videos, in the next month, you can safely downgrade to STANDBY and save money.'
    },
    {
        q: 'Who\'s handling my order?',
        a: 'Stripe.com is processing credit card payments, which is much safer than using your credit card at a local store.'
    },
    {
        q: 'At which point in time can I cancel my subscription?',
        a: 'You can cancel your subscription at any time. At the next billing cycle we will not resume your subscription.'
    },
    {
        q: 'What is the billing interval?',
        a: 'We bill you on a monthly basis starting from the day you subscribed, you can cancel your subscription at any time.'
    },

    {
        q: 'Is the content I create permissible for commercial use?',
        a: 'Yes, the content you generate can be used for commercial purposes, but only if you are subscribed to a plan.'
    },


    {
        q: 'How are characters counted for text-to-audio?',
        a: 'You are limited by the number of characters you convert to audio. It doesn\'t matter which language or voice you select. This counting happens only once; in other words, if the text has been converted to audio once, you are not counted again whenever you use that text.'
    },

    {
        q: 'What happens to my unused characters at the end of the month?',
        a: 'Unused characters do not roll over into the next month. Your subscription will automatically renew with each billing cycle and your characters will reset. However, if you upgrade to a higher plan, any unused characters from your previous plan will roll over into the next billing cycle.'
    },



]



const Faq = ({ list = [] }) => {

    const [expanded, setExpanded] = useState([0]);

    const toggle = (index) => setExpanded(prev => prev.includes(index) ? [...prev.filter(v => v != index)] : [...prev, index]);

    const listQuestions = list.map(({ q, a }, index) =>
        <div
            className="px-2 py-1 bg-white rounded flex flex-row items-start"
            key={q}>

            <div className="flex-1">
                <p
                    className={classNames('text-gray-700 opacity-60 cursor-pointer transition-opacity duration-150 hover:!opacity-100', expanded.includes(index) && '!opacity-100 !text-blue-400')}
                    onClick={() => toggle(index)}>
                    {q}
                </p>
                {expanded.includes(index) && <p className="my-2 text-gray-900">{a}</p>}

            </div>

            <button
                className="p-1 flex flex-row items-center justify-center"
                onClick={() => toggle(index)}>
                <img
                    className={classNames('w-2 rotate-180', expanded.includes(index) && '!rotate-0 filter-white')}
                    src={require("../assets/svg/minimize.svg").default} />

            </button>

        </div >

    )

    return (
        <div className="flex-1 p-0 bg-neutral-50 overflow-y-auto scroll-smooth">
            <div className="h-0 flex flex-col space-y-2">
                {listQuestions}
            </div>
        </div>
    )

}


export default Faq;