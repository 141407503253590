import { storage } from "../config/firebase";
import { ref, uploadBytes, getDownloadURL, deleteObject, listAll } from "firebase/storage";



// ─── Files ───────────────────────────────────────────────────────────────────

export const storageUploadFile = async (path, data) => {

    const filesFolderRef = ref(storage, path);
    try {
        const snapshot = await uploadBytes(filesFolderRef, data);
        const url = await getDownloadURL(snapshot.ref);//este persistent?

        return url;

    } catch (err) {
        console.error(err);
    }

    return null;

}

export const storageUploadFiles = async (files) => {

    const promises = files.map(file => new Promise((resolve, reject) => {

        uploadBytes(ref(storage, file.path), file.data).then(
            (snapshot) => {

                getDownloadURL(snapshot.ref).then(
                    (url) => {

                        resolve({ ...file, url });

                    },
                    () => {
                        reject()

                    }
                )

            },
            () => {
                reject();
            }
        )

    }));


    try {
        const arr = await Promise.all(promises);
        return arr;
    }
    catch (err) {
        console.log(err);
    }

    return null;


}

export const storageDeleteFile = async (file) => {

    try {
        const fileRef = ref(storage, file);
        await deleteObject(fileRef);

        return true;

    } catch (err) {
        console.log(err);

    }


    return false;

}

// ─── Folders ─────────────────────────────────────────────────────────────────

export const storageDeleteFolder = async (folder) => {


    try {
        const listRef = ref(storage, folder);

        const result = await listAll(listRef);
        if (!result?.items) return true;

        const promises = result.items.map(fileRef => new Promise(async (resolve, reject) => {
            try {
                await deleteObject(fileRef);
                resolve(true);

            } catch (err) {
                console.log(err);
                reject();
            }

        }));

        const response = await Promise.all(promises);
        return response.every(v => v);


    } catch (err) {
        console.log(err);

    }


    return false;


}