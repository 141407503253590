import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';


import { ElemStatic, ElemEditableStaticText } from "../editor/Elems";


import emitter from "../emmiter";

import classNames from "classnames";




export const Layout100 = ({ motion, elems }) => {

    const dispatch = useContext(DispatchContext);



    const handleReplay = () => {
        if (!motion) return;
        emitter.emit('replay');
    }

    const handleOpen = (url) => {
        if (!motion) return;

        if (!url) return;
        if (!url.startsWith('http')) url = `https://${url}`;

        const href = window.location.href;
        const player = href.includes('/play/');
        const param = player ? '_self' : '_blank';

        window.open(url, param);
    }


    const handleMouseDown = () => {
        if (motion) return;

        dispatch({ type: 'elem-set', elem: null });
    }


    //https://www.toptal.com/designers/htmlarrows/arrows/

    return (
        <div
            className="relative flex-1 flex flex-col items-center"
            onMouseDown={() => handleMouseDown()}>

            <div className={classNames('mt-[20vh] px-4 w-full md:w-[35rem] flex flex-col', elems.text1.align == 'text-left' && 'items-start', elems.text1.align == 'text-center' && 'items-center', elems.text1.align == 'text-right' && 'items-end')}>




                <ElemStatic className="w-full" props={elems.text1}>

                    <ElemEditableStaticText className="mb-14 font-bold uppercase dark:text-white" motion={motion} line={false} props={elems.text1} />

                </ElemStatic>




                {elems.button1 &&
                    <ElemStatic props={elems.button1}>

                        <div
                            className={classNames('mb-8 px-6 py-2 bg-red-500 rounded shadow-sm', motion && 'cursor-pointer active:translate-y-px active:!shadow-none')}
                            data-ignore="outside"
                            onClick={() => handleReplay()}>
                            
                            <div className="flex flex-row items-center space-x-2">
                                <div className={classNames('text-white', elems.button1.size)}>&#8634;</div>
                                <ElemEditableStaticText className="font-semibold text-white" motion={motion} line={true} autoWidth={true} props={elems.button1} />
                            </div>

                        </div>

                    </ElemStatic>
                }

                {elems.button2 &&
                    <ElemStatic ElemStatic props={elems.button2}>

                        <div
                            className={classNames('group flex flex-row items-center', motion && 'cursor-pointer')}
                            data-ignore="outside"
                            onClick={() => handleOpen(elems.button2.url)}>
                            <ElemEditableStaticText className={classNames('text-black dark:text-white italic underline underline-offset-4 decoration-solid decoration-blue-500 opacity-80', motion && 'group-hover:!text-blue-500 group-hover:!opacity-100')} motion={motion} line={true} autoWidth={true} props={elems.button2} />
                     
                            {elems.button2.url.includes('/play/') &&
                                <div className={classNames('text-black dark:text-white underline underline-offset-4 decoration-solid decoration-blue-500 opacity-80', elems.button2.size, motion && 'group-hover:!text-blue-500 group-hover:!opacity-100')}>&nbsp;&#x2794;</div>
                            }

                        </div>

                    </ElemStatic>
                }







            </div>



        </div >
    )
}