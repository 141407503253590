import { useState } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import { fnCancelSubscription, fnStopPendingCancellation, fnChangeSubscription } from "../firebase/functions";


import { useCheckoutSession } from "../hooks/subscribe";

import Info from "../ui/Info";

import { prettify, audioCharsDuration } from "./utils";


import classNames from "classnames";




const Credits = ({ plan }) => {

    const metadata = { ...plan.metadata };
    Object.keys(metadata).forEach(key => metadata[key] = Number(metadata[key]));

    const pretty = prettify(metadata, 'unlimited');


    const list = Object.keys(pretty).map(key =>
        <div
            className="my-0.5 py-1 border-b border-b-gray-200 grid grid-cols-2 gap-3"
            key={key}>
            <div className="text-sm">{pretty[key].caption}</div>

            <div className="flex flex-row items-center space-x-2">
                <div className="flex-1 text-sm">{pretty[key].value}</div>
                {key == 'tts' && metadata[key] && <Info text={audioCharsDuration(metadata[key])} />}
            </div>

        </div>
    )

    return (
        <div className="mt-4 w-full">
            {list}
        </div>
    )



}

const Plans = () => {

    const state = useContext(StateContext);

    const { user, subscription, plans } = state;

    const interval = 'month';

    const [isUpdating, setIsUpdating] = useState(null);


    const [checkout, setCheckout] = useState(null);
    const url = useCheckoutSession(user.uid, checkout);

    if (url) window.location.assign(url);
    // if (url) window.open(url);


    const handleClick = async (plan) => {

        window.prevent = true;
        setIsUpdating(plan.id);

        const isSubscribed = subscribed(plan);

        if (isSubscribed) {

            if (subscription.cancel_at_period_end) {

                await fnStopPendingCancellation(subscription.id);

                setIsUpdating(null);
                window.prevent = false;

            }
            else {

                await fnCancelSubscription(subscription.id);

                setIsUpdating(null);
                window.prevent = false;

            }




        }
        else {

            const p = price(plan);
            if (!p) return;

            if (subscription) {

                // console.log('change subscription');

                await fnChangeSubscription(subscription.id, subscription.subItemId, p.id, plan.metadata);

                setIsUpdating(null);
                window.prevent = false;

            }

            else {

                // console.log('new subscription');

                setCheckout({ pid: p.id, metadata: plan.metadata });

            }
        }


    }

    const sp = subscription && subscription.status == 'active' ? plans.find(({ id }) => id == subscription.product) : null;
    const subscribed = (plan) => plan.id == sp?.id;

    const price = (plan) => {
        if (!plan) return null;
        if (!plan.prices) return null;

        const p = plan.prices.find(({ recurring }) => recurring.interval == interval);
        return p ? { id: p.id, value: Math.round(p.unit_amount / 100) } : null;
    }


    const caption = (plan) => subscribed(plan) ? subscription.cancel_at_period_end ? 'Stop Pending Cancellation' : 'Cancel Subscription' : subscription && sp ? price(sp).value < price(plan)?.value ? 'Upgrade' : 'Downgrade' : 'Subscribe';



    //business plan

    const [businessPlan, setBusinessPlan] = useState(false);
    const list = plans.filter(plan => plan.name == 'Business' ? businessPlan || subscribed(plan) : true);

    const handleBusinessPlanClick = (e) => { if (e.shiftKey) setBusinessPlan(true); };



    const listPlans = list.map(plan =>
        <div
            className={classNames('p-2 border border-gray-100 flex flex-col items-start', subscribed(plan) && '!bg-gray-50')}
            key={plan.id}>

            <h1 className="font-semibold text-sm uppercase">{plan.name}</h1>

            <div className="mt-4 flex flex-row items-start">
                <span className="pt-1.5 font-serif text-sm">$</span>
                <span className="font-serif text-3xl">{price(plan)?.value}</span>
                <span className="self-end ml-2 text-sm">per {interval}</span>
            </div>

            {/* <p className="h-[3rem] text-sm text-gray-500">{plan.description}</p> */}


            <button
                className={classNames('mt-8 group px-2 py-1 bg-red-500 rounded flex flex-row items-center justify-center space-x-2', !subscribed(plan) && '!bg-green-500')}
                onClick={() => handleClick(plan)}>
                {isUpdating == plan.id && !url && <span className="animate-spin w-2 h-2 bg-white"></span>}
                <span className="text-sm text-white group-active:translate-y-px">{caption(plan)}</span>
            </button>

            <p className="mt-6">Limits per {interval}</p>

            <Credits plan={plan} />

            <small className="mt-auto text-gray-500">Prices are tax-exclusive.</small>

        </div>
    )

    return (
        <>
            <div
                className="flex-1 flex flex-col"
                onClick={(e) => handleBusinessPlanClick(e)}>

                <div className="flex-1 grid grid-cols-3 gap-2">
                    {listPlans}
                </div>

            </div>

            {isUpdating && <div className="fixed left-0 top-0 right-0 bottom-0 z-[9999]"></div>}


        </>
    )


}






export default Plans;