import { useState } from "react";
import { useEffect } from 'react';
import { useRef } from 'react';

import { useDrag } from "../hooks";

import Tooltip from "./Tooltip";

import classNames from 'classnames';




const Slider = ({ className, tooltip, min, max, unit, value, setValue }) => {

    const ref = useRef(null);

    const [isDragging, setIsDragging] = useState(false);


    useDrag(isDragging, (x) => updateValue(x), () => setIsDragging(false));

    const handleMouseDown = (e) => {
        e.stopPropagation();

        updateValue(e.clientX);
        setIsDragging(true);
    };

    const updateValue = (x) => {
        const r = ref.current.getBoundingClientRect();

        let newValue = Math.round(min + ((x - r.left) / r.width) * (max - min));
        newValue = Math.max(newValue, min);
        newValue = Math.min(newValue, max);

        setValue(newValue);
    };

    const l = Math.round(((value - min) / (max - min)) * 100);
    const style1 = { width: `${l}%` };
    const style2 = { left: `${l}%` };


    return (
        <div className={classNames('relative group w-24 h-full select-none flex flex-row items-center', className)}>

            <div
                ref={ref}
                className="relative mx-1 h-full flex-1"
                onMouseDown={(e) => handleMouseDown(e)}>

                <span className="absolute z-10 left-0 right-0 top-1/2 h-0.5 bg-slate-600 pointer-events-none -translate-y-1/2"></span>
                <span className="absolute z-10 left-0 top-1/2 h-0.5 bg-orange-600 pointer-events-none -translate-y-1/2" style={style1}></span>

                <span
                    className={classNames('absolute z-20 top-1/2 w-3 h-3 bg-slate-600 border-2 border-orange-500 rounded-full -translate-x-1/2 -translate-y-1/2 hover:!bg-black', isDragging && '!bg-black')}
                    style={style2}
                    onMouseDown={(e) => { handleMouseDown(e); }}>
                </span>

            </div>

            {unit &&
                <div className="ml-2 w-10 text-xs text-gray-500">
                    {value}{unit}
                </div>
            }

            {tooltip && <Tooltip>{tooltip}</Tooltip>}

        </div>
    );

};

export default Slider;
