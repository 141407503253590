
import { useState } from "react";

import { Dialog } from ".";

import classNames from "classnames";

const Tabs = ({ data, selected, change }) => {

    const listTabs = Object.keys(data).map(key =>
        <li
            className={classNames('pl-2 pr-6 py-1 text-white rounded-tr opacity-70 cursor-pointer', selected == key && ' bg-white !text-black !opacity-100')}
            key={key}
            onClick={() => change(key)}>

            <span className="text-sm">{data[key].caption}</span>

        </li>
    )

    return (
        <ul
            className="flex flex-row items-center">
            {listTabs}
        </ul>
    )
}


const DialogWithTabs = ({ className, tabs, close }) => {


    const [selected, setSelected] = useState(Object.keys(tabs)[0]);

    const listTabs = Object.keys(tabs).map(key =>
        <div
            className={classNames('hidden p-2 bg-white', key == selected && 'flex-1 !flex flex-col')}
            key={key}>
            {tabs[key].comp}
        </div>
    )

    return (
        <Dialog
            className={classNames('w-[49.5rem] h-[36rem] !bg-slate-800 !p-2 select-none flex flex-col', className)}
            close={close}>

            <Tabs data={tabs} selected={selected} change={(v) => setSelected(v)} />

            {listTabs}

        </Dialog>
    )



}



export default DialogWithTabs;