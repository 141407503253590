

import { Dialog } from "../dialogs";

import { RUNNING_VERSION } from "../platform";

import { usePlatformVersion } from "../hooks/subscribe";


import classNames from "classnames";



const About = ({ close }) => {

    const version = usePlatformVersion(RUNNING_VERSION);

    const year = new Date().getFullYear();

    const handleClick = () => {
        location.reload();
    }


    return (
        <Dialog
            className="w-[26rem] h-[18rem] !p-8 select-none flex flex-col"
            close={close}>

            <a href="http://www.demo-builder.com" target="_blank">
                <img
                    className="w-28 drop-shadow-sm"
                    src={require("../assets/svg/logo.svg").default} />
            </a>

            <div className="flex-1 flx flex-col">
                <h1 className="mt-6 font-light text-xs">Running version</h1>
                <p className="font-semibold text-sm uppercase text-slate-900">{RUNNING_VERSION}</p>

                {version == RUNNING_VERSION &&
                    <p className="mt-8">Your version is up to date.</p>
                }

                {version != RUNNING_VERSION &&
                    <p className="mt-8">A new version is available. <a className="text-blue-500 underline-offset-2 uppercase hover:underline" href="#" onClick={() => handleClick()}>Refresh</a></p>
                }


            </div>

            <p className="text-xs text-gray-300">{year} Tango Delta</p>


        </Dialog >
    )

}


export default About;