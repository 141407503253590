
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import { useParams } from "react-router-dom";

import { fnGetFile } from "../firebase/functions";

import { loadLang, loadLanguages, loadImg, loadAudio, Loader } from "../editor/Loader";
import { fileTranslate, Controller } from "../editor/Controller";

import { APP_NAME } from "../utils/consts";

import { setData } from "./rec";

const Loading = ({ message }) => {

    return (
        <div className="flex-1 flex flex-col items-center">
            <div className="mt-[20vh]">
                <div className="h-16"></div>
                {!message &&
                    <svg
                        className="animate-fade mt-6 w-14 h-14"
                        width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="black">
                        <g fill="none" fillRule="evenodd" strokeWidth="2">
                            <circle cx="22" cy="22" r="1">
                                <animate attributeName="r"
                                    begin="0s" dur="1.8s"
                                    values="1; 20"
                                    calcMode="spline"
                                    keyTimes="0; 1"
                                    keySplines="0.165, 0.84, 0.44, 1"
                                    repeatCount="indefinite" />
                                <animate attributeName="stroke-opacity"
                                    begin="0s" dur="1.8s"
                                    values="1; 0"
                                    calcMode="spline"
                                    keyTimes="0; 1"
                                    keySplines="0.3, 0.61, 0.355, 1"
                                    repeatCount="indefinite" />
                            </circle>
                            <circle cx="22" cy="22" r="1">
                                <animate attributeName="r"
                                    begin="-0.9s" dur="1.8s"
                                    values="1; 20"
                                    calcMode="spline"
                                    keyTimes="0; 1"
                                    keySplines="0.165, 0.84, 0.44, 1"
                                    repeatCount="indefinite" />
                                <animate attributeName="stroke-opacity"
                                    begin="-0.9s" dur="1.8s"
                                    values="1; 0"
                                    calcMode="spline"
                                    keyTimes="0; 1"
                                    keySplines="0.3, 0.61, 0.355, 1"
                                    repeatCount="indefinite" />
                            </circle>
                        </g>
                    </svg>
                }
                {message && <span className="text-sm">{message}</span>}
            </div>
        </div>
    )
}






const useLoad = (id, dispatch) => {

    const [loading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!id) return;

        const load = async () => {
            const response = await fnGetFile(id, !window.rec);
            // console.log('response: ', response);

            if (response)
                dispatch({ type: 'file-set', file: response });


            setIsLoading(false);
        }

        load();


    }, [id])

    return loading;

};

export const useResources = (file, initialCode) => {

    const [loading, setIsLoading] = useState(true);

    const [lang, setLang] = useState(null);
    const [languages, setLanguages] = useState({ source: [], target: [] });

    const [code, setCode] = useState(null);


    useEffect(() => {
        if (!file) return;

        setIsLoading(true);

        const load = async () => {


            let lCode = code;


            //player

            if (initialCode) {

                const p1 = loadLang(file);
                const p2 = loadLanguages();

                const result = await Promise.all([p1, p2]);

                // const detect = null;
                const detect = result[0];

                const { source, target } = result[1];

                // const sameLanguage = detect?.code == initialCode;

                const sourceLangExists = !detect || source.find(item => item.code == detect?.code);
                const targetLangExists = target.find(item => item.code == initialCode);

                // lCode = sameLanguage || !sourceLangExists || !targetLangExists ? null : initialCode;
                lCode = !sourceLangExists || !targetLangExists ? null : initialCode;


                const t = sourceLangExists ? await fileTranslate(file, detect?.code, lCode) : false;
                if (!t) lCode = null;


                setCode(lCode);

                setLang(detect);
                setLanguages(target);

            }



            const p3 = loadImg(file);
            const p4 = loadAudio(file, lCode);

            await Promise.all([p3, p4]);




            setIsLoading(false);
        }

        load();


    }, [file, initialCode])



    return [loading, lang, languages, code];

}

const userAgent = () => {

    //e.g. RECORDER:en

    const ua = window.navigator.userAgent;

    const rec = ua.startsWith('RECORDER');

    const part = rec ? ua.split(':')[1] : null;
    const code = part ? part : null;

    return { rec, c: code };

}


const useIntroPlayButton = (file, setConfirmation) => {

    useEffect(() => {
        if (!file) return;
        if (window.rec) return;

        const scene = file.content.scenes.find(scene => scene.visible);
        if (scene?.template != 'intro') return;

        const v = !scene.speak.enabled && scene.elems.find(({ name }) => name == 'button1').visible;

        setConfirmation(v);

    }, [file]);

}

const Player = () => {

    const { id } = useParams();

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file } = state;

    document.title = file ? file.name : APP_NAME;


    const { rec, c } = userAgent();
    window.rec = rec;


    const loading1 = useLoad(id, dispatch);


    //todo: caz cind nu are ce traduce

    // const arr = fileTextArr(file);
    const autoTranslate = (rec && c) || file?.settings.share.translate;// arr.length;

    const userLanguage = rec && c ? c : navigator.language || navigator.userLanguage;
    const initialCode = autoTranslate ? userLanguage : null;



    const [loading2, lang, languages, code] = useResources(file, initialCode);



    //todo: caz cind nu are scene


    const [confirmation, setConfirmation] = useState(window.rec);
    useIntroPlayButton(file, setConfirmation);



    if (window.rec && file && !loading2) setData(file, code);


    return (
        <div className="relative w-screen h-screen bg-[#f7f7f7] flex flex-col">

            {window.rec && !loading1 && file && !loading2 && confirmation && <div id="start" className="fixed left-0 top-0 invisible">START {window.navigator.userAgent}</div>}

            {(loading1 || (!loading1 && !file)) && <Loading message={loading1 ? null : 'Uh oh! It seems we couldn\'t find this movie.'} />}
            {!loading1 && file && (loading2 || !confirmation) && <Loader loading={loading2} name={file.name} mode={file.settings.mode} button={!loading2 && !confirmation} callback={() => setConfirmation(true)} />}
            {!loading1 && file && !loading2 && confirmation && <Controller key={file.id} pilot={!window.rec && file.settings.share.pilot} toc={!window.rec && file.settings.share.toc} lang={lang} languages={languages} translate={!window.rec && autoTranslate && code} initialCode={code} />}

        </div>
    )


}


export default Player;