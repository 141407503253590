import { useState } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import { Timestamp } from "firebase/firestore";

import { dbDeleteJob } from "../firebase/database";

import { useCredits, useJobs } from "../hooks/subscribe";

import { storageDeleteFile } from "../firebase/storage";

import ToolbarExport from "./ToolbarExport";

import { tailwindColorToHex } from "../utils";

import { JOB_EXT } from "../utils/consts";


import moment from "moment";

import classNames from "classnames";



const Job = ({ id, w, h, code, url, status, created }) => {

    const state = useContext(StateContext);

    const { user, file, languages } = state;

    const { target } = languages;


    // const canRemove = true;
    const canRemove = ['pending', 'error', 'ready'].includes(status);


    const remove = async () => {

        if (!canRemove) return;

        await dbDeleteJob(id);

        if (status == 'ready')
            await storageDeleteFile(`users/${user.uid}/${file.id}/${id}${JOB_EXT}`);

    }

    const color = {
        'recording': tailwindColorToHex('bg-red-500'),
        'packing': tailwindColorToHex('bg-indigo-500'),
    }


    //https://momentjs.com/
    const friendlyDate = created instanceof Timestamp ? moment(created.toDate()).format('MMM Do') : null;


    const language = target.find(item => item.code == code)?.name || 'No translation';

    //does not work as it is not on the same domain
    // const filename = file.name + (code ? `-${code}` : '');




    return (
        <div
            className="snap-start bg-gray-200 select-none cursor-default flex flex-row">

            <div className="px-3 py-2">
                <div className="w-7 h-7">

                    {status == 'error' &&
                        <img
                            className="w-full h-full p-0.5"
                            src={require("../assets/svg/job-error.svg").default} />
                    }

                    {status == 'pending' &&
                        <img
                            className="animate-bounce w-full h-full p-0.5"
                            src={require("../assets/svg/job-pending.svg").default} />
                    }

                    {Object.keys(color).includes(status) &&
                        <svg
                            className="animate-spin w-full h-full p-1"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" strokeWidth="4" stroke="black"></circle>
                            <path d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" fill={color[status]}></path>
                        </svg>
                    }

                    {url &&
                        <a
                            className="group w-full h-full p-1.5 bg-blue-500 rounded hover:bg-blue-600 flex flex-row items-center justify-center"
                            href={url}
                            target="_blank"
                        // download={filename}
                        >
                            <img
                                className="w-full h-full group-active:translate-y-px"
                                src={require("../assets/svg/job-download.svg").default} />
                        </a>
                    }
                </div>

            </div>

            <div className="flex-1 pl-1 py-2 flex flex-col">

                {/* <h1 className="font-medium text-sm text-nowrap">{w}x{h}</h1> */}
                <h1 className="font-medium text-sm text-nowrap">{h}p</h1>
                <p className={classNames('max-w-24 text-xs text-nowrap truncate', !code && '!opacity-50')}>{language}</p>

                <p className="mt-6 text-xs text-nowrap">{friendlyDate}</p>


            </div>

            <div className="px-2">
                <button
                    className={classNames('opacity-50 hover:!opacity-100 hover:filter-red', !canRemove && '!opacity-20 pointer-events-none')}
                    onClick={() => remove()}>
                    <img
                        src={require("../assets/svg/job-delete.svg").default} />
                </button>
            </div>


        </div>
    )

}

const Jobs = ({ jobs }) => {

    const listJobs = jobs.sort((a, b) => a.created instanceof Timestamp && b.created instanceof Timestamp ? b.created.seconds - a.created.seconds : -1).map(job => <Job key={job.id} {...job} />)

    return (
        <div className="ml-4 mt-8 flex-1 flex flex-col">

            <h1 className="mb-4 font-light text-sm">Exported movies</h1>

            <div className="animate-fade animate-duration-75 md:w-[35rem] flex-1 overflow-y-auto scroll-smooth snap-y">
                <div className="h-0 grid md:grid-cols-3 gap-1">
                    {listJobs}
                </div>
            </div>

        </div>
    )

}


const Export = ({ onBack }) => {

    const state = useContext(StateContext);

    const { user, file, subscription } = state;

    const credits = useCredits(user.uid, subscription?.metadata);
    const jobs = useJobs(user.uid, file.id);

    return (
        <>
            <ToolbarExport credits={credits} jobs={jobs} onBack={onBack} />

            {jobs.length == 0 && <p className="animate-fade animate-duration-75 animate-delay-500 ml-4 mt-8 text-sm text-gray-400">There are no exported movies.<br /> Press the plus button above to export your movie.</p>}
            {jobs.length > 0 && <Jobs jobs={jobs} />}
        </>
    );

};

export default Export;
