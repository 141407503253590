
import compute from "../editor/compute"


export const setData = (file, code) => {

    if (!file) return;

    const data = {};

    const scenes = file.content.scenes.filter(scene => scene.visible).map(scene => ({ url: scene.speak.enabled && scene.speak.text ? scene.speak.text.translate(code).audio(scene.speak.voice_id)?.src : null, duration: compute.calc(file, scene, code).duration }));
    const duration = scenes.reduce((a, c) => { c.start = a; return a + c.duration; }, 0);

    const audio = scenes.filter(({ url }) => url).map(({ url, start }) => ({ url, start }));

    data.duration = duration;
    data.audio = audio;

    // console.log('data: ', data);


    window.data = data;



}