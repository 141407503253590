


import { PLACEHOLDER } from "../utils/consts";


//global
var preload = {
    // 'address': { img: new Image(), promise: new Promise, status: 'loading' }
};



String.prototype.img = function () {

    const self = String(this);

    if (!self) return;

    const item = preload[self];

    if (!item) return self.load();

    if (item.status == 'ready') return item.img;

    return item.promise;

};


String.prototype.load = function () {

    const self = String(this);

    if (!self) return;

    
    if (preload[self]) return preload[self].promise;


    const img = new Image();
    img.crossOrigin = 'anonymous';
    // img.crossOrigin = '';

    const data = {
        img,
        promise: new Promise((resolve, reject) => {

            img.onload = () => {

                const item = preload[self];
                item.status = 'ready';

                resolve(img);
            };

            img.src = self;

        }),
        status: 'loading'
    }

    preload[self] = data;

    return data.promise;

};




export const add = (arr) => arr.map(url => url.load())

export const remove = (arr) => arr.forEach(url => delete preload[url]);


PLACEHOLDER.load();