import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import ToolbarPreview from "./ToolbarPreview";


import { loadLang, loadImg, loadAudio, Loader } from "./Loader";

import { Controller } from "./Controller";

import emitter from "../emmiter";

export const useResources = (file) => {

    const [loading, setIsLoading] = useState(true);
    const [lang, setLang] = useState(null);


    useEffect(() => {
        if (!file) return;

        setIsLoading(true);


        const load = async () => {
            //preview

            const p1 = loadLang(file);

            const p2 = loadImg(file);
            const p3 = loadAudio(file);

            const result = await Promise.all([p1, p2, p3]);

            const detect = result[0];

            setLang(detect);
            setIsLoading(false);
        }

        load();


    }, [file])



    return [loading, lang];

}





const Preview = ({ onBack }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, languages } = state;

    const { target } = languages;



    //preview always display auto-translate to allow the end-user to test it

    // const arr = fileTextArr(file);
    const translate = true;// arr.length;


    const [loading, lang] = useResources(file);


    const idRef = useRef(null);

    const handleSceneChange = (status) => {

        idRef.current = status;

    }

    const translatingRef = useRef(null);

    const handlePaused = ({ reason }) => {

        translatingRef.current = reason == 'translating';

    }


    useEffect(() => {

        emitter.on('scene.change', handleSceneChange);
        emitter.on('paused', handlePaused);

        return () => {
            emitter.off('scene.change', handleSceneChange);
            emitter.off('paused', handlePaused);
        }

    }, []);


    const back = () => {
        dispatch({ type: 'scene-set', scene: null });

        onBack();

    }

    const replay = () => {

        if (!translatingRef.current) emitter.emit('replay');

    }

    const edit = () => {

        const scene = file.content.scenes.find(scene => scene.id == idRef.current);
        if (scene) dispatch({ type: 'scene-set', scene });

        onBack();

    }

    //tood: analizeaza de ce tirmiti key la controller

    return (
        <div className="flex-1 flex flex-col">
            <ToolbarPreview loading={loading} onBack={back} onReplay={replay} onEdit={edit} />

            <div className="relative flex-1 flex flex-col">

                {loading && <Loader name={file.name} mode={file.settings.mode} />}
                {!loading && <Controller key={file.id} pilot={true} toc={true} lang={lang} languages={target} translate={translate} initialCode={null} />}

            </div>


        </div>
    );


};

export default Preview;
