import classNames from "classnames";

const Highlighted = ({ text = '', highlight = '' }) => {

    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    const listParts = parts.map((part, i) =>
        <span
            className={classNames(part.toLowerCase() == highlight.toLowerCase() && 'font-bold')}
            key={i}>
            {part}
        </span>
    )

    return <span className="flex-1"> {!highlight ? text : listParts} </span>;
}

export default Highlighted;