import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';


import { fnDetect } from "../firebase/functions.js";

import { ask } from "../openai/index.js";

import { UploadBgImage } from './Upload.js';
import { CropBgImage, BlurBgImage } from "./ImageModifiers.js";


import Arrows from "./Arrows.js";
import Cursors from "./Cursors.js";

import { ButtonFlatIcon, ButtonFlatIconToggle, ButtonWithOptions, ButtonWithModal } from '../ui/Buttons.js';

import { objectsEqual, tailwindColorToHex, randomFromList } from "../utils/index.js";

import { TEXT_SIZES, TEXT_ALIGN, FOCUS_BORDER, FOCUS_INPUT_BG, ARROW_BG, TOUCH_BG, TEXT_BG, BUTTON_BG, CONTINUE_VARIANTS } from "../utils/consts.js";
import { OPENAI_PROMPTS } from "../openai/index.js";

import { cursor, zoom, get } from "./utils.js";

import Wait from "../ui/Wait.js";

import { TRANSITIONS_FOCUS, TRANSITIONS_TEXT, TRANSITIONS_ARROW, TRANSITIONS_BUTTON } from "../utils/consts.js";
import Transition from "../ui/Transition.js";


import Slider from "../ui/Slider.js";

import MoreOptions from "../ui/MoreOptions.js";

import { useSortFiles } from "../explorer/index.js";

import Tooltip from "../ui/Tooltip.js";


import classNames from "classnames";




const Space = ({ w }) => {
    return (<span className={w}></span>)
}


export const ColorSelect = ({ colors, color, setColor }) => {

    const bg = (c) => c.split('-').map((s, i) => i == 0 ? 'bg' : s).join('-').split('/')[0];

    const listColors = colors.map(c =>
        <button
            className={classNames('w-5 h-5', bg(c) == 'bg-black' ? 'bg-slate-700' : bg(c), c == color && 'outline outline-1 outline-offset-2 outline-white')}
            key={c}
            onMouseDown={() => setColor(c)}>
        </button>
    );

    return (
        <div className="flex flex-row items-center space-x-1.5">
            {listColors}
        </div>
    );

};

export const TextSize = ({ size, setSize }) => {

    const list = Object.keys(TEXT_SIZES);

    const reset = () => {
        setSize('text-base');
    }

    const change = (v) => {
        const index = Math.min(Math.max(list.indexOf(size) + v, 0), list.length - 1);
        setSize(list[index]);
    }

    return (
        <div className="flex flex-row items-center">
            <button
                className={classNames('group rounded-sm hover:bg-slate-600', size == list.at(0) && 'opacity-20 pointer-events-none')}
                onMouseDown={() => change(-1)}>
                <svg
                    className="group-active:translate-y-px"
                    xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="white" fillOpacity="1" fillRule="evenodd" stroke="white" strokeWidth="1.5" d="M21 16H10v-1h11v1z"></path>
                </svg>
            </button>

            <button
                className="relative group w-12 focus:outline-none flex flex-row items-center justify-center"
                onMouseDown={() => reset()}>
                <span
                    className="text-xs text-slate-100 uppercase group-hover:!text-slate-50" >
                    {size.split('-').pop()}
                </span>
            </button>

            <button
                className={classNames('group rounded-sm hover:bg-slate-600', size == list.at(-1) && 'opacity-20 pointer-events-none')}
                onMouseDown={() => change(+1)}>
                <svg
                    className="group-active:translate-y-px"
                    xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="white" fillOpacity="1" fillRule="evenodd" stroke="white" strokeWidth="1.5" d="M15 15v-5h1v5h5v1h-5v5h-1v-5h-5v-1h5z"></path>
                </svg>
            </button>
        </div>
    );

};

export const TextAlign = ({ align, setAlign }) => {

    const list = TEXT_ALIGN;

    const handleClick = () => {
        const i = list.indexOf(align);
        const index = i < list.length - 1 ? i + 1 : 0;

        setAlign(list[index]);
    };

    return (
        <ButtonFlatIcon className="h-5" svg={`${align}.svg`} tooltip="Align" click={handleClick} />
    );

};



const param = (arr, props) => arr.reduce((a, c) => { a[c] = props[c]; return a; }, {});




export const InputOptions = ({ input, refInput }) => {

    const dispatch = useContext(DispatchContext);

    const colors = FOCUS_INPUT_BG;


    const remove = () => {
        dispatch({ type: 'elem-update', param: { input: { ...input, text: '' } } });
        refInput.current.close();
    }

    const handleKeyDown = (e) => {
        if (e.keyCode == 27) refInput.current.close();
        if (e.keyCode == 13) refInput.current.close();
    }


    return (

        <div className="w-56 h-40 bg-black flex flex-col">

            {!input.text &&

                <p className="p-2 text-xs text-gray-300">Display text with a typewriting effect, or prompt users to type it to continue when Pause mode is activated.</p>
            }
            {input.text &&
                <div className="h-9 pl-2 bg-slate-800 flex flex-row items-center">

                    <div className="flex-1 h-full flex flex-row">
                        <ColorSelect colors={colors} color={input.bg} setColor={(v) => dispatch({ type: 'elem-update', param: { input: { ...input, bg: v } } })} />
                        <Space w="w-4" />
                        <TextAlign align={input.align} setAlign={(v) => dispatch({ type: 'elem-update', param: { input: { ...input, align: v } } })} />
                    </div>

                    <Wait value={input.wait} change={(v) => dispatch({ type: 'elem-update', param: { input: { ...input, wait: v } } })} />
                </div>
            }

            <div className="mt-auto mb-3 mx-2 bg-white flex flex-row items-center">
                <div className="flex-1 px-2 py-0.5">
                    <input className="appearance-none w-full p-0 bg-white text-sm focus:outline-none placeholder:text-gray-300"
                        placeholder="type in"
                        type="text"
                        maxLength="50"
                        required={true}
                        autoComplete="off"
                        spellCheck={false}
                        autoFocus={true}
                        value={input.text}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => dispatch({ type: 'elem-update', param: { input: { ...input, text: e.target.value } } })}
                        onKeyDown={handleKeyDown}
                    />
                </div>

                <button
                    className={classNames('relative group opacity-80 invisible hover:!opacity-100 flex flex-row items-center justify-center', input.text && '!visible')}
                    onClick={() => dispatch({ type: 'elem-update', param: { input: { ...input, password: !input.password } } })}>
                    <img
                        className={classNames('w-4 group-active:scale-90', input.password && 'filter-red')}
                        src={require("../assets/svg/focus-password.svg").default} />

                    <Tooltip>Password</Tooltip>

                </button>

                <button
                    className={classNames('group px-2 opacity-80 invisible filter-black hover:!opacity-100 flex flex-row items-center justify-center', input.text && '!visible')}
                    onClick={() => remove()}>
                    <img
                        className="w-3 h-3 group-active:scale-90"
                        src={require("../assets/svg/x.svg").default} />

                </button>
            </div>

        </div>
    )
}

export const PropFocus = (props) => {

    const dispatch = useContext(DispatchContext);

    const colors = FOCUS_BORDER;

    const refInput = useRef(null);

    const options = [
        {
            name: 'Apply to all scenes',
            action: () => dispatch({ type: 'applytoall', target: 'elem', param: param(['wait', 'transition', 'border', 'cover', 'pause'], props) }),
        }
    ];

    const change = (opacity) => dispatch({ type: 'elem-update', param: { cover: { ...props.cover, enabled: opacity > 0, opacity } } });


    const list = [0, 0.2, 0.5, 0.8, 1];

    const tooltip = `Pause movie${props.pause ? props.input.text ? ', then resume when the user types "' + props.input.text + '".' : ', then resume when the user clicks on Focus.' : ''}`;


    return (
        <div className="prop">
            <img
                className="ml-2 mr-4 w-4 opacity-70"
                src={require("../assets/svg/el-focus.svg").default}
            />

            <ColorSelect colors={colors} color={props.border} setColor={(v) => dispatch({ type: 'elem-update', param: { border: v } })} />

            <Space w="w-6" />

            <Slider tooltip="Cover" min={0} max={list.length - 1} value={list.indexOf(props.cover.opacity)} setValue={(v) => change(list[v])} />

            <Space w="w-6" />


            <ButtonFlatIconToggle classNameGroup={classNames(props.pause && '!bg-red-500')} className="w-5 h-5" svg="focus-pause.svg" tooltip={tooltip} isDown={props.pause} setIsDown={(v) => dispatch({ type: 'elem-update', param: { pause: v } })} />

            <ButtonWithOptions place="bl" svg="focus-input.svg" className={classNames('h-6', props.input.text && (props.pause ? 'filter-red' : 'filter-blue-2'))} tooltip="Type in" ref={refInput}>
                <InputOptions input={props.input} refInput={refInput} />
            </ButtonWithOptions >



            <div className="ml-auto h-full flex flex-row items-center">

                <Wait value={props.wait} change={(v) => dispatch({ type: 'elem-update', param: { wait: v } })} />
                <Transition value={props.transition} transitions={['move', ...TRANSITIONS_FOCUS, 'outline']} change={(v) => dispatch({ type: 'elem-update', param: { transition: v } })} />

                <MoreOptions options={options} />
            </div>

        </div >
    );

};

export const PropArrow = (props) => {

    const dispatch = useContext(DispatchContext);

    const colors = ARROW_BG;

    const options = [
        {
            name: 'Apply to all scenes',
            action: () => dispatch({ type: 'applytoall', target: 'elem', param: param(['wait', 'transition', 'style', 'bg', 'scale'], props) }),
        }
    ];


    const select = (style) => dispatch({ type: 'elem-update', param: { style } });

    const rotate = { 'transform': `rotate(${props.angle}deg)` };

    const fill = tailwindColorToHex('bg-white');
    const A = Object.keys(Arrows).map((style => ({ style, comp: React.createElement(Arrows[style].comp, { key: style, fill }) })));

    const listArrows = A.map(({ style, comp }) =>
        <li
            className={classNames('w-16 h-16 cursor-pointer hover:bg-slate-600', props.style == style && '!bg-blue-500')}
            key={style}
            onMouseDown={() => select(style)}>
            <div className="w-full h-full p-4 [&>*:first-child]:w-full [&>*:first-child]:h-full"
                style={rotate}>
                {comp}
            </div>

        </li>);



    return (
        <div className="prop">
            <img
                className="ml-2 mr-4 w-4 opacity-70"
                src={require("../assets/svg/el-arrow.svg").default}
            />

            <ColorSelect colors={colors} color={props.bg} setColor={(v) => dispatch({ type: 'elem-update', param: { bg: v } })} />

            <Space w="w-8" />

            <ButtonWithOptions place="bl" svg="collection.svg" tooltip="Styles">

                <ul className="p-1 grid grid-cols-2 gap-0 justify-between">
                    {listArrows}
                </ul>

            </ButtonWithOptions>


            <div className="ml-auto h-full flex flex-row items-center">

                <Wait value={props.wait} change={(v) => dispatch({ type: 'elem-update', param: { wait: v } })} />
                <Transition value={props.transition} transitions={['point', ...TRANSITIONS_ARROW, 'wag', 'shake']} change={(v) => dispatch({ type: 'elem-update', param: { transition: v } })} />

                <MoreOptions options={options} />
            </div>

        </div>
    );

};

export const PropText = (props) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, scene } = state;

    const { speak } = scene;

    const colors = TEXT_BG;

    const refCopySpeak = useRef(null);
    const refRewrite = useRef(null);

    const options = [
        {
            name: 'Apply to all scenes',
            action: () => dispatch({ type: 'applytoall', target: 'elem', param: param(['wait', 'transition', 'bg', 'size', 'align'], props) }),
        }
    ];


    const copySpeak = [

        {
            name: 'Copy from SPEAK',
            action: () => dispatch({ type: 'elem-update', param: { text: speak.text } }),
        },
        {
            name: 'Copy from SPEAK (all scenes)',
            action: () => dispatch({ type: 'applytoall', target: 'copy-from-speak', param: props.name }),
        },

    ];

    const listCopySpeak = copySpeak.map(({ name, action }) =>
        <li
            className="context-menu-item cursor-pointer"
            key={name}
            onClick={() => { action(); refCopySpeak.current.close(); }}>

            {name}

        </li>
    )

    const askOpenAI = async (key) => {

        refRewrite.current.close();

        dispatch({ type: 'runtime-rewriting-set', param: props.name });

        const lang = await fnDetect(file.userId, get.textForDetect(file));
        const text = await ask(file.userId, props.text, lang?.name, key);

        dispatch({ type: 'runtime-rewriting-set', param: null });

        if (text) dispatch({ type: 'elem-update', param: { text } })

    }

    const listOpenAIPrompts = Object.keys(OPENAI_PROMPTS).map(key =>
        <li
            className={classNames('context-menu-item cursor-pointer', key == 'rewrite-formal' && 'delimiter')}
            key={key}
            onClick={() => askOpenAI(key)}>

            {OPENAI_PROMPTS[key].caption}

        </li>
    )

    return (
        <div className="prop">
            <img
                className="ml-2 mr-4 w-4 opacity-70"
                src={require("../assets/svg/el-text.svg").default}
            />

            <ColorSelect colors={colors} color={props.bg} setColor={(v) => dispatch({ type: 'elem-update', param: { bg: v } })} />

            <Space w="w-5" />

            <TextSize size={props.size} setSize={(v) => dispatch({ type: 'elem-update', param: { size: v } })} />

            <Space w="w-4" />

            <TextAlign align={props.align} setAlign={(v) => dispatch({ type: 'elem-update', param: { align: v } })} />

            <Space w="w-8" />

            <ButtonWithOptions place="bl" svg="copy.svg" disabled={!(speak.enabled && speak.text)} tooltip="Copy from SPEAK" ref={refCopySpeak}>
                <ul className="p-1">
                    {listCopySpeak}
                </ul>
            </ButtonWithOptions>

            <ButtonWithOptions place="bl" svg="rewrite.svg" className="h-5" disabled={!props.text} tooltip="Rewrite" ref={refRewrite}>
                <ul className="p-1">
                    {/* {listRewriteStyles} */}
                    {listOpenAIPrompts}
                </ul>
            </ButtonWithOptions>

            <div className="ml-auto h-full flex flex-row items-center">

                <Wait value={props.wait} change={(v) => dispatch({ type: 'elem-update', param: { wait: v } })} />
                <Transition value={props.transition} transitions={[...TRANSITIONS_TEXT]} change={(v) => dispatch({ type: 'elem-update', param: { transition: v } })} />

                <MoreOptions options={options} />
            </div>

        </div>
    );

};

export const PropButton = (props) => {

    const dispatch = useContext(DispatchContext);

    const colors = BUTTON_BG;

    const options = [
        {
            name: 'Apply to all scenes',
            action: () => dispatch({ type: 'applytoall', target: 'elem', param: param(['transition', 'bg', 'size', 'replay'], props) }),
        }
    ];

    return (
        <div className="prop">
            <img
                className="ml-2 mr-4 w-4 opacity-70"
                src={require("../assets/svg/el-button.svg").default}
            />

            <ColorSelect colors={colors} color={props.bg} setColor={(v) => dispatch({ type: 'elem-update', param: { bg: v } })} />
            <Space w="w-5" />

            <TextSize size={props.size} setSize={(v) => dispatch({ type: 'elem-update', param: { size: v } })} />
            <Space w="w-6" />

            <ButtonFlatIconToggle className="w-3 h-3" svg="continue-replay.svg" tooltip="Replay" isDown={props.replay} setIsDown={(v) => dispatch({ type: 'elem-update', param: { replay: v } })} />

            <Space w="w-8" />

            <ButtonFlatIcon className="h-5" svg="rewrite.svg" tooltip="Rewrite" click={() => dispatch({ type: 'elem-update', param: { caption: randomFromList(CONTINUE_VARIANTS, props.caption) } })} />

            <div className="ml-auto h-full flex flex-row items-center">

                <Transition value={props.transition} transitions={[...TRANSITIONS_BUTTON]} change={(v) => dispatch({ type: 'elem-update', param: { transition: v } })} />

                <MoreOptions options={options} />
            </div>

        </div>
    );

};

export const PropTouch = (props) => {

    const dispatch = useContext(DispatchContext);

    const colors = TOUCH_BG;

    const options = [
        {
            name: 'Apply to all scenes',
            action: () => dispatch({ type: 'applytoall', target: 'elem', param: param(['bg', 'scale'], props) }),
        }
    ];

    return (
        <div className="prop">
            <img
                className="ml-2 mr-4 w-4 opacity-70"
                src={require("../assets/svg/el-touch.svg").default}
            />

            <ColorSelect colors={colors} color={props.bg} setColor={(v) => dispatch({ type: 'elem-update', param: { bg: v } })} />

            <div className="ml-auto h-full flex flex-row items-center">

                <MoreOptions options={options} />
            </div>

        </div>
    );

};

export const PropCursor = (props) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, scene } = state;


    const options = [
        {
            name: 'Align to previous cursor',
            action: () => dispatch({ type: 'elem-update', param: { point1: cursor.prev(file, scene) } }),
            disabled: !cursor.prev(file, scene),
        },
        {
            name: 'Align to next cursor',
            action: () => dispatch({ type: 'elem-update', param: { point2: cursor.next(file, scene) } }),
            disabled: !cursor.next(file, scene),
            delimiter: true,
        },
        {
            name: 'Apply to all scenes',
            action: () => dispatch({ type: 'applytoall', target: 'elem', param: param(['style', 'scale', 'path', 'hili', 'effect'], props) }),
        }



    ];


    const list = [1, 2, 4, 6];
    const change = (scale) => dispatch({ type: 'elem-update', param: { scale } });

    const select = (style) => dispatch({ type: 'elem-update', param: { style } });


    const stroke = 'black';
    const fill = 'white';

    const C = Object.keys(Cursors).map((style => ({ style, comp: React.createElement(Cursors[style].comp, { key: style, stroke, fill }) })));

    const listCursors = C.map(({ style, comp }) =>
        <li
            className={classNames('w-16 h-16 cursor-pointer hover:bg-slate-600', props.style == style && '!bg-blue-500')}
            key={style}
            onMouseDown={() => select(style)}>
            <div className="w-full h-full p-4 [&>*:first-child]:w-full [&>*:first-child]:h-full">
                {comp}
            </div>

        </li>);

    return (
        <div className="prop">
            <img
                className="ml-2 mr-4 w-4 opacity-70"
                src={require("../assets/svg/el-cursor.svg").default}
            />

            <Slider tooltip="Scale" min={0} max={list.length - 1} value={list.indexOf(props.scale)} setValue={(v) => change(list[v])} />

            <Space w="w-8" />

            <ButtonWithOptions place="bl" svg="collection.svg" tooltip="Styles">

                <ul className="p-1 grid grid-cols-2 gap-0 justify-between">
                    {listCursors}
                </ul>

            </ButtonWithOptions>


            <Space w="w-8" />

            <ButtonFlatIconToggle className="w-4 h-4" svg="cursor-curve.svg" tooltip="Curved path" isDown={props.path == 'curve'} setIsDown={(v) => dispatch({ type: 'elem-update', param: { path: v ? 'curve' : '' } })} />
            <Space w="w-px" />
            <ButtonFlatIconToggle className="w-4 h-4" svg="cursor-hili.svg" tooltip="Highlight" isDown={props.hili} setIsDown={(v) => dispatch({ type: 'elem-update', param: { hili: v } })} />
            <Space w="w-px" />
            <ButtonFlatIconToggle className="w-4 h-4" svg="cursor-click.svg" tooltip="Click effect" isDown={props.effect == 'click'} setIsDown={(v) => dispatch({ type: 'elem-update', param: { effect: v ? 'click' : '' } })} />


            <div className="ml-auto h-full flex flex-row items-center">

                <MoreOptions options={options} />
            </div>

        </div>
    );

};


export const PropBgImage = (props) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, scene } = state;



    const [showUploadBgImage, setShowUploadBgImage] = useState(false);
    const [showCropBgImage, setShowCropBgImage] = useState(false);
    const [showBlurBgImage, setShowBlurBgImage] = useState(false);

    const isEmpty = props.url.includes('placeholder.svg');
    const prevZoom = zoom.prev(file, scene);

    const hasZoom = props.zoom.enabled ? prevZoom ? !objectsEqual(prevZoom, props.zoom) : props.zoom.value != 1 : false;


    const options = [
        {
            name: 'Zoom',
            disabled: isEmpty,
            delimiter: true,
            children: [
                {
                    name: 'Copy from previous scene',
                    action: () => dispatch({ type: 'scene-bgimage-zoom', zoom: { ...prevZoom } }),
                    disabled: prevZoom == undefined,
                    delimiter: true,
                },
                {
                    name: 'Apply to previous scenes',
                    action: () => dispatch({ type: 'applytoall', target: 'zoom', param: 'prev' }),
                },
                {
                    name: 'Apply to next scenes',
                    action: () => dispatch({ type: 'applytoall', target: 'zoom', param: 'next' }),
                    delimiter: true,
                },
                {
                    name: 'Apply to all scenes',
                    action: () => dispatch({ type: 'applytoall', target: 'zoom' }),
                },
            ]

        },
        {
            name: 'Same elems across scenes',
            action: () => dispatch({ type: 'applytoall', target: 'elems' }),
        },

    ];





    return (
        <>
            <div className="prop">
                <ButtonFlatIcon className="h-5" svg="image-upload.svg" tooltip="Replace" click={() => setShowUploadBgImage(true)} />
                <ButtonFlatIcon className="h-4" svg="image-crop.svg" disabled={isEmpty} tooltip="Crop" click={() => setShowCropBgImage(true)} />
                <ButtonFlatIcon className="h-4" svg="image-blur.svg" disabled={isEmpty} tooltip="Blur" click={() => setShowBlurBgImage(true)} />


                <div className="ml-auto h-full flex flex-row items-center">

                    {hasZoom &&
                        <Wait value={props.wait} tooltip="Wait before zoom" change={(v) => dispatch({ type: 'elem-update', param: { wait: v } })}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.1515 5.57888L23.1096 4.64313C23.6777 4.08828 24 3.31351 24 2.50235V1.1527C24.0002 0.835798 23.8755 0.544929 23.6758 0.337722C23.4769 0.129654 23.1976 -0.000211616 22.8934 2.58861e-07H18.1066C17.8023 -0.000211616 17.5229 0.129654 17.324 0.337701C17.1243 0.544929 16.9996 0.835777 17 1.15268V2.50231C17 3.31346 17.3223 4.08826 17.8904 4.64309L18.8483 5.57846C18.901 5.63016 18.9311 5.70205 18.9311 5.77762V6.22863C18.9311 6.30413 18.901 6.37588 18.8485 6.42737L17.8904 7.36315C17.3223 7.91777 17 8.69274 17 9.50394V10.8536C16.9996 11.1705 17.1243 11.4613 17.324 11.6685C17.523 11.8766 17.8024 12.0065 18.1066 12.0063H22.8934C23.1976 12.0065 23.4769 11.8766 23.6758 11.6685C23.8755 11.4613 24.0002 11.1705 24 10.8536V9.50394C24 8.69281 23.6777 7.91784 23.1096 7.36322L22.1517 6.42758C22.0988 6.37588 22.069 6.3042 22.069 6.22863V5.77762C22.069 5.70212 22.0988 5.63023 22.1515 5.57888ZM23.1552 10.8536C23.155 10.9304 23.1265 10.9956 23.0784 11.0463C23.0298 11.0964 22.9671 11.1261 22.8934 11.1263H18.1066C18.0329 11.1261 17.9701 11.0964 17.9214 11.0463C17.8733 10.9956 17.845 10.9304 17.8448 10.8536V9.50394C17.8448 8.93618 18.0705 8.3935 18.4682 8.00527L19.426 7.06949C19.6492 6.85176 19.7758 6.54722 19.7758 6.22863V5.77762C19.7758 5.45889 19.6492 5.15435 19.4259 4.93658L18.4682 4.00103C18.0705 3.61284 17.8448 3.07031 17.8448 2.50235V1.1527C17.845 1.07595 17.8733 1.01071 17.9214 0.959801C17.9701 0.909926 18.0329 0.880214 18.1066 0.880023H22.8934C22.967 0.880214 23.0298 0.909898 23.0784 0.959801C23.1265 1.01071 23.155 1.07592 23.1552 1.1527V2.50233C23.1552 3.07028 22.9295 3.61282 22.5318 4.00101L21.5739 4.93656C21.3507 5.15428 21.2241 5.45889 21.2241 5.77762V6.22863C21.2241 6.54715 21.3507 6.85176 21.5741 7.06985L22.5318 8.00506C22.9295 8.39343 23.1552 8.93618 23.1552 9.50394V10.8536Z" fill="white" />
                                <path d="M20.1534 9.36306L18.4126 11.1764H20.5752H22.7377L20.9969 9.36306C20.7641 9.12068 20.3865 9.12068 20.1534 9.36306Z" fill="white" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.66667 9.83334C5.66667 7.53215 7.53215 5.66667 9.83334 5.66667C12.1345 5.66667 14 7.53215 14 9.83334C14 10.9842 13.5346 12.0247 12.7796 12.7796C12.0247 13.5346 10.9842 14 9.83334 14C7.53215 14 5.66667 12.1345 5.66667 9.83334ZM9.83334 4C6.61168 4 4 6.61168 4 9.83334C4 13.055 6.61168 15.6667 9.83334 15.6667C11.1433 15.6667 12.3533 15.2342 13.3268 14.5053L17.5774 18.7559C17.9029 19.0814 18.4305 19.0814 18.7559 18.7559C19.0814 18.4305 19.0814 17.9029 18.7559 17.5774L14.5053 13.3268C15.2342 12.3533 15.6667 11.1433 15.6667 9.83334C15.6667 6.61168 13.055 4 9.83334 4Z" fill="white" />
                            </svg>



                        </Wait>
                    }


                    <MoreOptions options={options} />
                </div>

            </div>

            {showUploadBgImage && <UploadBgImage close={() => setShowUploadBgImage(false)} />}
            {showCropBgImage && <CropBgImage close={() => setShowCropBgImage(false)} />}
            {showBlurBgImage && <BlurBgImage close={() => setShowBlurBgImage(false)} />}

        </>
    );

};


const Links = ({ select }) => {

    const state = useContext(StateContext);

    const { files, file, global } = state;

    const { filterField, filterDirection } = global;

    const list1 = useSortFiles(files, filterField, filterDirection)
    const list2 = list1.filter(({ id, folderId }) => folderId == file.folderId && id != file.id).map(({ id, name }) => ({ id, name }));


    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleClick = (id, name) => {
        const url = `https://run.demo-builder.com/play/${id}`;

        select(url, name);
        setIsModalVisible(false);

    }

    const listLinks = list2.map(({ id, name }) =>
        <li
            className="px-2 py-1 text-sm text-white cursor-pointer hover:bg-slate-600"
            key={id}
            onClick={() => handleClick(id, name)}>

            {name}

        </li>)

    if (!list2.length) return;

    return (
        <ButtonWithModal place="bl" isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>
            <button className={classNames('group h-full px-2 hover:bg-slate-200 flex flex-row items-center justify-center', isModalVisible && '!bg-slate-200')}>

                <svg
                    className={classNames('w-2 opacity-80 transition-opacity group-hover:!opacity-100 group-active:translate-y-px', isModalVisible && 'translate-y-px')}
                    width="34" height="19" viewBox="0 0 34 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.26777 1.27667C0.291441 2.25294 0.291441 3.83594 1.26777 4.81219L13.4982 17.0307C15.4512 18.9817 18.6157 18.9809 20.5677 17.0292L32.7935 4.80319C33.77 3.82694 33.77 2.24394 32.7935 1.26764C31.8172 0.291319 30.2342 0.291319 29.258 1.26764L18.794 11.7317C17.8177 12.7082 16.2347 12.7079 15.2585 11.7317L4.80329 1.27667C3.82699 0.300343 2.24407 0.300343 1.26777 1.27667Z" fill="black" />
                </svg>

            </button>

            <ul className="max-h-[23rem] w-full p-1 bg-slate-800 overflow-y-auto shadow-light">
                {listLinks}
            </ul>

        </ButtonWithModal>
    )
}

export const PropStatic = (props) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, scene } = state;

    const { speak } = scene;


    const refUrl = useRef(null);

    const refCopySpeak = useRef(null);
    const refRewrite = useRef(null);


    const handleApplyToAll = () => {

        const arr = [];
        if ('wait' in props) arr.push('wait');
        if ('transition' in props) arr.push('transition');
        if ('size' in props) arr.push('size');
        if ('align' in props) arr.push('align');

        if (arr.length) dispatch({ type: 'applytoall', target: 'elem', param: param(arr, props) });

    }

    const options = [
        {
            name: 'Apply to all scenes',
            action: () => handleApplyToAll()
        }
    ];


    const copySpeak = [

        {
            name: 'Copy from SPEAK',
            action: () => dispatch({ type: 'elem-update', param: { text: speak.text } }),
        },
        {
            name: 'Copy from SPEAK (all scenes)',
            action: () => dispatch({ type: 'applytoall', target: 'copy-from-speak', param: props.name }),
        },

    ];

    const listCopySpeak = copySpeak.map(({ name, action }) =>
        <li
            className="context-menu-item cursor-pointer"
            key={name}
            onClick={() => { action(); refCopySpeak.current.close(); }}>

            {name}

        </li>
    )

    const askOpenAI = async (key) => {

        refRewrite.current.close();

        dispatch({ type: 'runtime-rewriting-set', param: props.name });

        const lang = await fnDetect(file.userId, get.textForDetect(file));
        const text = await ask(file.userId, props.text, lang?.name, key);

        dispatch({ type: 'runtime-rewriting-set', param: null });

        if (text) dispatch({ type: 'elem-update', param: { text } })

    }

    const listOpenAIPrompts = Object.keys(OPENAI_PROMPTS).map(key =>
        <li
            className={classNames('context-menu-item cursor-pointer', key == 'rewrite-formal' && 'delimiter')}
            key={key}
            onClick={() => askOpenAI(key)}>

            {OPENAI_PROMPTS[key].caption}

        </li>
    )

    const selectLink = (url, name) => dispatch({ type: 'elem-update', param: { url, caption: `Next demo: ${name}` } })

    const handleKeyDown = (e) => {
        if (e.keyCode == 27) refUrl.current.close();
        if (e.keyCode == 13) refUrl.current.close();
    }


    const transitions = props.type == 'text' ? TRANSITIONS_TEXT : TRANSITIONS_BUTTON;

    return (
        <div className="prop">

            <img
                className="ml-2 mr-4 w-4 opacity-70"
                src={require("../assets/svg/el-text.svg").default}
            />


            {'size' in props &&
                <>
                    <Space w="w-5" />
                    <TextSize size={props.size} setSize={(v) => dispatch({ type: 'elem-update', param: { size: v } })} />
                </>
            }


            {'align' in props &&
                <>
                    <Space w="w-4" />
                    <TextAlign align={props.align} setAlign={(v) => dispatch({ type: 'elem-update', param: { align: v } })} />
                </>
            }


            {'text' in props &&

                <>
                    <Space w="w-8" />

                    <ButtonWithOptions place="bl" svg="copy.svg" disabled={!(speak.enabled && speak.text)} tooltip="Copy from SPEAK" ref={refCopySpeak}>
                        <ul className="p-1">
                            {listCopySpeak}
                        </ul>
                    </ButtonWithOptions>

                    <ButtonWithOptions place="bl" svg="rewrite.svg" className="h-5" disabled={!props.text} tooltip="Rewrite" ref={refRewrite}>
                        <ul className="p-1">
                            {listOpenAIPrompts}
                        </ul>
                    </ButtonWithOptions>
                </>
            }



            {
                props.type == 'button' && 'url' in props &&


                <>
                    <Space w="w-12" />

                    <ButtonWithOptions place="bl" svg="link.svg" className="h-5" tooltip="Url" ref={refUrl}>

                        <div className="p-2 w-56">
                            <p className="text-xs text-gray-300">Enter the URL you'd like to navigate to when the user clicks the button.</p>

                            <div className="mt-12 bg-white flex flex-row">
                                <input className="flex-1 px-2 py-1 appearance-none bg-transparent text-sm focus:outline-none placeholder:text-gray-300"
                                    placeholder="https://"
                                    type="text"
                                    maxLength="300"
                                    required={true}
                                    autoComplete="off"
                                    spellCheck={false}
                                    autoFocus={true}
                                    value={props.url}
                                    onFocus={(e) => e.target.select()}
                                    onChange={(e) => dispatch({ type: 'elem-update', param: { url: e.target.value } })}
                                    onKeyDown={handleKeyDown}
                                />

                                <Links select={selectLink} />
                            </div>


                        </div>

                    </ButtonWithOptions>
                </>
            }


            <div className="ml-auto h-full flex flex-row items-center">

                {'wait' in props &&
                    <Wait value={props.wait} change={(v) => dispatch({ type: 'elem-update', param: { wait: v } })} />
                }
                {'transition' in props &&
                    <Transition value={props.transition} transitions={transitions} change={(v) => dispatch({ type: 'elem-update', param: { transition: v } })} />
                }


                <MoreOptions options={options} />
            </div>

        </div>
    );

};
