import { useState } from "react";
import { useEffect } from 'react';
import { useRef } from 'react';

import { ButtonWithOptions } from "./Buttons";



import classNames from 'classnames';




const Transition = ({ value = '', transitions, tooltip = "Transition", change }) => {

    const ref = useRef(null);

    const NONE = '(none)';
    const friendly = (v) => v ? v.includes('-') ? v.split('-').splice(1).filter(part => part.length > 1).join(' ') : v : NONE;

    const handleClick = (v) => {
        ref.current.close();

        change(v);

    }




    const list = ['', ...transitions].map((item, index) =>
        <li
            className={classNames('pl-2 pr-2 py-1 cursor-pointer hover:bg-slate-600', value == item && '!bg-blue-500')}
            key={item}
            onClick={() => handleClick(item)}>
            <span className={classNames('text-sm text-white text-nowrap', index == 0 && 'opacity-70', index > 0 && 'capitalize')}>{friendly(item)}</span>
        </li>)

    return (
        <ButtonWithOptions place="bl" same={true} tooltip={tooltip} ref={ref}>

            <div className="min-w-20 flex flex-row items-center space-x-1">
                <svg width="31" height="25" viewBox="0 0 31 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.3123 19.726C10.7132 20.1058 11.3461 20.0887 11.726 19.6877C12.1058 19.2868 12.0887 18.6539 11.6877 18.274L10.3123 19.726ZM7 15.2105L6.275 14.5217L5.5853 15.2477L6.31225 15.9365L7 15.2105ZM11.6877 18.274L7.68775 14.4845L6.31225 15.9365L10.3123 19.726L11.6877 18.274ZM7.725 15.8993L11.725 11.6888L10.275 10.3112L6.275 14.5217L7.725 15.8993Z" fill="white" />
                    <path d="M23 13C23.5523 13 24 12.5523 24 12C24 11.4477 23.5523 11 23 11V13ZM19 13H23V11H19V13Z" fill="white" />
                    <path d="M13 15H23" stroke="white" strokeWidth="2" strokeLinecap="round" />
                </svg>



                <span className={classNames('text-sm text-white', value && 'capitalize')}>{friendly(value)}</span>
            </div>

            <ul className="p-1">
                {list}
            </ul>
        </ButtonWithOptions>
    );

};

export default Transition;
