
import pixelmatch from "pixelmatch";

import "../cache/cache-img"

import { compress } from "./compress";

import { storageUploadFile } from "../firebase/storage";



//https://github.com/mapbox/pixelmatch


const drawBlurredRect = (source, img, rect, blur) => {

    const canvas = document.createElement('canvas');
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;

    const ctx = canvas.getContext('2d');

    ctx.filter = `blur(${blur}px)`;
    ctx.drawImage(img, 0, 0);

    source.drawImage(canvas, rect.left, rect.top, rect.width, rect.height, rect.left, rect.top, rect.width, rect.height);

}


export default (id, path, url, rects, blur) => {

    return new Promise(async (resolve, reject) => {

        const img = await url.img();

        const canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        let changed = false;

        for (const { rect, ref } of rects) {

            const part = ctx.getImageData(rect.left, rect.top, rect.width, rect.height);
            const dpx = pixelmatch(ref.data, part.data, null, rect.width, rect.height, { threshold: 0.2 });

            if (dpx > 0) continue;

            drawBlurredRect(ctx, img, rect, blur);

            changed = true;
        }

        if (!changed) {

            resolve({ id });

        }
        else {

            canvas.toBlob(async (blob) => {

                try {
                    // normally after re-compressing, the quality is lower, but if I send quality 1, the file size is bigger
                    // leave it like this as we need small size
                    const { data } = await compress(blob);

                    const newUrl = await storageUploadFile(path, data);

                    resolve({ id, url: newUrl });


                } catch (err) {
                    reject(err);
                }



            });


        };

    })


}