import { PLACEHOLDER, SCENE_WAIT } from '../utils/consts';

import Templates from '../templates/';

import { duplicateFilename } from '../utils';

export const uniqueId = () => Date.now().toString(36) + Math.random().toString(36).substring(2, 12).padStart(12, 0);


const initSpeak = (preferences) => JSON.parse(JSON.stringify({
    enabled: false,
    voice_id: preferences.voice_id,
    text: '',
}));



export const actions = {

    addSceneBlank: (preferences, dispatch, index = undefined) => {

        const key = 'bg-image';
        const Template = Templates[key];

        const scene = {
            name: '',
            id: uniqueId(),
            template: key,
            layout: Template.layouts[0].name,
            visible: true,
            elems: JSON.parse(JSON.stringify(Template.elems)),
            speak: initSpeak(preferences),
            wait: SCENE_WAIT,

        };

        const placeholder = PLACEHOLDER;
        const placeholderWidth = 800;
        const placeholderHeight = 600;

        const bgimage = scene.elems.find(elem => elem.name == 'bgimage');

        bgimage.url = placeholder;
        bgimage.naturalWidth = placeholderWidth;
        bgimage.naturalHeight = placeholderHeight;
        bgimage.crop = { left: 0, top: 0, width: placeholderWidth, height: placeholderHeight };




        dispatch({ type: 'scenes-insert', scenes: [{ scene, index }] });

        return scene;

    },


    addScenesFromImages: (preferences, dispatch, index = undefined, arr) => {

        const key = 'bg-image';
        const Template = Templates[key];

        const scenes = arr.map(item => {
            const scene = {
                name: item.name,
                id: item.id,
                template: key,
                layout: Template.layouts[0].name,
                visible: true,
                elems: JSON.parse(JSON.stringify(Template.elems)),
                speak: initSpeak(preferences),
                wait: SCENE_WAIT,

            };

            const bgimage = scene.elems.find(elem => elem.name == 'bgimage');

            bgimage.url = item.url;
            bgimage.naturalWidth = item.naturalWidth;
            bgimage.naturalHeight = item.naturalHeight;
            bgimage.crop = { left: 0, top: 0, width: item.naturalWidth, height: item.naturalHeight };


            const { elems, speak } = item;

            if (elems)
                Object.keys(elems).forEach(name => {
                    const elem = scene.elems.find(elem => elem.name == name);
                    Object.keys(elems[name]).forEach(key => elem[key] = elems[name][key]);
                })

            if (speak)
                Object.keys(speak).forEach(key => scene.speak[key] = speak[key]);



            return { scene, index };
        })




        dispatch({ type: 'scenes-insert', scenes });

        return scenes;

    },


    addSceneFromTemplate: (preferences, dispatch, index = undefined, key) => {

        const Template = Templates[key];

        const scene = {
            name: Template.title,
            id: uniqueId(),
            template: key,
            layout: Template.layouts[0].name,
            visible: true,
            elems: JSON.parse(JSON.stringify(Template.elems)),
            speak: initSpeak(preferences),
            wait: SCENE_WAIT,

        };


        if (key == 'intro') index = 0;
        if (key == 'outro') index = undefined;

        dispatch({ type: 'scenes-insert', scenes: [{ scene, index }] });

        return scene;

    },

    duplicateScenes: (dispatch, scenes) => {

        //todo: how about resources?

        const newScenes = scenes.map(({ index, scene }) => {
            const newScene = JSON.parse(JSON.stringify(scene));
            newScene.id = uniqueId();
            newScene.name = duplicateFilename(newScene.name);

            return { scene: newScene, index };
        });


        dispatch({ type: 'scenes-insert', scenes: newScenes });

        return newScenes;

    },

    duplicateScene: (dispatch, index = undefined, scene) => {

        //todo: how about resources?

        const newScene = JSON.parse(JSON.stringify(scene));
        newScene.id = uniqueId();
        newScene.name = duplicateFilename(newScene.name);


        dispatch({ type: 'scenes-insert', scenes: [{ scene: newScene, index }] });

        return newScene;

    },



}