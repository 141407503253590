import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useContext } from 'react';


import { StateContext, DispatchContext } from '../context/';

import { fnTranslate } from "../firebase/functions";

import { pause as cacheAudioPause, stop as cacheAudioStop } from "../cache/cache-audio";

import "../cache/cache-translate";

import compute from "./compute";

import { EditCanvas } from "./EditScene";


import { useClickOutside } from "../hooks";


import { loadAudio } from "./Loader";

import emitter from "../emmiter";


import { get } from "./utils";


import classNames from "classnames";


const msToMinSec = ms => {
    const totalSec = Math.floor(ms / 1000);
    const min = Math.floor(totalSec / 60);
    const sec = totalSec % 60;
    const msRemain = Math.floor((ms % 1000) / 100);

    const minPadded = min > 0 ? String(min).padStart(2, '0') + ':' : '';
    const secPadded = String(sec).padStart(1, '0');
    const msPadded = String(msRemain).padStart(1, '0');

    return min > 0 ? `${minPadded}${secPadded}.${msPadded}` : `${secPadded}.${msPadded}`;
}




export const Play = ({ status, disabled, duration, play, stop }) => {

    const [currentTime, setCurrentTime] = useState(0);

    const ref = useRef(null);

    const requestRef = useRef(null);
    const timeRef = useRef(null);

    const tick = () => {

        const elapsed = performance.now() - timeRef.current;

        if (elapsed <= duration) {
            setCurrentTime(elapsed);
            emitter.emit('scene.progress', { elapsed });
        }
        else {
            stop();
            return;
        }


        requestRef.current = requestAnimationFrame(tick);
    }

    useEffect(() => {
        if (status != 'playing') {
            setCurrentTime(0);
            return;
        }

        setCurrentTime(0);
        timeRef.current = performance.now();

        requestRef.current = requestAnimationFrame(tick);
        return () => cancelAnimationFrame(requestRef.current);

    }, [status]);




    const handleClick = () => {

        if (!status) play();

        if (status == 'loading') stop();
        if (status == 'playing') stop();

    }


    useClickOutside(['loading', 'playing'].includes(status), [ref], null, handleClick);


    const info = `${msToMinSec(currentTime)} / ${msToMinSec(duration)}`;


    return (
        <div className="flex flex-row items-center space-x-2">
            <button
                ref={ref}
                className={classNames('group w-9 h-9 p-2.5 bg-red-500 rounded focus:outline-none hover:!bg-red-600 flex flex-row items-center justify-center',
                    status == 'loading' && 'pointer-events-none',
                    status == 'playing' && '!bg-red-600',
                    disabled && 'opacity-50 pointer-events-none')}
                onClick={() => handleClick()}>

                {!status &&
                    <svg
                        className="w-full h-full group-active:scale-110"

                        width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3 1.11694L3.757 1.57094L13.757 7.57094L14.472 7.99994L13.757 8.42894L3.757 14.4289L3 14.8829V1.11694ZM4 2.88294V13.1169L12.528 7.99994L4 2.88294Z" fill="white" />
                    </svg>
                }

                {status == 'playing' &&
                    <svg
                        className="w-full h-full group-active:scale-110"
                        width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="15" height="15" stroke="white" strokeWidth="3" />
                    </svg>
                }

                {status == 'loading' &&
                    <svg
                        className="animate-spin w-full h-full"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" strokeWidth="4" stroke="black"></circle>
                        <path d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" fill="white"></path>
                    </svg>
                }


            </button>

            <div className="w-20 font-thin text-sm text-right">{info}</div>


        </div>
    );

};




const TimeBar = () => {

    const [elapsed, setElapsed] = useState(0);
    const [duration, setDuration] = useState(0);

    const [paused, setPaused] = useState(false);
    const [reason, setReason] = useState('');

    const [ended, setEnded] = useState(false);


    const handleProgress = ({ elapsed, duration }) => {

        const l = Math.min(Math.round(elapsed / 100) * 100, duration);

        setElapsed(l);
        setDuration(duration);

        setEnded(false);
    }

    const handlePaused = ({ paused, reason }) => {
        // console.log('paused, reason : ', paused, reason);
        setPaused(paused);
        setReason(reason);

    }

    const handleEnded = () => {
        setPaused(false);
        setReason('');

        setEnded(true);

    }



    useEffect(() => {
        emitter.on('movie.progress', handleProgress);
        emitter.on('paused', handlePaused);
        emitter.on('ended', handleEnded);

        return () => {
            emitter.off('movie.progress', handleProgress);
            emitter.off('paused', handlePaused);
            emitter.off('ended', handleEnded);
        }

    }, []);



    const handleClick = () => {

        if (ended) emitter.emit('replay')
        else if (paused) emitter.emit('resume'); else emitter.emit('pause', 'paused');

    }

    const info = `${msToMinSec(elapsed)} / ${msToMinSec(duration)}`;

    const style = { width: `${elapsed / duration * 100}%` };


    return (
        <>
            <div className="absolute z-[9997] left-0 top-0 right-0 h-1.5 bg-slate-600">
                <div
                    className="absolute left-0 top-0 bottom-0 bg-orange-500/90"
                    style={style}>
                </div>
            </div>

            <div
                className="absolute z-[9998] left-0 top-2.5 p-1 bg-slate-800/90 rounded-r-md select-none flex flex-row items-center"
                data-ignore="outside">

                <button
                    className="group w-6 h-6 p-1 focus:outline-none flex flex-row items-center justify-center"
                    onClick={() => handleClick()}>

                    {!ended && !paused &&
                        <svg
                            width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0H3V10H0V0Z" fill="white" />
                            <path d="M5 0H8V10H5V0Z" fill="white" />
                        </svg>

                    }

                    {!ended && paused &&
                        <svg
                            className="group-hover:filter-orange group-active:scale-110"
                            width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0L0.527894 0.329799L7.50139 4.68838L8 5.00001L7.50139 5.31165L0.527894 9.6702L0 10V0ZM0.69735 1.28287V8.71713L6.64435 5.00001L0.69735 1.28287Z" fill="white" />
                        </svg>
                    }

                    {ended &&
                        <svg
                            className="group-hover:filter-orange group-active:scale-110"
                            width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 6.88235C1 9.15647 2.79086 11 5 11C7.20914 11 9 9.15647 9 6.88235C9 4.60824 7.20914 2.76471 5 2.76471H2.14286M2.14286 2.76471L3.85714 1M2.14286 2.76471L3.85714 4.52941" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                    }


                </button>

                <div className="px-2 text-xs text-white">{info}</div>

                {reason && <div className="pr-2 font-semibold text-xs text-red-500 uppercase">{reason}</div>}


            </div>

        </>

    )

}




export const fileTranslate = async (file, lang, code) => {

    if (!code || code == 'original') return true;

    if (lang == code) return true;

    const arr = get.textForTranslate(file);
    if (!arr.length) return true;

    const result = await fnTranslate(file.userId, arr, lang, code);
    if (!result) return false;

    if (result.length == arr.length) {
        arr.forEach((item, index) => String(item).learn(code, result[index]));
        return true;
    }

    return false;
}


const useTranslate = (file, lang, initialCode, code, done) => {

    const [translating, setTranslating] = useState(false);

    useEffect(() => {
        if (!code) return;

        if (initialCode == code) {

            //loader did this job

            // console.log('no need to translate into, already translated', code, initialCode)

            done(code);
            return;

        }

        const translate = async () => {

            const result = await fileTranslate(file, lang?.code, code);
            if (result) await loadAudio(file, code);

            setTranslating(false);

            done(code);

        }

        setTranslating(true);
        translate();


    }, [code])

    return translating;
}


const Translating = () => {

    const fill = 'white';

    return (
        <div className="animate-mask-more absolute left-0 top-0 right-0 bottom-0 z-[9999] bg-slate-900/90 flex flex-col items-center">

            <div className="mt-[30vh] flex flex-col items-center">

                <svg
                    className="animate-fade mt-6 w-14 h-14"
                    width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke={fill}>
                    <g fill="none" fillRule="evenodd" strokeWidth="2">
                        <circle cx="22" cy="22" r="1">
                            <animate attributeName="r"
                                begin="0s" dur="1.8s"
                                values="1; 20"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.165, 0.84, 0.44, 1"
                                repeatCount="indefinite" />
                            <animate attributeName="stroke-opacity"
                                begin="0s" dur="1.8s"
                                values="1; 0"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.3, 0.61, 0.355, 1"
                                repeatCount="indefinite" />
                        </circle>
                        <circle cx="22" cy="22" r="1">
                            <animate attributeName="r"
                                begin="-0.9s" dur="1.8s"
                                values="1; 20"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.165, 0.84, 0.44, 1"
                                repeatCount="indefinite" />
                            <animate attributeName="stroke-opacity"
                                begin="-0.9s" dur="1.8s"
                                values="1; 0"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.3, 0.61, 0.355, 1"
                                repeatCount="indefinite" />
                        </circle>
                    </g>
                </svg>

            </div>

        </div>
    )
}



const useController = (file, code) => {


    const get = (file) => {

        const scenes = file.content.scenes.filter(scene => scene.visible).map(scene => ({ ...scene, calc: compute.calc(file, scene, code) }));

        const duration = scenes.reduce((a, c) => { c.start = a; return a + c.calc.duration; }, 0);
        const statuses = scenes.reduce((a, c) => { a[c.id] = { a: c.start, b: c.start + c.calc.duration, interact: c.calc.interact }; return a; }, {});

        return { statuses, duration };
    }

    const { statuses, duration } = useMemo(() => get(file, code), [file, code]);

    if (!Object.keys(statuses).length) return;



    const requestRef = useRef(null);

    const timeRef = useRef(null);
    const pausedRef = useRef(null);

    const statusRef = useRef(null);
    const interactRef = useRef(null);


    const arr = Object.keys(statuses).reverse();

    const update = () => {

        const elapsed = performance.now() - timeRef.current;

        emitter.emit('movie.progress', { elapsed, duration });

        const key = arr.find(key => statuses[key].a <= elapsed && elapsed < statuses[key].b);
        if (!key) return { elapsed };

        const event = { status: key, elapsed: elapsed - statuses[key].a, duration: statuses[key].b - statuses[key].a };

        if (statusRef.current != key) {
            interactRef.current = null;
            emitter.emit('scene.change', key);

            statusRef.current = key;

            return { elapsed, event };
        }


        emitter.emit('scene.progress', event);

        return { elapsed, event };

    }


    const check = (event) => {

        if (!event) return;


        const { status, elapsed } = event;
        const interact = statuses[status].interact;

        if (!interact) return;

        if (elapsed > interact && interactRef.current != interact) {

            emitter.emit('pause', 'Waiting for interaction');

            interactRef.current = interact;

        }


    }


    const ended = (elapsed) => {

        const last = arr[0];
        if (statuses[last].a < elapsed) {
            if (statuses[last].b == Infinity || statuses[last].b < elapsed) {
                emitter.emit('ended');
                return true
            }
        }

        return false;
    }

    const tick = () => {

        const { elapsed, event } = update();

        check(event);


        if (ended(elapsed)) requestRef.current = null;

        if (!requestRef.current) return;
        requestRef.current = requestAnimationFrame(tick);
    }

    const play = () => {

        timeRef.current = performance.now();
        pausedRef.current = null;

        statusRef.current = null;
        interactRef.current = null;

        requestRef.current = requestAnimationFrame(tick);

        emitter.emit('paused', { paused: false });

    }




    const isPaused = () => pausedRef.current !== null;
    const isEnded = () => requestRef.current === null && pausedRef.current === null;
    const isWaitingForInteraction = () => interactRef.current !== null;


    const handleReplay = () => {

        // emitter.emit('paused', { paused: false });

        play();

    }

    const handlePause = (reason) => {

        pausedRef.current = performance.now();

        cancelAnimationFrame(requestRef.current);
        requestRef.current = null;

        emitter.emit('paused', { paused: true, reason });
    }

    const handleResume = () => {

        timeRef.current = timeRef.current + performance.now() - pausedRef.current;
        pausedRef.current = null;

        requestRef.current = requestAnimationFrame(tick);
        emitter.emit('paused', { paused: false });
    }


    const handleGoTo = (scene) => {

        const { id } = scene;

        const now = performance.now();

        timeRef.current = now - statuses[id].a;

        let start = isEnded();

        if (isPaused()) {
            if (isWaitingForInteraction()) {
                pausedRef.current = null;
                emitter.emit('paused', { paused: false });
                start = true;
            }
            else pausedRef.current = now;
        }

        statusRef.current = null;
        interactRef.current = null;

        update();

        if (start) requestRef.current = requestAnimationFrame(tick);

    }


    const handleInteractContinue = () => {
        const id = statusRef.current;
        if (!id) return;

        const start = isPaused();

        timeRef.current = performance.now() - statuses[id].a - statuses[id].interact;
        pausedRef.current = null;

        // statusRef.current = null;
        interactRef.current = statuses[id].interact;

        emitter.emit('paused', { paused: false });

        if (start) requestRef.current = requestAnimationFrame(tick);

    }

    const handleInteractReplayScene = () => {

        const id = statusRef.current;
        if (!id) return;

        const start = isPaused();

        timeRef.current = performance.now() - statuses[id].a;
        pausedRef.current = null;

        statusRef.current = null;
        interactRef.current = null;

        emitter.emit('paused', { paused: false });

        if (start) requestRef.current = requestAnimationFrame(tick);


    }


    const v = JSON.stringify(statuses);

    useEffect(() => {

        emitter.on('replay', handleReplay);
        emitter.on('pause', handlePause);
        emitter.on('resume', handleResume);

        emitter.on('goto', handleGoTo);

        emitter.on('interact.continue', handleInteractContinue);
        emitter.on('interact.replay.scene', handleInteractReplayScene);

        play();

        return () => {

            cancelAnimationFrame(requestRef.current);
            requestRef.current = null;

            emitter.off('replay', handleReplay);
            emitter.off('pause', handlePause);
            emitter.off('resume', handleResume);

            emitter.off('goto', handleGoTo);

            emitter.off('interact.continue', handleInteractContinue);
            emitter.off('interact.replay.scene', handleInteractReplayScene);

        }

    }, [v, code])

}



export const Controller = ({ pilot, toc, lang, languages, translate, initialCode }) => {

    const state = useContext(StateContext);


    const { file } = state;


    const scenes = file.content.scenes.filter(scene => scene.visible);
    if (!scenes.length) return;


    const [preferences, setPreferences] = useState({ pilot: false, toc: false });
    const [settings, setSettings] = useState({ ...file.settings });


    const [status, setStatus] = useState(scenes[0].id);
    const [paused, setPaused] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [interactContinue, setInteractContinue] = useState(false);


    const [ended, setEnded] = useState(false);



    const [code, setCode] = useState(initialCode);
    const [translateTo, setTranslateTo] = useState(null);

    const translating = useTranslate(file, lang, initialCode, translateTo, (v) => {

        // console.log('restart translation with code', v);
        setCode(v);//restarts useController and replay

    });




    useController(file, code);


    const handleTogglePilot = () => {
        setPreferences(prev => ({ ...prev, pilot: !prev.pilot }));
    }

    const handleToggleToc = () => {
        setPreferences(prev => ({ ...prev, toc: !prev.toc }));
    }

    const handleMode = (mode) => {
        setSettings(prev => ({ ...prev, mode }));
    }






    const handleSceneChange = (status) => {
        // console.log('SCENE CHANGE', status)

        cacheAudioStop();

        setStatus(status);
        setRefresh(prev => prev + 1);

        setInteractContinue(false);
    }

    const handlePaused = ({ paused }) => {

        if (paused) cacheAudioPause();
        setPaused(paused);

    }


    const handleEnded = () => {

        setEnded(true);

    }

    const handleTranslate = (code) => {

        setTranslateTo(code);
        emitter.emit('pause', 'translating');

    }

    const handleInteractContinue = () => {

        cacheAudioStop();
        setInteractContinue(true);

    }

    const handleInteractReplayScene = () => {

        // cacheAudioStop();


    }


    useEffect(() => {

        emitter.on('toggle.pilot', handleTogglePilot);
        emitter.on('toggle.toc', handleToggleToc);
        emitter.on('mode', handleMode);


        emitter.on('scene.change', handleSceneChange);
        emitter.on('paused', handlePaused);
        emitter.on('ended', handleEnded);

        emitter.on('translate', handleTranslate);

        emitter.on('interact.continue', handleInteractContinue);
        emitter.on('interact.replay.scene', handleInteractReplayScene);

        return () => {

            emitter.off('toggle.pilot', handleTogglePilot);
            emitter.off('toggle.toc', handleToggleToc);
            emitter.off('mode', handleMode);


            emitter.off('scene.change', handleSceneChange);
            emitter.off('paused', handlePaused);
            emitter.off('ended', handleEnded);

            emitter.off('translate', handleTranslate);

            emitter.off('interact.continue', handleInteractContinue);
            emitter.off('interact.replay.scene', handleInteractReplayScene);

        }

    }, []);




    const scene = scenes.find(scene => scene.id == status);

    if (scene) {


        if (!window.rec && !paused && !interactContinue && scene && scene.speak.enabled && scene.speak.text) {

            const text = scene.speak.text.translate(code);
            const { voice_id } = scene.speak;

            if (text.canPlay(voice_id))
                text.play(file.userId, voice_id, null, false);

        }


    }






    useEffect(() => { return () => cacheAudioStop(); }, [])


    //todo: pilot
    //tood: lang, doar daca e autotranslate iar in play tot timpul


    // console.log('ended: ', ended);


    return (
        <>
            {window.rec && ended && <div id="end" className="fixed left-0 top-0 invisible">END</div>}


            {!window.rec && <TimeBar />}

            <EditCanvas preferences={preferences} settings={settings} scenes={scenes} scene={scene} motion={true} paused={paused} pilot={pilot} toc={toc} lang={lang} languages={languages} translate={translate} mode={!window.rec} code={code} />

            {translating && <Translating />}
        </>
    )





}