import "../config/firebase";

import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";


const functions = getFunctions();

//TODO: only for testing, comment for production
// connectFunctionsEmulator(functions, "127.0.0.1", 5001);


// start
// firebase emulators:start --only functions


// deploy
// https://firebase.google.com/docs/cli/#partial_deploys
// firebase deploy --only functions
// firebase deploy --only functions:function1

//https://console.cloud.google.com/functions/list?project=demobuilder-d24ed


const getVoices = httpsCallable(functions, 'getVoices');
const textToSpeech = httpsCallable(functions, 'textToSpeech');

const ask = httpsCallable(functions, 'ask');

const detect = httpsCallable(functions, 'detect');

const getLanguages = httpsCallable(functions, 'getLanguages');
const translate = httpsCallable(functions, 'translate');

const rec = httpsCallable(functions, 'rec');

const sendEmail = httpsCallable(functions, 'sendEmail');

const getFile = httpsCallable(functions, 'getFile');
const copyFile = httpsCallable(functions, 'copyFile');

const copyFolder = httpsCallable(functions, 'copyFolder');

const getView = httpsCallable(functions, 'getView');


const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
const stopPendingCancellation = httpsCallable(functions, 'stopPendingCancellation');
const changeSubscription = httpsCallable(functions, 'changeSubscription');


const goodbye = httpsCallable(functions, 'goodbye');


// Firebase Stripe Extension
const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');




// ─── TextToSpeech ────────────────────────────────────────────────────────────

export const fnGetVoices = async () => {

    try {
        const result = await getVoices();
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return [];
};

export const fnTextToSpeech = async (uid, voice_id, text) => {

    if (!text) return null;
    if (!text.length) return null;

    const tag = ' <break time="1.5s" /> ';

    let lText = text.trim();
    lText = lText.replace(/\n+/g, '\n');//remove two consecutive \n
    lText = lText.length > 0 && lText.charAt(0) === '\n' ? lText.slice(1) : lText;
    lText = lText.length > 0 && lText.slice(-1) === '\n' ? lText.slice(0, -1) : lText;
    lText = lText.replace(/\n/g, tag);

    if (!lText) return null;

    const marks = ['.', '?', '!', ',', ';', ':'];
    if (!marks.some(mark => lText.endsWith(mark))) lText = lText + '.';


    try {
        const result = await textToSpeech({ uid, voice_id, text: lText });
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return null;

};

// ─── OpenAi ──────────────────────────────────────────────────────────────────

export const fnAsk = async (uid, prompt) => {

    try {
        const result = await ask({ uid, prompt });
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return null;
};

// ─── Detect Language ─────────────────────────────────────────────────────────

export const fnDetect = async (uid, text) => {

    if (!text) return null;

    try {
        const result = await detect({ uid, text });
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return null;
};

// ─── Translate ───────────────────────────────────────────────────────────────

export const fnGetLanguages = async () => {

    try {
        const result = await getLanguages();
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return { source: [], target: [] };
};

export const fnTranslate = async (uid, arr, lang, code) => {

    if (!arr.length) return [];

    try {
        const result = await translate({ uid, arr, lang, code });
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return [];
};

// ─── Export ──────────────────────────────────────────────────────────────────

export const fnRec = async (job) => {

    try {
        const result = await rec(job);
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return null;
};

// ─── Contact ─────────────────────────────────────────────────────────────────

export const fnSendEmail = async (data) => {

    try {
        const result = await sendEmail(data);
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return null;
};

// ─── Files ───────────────────────────────────────────────────────────────────

export const fnGetFile = async (id, count) => {

    try {
        const result = await getFile({ id, count });
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return null;
};

export const fnCopyFile = async (id, name = null, folderId = undefined) => {

    const params = folderId === undefined ? { id, name } : { id, name, folderId };

    try {
        const result = await copyFile(params);
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return null;
};

export const fnCopyFolder = async (folderId, name = null) => {

    try {
        const result = await copyFolder({ folderId, name });
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return null;
};

// ─── View ────────────────────────────────────────────────────────────────────

export const fnGetView = async (type, param) => {
    //type : 'folder', 'workspace'
    //param: folderId or userId

    try {
        const result = await getView({ type, param });
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return null;
};

// ─── Stripe ──────────────────────────────────────────────────────────────────

export const fnCancelSubscription = async (id) => {

    try {
        const result = await cancelSubscription(id);
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return null;
};

export const fnStopPendingCancellation = async (id) => {

    try {
        const result = await stopPendingCancellation(id);
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return null;
};

export const fnChangeSubscription = async (id, subItemId, pid, metadata) => {

    try {
        const result = await changeSubscription({ id, subItemId, pid, metadata });
        return result.data;
    }
    catch (err) {
        console.log(err);
    }

    return null;
};


// ─── Users ───────────────────────────────────────────────────────────────────

export const fnGoodbye = async () => {

    try {
        const result = await goodbye();
        return result.data;

    }
    catch (err) {
        console.log(err);
    }

    return null;
};

// ─── Firebase Stripe Extension ───────────────────────────────────────────────

export const fnCreatePortalLink = async () => {

    try {

        const result = await createPortalLink({
            returnUrl: window.location.origin,
            locale: "auto", // Optional, defaults to "auto"
            // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
        })

        if (!result?.data) return null;

        const { url } = result.data;

        return url;


    } catch (err) {
        console.log(err);

    }

    return null;

}
