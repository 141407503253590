import { useState } from "react";
import { useEffect } from 'react';
import { useRef } from 'react';

import { ButtonWithOptions } from "./Buttons";



import classNames from 'classnames';




const Wait = ({ value = 0, min = 0, max = 5000, place = "bl", tooltip = "Wait", change, children }) => {

    const ref = useRef(null);


    const handleClick = (v) => {
        ref.current.close();

        change(v * 1000);
    }

    const friendly = (v) => `Wait ${v}s`;

    const range = [...Array(max / 1000 - min / 1000 + 1).keys()].map(i => i + min / 1000);

    const list = range.map(item =>
        <li
            className={classNames('pl-2 pr-2 py-1 cursor-pointer hover:bg-slate-600', value == item * 1000 && '!bg-blue-500')}
            key={item}
            onClick={() => handleClick(item)}>
            <span className="text-sm text-white text-nowrap">{friendly(item)}</span>
        </li>)

    return (
        <ButtonWithOptions place={place} same={true} tooltip={tooltip} ref={ref}>

            <div className="min-w-12 flex flex-row items-center space-x-1">
                {!children &&
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.2942 10.8993L15.6508 9.57434C16.4552 8.78872 16.9115 7.69169 16.9115 6.54315V4.63214C16.9118 4.18343 16.7352 3.77158 16.4524 3.47819C16.1708 3.18358 15.7754 2.9997 15.3447 3H8.56682C8.13606 2.9997 7.74043 3.18358 7.45875 3.47816C7.17598 3.77158 6.99943 4.1834 7 4.63211V6.54309C7 7.69162 7.45629 8.78869 8.26073 9.57428L9.61701 10.8987C9.69166 10.9719 9.73425 11.0737 9.73425 11.1807V11.8193C9.73425 11.9262 9.69166 12.0278 9.6173 12.1007L8.26076 13.4257C7.45633 14.211 7.00003 15.3083 7.00003 16.4569V18.3679C6.99946 18.8166 7.17605 19.2284 7.45878 19.5218C7.74049 19.8164 8.13609 20.0003 8.56686 20H15.3447C15.7754 20.0003 16.1708 19.8164 16.4525 19.5218C16.7352 19.2284 16.9118 18.8166 16.9115 18.3679V16.4569C16.9115 15.3084 16.4552 14.2111 15.6508 13.4258L14.2945 12.101C14.2196 12.0278 14.1773 11.9263 14.1773 11.8193V11.1807C14.1773 11.0738 14.2196 10.972 14.2942 10.8993ZM15.7153 18.3679C15.715 18.4766 15.6747 18.569 15.6066 18.6408C15.5377 18.7117 15.449 18.7537 15.3446 18.754H8.56682C8.4625 18.7537 8.37353 18.7117 8.30462 18.6408C8.23653 18.569 8.19647 18.4766 8.19618 18.3679V16.4569C8.19618 15.653 8.51576 14.8846 9.07883 14.3349L10.4351 13.0099C10.7511 12.7016 10.9304 12.2704 10.9304 11.8193V11.1807C10.9304 10.7294 10.7511 10.2982 10.4349 9.98985L9.07883 8.66517C8.51573 8.11553 8.19618 7.34734 8.19618 6.54315V4.63214C8.19647 4.52347 8.23653 4.43109 8.30462 4.35901C8.37353 4.28839 8.4625 4.24632 8.56682 4.24605H15.3446C15.4489 4.24632 15.5377 4.28835 15.6066 4.35901C15.6747 4.43109 15.715 4.52343 15.7153 4.63214V6.54312C15.7153 7.3473 15.3957 8.11549 14.8326 8.66514L13.4763 9.98982C13.1603 10.2981 12.981 10.7294 12.981 11.1807V11.8193C12.981 12.2703 13.1603 12.7016 13.4766 13.0104L14.8326 14.3346C15.3957 14.8845 15.7153 15.653 15.7153 16.4569V18.3679Z" fill="white" />
                        <path d="M11.4649 16.2574L9 18.825H12.062H15.1241L12.6592 16.2574C12.3295 15.9142 11.7949 15.9142 11.4649 16.2574Z" fill="white" />
                    </svg>


                }

                {children}

                <span className="text-sm text-white">{Math.round(value / 1000)}s</span>
            </div>

            <ul className="p-1">
                {list}
            </ul>
        </ButtonWithOptions>
    );

};

export default Wait;
