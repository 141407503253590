import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import { remove as cacheImgRemove, add as cacheImgAdd} from "../cache/cache-img";
import { can, check } from "./utils.js";
import { getBase64 } from '../utils';

import { useRefObserveDimensions, useDrag } from "../hooks/index.js";


import ContextMenu from '../ui/ContextMenu.js';

import { objectsEqual } from "../utils/index.js";

import { EXT } from "../utils/compress.js";

import blurImage from "../utils/blur.js";

import classNames from "classnames";



export const Rect = ({ rect, change, remove, contextMenu }) => {

    const { left, top, width, height, minW, minH } = rect;

    const ref = useRef(null);


    const [isDragging, setIsDragging] = useState(false);
    const [dragInfo, setDragInfo] = useState(null);

    const [isResizing, setIsResizing] = useState(false);
    const [resizeInfo, setResizeInfo] = useState(null);


    const options = [
        {
            name: 'Delete',
            action: () => remove(),
            disabled: !rect.can.includes('remove'),
        }
    ];



    const checkDrag = (rect) => {

        const pw = ref.current.parentElement.offsetWidth;
        const ph = ref.current.parentElement.offsetHeight;

        rect.left = Math.max(rect.left, 0);
        rect.left = Math.min(rect.left, pw - rect.width);

        rect.top = Math.max(rect.top, 0);
        rect.top = Math.min(rect.top, ph - rect.height);

        rect.left = Math.round(rect.left);
        rect.top = Math.round(rect.top);
        rect.width = Math.round(rect.width);
        rect.height = Math.round(rect.height);

        return rect;
    }


    useDrag(isDragging,
        (x, y) => {

            const scale = ref.current.parentElement.getBoundingClientRect().width / ref.current.parentElement.offsetWidth;

            const deltaX = x - dragInfo.x;
            const deltaY = y - dragInfo.y;

            const newLeft = dragInfo.rect.left + deltaX * 1 / scale;
            const newTop = dragInfo.rect.top + deltaY * 1 / scale;

            const rect = checkDrag({ ...dragInfo.rect, left: newLeft, top: newTop });

            change(rect);


        },
        () => {
            setIsDragging(false);

        })



    const handleMouseDown = (e) => {
        if (!rect.can.includes('move')) return;

        e.stopPropagation();

        setIsDragging(true);
        setDragInfo({ x: e.clientX, y: e.clientY, rect: { left, top, width, height } })

    }


    const handleKeyDown = (e) => {

        e.stopPropagation();


        switch (e.keyCode) {
            case 46:

                if (rect.can.includes('remove')) remove();

                break;

            case 27:
                if (isDragging) {
                    change(dragInfo.rect);
                    setIsDragging(false);
                };

                if (isResizing) {
                    change(resizeInfo.rect);
                    setIsResizing(false);
                };

                break;
        }


    };


    const checkResize = (rect, mode) => {

        const pw = ref.current.parentElement.offsetWidth;
        const ph = ref.current.parentElement.offsetHeight;

        if (rect.left < 0) {
            rect.width = rect.width + rect.left;
            rect.left = 0;
        }

        if (rect.top < 0) {
            rect.height = rect.height + rect.top;
            rect.top = 0;
        }

        if (rect.left + rect.width > pw) {
            rect.width = pw - rect.left;
        }

        if (rect.top + rect.height > ph) {
            rect.height = ph - rect.top;
        }

        if (['ew', 'nwse', 'swne'].includes(mode)) {
            if (rect.width < minW) {
                rect.left = rect.left + rect.width - minW;
                rect.width = minW;
            }
        }

        if (['ns', 'nwse', 'nesw'].includes(mode)) {
            if (rect.height < minH) {
                rect.top = rect.top + rect.height - minH;
                rect.Height = minH;
            }
        }

        if (rect.width < minW) rect.width = minW;
        if (rect.height < minH) rect.height = minH;



        rect.left = Math.round(rect.left);
        rect.top = Math.round(rect.top);
        rect.width = Math.round(rect.width);
        rect.height = Math.round(rect.height);

        return rect;
    }


    useDrag(isResizing,
        (x, y) => {

            const scale = ref.current.parentElement.getBoundingClientRect().width / ref.current.parentElement.offsetWidth;

            const deltaX = x - resizeInfo.x;
            const deltaY = y - resizeInfo.y;

            let newLeft = resizeInfo.rect.left;
            let newTop = resizeInfo.rect.top;
            let newWidth = resizeInfo.rect.width;
            let newHeight = resizeInfo.rect.height;


            switch (resizeInfo.mode) {
                case 'ns':
                    newTop = newTop + deltaY * 1 / scale;
                    newHeight = newHeight - deltaY * 1 / scale;
                    break;
                case 'sn':
                    newHeight = newHeight + deltaY * 1 / scale;
                    break;
                case 'ew':
                    newLeft = newLeft + deltaX * 1 / scale;
                    newWidth = newWidth - deltaX * 1 / scale;
                    break;
                case 'we':
                    newWidth = newWidth + deltaX * 1 / scale;
                    break;
                case 'nwse':
                    newTop = newTop + deltaY * 1 / scale;
                    newHeight = newHeight - deltaY * 1 / scale;

                    newLeft = newLeft + deltaX * 1 / scale;
                    newWidth = newWidth - deltaX * 1 / scale;
                    break;
                case 'senw':
                    newHeight = newHeight + deltaY * 1 / scale;
                    newWidth = newWidth + deltaX * 1 / scale;
                    break;
                case 'nesw':
                    newTop = newTop + deltaY * 1 / scale;
                    newHeight = newHeight - deltaY * 1 / scale;

                    newWidth = newWidth + deltaX * 1 / scale;
                    break;
                case 'swne':
                    newLeft = newLeft + deltaX * 1 / scale;
                    newWidth = newWidth - deltaX * 1 / scale;

                    newHeight = newHeight + deltaY * 1 / scale;
                    break;

            }

            const rect = checkResize({ left: newLeft, top: newTop, width: newWidth, height: newHeight }, resizeInfo.mode);

            change(rect);


        },
        () => {
            setIsResizing(false);
        })


    const handleMouseDownResize = (e, mode) => {
        e.stopPropagation();

        setIsResizing(true);
        setResizeInfo({ mode, x: e.clientX, y: e.clientY, rect: { left, top, width, height } });
    }

    const handleWheel = (e) => {
        // if (rect.can.includes('move')) e.stopPropagation();
    }

    const handleContextMenu = (e) => {
        if (!options.every(option => option.disabled)) {
            e.stopPropagation();
            contextMenu(e.clientX, e.clientY, options);
        }
    }




    const style = { left: `${left}px`, top: `${top}px`, width: `${width}px`, height: `${height}px`, ...rect.effect }



    return (

        <>
            {isResizing &&
                <div
                    className={
                        classNames('absolute left-0 top-0 right-0 bottom-0',
                            isResizing && resizeInfo.mode == 'ns' && 'cursor-ns-resize',
                            isResizing && resizeInfo.mode == 'sn' && 'cursor-ns-resize',
                            isResizing && resizeInfo.mode == 'ew' && 'cursor-ew-resize',
                            isResizing && resizeInfo.mode == 'we' && 'cursor-ew-resize',
                            isResizing && resizeInfo.mode == 'nwse' && 'cursor-nwse-resize',
                            isResizing && resizeInfo.mode == 'senw' && 'cursor-nwse-resize',
                            isResizing && resizeInfo.mode == 'nesw' && 'cursor-nesw-resize',
                            isResizing && resizeInfo.mode == 'swne' && 'cursor-nesw-resize',

                        )
                    }>
                </div>}

            <div
                ref={ref}
                className={classNames('absolute focus:outline-none', rect.selecting && 'pointer-events-none', !isResizing && rect.can.includes('move') && 'cursor-default')}
                style={style}
                tabIndex={-1}
                onMouseDown={(e) => handleMouseDown(e)}
                onKeyDown={(e) => handleKeyDown(e)}
                onWheel={(e) => handleWheel(e)}
                onContextMenu={(e) => { e.preventDefault(); handleContextMenu(e); }}>


                {/* using this div it's a fix, even my calculations below are correct, overflow-clip do not show when maximized */}
                <div className="absolute -left-px -top-px -right-px -bottom-px">


                    <div
                        className={classNames('absolute -left-px -right-px top-0 h-1 border-b border-b-red-500 cursor-ns-resize hover:!border-b-red-600 -translate-y-full', isResizing && resizeInfo.mode == 'ns' && '!border-b-red-600')}
                        onMouseDown={(e) => handleMouseDownResize(e, 'ns')}>
                    </div>

                    <div
                        className={classNames('absolute -left-px -right-px bottom-0 h-1 border-t border-t-red-500 cursor-ns-resize hover:!border-t-red-600 translate-y-full', isResizing && resizeInfo.mode == 'sn' && '!border-t-red-600')}
                        onMouseDown={(e) => handleMouseDownResize(e, 'sn')}>
                    </div>

                    <div
                        className={classNames('absolute left-0 -top-px -bottom-px w-1 border-r border-r-red-500 cursor-ew-resize hover:!border-r-red-600 -translate-x-full', isResizing && resizeInfo.mode == 'ew' && '!border-r-red-600')}
                        onMouseDown={(e) => handleMouseDownResize(e, 'ew')}>
                    </div>

                    <div
                        className={classNames('absolute right-0 -top-px -bottom-px w-1 border-l border-l-red-500 cursor-ew-resize hover:!border-l-red-600 translate-x-full', isResizing && resizeInfo.mode == 'we' && '!border-l-red-600')}
                        onMouseDown={(e) => handleMouseDownResize(e, 'we')}>
                    </div>





                    <div
                        className="absolute left-0 top-0 w-6 h-6 max-w-[30%] max-h-[30%] cursor-nwse-resize -translate-x-px -translate-y-px"
                        onMouseDown={(e) => handleMouseDownResize(e, 'nwse')}>
                        {rect.can.includes('show-corners') && <div className={classNames('absolute left-0 top-0 right-0 bottom-0 border-l-4 border-l-red-500 border-t-4 border-t-red-500 hover:!border-l-red-600 hover:!border-t-red-600 translate-x-[2px] translate-y-[2px]', isResizing && resizeInfo.mode == 'nwse' && '!border-l-red-600 !border-t-red-600')}></div>}
                    </div>

                    <div
                        className="absolute right-0 bottom-0 w-6 h-6 max-w-[30%] max-h-[30%] cursor-nwse-resize translate-x-px translate-y-px"
                        onMouseDown={(e) => handleMouseDownResize(e, 'senw')}>
                        {rect.can.includes('show-corners') && <div className={classNames('absolute left-0 top-0 right-0 bottom-0 border-r-4 border-r-red-500 border-b-4 border-b-red-500 hover:!border-r-red-600 hover:!border-b-red-600 -translate-x-[2px] -translate-y-[2px]', isResizing && resizeInfo.mode == 'senw' && '!border-r-red-600 !border-b-red-600')}></div>}
                    </div>

                    <div
                        className="absolute right-0 top-0 w-6 h-6 max-w-[30%] max-h-[30%] cursor-nesw-resize translate-x-px -translate-y-px"
                        onMouseDown={(e) => handleMouseDownResize(e, 'nesw')}>
                        {rect.can.includes('show-corners') && <div className={classNames('absolute left-0 top-0 right-0 bottom-0 border-r-4 border-r-red-500 border-t-4 border-t-red-500 hover:!border-r-red-600 hover:!border-t-red-600 -translate-x-[2px] translate-y-[2px]', isResizing && resizeInfo.mode == 'nesw' && '!border-r-red-600 !border-t-red-600')}></div>}
                    </div>

                    <div
                        className="absolute left-0 bottom-0 w-6 h-6 max-w-[30%] max-h-[30%] cursor-nesw-resize -translate-x-px translate-y-px"
                        onMouseDown={(e) => handleMouseDownResize(e, 'swne')}>
                        {rect.can.includes('show-corners') && <div className={classNames('absolute left-0 top-0 right-0 bottom-0 border-l-4 border-l-red-500 border-b-4 border-b-red-500 hover:!border-l-red-600 hover:!border-b-red-600 translate-x-[2px] -translate-y-[2px]', isResizing && resizeInfo.mode == 'swne' && '!border-l-red-600 !border-b-red-600')}></div>}
                    </div>
                    
                </div >


            </div >


        </>
    );

};





const calc = (dimensions, w, h, factor) => {

    if (!dimensions) return { dw: w, dh: h, scale: 1 };

    const cw = dimensions.width * factor;
    const ch = dimensions.height * factor;
    const scale = Math.min(Math.min(cw / w, ch / h), 1);

    const dw = w * scale;
    const dh = h * scale;

    return { dw, dh, scale };

}

const useBase64 = (url, crop) => {

    const [base64, setBase64] = useState(null)

    useEffect(() => {

        const load = async () => {
            const img = await url.img();
            setBase64(getBase64(img, crop));
        };

        load();

    }, []);

    return base64;
}

const Modifier = ({ caption, confirmMessage, url, crop, naturalWidth, naturalHeight, rects, options, change, add, remove, reset, execute, close }) => {

    const ref = useRef(null);
    const dimensions = useRefObserveDimensions(ref);

    const initialZoom = { value: 1, x: 0, y: 0 };
    const initialMode = add ? 'select' : '';

    const [zoom, setZoom] = useState(initialZoom);
    const [mode, setMode] = useState(initialMode);

    const [isPanning, setIsPanning] = useState(false);
    const [panInfo, setPanInfo] = useState(null);

    const [isSelecting, setIsSelecting] = useState(false);
    const [selectInfo, setSelectInfo] = useState(null);


    const [isConfirming, setIsConfirming] = useState(false);

    const [isExecuting, setIsExecuting] = useState(false);
    const [executeInfo, setExecuteInfo] = useState(null);


    const [contextMenuParams, setContextMenuParams] = useState(null);

    const w = crop ? crop.width : naturalWidth;
    const h = crop ? crop.height : naturalHeight;


    const { dw, dh, scale } = calc(dimensions, w, h, 0.7);

    const z = zoom.value != 1 ? { 'transform': `scale(${zoom.value})`, 'left': `${zoom.x}px`, 'top': `${zoom.y}px`, } : null;

    const style1 = { width: `${dw}px` };
    const style2 = { width: `${dw}px`, height: `${dh}px`, };
    const style3 = { 'transform': `scale(${scale})` };
    const style4 = { width: `${w}px`, height: `${h}px`, ...z };
    const style5 = { width: `${w}px`, height: `${h}px`, };


    const base64 = useBase64(url, crop);



    const transformPoint = (x, y, target) => {
        const { left, top } = target.getBoundingClientRect();

        const newX = (x - left) * 1 / zoom.value * 1 / scale;
        const newY = (y - top) * 1 / zoom.value * 1 / scale;

        return { x: newX, y: newY };
    }

    const getSelectingRect = (x, y) => {
        const point = transformPoint(x, y, selectInfo.target);

        const pr = selectInfo.target.getBoundingClientRect();

        const x1 = selectInfo.x;
        const y1 = selectInfo.y;

        const x2 = Math.min(Math.max(point.x, 0), pr.width * 1 / zoom.value * 1 / scale);
        const y2 = Math.min(Math.max(point.y, 0), pr.height * 1 / zoom.value * 1 / scale);

        let left = Math.min(x1, x2);
        let top = Math.min(y1, y2);

        let width = Math.abs(x2 - x1);
        let height = Math.abs(y2 - y1);

        left = Math.round(left);
        top = Math.round(top);
        width = Math.round(width);
        height = Math.round(height);

        return { left, top, width, height };

    }

    useDrag(isSelecting,
        (x, y) => {
            const { left, top, width, height } = getSelectingRect(x, y);
            change(rects.length - 1, { left, top, width, height });

        },
        (x, y) => {
            const r = rects[rects.length - 1];
            const { left, top, width, height } = getSelectingRect(x, y);

            if (width < r.minW || height < r.minW) remove(rects.length - 1);
            else change(rects.length - 1, { left, top, width, height, selecting: false })

            setIsSelecting(false);

        })

    useDrag(isPanning,
        (x, y) => {
            const deltaX = x - panInfo.x;
            const deltaY = y - panInfo.y;

            const { newX, newY } = check.pan(panInfo.zoom.x + deltaX * 1 / scale, panInfo.zoom.y + deltaY * 1 / scale, { width: w, height: h }, zoom.value);

            setZoom({ ...zoom, x: newX, y: newY })


        },
        () => {
            setIsPanning(false);

        })


    const handleMouseDown = (e) => {

        e.stopPropagation();

        switch (mode) {
            case 'select':
                if (!add) break;

                const point = transformPoint(e.clientX, e.clientY, e.target);

                setIsSelecting(true);
                setSelectInfo({ ...point, target: e.target });

                add({ left: point.x, top: point.y, width: 0, height: 0 });

                break;
            case 'pan':
                setIsPanning(true);
                setPanInfo({ x: e.clientX, y: e.clientY, zoom: { ...zoom } })

                break;

        }



    }


    const handleKeyDown = (e) => {

        e.stopPropagation();

        switch (e.keyCode) {
            case 72:
                //H - hand tool

                if (zoom.value != 1 && !isPanning && !isSelecting) setMode(null);

                break;

            case 27:
                if (isSelecting) {
                    remove(rects.length - 1);
                    setIsSelecting(false);
                    return;
                }

                if (isPanning) {
                    setZoom({ ...panInfo.zoom })
                    setIsPanning(false);
                    return;
                };

                if (mode == 'pan') setMode(null);

                break;
        }


    };

    const handleWheel = (e) => {

        if (rects.some(r => r.width == w && r.height == h)) return;


        const v = Math.sign(e.deltaY);
        const newValue = Math.min(Math.max(zoom.value - 0.1 * v, 1), 3);

        if (zoom.value == newValue) return;

        const left = dimensions.left + (dimensions.width - w * scale) / 2;
        const top = dimensions.top + (dimensions.height - h * scale) / 2;

        let lX = e.clientX - left;
        let lY = e.clientY - top;

        lX = lX * 1 / scale * 1 / zoom.value;
        lY = lY * 1 / scale * 1 / zoom.value;

        lX = lX - zoom.x * 1 / zoom.value;
        lY = lY - zoom.y * 1 / zoom.value;

        const deltaX = lX * newValue - lX * zoom.value;
        const deltaY = lY * newValue - lY * zoom.value;

        const { newX, newY } = check.pan(zoom.x - deltaX, zoom.y - deltaY, { width: w, height: h }, newValue);

        setZoom({ value: newValue, x: newX, y: newY })

        setMode(newValue != 1 ? 'pan' : null);

    }

    const handleReset = () => {
        setZoom(initialZoom)
        setMode(initialMode);

        reset();
    }

    const handleExecute = (v) => {

        setIsExecuting(true);

        execute(v,
            () => {
                setIsExecuting(false);
                close();

            }, (err) => {
                console.log(err);
                setIsExecuting(false);
            });

    }

    const handleClose = (v) => {

        setExecuteInfo(v);

        if (confirmMessage) {
            setIsConfirming(true);
        }
        else {
            handleExecute(v);
        }


    }

    const handleConfirm = (v) => {

        if (v) {
            handleExecute(executeInfo);
        }
        else {
            setIsConfirming(false);
        }



    }

    const listRects = rects.map((rect, index) => <Rect key={index} rect={rect} change={(v) => change(index, v)} remove={() => { if (remove) remove(index) }} contextMenu={(x, y, options) => setContextMenuParams({ x, y, sender: null, options })} />)


    return (
        <>
            <div
                className="animate-mask-more animate-fill-forwards fixed left-0 top-0 right-0 bottom-0 bg-black z-[99]"
                onMouseDown={() => close()}>
            </div>

            <div
                ref={ref}
                className="fixed left-0 top-0 right-0 bottom-0 z-[100] flex flex-col items-center justify-center"
                onMouseDown={() => close()}>

                {dimensions &&
                    <>
                        <div
                            className="min-w-[22rem] px-2 py-2 mb-1 bg-white shadow flex flex-row items-center"
                            style={style1}
                            onMouseDown={(e) => e.stopPropagation()}>

                            {!isConfirming &&
                                <>
                                    <div className="flex-1 flex flex-row items-center space-x-1">
                                        <button
                                            className={classNames('group px-5 py-2 border border-gray-100 text-xs rounded transition-all hover:bg-red-500 hover:text-white flex flex-row items-center justify-center space-x-2', !rects.length && 'text-gray-400 pointer-events-none')}
                                            onClick={() => handleClose({ applytoall: false })}>
                                            {isExecuting && !executeInfo.applytoall && <span className="animate-spin w-2 h-2 bg-black"></span>}
                                            <span className="group-active:translate-y-px">{caption}</span>
                                        </button>

                                        <button
                                            className={classNames('group px-5 py-2 border border-gray-100 text-xs rounded transition-all hover:bg-red-500 hover:text-white flex flex-row items-center justify-center space-x-2', !rects.length && 'text-gray-400 pointer-events-none')}
                                            onClick={() => handleClose({ applytoall: true })}>
                                            {isExecuting && executeInfo.applytoall && <span className="animate-spin w-2 h-2 bg-black"></span>}
                                            <span className="group-active:translate-y-px">{caption} all scenes</span>
                                        </button>
                                    </div>


                                    <div className="flex flex-row items-center">
                                        {add &&
                                            <button
                                                className={classNames('group w-8 h-8 rounded-sm hover:bg-gray-200 flex flex-row items-center justify-center', mode == 'select' && '!bg-blue-500')}
                                                onMouseDown={() => setMode(mode != 'select' ? 'select' : null)}>

                                                <svg
                                                    className={classNames('h-3', mode == 'select' && 'filter-white')}
                                                    width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <line x1="14.5" y1="6.5567e-08" x2="14.5" y2="28" stroke="black" strokeWidth="3" />
                                                    <path d="M0.5 14L28.5 14" stroke="black" strokeWidth="3" />
                                                </svg>


                                            </button>
                                        }


                                        <button
                                            className={classNames('group w-8 h-8 rounded-sm hover:bg-gray-200 flex flex-row items-center justify-center', mode == 'pan' && 'animate-wiggle animate-infinite !bg-blue-500', zoom.value == 1 && 'opacity-20 pointer-events-none')}
                                            onMouseDown={() => setMode(mode != 'pan' ? 'pan' : null)}>

                                            <svg
                                                className={classNames('h-4', mode == 'pan' && 'filter-white')}
                                                width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.8 9.6V12.8H10.4L8 16L5.6 12.8H7.2V9.6H8.8ZM12.8 5.6L16 8L12.8 10.4V8.8H9.6V7.2H12.8V5.6ZM3.2 5.6V7.2H6.4V8.8H3.2V10.4L0 8L3.2 5.6ZM8 0L10.4 3.2H8.8V6.4H7.2V3.2H5.6L8 0Z" fill="black" />
                                            </svg>

                                        </button>

                                        <span className="w-4"></span>

                                        <button
                                            className="group w-8 h-8 flex flex-row items-center justify-center"
                                            onClick={() => handleReset()}>

                                            <svg
                                                className="h-4 opacity-70 group-hover:!opacity-100 group-active:translate-y-px"
                                                width="36" height="36" viewBox="0 -4 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3 25.5V28C3 30.7615 5.23857 33 8 33H10.5M33 25.5V28C33 30.7615 30.7615 33 28 33H25.5M3 10.5V8C3 5.23857 5.23857 3 8 3H10.5M33 10.5V8C33 5.23857 30.7615 3 28 3H25.5" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>


                                        </button>
                                    </div>
                                </>
                            }

                            {isConfirming &&
                                <>

                                    <div className="animate-fade-left animate-delay-100 animate-duration-500 flex-1 flex flex-row items-center space-x-1">
                                        <button
                                            className="group px-5 py-2 bg-red-400 border border-gray-100 text-xs rounded transition-all hover:bg-red-500 hover:text-white flex flex-row items-center justify-center space-x-2"
                                            onClick={() => handleConfirm(true)}>
                                            {isExecuting && <span className="animate-spin w-2 h-2 bg-black"></span>}
                                            <span className="group-active:translate-y-px">Confirm</span>
                                        </button>

                                        <button
                                            className="group px-5 py-2 border border-gray-100 text-xs rounded transition-all hover:bg-red-500 hover:text-white flex flex-row items-center justify-center"
                                            onClick={() => handleConfirm(false)}>
                                            <span className="group-active:translate-y-px">Cancel</span>
                                        </button>

                                        <p className="px-2 text-xs text-red-500 select-none">{confirmMessage}</p>
                                    </div>

                                </>

                            }

                        </div>





                        <div
                            className={classNames('relative bg-white overflow-clip select-none', mode == 'pan' && 'cursor-grab', isPanning && '!cursor-grabbing', mode == 'select' && 'cursor-crosshair')}
                            style={style2}
                            onMouseDown={(e) => e.stopPropagation()}
                            // onWheel={(e) => handleWheel(e)}
                            onContextMenu={(e) => { e.preventDefault(); setContextMenuParams({ x: e.clientX, y: e.clientY, sender: null, options: options }); }}>



                            <div
                                className="absolute left-0 top-0"
                                style={style3}>
                                <div
                                    className="absolute origin-top-left focus:outline-none"
                                    style={style4}
                                    tabIndex={-1}
                                    onMouseDown={(e) => handleMouseDown(e)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onWheel={(e) => handleWheel(e)}>
                                    <img
                                        className="absolute origin-top-left max-w-none max-h-none pointer-events-none"
                                        style={style5}
                                        src={base64} />

                                    {listRects}
                                </div>
                            </div>

                            {isConfirming && <div className="animate-fade animate-delay-100 animate-duration-500 absolute left-0 top-0 right-0 bottom-0 bg-black/30 cursor-default" onClick={() => handleConfirm(false)} onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); }}></div>}

                        </div>
                    </>

                }


            </div>

            {contextMenuParams && <ContextMenu x={contextMenuParams.x} y={contextMenuParams.y} sender={contextMenuParams.sender} options={contextMenuParams.options} closeContextMenu={() => setContextMenuParams(null)} />}


            {/* do not allow click  */}
            {isExecuting && <div className="fixed z-[9999] left-0 top-0 right-0 bottom-0"></div>}

        </>

    );

};


const Cropper = ({ url, naturalWidth, naturalHeight, crop, execute, close }) => {

    const initialRects = [{ ...crop, minW: 50, minH: 50, effect: { 'outlineStyle': 'solid', 'outlineOffset': '1px', 'outlineWidth': '9999px', 'outlineColor': 'rgba(0,0,0,0.3)' }, can: ['move', 'show-corners'] }];
    const [rects, setRects] = useState(initialRects);

    const options = [
        {
            name: 'Initial',
            action: () => undo(),
            disabled: objectsEqual(crop, { left: 0, top: 0, width: naturalWidth, height: naturalHeight }),
        },
        {
            name: 'Reset',
            action: () => reset(),

        }
    ];

    const undo = () => {
        const rs = [...rects];
        rs.splice(0, 1, { ...rects[0], ...crop });

        setRects(rs);
    }

    const reset = () => {
        const rs = [...rects];
        rs.splice(0, 1, { ...rects[0], left: 0, top: 0, width: naturalWidth, height: naturalHeight });

        setRects(rs);
    }


    const change = (index, v) => {
        const rs = [...rects];
        rs.splice(index, 1, { ...rects[index], ...v, });

        setRects(rs);
    }


    const handleExecute = (v, success, error) => {
        const { left, top, width, height } = rects[0];
        execute({ crop: { left, top, width, height }, ...v }, success, error);
    }


    return (
        <Modifier caption="Crop" url={url} crop={null} naturalWidth={naturalWidth} naturalHeight={naturalHeight} rects={rects} options={options} change={change} remove={null} add={null} reset={reset} execute={(v, success, error) => handleExecute(v, success, error)} close={close} />
    );

};


const Blurrer = ({ url, naturalWidth, naturalHeight, crop, execute, close }) => {

    const initialRects = [];
    const [rects, setRects] = useState(initialRects);


    const minBlur = 2;
    const maxBlur = 6;

    const [blurValue, setBlurValue] = useState(3);

    const rect = (v) => ({ minW: 10, minH: 10, effect: { 'backdropFilter': `blur(${v}px)` }, can: ['move', 'remove'] });


    const options = [
        {
            name: 'Clear',
            action: () => reset(),
            delimiter: true,
        },
        {
            name: 'Blur -',
            action: () => blur(-1),
            disabled: blurValue <= minBlur,
        },
        {
            name: 'Blur +',
            action: () => blur(+1),
            disabled: blurValue >= maxBlur,
        },
    ];

    const reset = () => {
        setRects([]);
    }


    const blur = (v) => {
        const l = Math.min(Math.max(blurValue + v, minBlur), maxBlur);
        if (blurValue == l) return;

        setBlurValue(l);

        const rs = rects.map(r => ({ ...r, effect: rect(l).effect }));
        setRects(rs);
    }

    const change = (index, v) => {
        const rs = [...rects];
        rs.splice(index, 1, { ...rects[index], ...v, });

        setRects(rs);
    }

    const add = (r) => {
        const rs = [...rects, { ...rect(blurValue), ...r, selecting: true }];
        setRects(rs);
    }

    const remove = (index) => {
        const rs = [...rects];
        rs.splice(index, 1);

        setRects(rs);
    }

    const handleExecute = (v, success, error) => {
        const rs = rects.map(({ left, top, width, height }) => ({ left: crop.left + left, top: crop.top + top, width, height }));
        execute({ rects: rs, blur: blurValue, ...v }, success, error);
    }


    return (
        <Modifier caption="Blur" confirmMessage="This action cannot be undone." url={url} crop={crop} naturalWidth={naturalWidth} naturalHeight={naturalHeight} rects={rects} options={options} change={change} add={add} remove={remove} reset={reset} execute={(v, success, error) => handleExecute(v, success, error)} close={close} />
    );

};




export const CropBgImage = ({ close }) => {
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { scene } = state;
    const bgimage = scene.elems.find(elem => elem.name == 'bgimage');

    const { url, naturalWidth, naturalHeight, crop } = bgimage;

    const handleExecute = (v, success, error) => {

        if (!objectsEqual(crop, v.crop)) dispatch({ type: 'scene-bgimage-crop', crop: v.crop });
        if (v.applytoall) dispatch({ type: 'applytoall', target: 'crop' });

        success();
    }

    return (<Cropper url={url} naturalWidth={naturalWidth} naturalHeight={naturalHeight} crop={crop} execute={(v, success, error) => handleExecute(v, success, error)} close={close} />);

};



export const BlurBgImage = ({ close }) => {
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { user, file, scene } = state;
    const bgimage = scene.elems.find(elem => elem.name == 'bgimage');

    const { url, naturalWidth, naturalHeight, crop } = bgimage;



    const extract = async (url, rects) => {

        const img = await url.img();

        const canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        //rect values must be rounded for pixelmatch 

        return rects.map(rect => ({ rect, ref: ctx.getImageData(rect.left, rect.top, rect.width, rect.height) }));

    }

    const handleExecute = async (v, success, error) => {

        let arr =
            file.content.scenes
                .filter(scn => scn.id != scene.id && scn.template == scene.template)
                .map(scn => ({ id: scn.id, bgimage: scn.elems.find(elem => elem.name == 'bgimage') }))
                .filter(item => can.blur(item.bgimage, bgimage))
                .map(item => ({ id: item.id, url: item.bgimage.url }));

        if (!v.applytoall) arr = [];

        arr.push({ id: scene.id, url });



        arr = arr.map(({ id, url }) => ({ id, url, path: `users/${user.uid}/${file.id}/${id}.${EXT}` }))

        const rects = await extract(url, v.rects);
        const promises = arr.map(({ id, path, url }) => blurImage(id, path, url, rects, v.blur));

        try {
            const result = await Promise.all(promises);
            const changed = result.filter(item => item.url);

            dispatch({ type: 'scenes-bgimage-url', items: changed });

            const u = arr.filter(({ id }) => changed.find(item => item.id == id)).map(({ url }) => url);
            cacheImgRemove(u);

            const n = changed.map(({ url }) => url);
            const p = cacheImgAdd(n);
            await Promise.all(p);

            success();

        }
        catch (err) {
            error(err);
        }


    }

    return (<Blurrer url={url} naturalWidth={naturalWidth} naturalHeight={naturalHeight} crop={crop} execute={(v, success, error) => handleExecute(v, success, error)} close={close} />);

};