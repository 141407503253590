
import { useState } from "react";
import { useEffect } from "react";

import classNames from "classnames";


const Rewriting = ({ className, color, size }) => {

  return (
    <div className={classNames('absolute left-2 top-1', className, !color && 'filter-neutral', color == 'text-white' && 'filter-white', color == 'text-black' && 'filter-black')}>
      <div className={classNames('relative', size)}>
        <span className="invisible">TDA</span>

        <img
          alt=""
          className="absolute left-0 top-0 w-auto h-full"
          src={require("../assets/svg/rewriting.svg").default}
        />

      </div>

    </div>
  )


};

export default Rewriting;