import { useState } from 'react';
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import Loading from '../ui/Loading.js';

import { actions, uniqueId } from './actions.js';
import { storageUploadFile, storageUploadFiles } from '../firebase/storage.js';


import { Dialog } from "../dialogs";

import DropZone from '../ui/DropZone';


import classNames from "classnames";


const Upload = ({ title, text, max, canPaste, onDropFiles, isUploading, close }) => {


    const handleClose = () => {
        if (!isUploading) close();
    }

    return (
        <Dialog
            className="w-[30rem] h-[20rem]"
            close={handleClose}>

            {/* <h1 className="mb-4 text-sm uppercase">{title}</h1> */}

            <div className='flex-1 relative p-0 flex flex-col'>
                {isUploading && <Loading />}

                {!isUploading &&
                    <div className="group flex-1 relative bg-pattern-diagonal">
                        <DropZone max={max} canPaste={canPaste} execute={onDropFiles} >
                            <img
                                className="h-8 opacity-60 transition-all filter-black group-hover:!opacity-100"
                                src={require("../assets/svg/image-upload.svg").default}
                            />
                            <p className="mt-4 text-xs">{text}</p>
                            {canPaste &&
                                <>
                                    <p className="text-sm">or</p>
                                    <p>CTRL-V</p>
                                </>
                            }

                        </DropZone>
                    </div>
                }
            </div>

        </Dialog>
    );

};

export const UploadBgImage = ({ close }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { user, file, scene } = state;

    const [isUploading, setIsUploading] = useState(false);


    const onDropFiles = async (files) => {
        const f = files[0];

        setIsUploading(true);

        // await new Promise(r => setTimeout(r, 5000));

        const url = await storageUploadFile(`users/${user.uid}/${file.id}/${scene.id}.${f.ext}`, f.data);

        if (url) {
            delete f.data;
            dispatch({ type: 'scene-bgimage-url', ...f, url });
        };

        setIsUploading(false);

        close();
    }


    return (
        <Upload
            title="Replace image"
            text="Drop here an image to replace the existing one for this scene."
            max={1}
            canPaste={true}
            onDropFiles={onDropFiles}
            isUploading={isUploading}
            close={close} >
        </Upload>
    );

};

export const UploadImages = ({ index, close }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { user, file, preferences } = state;

    const [isUploading, setIsUploading] = useState(false);


    const onDropFiles = async (files) => {

        const arr = files.map(f => { const id = uniqueId(); return { ...f, id, path: `users/${user.uid}/${file.id}/${id}.${f.ext}` } });

        setIsUploading(true);

        const result = await storageUploadFiles(arr);

        if (result) {
            const fs = result.map(({ name, id, naturalWidth, naturalHeight, url }) => ({ name, id, naturalWidth, naturalHeight, url }));
            actions.addScenesFromImages(preferences, dispatch, index, fs);
        }

        setIsUploading(false);

        close();
    }


    return (
        <Upload
            title="Import images"
            text="Drag your images here to craft new scenes."
            max={20}
            canPaste={false}
            onDropFiles={onDropFiles}
            isUploading={isUploading}
            close={close} >
        </Upload>
    );

};
