import moment from "moment";


export const k = (v) => {
    if (v < 1000) return v;

    const v1 = Math.floor(v / 1000);
    const v2 = Math.floor((v % 1000) / 100)

    return v2 > 0 ? `${v1}.${v2}k` : `${v1}k`;
}

export const prettify = (credits, unlimited = '-') => {


    const dict = {
        'detect': { caption: 'Detect language' },
        'export': { caption: 'Export', unit: 'mp4' },
        'openai': { caption: 'Rewrite', unit: 'chars' },
        'translate': { caption: 'Translate', unit: 'chars' },
        'tts': { caption: 'TextToAudio', unit: 'chars' },
        'views': { caption: 'Views' },
    }


    // const hidden = [];
    const hidden = ['detect'];

    const list = [...new Set([...Object.keys(dict), ...Object.keys(credits)].filter(key => !hidden.includes(key)).sort((a, b) => a.localeCompare(b)))];

    return list.reduce((a, c) => {
        a[c] = {
            caption: dict[c] ? dict[c].caption : '',
            value: credits[c] != undefined ? dict[c] && dict[c].unit ? `${k(credits[c])} ${dict[c].unit}` : k(credits[c]) : unlimited
        };
        return a;
    }, {});
}


export const audioCharsDuration = (n) => {

    if (n <= 0) return 'no audio';

    const CHARS_PER_MINUTE = 833;
    const ms = Math.floor((n / CHARS_PER_MINUTE) * 60 * 1000);

    const t = moment.duration(ms);
    const h = t.hours();
    const m = t.minutes();//gives the remaining minutes after the hours are computed

    if (h == 0 && m == 0) return 'no audio';

    const v1 = h ? `${h} hours` : '';
    const v2 = m ? `${m} minutes` : '';

    const v = v1 ? `${v1} and ${v2}` : v2;

    return `~${v} of generated audio`;

}