import { useState } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';


import { fnDetect, fnGetLanguages } from "../firebase/functions";




import { get } from "./utils";


import { add as cacheImgAdd } from "../cache/cache-img";

import classNames from "classnames";



export const loadLang = (file) => new Promise(async (resolve, reject) => {

    const lang = await fnDetect(file.userId, get.textForDetect(file));

    resolve(lang);

})

export const loadLanguages = () => new Promise(async (resolve, reject) => {

    const languages = await fnGetLanguages();
    resolve(languages);

})

export const loadImg = (file) => new Promise(async (resolve, reject) => {

    //todo: progressive

    const arr = file.content.scenes.filter(scene => scene.template == 'bg-image').map(scene => scene.elems.find(elem => elem.name == 'bgimage').url);
    const p = cacheImgAdd(arr);

    await Promise.all(p);

    resolve();

});

export const loadAudio = (file, code) => new Promise(async (resolve, reject) => {
    //todo: progressive

    const arr = file.content.scenes.filter(scene => scene.visible && scene.speak.enabled && scene.speak.text).map(({ speak }) => speak.text.translate(code).convert(file.userId, speak.voice_id)).filter(item => item !== undefined);

    await Promise.all(arr);

    resolve();

});




export const Loader = ({ loading = true, name, mode, button, callback }) => {


    const destruct = () => {

        let title = name
        let subtitle = null;

        if (name.includes('-')) {
            const [first, ...rest] = name.split('-');

            title = first;
            subtitle = rest.join('-').trim();
        }


        return { title, subtitle }
    }


    const { title, subtitle } = destruct();


    const fill = mode == 'dark' ? 'white' : 'black';

    return (
        <div className={classNames('flex-1', mode == 'dark' && 'dark')}>
            <div className="w-full h-full bg-pattern-light dark:bg-pattern-dark flex flex-col items-center">

                <div className="mt-[20vh] xl:w-[30rem] flex flex-col items-center">

                    <div className="animate-fade-up animate-duration-500 h-16 flex flex-col items-center">

                        <h1 className="text-xl dark:text-white text-center">{title}</h1>
                        {subtitle && <p className="text-sm text-gray-500 dark:text-white text-center">{subtitle}</p>}

                    </div>

                    {loading &&
                        <svg
                            className="animate-fade mt-6 w-14 h-14"
                            width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke={fill}>
                            <g fill="none" fillRule="evenodd" strokeWidth="2">
                                <circle cx="22" cy="22" r="1">
                                    <animate attributeName="r"
                                        begin="0s" dur="1.8s"
                                        values="1; 20"
                                        calcMode="spline"
                                        keyTimes="0; 1"
                                        keySplines="0.165, 0.84, 0.44, 1"
                                        repeatCount="indefinite" />
                                    <animate attributeName="stroke-opacity"
                                        begin="0s" dur="1.8s"
                                        values="1; 0"
                                        calcMode="spline"
                                        keyTimes="0; 1"
                                        keySplines="0.3, 0.61, 0.355, 1"
                                        repeatCount="indefinite" />
                                </circle>
                                <circle cx="22" cy="22" r="1">
                                    <animate attributeName="r"
                                        begin="-0.9s" dur="1.8s"
                                        values="1; 20"
                                        calcMode="spline"
                                        keyTimes="0; 1"
                                        keySplines="0.165, 0.84, 0.44, 1"
                                        repeatCount="indefinite" />
                                    <animate attributeName="stroke-opacity"
                                        begin="-0.9s" dur="1.8s"
                                        values="1; 0"
                                        calcMode="spline"
                                        keyTimes="0; 1"
                                        keySplines="0.3, 0.61, 0.355, 1"
                                        repeatCount="indefinite" />
                                </circle>
                            </g>
                        </svg>
                    }

                    {button &&
                        <button
                            className="animate-fade group mt-6 w-14 h-14 p-1 bg-red-500 text-white rounded-full transition-colors shadow hover:bg-red-600 flex flex-row items-center justify-center"
                            onClick={() => callback()}>

                            <svg
                                className="group-active:scale-110"
                                width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.9311 12.7653C24.6516 13.8379 25.5117 14.3742 25.8068 15.061C26.0644 15.6606 26.0644 16.3394 25.8068 16.9392C25.5117 17.6258 24.6516 18.1621 22.9311 19.2348L14.8505 24.2729C12.941 25.4634 11.9863 26.0587 11.1984 25.9954C10.5116 25.9404 9.8821 25.5924 9.47129 25.0406C9 24.4076 9 23.2845 9 21.0382V10.9619C9 8.71563 9 7.5925 9.47129 6.95948C9.8821 6.40771 10.5116 6.05964 11.1984 6.00455C11.9863 5.94137 12.941 6.53664 14.8505 7.72717L22.9311 12.7653Z" stroke="white" strokeWidth="3" strokeLinejoin="round" />
                            </svg>

                        </button>
                    }

                </div>

            </div>
        </div>
    )


}


