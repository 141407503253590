import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";




const firebaseConfig = {
  apiKey: "AIzaSyAQKkM8Af68EjH6h210D88P7GKOlQjfTt4",
  authDomain: "demobuilder-d24ed.firebaseapp.com",
  projectId: "demobuilder-d24ed",
  storageBucket: "demobuilder-d24ed.appspot.com",
  messagingSenderId: "792914472824",
  appId: "1:792914472824:web:6840e15b6e571aa6a3228d",
  measurementId: "G-R6K16104RV"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);
export const storage = getStorage(app);


//https://firebase.google.com/docs/auth/web/auth-state-persistence
//need to store per tab to prevent login from different tabs
setPersistence(auth, browserSessionPersistence);