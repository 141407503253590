import { useState } from "react";
import { useEffect } from "react";

import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";

import { dbSubscribeToPlatformVersion, dbSubscribeToSession, dbSubscribeToReady, dbSubscribeToCredits, dbSubscribeToSubscription, dbSubscribeToPlans, dbSubscribeToViews, dbSubscribeToJobs, dbSubscribeToCheckoutSession } from "../firebase/database";


export const usePlatformVersion = (runningVersion) => {

    const [version, setVersion] = useState(runningVersion);

    useEffect(() => {

        let unsubscribe = () => { };

        dbSubscribeToPlatformVersion((v) => unsubscribe = v, (v) => setVersion(v));

        return () => unsubscribe();
    }, []);

    return version;

}


export const useUniqueSession = (uid, session) => {

    useEffect(() => {
        if (!uid) return;
        if (!session) return;

        let unsubscribe = () => { };

        dbSubscribeToSession(uid, (v) => unsubscribe = v, (v) => {

            if (session != v) {

                // console.log('user created a new session, so logged on somewhere', session, v);

                const forceSignOut = async () => {
                    try {
                        await signOut(auth);
                    } catch (err) {
                        console.error(err);
                    }
                }

                forceSignOut();
            }



        })

        return () => unsubscribe();
    }, [uid, session]);


}

export const useReady = (uid) => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (!uid) {
            setReady(false);
            return;
        }

        let unsubscribe = () => { };

        dbSubscribeToReady(uid, (v) => unsubscribe = v, (v) => { setReady(v); if (v) unsubscribe(); })

        return () => unsubscribe();

    }, [uid]);


    return ready;
}

export const useCredits = (uid, metadata) => {
    const [credits, setCredits] = useState({});

    useEffect(() => {
        if (!uid) return;

        let unsubscribe = () => { };

        const trim = (credits) => {
            if (!metadata) return credits;

            const result = Object.keys(metadata).reduce((a, c) => { a[c] = credits[c]; return a; }, {});//keys that does not exist in metadata, means that are not counted.

            // console.log('trimmed credits: ', credits, result);

            return result;
        }

        dbSubscribeToCredits(uid, (v) => unsubscribe = v, (v) => setCredits(trim(v)))

        return () => unsubscribe();

    }, [uid, metadata]);


    return credits;

}

export const useSubscription = (uid, dispatch) => {

    useEffect(() => {
        // dispatch({ type: 'subscription-set', subscription: null });

        if (!uid) return;

        let unsubscribe = () => { };

        dbSubscribeToSubscription(uid, (v) => unsubscribe = v, (v) => dispatch({ type: 'subscription-set', subscription: v }));

        return () => unsubscribe();

    }, [uid]);

}

export const usePlans = (dispatch) => {

    useEffect(() => {
        let unsubscribe = () => { };

        dbSubscribeToPlans((v) => unsubscribe = v, (v) => dispatch({ type: 'plans-set', plans: v }));

        return () => unsubscribe();

    }, []);

}

export const useViews = (id) => {
    const [views, setViews] = useState(undefined);

    useEffect(() => {
        let unsubscribe = () => { };

        dbSubscribeToViews(id, (v) => unsubscribe = v, (v) => setViews(v))

        return () => unsubscribe();
    }, [id]);


    return views;

}


export const useJobs = (uid, id) => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        let unsubscribe = () => { };

        dbSubscribeToJobs(uid, id, (v) => unsubscribe = v, (v) => setJobs(v))

        return () => unsubscribe();

    }, [id]);


    return jobs;
}


export const useCheckoutSession = (uid, checkout) => {
    const [url, setUrl] = useState(null);

    useEffect(() => {
        if (!checkout) return;

        let unsubscribe = () => { };

        const load = async () => await dbSubscribeToCheckoutSession(uid, checkout.pid, checkout.metadata, (v) => unsubscribe = v, (v) => setUrl(v))

        load();

        return () => unsubscribe();

    }, [checkout]);


    return url;

}
