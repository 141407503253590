import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import { uniqueId } from "../editor/actions";
import { dbGetSession, dbUpdateSession } from "../firebase/database";

import { useUniqueSession, useReady } from "../hooks/subscribe";

import Login from '../auth/Login';

import Explorer from '../explorer';
import Widget from "./Widget";

import Editor from '../editor';


import OnlineStatus from "../ui/OnlineStatus";

import classNames from 'classnames';


const useWakeUp = (uid, session) => {

    const timeout = 60000;
    const lastTime = useRef(null);

    useEffect(() => {
        if (!uid) return;
        if (!session) return;

        lastTime.current = performance.now();

        const check = async () => {

            const serverSession = await dbGetSession(uid);
            // console.log('serverSession: ', serverSession);

            if (session != serverSession) location.reload();
        }

        const interval = setInterval(() => {

            const now = performance.now();

            //returns from sleep
            if (now > (lastTime.current + timeout + 2000)) check();

            lastTime.current = performance.now();

        }, timeout);

        return () => clearInterval(interval);

    }, [uid, session])

}


const useUpdateSession = (uid, dispatch) => {

    useEffect(() => {
        if (!uid) return;

        const update = async () => {
            const session = uniqueId();
            const result = await dbUpdateSession(uid, session);

            if (result) dispatch({ type: 'session', session });
        }

        update();

    }, [uid])

}


const Layout = () => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { user, isLoggedIn, session, file } = state;

    const ready = useReady(user?.uid);

    const uid = ready ? user?.uid : null;

    useUpdateSession(uid, dispatch);
    useUniqueSession(uid, session);

    useWakeUp(uid, session);

    return (
        <div className="relative w-screen h-screen bg-[#f7f7f7]">

            {!isLoggedIn && <Login />}

            {isLoggedIn && !ready &&
                <div className="flex flex-col items-center justify-center space-y-4">
                    <div className="mt-[20vh] mb-16">
                        <img
                            className="w-36"
                            src={require("../assets/svg/logo.svg").default} />
                    </div>
                    <span className="animate-spin w-2 h-2 bg-black"></span>
                </div>
            }

            {isLoggedIn && ready &&
                <>
                    <div className="h-screen flex flex-row">

                        <div className="w-64 bg-gray-200 border-r border-gray-300 flex flex-col">
                            <Explorer />
                            <Widget />
                        </div>

                        {file && <Editor />}

                    </div>

                    <OnlineStatus />
                </>
            }

        </div>
    );

}

export default Layout;