

const roles = {
    'ROLE_SYSTEM_ALERT': '',
    'ROLE_SYSTEM_ANIMATION': '',
    'ROLE_SYSTEM_APPLICATION': '',
    'ROLE_SYSTEM_BORDER': '',
    'ROLE_SYSTEM_BUTTONDROPDOWN': 'dropdown button',
    'ROLE_SYSTEM_BUTTONDROPDOWNGRID': 'dropdown button',
    'ROLE_SYSTEM_BUTTONMENU': 'menu button',
    'ROLE_SYSTEM_CARET': '',
    'ROLE_SYSTEM_CELL': '',
    'ROLE_SYSTEM_CHARACTER': '',
    'ROLE_SYSTEM_CHART': '',
    'ROLE_SYSTEM_CHECKBUTTON': 'button',
    'ROLE_SYSTEM_CLIENT': '',
    'ROLE_SYSTEM_CLOCK': '',
    'ROLE_SYSTEM_COLUMN': '',
    'ROLE_SYSTEM_COLUMNHEADER': '',
    'ROLE_SYSTEM_COMBOBOX': 'combo box',
    'ROLE_SYSTEM_CURSOR': '',
    'ROLE_SYSTEM_DIAGRAM': '',
    'ROLE_SYSTEM_DIAL': '',
    'ROLE_SYSTEM_DIALOG': 'dialog',
    'ROLE_SYSTEM_DOCUMENT': '',
    'ROLE_SYSTEM_DROPLIST': 'drop list',
    'ROLE_SYSTEM_EQUATION': '',
    'ROLE_SYSTEM_GRAPHIC': '',
    'ROLE_SYSTEM_GRIP': '',
    'ROLE_SYSTEM_GROUPING': '',
    'ROLE_SYSTEM_HELPBALLOON': '',
    'ROLE_SYSTEM_HOTKEYFIELD': '',
    'ROLE_SYSTEM_INDICATOR': '',
    'ROLE_SYSTEM_LINK': 'link',
    'ROLE_SYSTEM_LIST': 'list',
    'ROLE_SYSTEM_LISTITEM': 'list item',
    'ROLE_SYSTEM_MENUBAR': 'menu bar',
    'ROLE_SYSTEM_MENUITEM': 'menu item',
    'ROLE_SYSTEM_MENUPOPUP': '',
    'ROLE_SYSTEM_OUTLINE': '',
    'ROLE_SYSTEM_OUTLINEITEM': '',
    'ROLE_SYSTEM_PAGETAB': 'page tab',
    'ROLE_SYSTEM_PAGETABLIST': 'page tab list',
    'ROLE_SYSTEM_PANE': '',
    'ROLE_SYSTEM_PROGRESSBAR': 'progress bar',
    'ROLE_SYSTEM_PROPERTYPAGE': '',
    'ROLE_SYSTEM_PUSHBUTTON': 'push button',
    'ROLE_SYSTEM_RADIOBUTTON': 'radio button',
    'ROLE_SYSTEM_ROW': '',
    'ROLE_SYSTEM_ROWHEADER': '',
    'ROLE_SYSTEM_SCROLLBAR': 'scrollbar',
    'ROLE_SYSTEM_SEPARATOR': '',
    'ROLE_SYSTEM_SLIDER': '',
    'ROLE_SYSTEM_SOUND': '',
    'ROLE_SYSTEM_SPINBUTTON': 'spin button',
    'ROLE_SYSTEM_SPLITBUTTON': 'split button',
    'ROLE_SYSTEM_STATICTEXT': 'text',
    'ROLE_SYSTEM_STATUSBAR': 'status bar',
    'ROLE_SYSTEM_TABLE': 'table',
    'ROLE_SYSTEM_TEXT': 'text',
    'ROLE_SYSTEM_TITLEBAR': 'title bar',
    'ROLE_SYSTEM_TOOLBAR': 'tool bar',
    'ROLE_SYSTEM_TOOLTIP': 'tooltip',
    'ROLE_SYSTEM_WHITESPACE': '',
    'ROLE_SYSTEM_WINDOW': 'window',

}


export default roles;