import { db } from "../config/firebase";
import {
  getDoc,
  getDocs,
  where,
  orderBy,
  query,
  onSnapshot,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
  Timestamp,
  serverTimestamp,
} from "firebase/firestore";



//https://firebase.google.com/docs/firestore/security/rules-conditions


const CURRENT_FILE_VERSION = 1;


// ─── Platform ───────────────────────────────────────────────────────────

export const dbSubscribeToPlatformVersion = (setUnsubscribe, notify) => {

  try {
    const versionRef = doc(db, 'platform', 'version');
    const unsubscribe = onSnapshot(versionRef, (doc) => {

      const data = doc.data();
      if (!data) return;

      const { latest } = data;
      if (!latest) return;

      notify(latest);

    });

    setUnsubscribe(unsubscribe);

  } catch (err) {
    console.log(err);

  }

}

// ─── Session ─────────────────────────────────────────────────────────────

export const dbGetSession = async (uid) => {

  try {
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {

      const data = userDoc.data();
      const { session } = data;

      return session;

    }
  } catch (err) {
    console.log(err);
  }

  return null;
};

export const dbUpdateSession = async (uid, session) => {
  const timestamp = serverTimestamp();

  try {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, { session, timestamp });
    
    return true;

  } catch (err) {
    console.log(err);
  }

  return false;
  
};

export const dbSubscribeToSession = (uid, setUnsubscribe, notify) => {

  try {
    const userRef = doc(db, 'users', uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {

      const data = doc.data();
      if (!data) return;

      const { session } = data;
      if (!session) return;

      notify(session);

    });

    setUnsubscribe(unsubscribe);

  } catch (err) {
    console.log(err);

  }


}

// ─── Files ────────────────────────────────────────────────────────────────

export const dbGetFiles = async (uid) => {

  try {
    const filesRef = collection(db, 'files');
    const q = query(filesRef, where('userId', '==', uid));

    const snapshot = await getDocs(q);

    return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, }));

  } catch (err) {
    console.log(err);
  }

  return null;

};

export const dbAddFile = async (uid, name, folderId) => {
  // const timestamp = serverTimestamp();
  const timestamp = Timestamp.fromDate(new Date());

  folderId = folderId || null;

  try {

    const doc = {
      name,
      version: CURRENT_FILE_VERSION,

      views: 0,

      settings: {
        mode: 'light',

        share: {
          enabled: true,
          toc: true,
          pilot: true,
          translate: false,

        },

        export: {
          w: 1280,
          h: 720,
          code: '',
        },

      },

      content: { scenes: [] },

      folderId,

      created: timestamp,
      modified: timestamp,
      userId: uid,
    };

    const filesRef = collection(db, 'files');
    const response = await addDoc(filesRef, doc);
    if (!response) return null;

    return { ...doc, id: response.id };

  } catch (err) {
    console.log(err);
  }

  return null;
};

export const dbDeleteFile = async (id) => {
  try {
    const fileRef = doc(db, 'files', id);
    await deleteDoc(fileRef);

    return true;

  } catch (err) {
    console.log(err);
  }

  return false;
};

export const dbRenameFile = async (id, name) => {
  try {
    const fileRef = doc(db, 'files', id);
    await updateDoc(fileRef, { name });

    return true;

  } catch (err) {
    console.log(err);
  }

  return false;
};

export const dbMoveFile = async (id, folderId) => {

  folderId = folderId || null;

  try {
    const fileRef = doc(db, 'files', id);
    await updateDoc(fileRef, { folderId });

    return true;

  } catch (err) {
    console.log(err);
  }

  return false;
};

export const dbUpdateFile = async (id, settings, content) => {
  // const timestamp = serverTimestamp();
  const timestamp = Timestamp.fromDate(new Date());

  try {
    const fileRef = doc(db, 'files', id);
    await updateDoc(fileRef, { settings, content, modified: timestamp });

    return true;

  } catch (err) {
    console.log(err);
  }

  return false;
};

// ─── Folders ─────────────────────────────────────────────────────────────────

export const dbGetFolders = async (uid) => {

  try {
    const foldersRef = collection(db, 'folders');
    const q = query(foldersRef, where('userId', '==', uid));

    const snapshot = await getDocs(q);

    return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, }));

  } catch (err) {
    console.log(err);
  }

  return null;

};

export const dbAddFolder = async (uid, name) => {

  try {
    const doc = {
      name,
      userId: uid,
    };

    const foldersRef = collection(db, 'folders');
    const response = await addDoc(foldersRef, doc);

    return { ...doc, id: response.id };

  } catch (err) {
    console.log(err);
  }

  return null;
};

export const dbRenameFolder = async (id, name) => {
  try {
    const folderRef = doc(db, 'folders', id);
    await updateDoc(folderRef, { name });

    return true;

  } catch (err) {
    console.log(err);
  }

  return false;
};

export const dbDeleteFolder = async (id) => {
  try {
    const folderRef = doc(db, 'folders', id);
    await deleteDoc(folderRef);

    return true;

  } catch (err) {
    console.log(err);
  }

  return false;
};

// ─── Jobs ────────────────────────────────────────────────────────────────────

export const dbAddJob = async (uid, id, job) => {
  // const timestamp = serverTimestamp();
  const timestamp = Timestamp.fromDate(new Date());

  try {

    const doc = {
      fileId: id,
      ...job,
      url: '',
      status: 'pending',
      created: timestamp,
      userId: uid,
    };

    const jobsRef = collection(db, 'jobs');
    const response = await addDoc(jobsRef, doc);

    return { ...doc, id: response.id };

  } catch (err) {
    console.log(err);
  }

  return null;
};

export const dbDeleteFileJobs = async (uid, id) => {
  try {

    const jobsRef = collection(db, 'jobs');
    const q = query(jobsRef, where('userId', '==', uid), where('fileId', '==', id));

    const snapshot = await getDocs(q);
    const result = snapshot.docs.map((doc) => doc.id);
    const promises = result.map(id => new Promise(async (resolve, reject) => {

      try {
        const jobRef = doc(db, 'jobs', id);
        await deleteDoc(jobRef);

        resolve(true);

      } catch (err) {
        console.log(err);

        reject();
      }

    }))


    const response = await Promise.all(promises);
    return response.every(v => v);

  } catch (err) {
    console.log(err);
  }

  return false;

};

export const dbDeleteJob = async (id) => {
  try {

    const jobRef = doc(db, 'jobs', id);
    await deleteDoc(jobRef);

    return true;

  } catch (err) {
    console.log(err);
  }

  return false;
};

export const dbSubscribeToJobs = (uid, id, setUnsubscribe, notify) => {

  try {
    const jobsRef = collection(db, 'jobs');

    const q = query(jobsRef, where('userId', '==', uid), where('fileId', '==', id));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      // if (!querySnapshot) return;

      let jobs = [];
      querySnapshot.forEach((doc) => jobs.push({ id: doc.id, ...doc.data() }));

      notify(jobs);

    });

    setUnsubscribe(unsubscribe);

  } catch (err) {
    console.log(err);

  }


}

// ─── Views ───────────────────────────────────────────────────────────────────

export const dbSubscribeToViews = (id, setUnsubscribe, notify) => {

  try {
    const fileRef = doc(db, 'files', id);
    const unsubscribe = onSnapshot(fileRef, (doc) => {

      const data = doc.data();
      if (!data) return;//can be deleted

      const { views } = data;

      notify(views);

    });

    setUnsubscribe(unsubscribe);

  } catch (err) {
    console.log(err);

  }


}

// ─── TextToSpeech ────────────────────────────────────────────────────────────

export const dbTextToSpeechConvertTask = (hash, setUnsubscribe, notify) => {

  try {
    const audioRef = doc(db, 'audio', hash);
    const unsubscribe = onSnapshot(audioRef, (doc) => {

      const data = doc.data();
      if (!data) return;//can be deleted      

      const { url, status } = data;

      if (status == 'ready') notify(url);

    });

    setUnsubscribe(unsubscribe);

  } catch (err) {
    console.log(err);

  }


}


// ─── Ready ───────────────────────────────────────────────────────────────────

export const dbSubscribeToReady = (uid, setUnsubscribe, notify) => {

  try {
    const userRef = doc(db, 'users', uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {

      const data = doc.data();
      if (!data) return;//can be deleted

      const { ready } = data;

      notify(ready);

    });

    setUnsubscribe(unsubscribe);

  } catch (err) {
    console.log(err);

  }

}

// ─── Credits ─────────────────────────────────────────────────────────────────

export const dbSubscribeToCredits = (uid, setUnsubscribe, notify) => {

  try {
    const userRef = doc(db, 'users', uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {

      const data = doc.data();
      if (!data) return;//can be deleted

      const { credits } = data;

      if (!credits) return;//trigger has not put credits yet

      notify(credits);

    });

    setUnsubscribe(unsubscribe);

  } catch (err) {
    console.log(err);

  }


}

// ─── Session ─────────────────────────────────────────────────────────────────

export const dbSubscribeToCheckoutSession = async (uid, pid, metadata, setUnsubscribe, notify) => {

  try {
    const sessionRef = collection(db, 'users', uid, 'checkout_sessions');

    const doc = {
      price: pid,

      allow_promotion_codes: true,
      automatic_tax: {
        enabled: true,
      },
      tax_id_collection: {
        enabled: true,
      },

      metadata,

      success_url: window.location.origin + '/thankyou',
      cancel_url: window.location.origin,

    };

    const result = await addDoc(sessionRef, doc);

    const unsubscribe = onSnapshot(result, (snap) => {

      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // window.location.assign(url);
        notify(url);
      }

    });

    setUnsubscribe(unsubscribe);

  } catch (err) {
    console.log(err);

  }


}

// ─── Subscription ────────────────────────────────────────────────────────────

export const dbSubscribeToSubscription = (uid, setUnsubscribe, notify) => {

  try {
    const subscriptionsRef = collection(db, 'users', uid, 'subscriptions');

    const q = query(subscriptionsRef, where('status', 'in', ['active']));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      if (!querySnapshot.docs.length) {
        notify(null);
        return;
      }

      const doc = querySnapshot.docs[0].data();

      if (!doc) return;

      const { metadata, status, current_period_start, current_period_end, cancel_at_period_end } = doc;

      Object.keys(metadata).forEach(key => metadata[key] = Number(metadata[key]));

      const { items } = doc;

      const { id, plan, price, subscription } = items[0];
      const { product } = plan;

      const { recurring, unit_amount } = price;
      const { interval } = recurring

      notify({ id: subscription, status, metadata, subItemId: id, product, current_period_start, current_period_end, cancel_at_period_end, interval, unit_amount });

    });

    setUnsubscribe(unsubscribe);



  } catch (err) {
    console.log(err);

  }


}

// ─── Plans ───────────────────────────────────────────────────────────────────

export const dbSubscribeToPlans = (setUnsubscribe, notify) => {

  try {
    const plansRef = collection(db, 'plans');

    const q = query(plansRef, where('active', '==', true));

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {

      if (!querySnapshot.docs.length) {
        notify([]);
        return;
      }

      const arr = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const promises = arr.map(({ id }) => getDocs(collection(db, 'plans', id, 'prices')))
      const pricesSnap = await Promise.all(promises);

      const prices = pricesSnap.map(snap => [...snap.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(({ active }) => active)]);

      const plans = arr.map((plan, index) => ({ ...plan, prices: prices[index] })).filter(({ active }) => active);


      notify(plans);

    });

    setUnsubscribe(unsubscribe);



  } catch (err) {
    console.log(err);

  }


}

