import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';


import { ElemStatic, ElemEditableStaticText } from "../editor/Elems";


import classNames from "classnames";


export const Layout100 = ({ motion, elems, scenes, scene }) => {

    const dispatch = useContext(DispatchContext);


    const index = scenes.findIndex(({ id }) => id == scene.id);
    const before = scenes.filter(({ visible, template }, i) => i < index && visible && template == 'chapter');
    const number = String(before.length + 1).padStart(2, '0');


    const handleMouseDown = () => {
        if (motion) return;

        dispatch({ type: 'elem-set', elem: null });
    }



    return (
        <div
            className="relative flex-1 flex flex-col items-center"
            onMouseDown={() => handleMouseDown()}>

            <div className={classNames('mt-[20vh] w-full md:w-[30rem] flex flex-col', elems.text1.align == 'text-center' && 'items-center', elems.text1.align == 'text-right' && 'items-end')}>

                <div className={classNames('relative mb-10 w-14 h-14 flex flex-row items-center justify-center', motion && 'animate-fade-up animate-duration-500')}>
                    <div className="absolute left-0 top-0 right-0 bottom-0 border-2 border-black dark:border-white rotate-45"></div>
                    <div className="font-mono dark:text-white text-2xl">{number}</div>
                </div>


                <ElemStatic className="w-full" props={elems.text1}>

                    <ElemEditableStaticText className="dark:text-white" motion={motion} line={false} props={elems.text1} />

                </ElemStatic>

            </div>

        </div>
    )
}