

export const APP_NAME = 'Demo Builder';

export const PLACEHOLDER = 'https://firebasestorage.googleapis.com/v0/b/demobuilder-d24ed.appspot.com/o/media%2Fplaceholder.svg?alt=media&token=d2b70e5d-22b4-4945-819b-4c592acc84d9';

export const CONTINUE_VARIANTS = ['Continue', 'Keep going', 'Forge ahead', 'Next', 'Move forward', 'Go on'];


export const ACCENTS_SUBTITLE = ['bg-orange-400/100', 'bg-blue-500/100'];
export const ACCENTS_GLOW = ['bg-emerald-300/100', 'bg-sky-400/100'];
export const ACCENTS_MOBILE = ['bg-orange-500/100', 'bg-blue-500/100'];


export const TEXT_SIZES = {
    'text-xs': { text: 'px-2 py-3', button: 'px-2 py-2' },
    'text-sm': { text: 'px-2 py-3', button: 'px-2 py-2' },
    'text-base': { text: 'px-2 py-3', button: 'px-2 py-2' },
    'text-lg': { text: 'px-2 py-3', button: 'px-2 py-2' },
    'text-xl': { text: 'px-2 py-3', button: 'px-2 py-2' },
    'text-2xl': { text: 'px-2 py-3', button: 'px-2 py-2' },
    'text-3xl': { text: 'px-2 py-3', button: 'px-3 py-3' },
    'text-4xl': { text: 'px-4 py-5', button: 'px-3 py-3' },
    'text-5xl': { text: 'px-4 py-5', button: 'px-4 py-4' },
    'text-6xl': { text: 'px-5 py-6', button: 'px-5 py-5' },
    'text-7xl': { text: 'px-5 py-6', button: 'px-6 py-6' },
    'text-8xl': { text: 'px-5 py-6', button: 'px-7 py-7' },

}


export const TEXT_ALIGN = ['text-left', 'text-center', 'text-right'];

export const FOCUS_BORDER = ['border-black/100', 'border-red-500/100', 'border-green-400/100', 'border-blue-500/100'];
export const FOCUS_INPUT_BG = ['bg-white/100', 'bg-black/100'];

export const ARROW_BG = ['border-black/100', 'bg-red-500/100', 'bg-green-400/100', 'bg-blue-500/100'];

export const TOUCH_BG = ['bg-black/100', 'bg-red-500/100', 'bg-green-500/100', 'bg-blue-500/100'];

export const TEXT_BG = ['bg-black/100', 'bg-red-500/100', 'bg-green-500/100', 'bg-blue-500/100'];

export const BUTTON_BG = ['bg-black/100', 'bg-red-500/100', 'bg-green-500/100', 'bg-blue-500/100'];


export const TEXT_INITIAL = '';

export const ZOOM_VALUES = [1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.75, 2, 2.5, 3];



export const TRANSITIONS_FOCUS = ['animate-fade', 'animate-zoom'];
export const TRANSITIONS_TEXT = ['animate-fade', 'animate-fade-down', 'animate-fade-up', 'animate-fade-left', 'animate-fade-right', 'animate-rotate-x', 'animate-shake-x'];
export const TRANSITIONS_ARROW = ['animate-fade'];
export const TRANSITIONS_BUTTON = ['animate-fade', 'animate-fade-down', 'animate-fade-up', 'animate-fade-left', 'animate-fade-right', 'animate-shake-x'];



export const SCENE_WAIT = 2000; //at end


export const SCENE_MIN_DURATION = 1000;
export const ELEM_MIN_DURATION = 3000;

export const ELEM_TRANSITION = 500; //200;


export const ZOOM_DURATION = 400;



export const FOCUS_INPUT_DELAY_CHAR = 200;
export const FOCUS_INPUT_CARET_DURATION = 3000;

export const CURSOR_DURATION_1920 = 4000;
export const CURSOR_DURATION_MIN = 1000;
export const CURSOR_CLICK = 200;

export const TOUCH_DURATION = 1700;


export const SPEAK_MIN_CHARS = 5;
export const SPEAK_MAX_CHARS = 800;

//must be in sync with export.js
export const MAX_JOB_DURATION = 1000 * 60 * 15;
export const MAX_CONCURRENT_JOBS = 2;
export const JOB_EXT = '.mp4';



export const circleGradient = (bg) => {
    const color = bg.split('-')[1];
    return `from-${color}-200 via-${color}-400 to-${color}-700`;
}

export const generate = () => {

    let list = ['divide-white', 'divide-black'];


    list.push(...ACCENTS_SUBTITLE.map(item => item.split('/')[0]));
    list.push(...ACCENTS_GLOW.map(item => item.split('/')[0]));
    list.push(...ACCENTS_MOBILE.map(item => item.split('/')[0]));

    list.push(...ACCENTS_MOBILE.map(item => circleGradient(item)));


    for (const [key, value] of Object.entries(TEXT_SIZES)) {
        list.push(key);
        list.push(...value.text.split(' '));
        list.push(...value.button.split(' '));

    }

    list.push(...TEXT_ALIGN);

    list.push(...FOCUS_BORDER.map(item => item.split('/')[0]));
    list.push(...FOCUS_BORDER.map(item => item.split('/')[0]).map(item => item.split('-').map(part => part == 'border' ? 'bg' : part).join('-')));
    list.push(...FOCUS_INPUT_BG.map(item => item.split('/')[0]));

    list.push(...ARROW_BG.map(item => item.split('/')[0]));

    list.push(...TOUCH_BG.map(item => item.split('/')[0]));
    list.push(...TOUCH_BG.map(item => item.split('/')[0]).map(item => item.split('-').map(part => part == 'bg' ? 'border' : part).join('-')));

    list.push(...TEXT_BG.map(item => item.split('/')[0]));
    list.push(...BUTTON_BG.map(item => item.split('/')[0]));



    list.push(...TRANSITIONS_FOCUS);
    list.push(...TRANSITIONS_TEXT);
    list.push(...TRANSITIONS_ARROW);
    list.push(...TRANSITIONS_BUTTON);


    const unique = [...new Set(list)];

    // console.log('safelist: ', unique);

    return unique;
}

export const safelist = generate();
