

import { fnAsk } from "../firebase/functions";


const instructions = 'Do not answer with questions or answers.'


const rewrite = (text, lang) => `Rewrite text below and write in the ${lang ? lang : 'same'} language. ${instructions} \n "${text}"`;
const rewriteWithStyle = (style, text, lang) => `In a ${style} style rewrite text below and write in the ${lang ? lang : 'same'} language. ${instructions} \n "${text}"`;
const fixSpelling = (text, lang) => `Fix spelling and write in the ${lang ? lang : 'same'} language. ${instructions} \n "${text}"`;


const en = (text) => `Translate into English in a friendly style: \n "${text}"`;



export const OPENAI_PROMPTS = {

    'rewrite': {
        caption: 'Rewrite',
        prompt: (text, lang) => rewrite(text, lang)
    },
    'rewrite-technical': {
        caption: 'More technical',
        prompt: (text, lang) => rewriteWithStyle('technical', text, lang)
    },
    'rewrite-friendly': {
        caption: 'More fun',
        prompt: (text, lang) => rewriteWithStyle('friendly', text, lang)
    },
    'rewrite-formal': {
        caption: 'More formal',
        prompt: (text, lang) => rewriteWithStyle('formal', text, lang)
    },

    'fix-spelling': {
        caption: 'Fix spelling',
        prompt: (text, lang) => fixSpelling(text, lang)
    },

}


// const isLocalHost = ['localhost', '127.0.0.1', '', '::1'].includes(window.location.hostname);
const userLang = navigator.language || navigator.userLanguage; 

if (userLang != 'en') OPENAI_PROMPTS['en'] = {
    caption: 'EN',
    prompt: (text, lang) => en(text)
}







const stripQuotes = (v) => v.charAt(0) === '"' && v.charAt(v.length - 1) === '"' ? v.slice(1, -1) : v;


export const ask = async (uid, text, lang, key) => {

    const prompt = OPENAI_PROMPTS[key].prompt(text, lang);

    const choices = await fnAsk(uid, prompt);
    const answer = choices ? stripQuotes(choices[0]) : null;

    return answer;
}