
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useContext } from 'react';


import { StateContext, DispatchContext } from '../context/';

import { fnDetect } from "../firebase/functions.js";


import { Play } from "./Controller.js";



import MoreOptions from "../ui/MoreOptions.js";

import { ButtonWithModal, ButtonFlatIcon } from '../ui/Buttons.js';

import { ask } from "../openai/index.js";

import { SPEAK_MIN_CHARS, SPEAK_MAX_CHARS } from "../utils/consts.js";
import { OPENAI_PROMPTS } from "../openai/index.js";

import Rewriting from "../ui/Rewriting.js";

import { get } from "./utils.js";

import "../cache/cache-audio.js";

import { Contact } from "../layout/Contact.js";

import classNames from "classnames";


const SelectVoice = ({ voices, voice_id, onSelect }) => {

    const [hoveredVoice, setHoveredVoice] = useState(null);

    const male = require("../assets/svg/speak-male.svg").default;
    const female = require("../assets/svg/speak-female.svg").default;

    const listLabels = () => {
        let labels = { ...hoveredVoice.labels };
        delete labels.accent;

        return Object.values(labels).map(label =>
            <span key={label} className="px-4 py-1 bg-teal-600 text-sm text-white text-nowrap rounded-full shadow-light">
                {label}
            </span>)
    }


    const listVoices = voices.map(voice =>
        <li
            className={classNames('w-28 mr-1 p-2 rounded-md select-none cursor-pointer hover:!bg-gray-100 flex flex-row items-center space-x-1', voice.voice_id == voice_id && '!bg-blue-100')}
            key={voice.voice_id}
            onMouseEnter={() => setHoveredVoice(voice)}
            onMouseLeave={() => setHoveredVoice(null)}
            onClick={() => onSelect(voice)}>
            <img
                className="h-4 opacity-60"
                src={voice.labels.gender == 'male' ? male : female}
            />

            <span className="text-sm">{voice.name}</span>
        </li>
    );

    return (
        <div className="relative p-2 bg-white shadow">

            <ul className="grid grid-cols-4 gap-0 justify-between">
                {listVoices}

                <li
                    className="w-28 mr-1 p-2 border border-gray-200 rounded-md select-none opacity-50 transition-opacity duration-200 cursor-pointer hover:!opacity-100 flex flex-row items-center space-x-1"
                    key="clone"
                    onClick={() => onSelect(null)}>
                    <img
                        className="h-4 opacity-60"
                        src={male}
                    />
                    <span className="text-sm">My voice</span>
                </li>

            </ul>

            {/* {hoveredVoice?.name} */}

            {hoveredVoice &&
                <>
                    <div className="absolute z-10 left-0 top-0 py-1 -translate-y-full flex flex-row space-x-1">
                        {listLabels()}
                    </div>
                    <audio autoPlay={true} src={hoveredVoice.preview_url}></audio>
                </>
            }

        </div>
    );

};




const EditTextToAudio = ({ status, duration, play, stop }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, scene, voices, preferences, runtime } = state;
    const { voice_id, text } = scene.speak;

    const voice = voices.find((v) => v.voice_id == voice_id);

    const [isSelectVoiceModalVisible, setIsSelectVoiceModalVisible] = useState(false);
    const [showContact, setShowContact] = useState(false);


    const [isLoading, setIsLoading] = useState(false);

    const [textUndo, setTextUndo] = useState(null);

    useEffect(() => setTextUndo(null), [scene.id]);

    useEffect(() => { if (runtime.rewriting == 'speak') setTextUndo(text); }, [runtime.rewriting]);


    const ref = useRef(null);

    useEffect(() => { if (ref?.current) ref.current.setSelectionRange(text.length, text.length) }, []);


    const askOpenAI = async (key) => {

        dispatch({ type: 'runtime-rewriting-set', param: 'speak' });

        const lang = await fnDetect(file.userId, get.textForDetect(file));
        const textRewritten = await ask(file.userId, text, lang?.name, key);

        dispatch({ type: 'runtime-rewriting-set', param: null });

        if (textRewritten) dispatch({ type: 'scene-speak-update', param: { text: textRewritten } })

    }

    const copyFrom = scene.elems.filter(elem => elem.type == 'text');

    const undo = () => {
        if (!textUndo || textUndo == text) return;
        dispatch({ type: 'scene-speak-update', param: { text: textUndo } })

    }

    const options = [
        {
            name: 'Copy from',
            children: [],
            disabled: !copyFrom.length,
        },
        {
            name: 'Copy from (all scenes)',
            children: [],
            disabled: !copyFrom.length,
            delimiter: true,
        },

        {
            name: 'Apply voice to all scenes',
            action: () => dispatch({ type: 'applytoall', target: 'speak-voice_id', param: voice_id }),
        },

    ];




    copyFrom.forEach(({ desc, name, text }) => (
        options[0].children.push(
            {
                name: desc,
                tag: name,
                action: () => dispatch({ type: 'scene-speak-update', param: { text } }),
            })));

    copyFrom.forEach(({ desc, name }) => (
        options[1].children.push(
            {
                name: desc,
                tag: name,
                action: () => dispatch({ type: 'applytoall', target: 'speak-copy-from', param: name }),
            })));




    const toggle = () => dispatch({ type: 'preferences-tta-toggle' });
    const select = (v) => {

        setIsSelectVoiceModalVisible(false);
        if (!v) setShowContact(true);
        if (!v) return;

        const { voice_id } = v;
        dispatch({ type: 'preferences-voice_id-set', param: voice_id });
        dispatch({ type: 'scene-speak-update', param: { voice_id } });

    };





    const handlePlay = async () => {

        setIsLoading(true);

        try {
            await text.play(file.userId, voice_id, () => setIsLoading(false));

            play();

        }
        catch (err) {
            // console.log(err);

            setIsLoading(false);

        }



    }

    const handleStop = () => {

        if (isLoading) setIsLoading(false);//clicked outside while loading

        text.stop(voice_id);

        stop();

    }


    const listOpenAIPrompts = Object.keys(OPENAI_PROMPTS).map(key =>
        <li
            className="group px-2 py-1 border border-blue-200 rounded-sm transition-colors duration-100 cursor-pointer hover:bg-blue-100 flex flex-row items-center justify-center"
            key={key}
            onClick={() => askOpenAI(key)}>

            <span className="text-sm text-nowrap opacity-70 select-none group-hover:!opacity-100 group-active:translate-y-px">{OPENAI_PROMPTS[key].caption}</span>
        </li>
    )



    const male = require("../assets/svg/speak-male.svg").default;
    const female = require("../assets/svg/speak-female.svg").default;



    return (
        <>
            <div className="relative flex flex-col">

                <div className="h-12 flex flex-row items-center">

                    <div className="flex flex-row items-center">

                        <ButtonWithModal className="mr-2" place="tl" isModalVisible={isSelectVoiceModalVisible} setIsModalVisible={setIsSelectVoiceModalVisible}>
                            <button className={classNames('group px-6 py-2 bg-gray-200 hover:!bg-gray-300 flex flex-row items-center space-x-2', isSelectVoiceModalVisible && '!bg-gray-300')}>
                                <img
                                    className={classNames('h-4 opacity-80 group-hover:!opacity-100', isSelectVoiceModalVisible && '!opacity-100 scale-110')}
                                    src={voice?.labels?.gender == 'male' ? male : female} />

                                <span className={classNames('text-sm opacity-80 group-hover:!opacity-100', isSelectVoiceModalVisible && '!opacity-100')}>
                                    {voice?.name.replace(/\p{Emoji}/gu, '')}
                                </span>
                            </button>

                            <SelectVoice voices={voices} voice_id={voice_id} onSelect={(v) => select(v)} />

                        </ButtonWithModal>


                        <Play status={isLoading ? 'loading' : status} disabled={text.length < SPEAK_MIN_CHARS} duration={duration} play={handlePlay} stop={handleStop} />

                    </div>

                    <div className={classNames('flex-1 relative', preferences.tta == 'maximized' && 'invisible')}>
                        <div
                            className={classNames('pl-4 pr-6 text-xs italic text-gray-400 line-clamp-1 break-all opacity-60 transition-opacity select-none cursor-pointer hover:!opacity-100', runtime.rewriting == 'speak' && 'invisible')}
                            onClick={() => toggle()}>
                            {text}
                        </div>

                        {runtime.rewriting == 'speak' && <Rewriting className="px-8" color="text-black" size="text-xs" />}
                    </div>

                    <div className="h-full flex flex-row items-center">

                        <ButtonFlatIcon classNameGroup="hover:!bg-transparent" className={classNames('w-3', preferences.tta == 'maximized' && 'rotate-180')} svg="minimize.svg" click={toggle} />

                        <MoreOptions place="tr" light={true} options={options} />
                    </div>

                </div>


                {preferences.tta == 'maximized' &&
                    <div className="relative">
                        <textarea
                            ref={ref}
                            className={classNames('w-full h-[10rem] pl-4 pr-[15rem] xl:pr-[33rem] py-2 bg-white/0 border-t border-t-gray-50 resize-none focus:outline-none hover:!bg-white/70 focus:!bg-white placeholder:font-light placeholder:italic placeholder:text-gray-400', runtime.rewriting == 'speak' && 'invisible')}
                            placeholder="type here the text you want to be converted into audio"
                            value={text}
                            maxLength={SPEAK_MAX_CHARS}
                            autoFocus={true}
                            onChange={(e) => dispatch({ type: 'scene-speak-update', param: { text: e.target.value } })}>

                        </textarea>

                        {runtime.rewriting == 'speak' && <Rewriting className="px-4 py-2" color="text-black" size="text-lg" />}


                        <div className="absolute top-1 right-1 w-[15rem] xl:w-[33rem] flex flex-col items-end">
                            <ul className="flex flex-row flex-wrap items-center justify-end space-x-0.5 space-y-0.5">
                                {textUndo && textUndo != text &&
                                    <li
                                        className="group px-2 py-1 rounded-sm cursor-pointer hover:text-blue-500 flex flex-row items-center justify-center"
                                        onClick={() => undo()}>

                                        <span className="text-[0.7rem] text-nowrap select-none group-active:translate-y-px">Undo</span>
                                    </li>
                                }

                                {listOpenAIPrompts}
                            </ul>

                        </div>

                    </div>
                }

                {preferences.tta == 'maximized' && text.length > SPEAK_MAX_CHARS / 2 &&
                    <div className="absolute right-1 bottom-2 text-xs text-gray-500 px-2 py-1 bg-white">
                        {text.length}/{SPEAK_MAX_CHARS}
                    </div>
                }


            </div >

            {showContact && <Contact placeholder="Would you like to clone your voice? Contact us." close={() => setShowContact(false)} />}
        </>
    );

};


export default EditTextToAudio;