

import { compress } from "../utils/compress";


import roles from "./roles";




const base64toBlob = (base64) => fetch(base64).then(res => res.blob());

export const base64Compress = (base64) => new Promise(async (resolve, reject) => {

    const blob = await base64toBlob(base64);
    const result = await compress(blob);

    resolve(result);

})



const compose = (event, name, role) => {

    if (!event && !name && !role) return null;

    //todo: name sau role pot nu veni, nu le a gasit

    const mouseEvent = {
        'left-click': '',
        'right-click': '',
        'move': '',
    }


    return name;



}



const extractFocus = (captures) => captures.map(({ desc }) => {
    if (!desc) return {};
    const { location } = desc;

    if (!location) return {};

    const m = 4;//border width

    const left = location.left - m;
    const top = location.top - m;
    const width = location.right - location.left + 2 * m;
    const height = location.bottom - location.top + 2 * m;

    //todo: keystroke

    return { visible: true, bounds: { pristine: false, left, top, width, height } };
})


const extractText = (captures) => captures.map(({ desc, event }) => {
    if (!desc) return {};
    const { name, role } = desc;

    const text = compose(event, name, role);
    if (!text) return {};

    return { visible: true, text };
})


const extractArrow = (captures) => captures.map(({ desc }) => {
    if (!desc) return {};
    const { location } = desc;

    if (!location) return {};

    //todo: normal mai elaborat, daca are spatiu

    const m = 10;

    const left = location.right + m;
    const top = location.top + Math.round((location.bottom - location.top) / 2);

    const angle = 0;

    return { visible: true, bounds: { pristine: false, left, top }, angle };
})


const extractButton = (captures) => captures.map(({ desc }) => {
    if (!desc) return {};
    const { location } = desc;

    if (!location) return {};

    //todo: normal mai elaborat, daca are spatiu

    const m = 10;

    const left = location.right + m;
    const top = location.top;


    return { visible: false, bounds: { pristine: false, left, top } };
})

const extractCursors = (captures) => captures.map(({ cursor }) => {
    if (!cursor) return {};

    const { x1, y1, x2, y2 } = cursor;

    return { visible: true, point1: { pristine: false, x: x1, y: y1 }, point2: { pristine: false, x: x2, y: y2 } };
})

const extractTouch = (captures) => captures.map(({ cursor }) => {
    if (!cursor) return {};

    const { x2, y2 } = cursor;

    return { visible: false, bounds: { pristine: false, left: x2, top: y2 } };
})



const extractSpeak = (captures) => captures.map(({ desc, event }) => {
    if (!desc) return {};
    const { name, role } = desc;

    const text = compose(event, name, role);
    if (!text) return {};

    return { enabled: true, text };
})




export const extract = (captures) => {

    //does not extract relevant information, so we will ignore for the moment at lest
    captures.forEach(capture => delete capture.desc);

    const focus = extractFocus(captures);
    const text = extractText(captures);
    const arrow = extractArrow(captures);
    const button = extractButton(captures);
    const cursors = extractCursors(captures);
    const touch = extractTouch(captures);

    const speak = extractSpeak(captures);

    let arr = [];

    for (let i = 0; i < captures.length; i++) arr.push({
        elems: {
            'focus1': focus[i],
            'text1': text[i],
            'arrow1': arrow[i],
            'button1': button[i],
            'cursor1': cursors[i],
            'touch1': touch[i],
        },
        speak: speak[i]
    })

    return arr;

}