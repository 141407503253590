

import "../cache/cache-audio";
import "../cache/cache-translate";

import { zoom as zu } from "./utils";
import { objectsEqual, trig } from "../utils";

import { SCENE_MIN_DURATION, ELEM_MIN_DURATION, ELEM_TRANSITION, ZOOM_DURATION, FOCUS_INPUT_DELAY_CHAR, FOCUS_INPUT_CARET_DURATION, CURSOR_DURATION_1920, CURSOR_DURATION_MIN, CURSOR_CLICK, TOUCH_DURATION } from "../utils/consts";



const calcZoom = (file, scene) => {

    if (scene.template != 'bg-image') return null;

    const bgimage = scene.elems.find(elem => elem.name == 'bgimage');
    if (!bgimage.zoom.enabled) return { wait: 0, zoom: bgimage.zoom, zoom1: null, zoom2: null };

    const prevZoom = zu.prev(file, scene);
    const nextZoom = zu.next(file, scene);

    const hasZoom1 = prevZoom ? !objectsEqual(prevZoom, bgimage.zoom) : bgimage.zoom.value != 1;
    const hasZoom2 = nextZoom ? !objectsEqual(nextZoom, bgimage.zoom) : bgimage.zoom.value != 1;

    const zoom1 = hasZoom1 ? prevZoom ? prevZoom : { value: 1, x: 0, y: 0 } : null;
    // const zoom2 = hasZoom2 ? nextZoom ? nextZoom : { value: 1, x: 0, y: 0 } : null;
    const zoom2 = hasZoom2 ? nextZoom ? null : { value: 1, x: 0, y: 0 } : null;


    return { wait: bgimage.wait, zoom: bgimage.zoom, zoom1, zoom2 };

};

const calcInteractPosition = (scene) => {
    if (window.rec) return undefined;

    const m = 50;

    if (scene.template == 'intro') {

        const text1 = scene.elems.find(elem => elem.name == 'text1');
        const button1 = scene.elems.find(elem => elem.name == 'button1');

        const r1 = text1.visible && text1.transition ? ELEM_TRANSITION : 0;
        const r2 = button1.visible && button1.transition ? ELEM_TRANSITION : 0;

        const r = Math.max(r1, r2);

        if (button1.visible) return r + m;

    }


    if (scene.template == 'outro') {

        const text1 = scene.elems.find(elem => elem.name == 'text1');
        const button1 = scene.elems.find(elem => elem.name == 'button1');
        const button2 = scene.elems.find(elem => elem.name == 'button2');

        const r1 = text1.visible && text1.transition ? ELEM_TRANSITION : 0;
        const r2 = button1.visible && button1.transition ? ELEM_TRANSITION : 0;
        const r3 = button2.visible && button2.transition ? ELEM_TRANSITION : 0;

        const r = Math.max(r1, r2, r3);

        if (button1.visible) return r + m;
        if (button2.visible) return r + m;

    }

    if (scene.template == 'bg-image') {

        const focus1 = scene.elems.find(elem => elem.name == 'focus1');
        const text1 = scene.elems.find(elem => elem.name == 'text1');
        const arrow1 = scene.elems.find(elem => elem.name == 'arrow1');
        const button1 = scene.elems.find(elem => elem.name == 'button1');

        const r1 = focus1.visible && focus1.transition ? ELEM_TRANSITION : 0;
        const r2 = text1.visible && text1.transition ? ELEM_TRANSITION : 0;
        const r3 = arrow1.visible && arrow1.transition ? ELEM_TRANSITION : 0;
        const r4 = button1.visible && button1.transition ? ELEM_TRANSITION : 0;

        const r = Math.max(r1, r2, r3, r4);

        if (focus1.visible && focus1.pause) return r + m;
        if (button1.visible) return r + m;

    }


    return undefined;
}


const calcMoveDuration = (scene) => {

    if (scene.template != 'bg-image') return 0;

    const cursor = scene.elems.find(elem => elem.name == 'cursor1');
    const touch = scene.elems.find(elem => elem.name == 'touch1');

    const d1 = cursor.visible ? Math.max(Math.floor((CURSOR_DURATION_1920 * trig.distance(cursor.point1, cursor.point2)) / 1920), CURSOR_DURATION_MIN) + (cursor.effect == 'click' ? CURSOR_CLICK : 0) : 0;
    const d2 = touch.visible ? TOUCH_DURATION + 50 : 0; //add 50ms to let the animation be removed at end

    return Math.max(d1, d2);

}


const calcDuration = (scene, zoom, code) => {


    let v = 0;

    let speak = 0;

    if (scene.speak.enabled && scene.speak.text) {

        const d = scene.speak.text.translate(code).duration(scene.speak.voice_id);

        speak = Math.round(d * 1000);
    }


    //todo: cind nu e bg-image


    const duration = (elem) => {


        let d = ('wait' in elem ? elem.wait : 0) + ELEM_MIN_DURATION;


        switch (elem.name) {
            case 'bgimage':

                const zd = zoom ? (zoom.zoom1 ? ZOOM_DURATION : 0) + (zoom.zoom2 ? ZOOM_DURATION : 0) : 0;

                d = (zd > 0 ? elem.wait : 0) + zd;

                break;

            case 'focus1':

                // d = elem.transition == 'move' ? 0 : elem.wait;
                d = elem.wait;
                if (elem.input.text) d = d + Math.max(elem.wait + elem.input.text.length * FOCUS_INPUT_DELAY_CHAR + FOCUS_INPUT_CARET_DURATION, ELEM_MIN_DURATION);
                else d = d + ELEM_MIN_DURATION;


                break;

            case 'button1':

                d = (elem.transition ? 2 * ELEM_TRANSITION  : 0) + 100; //500;

                break;

            case 'cursor1':

                d = 0;

                break;

            case 'touch1':

                d = 0;

                break;
        }


        return d;
    }



    // const mx = scene.template == 'bg-image' ? Math.max(Math.max(...scene.elems.filter(elem => elem.visible && 'wait' in elem).map(elem => duration(elem))), 0) : 0;

    let mx1 = 0;

    switch (scene.template) {
        case 'intro':
            mx1 = Math.max(Math.max(...scene.elems.filter(elem => elem.visible).map(elem => ('wait' in elem ? elem.wait : 0) + ELEM_MIN_DURATION)), 0);
            break;
        case 'outro':
            mx1 = Math.max(Math.max(...scene.elems.filter(elem => elem.visible).map(elem => ('wait' in elem ? elem.wait : 0) + ELEM_MIN_DURATION)), 0);
            break;        
        case 'bg-image':
            mx1 = Math.max(Math.max(...scene.elems.filter(elem => elem.visible).map(elem => duration(elem))), 0);
            break;
    }



    const interact = calcInteractPosition(scene);
    if (interact !== undefined && speak > 0) mx1 = Math.max(mx1, speak + interact + 10);


    const mx2 = calcMoveDuration(scene);

    // v = Math.max(speak, mx);
    v = Math.max(speak, mx1 + mx2);


    v = scene.wait + v;


    v = Math.max(v, SCENE_MIN_DURATION);


    return v;

};


const calcStart = (zoom) => {

    return zoom && zoom.zoom1 ? zoom.wait + ZOOM_DURATION : 0;

};



export default {

    calc: (file, scene, code) => {

        const zoom = calcZoom(file, scene);
        const duration = calcDuration(scene, zoom, code);
        const start = calcStart(zoom);


        //todo: analyze
        //daca folosesti, foloseste acleasi cacat si in editscene
        let duration_for_elems = zoom ? zoom.zoom2 ? duration - ZOOM_DURATION : duration : duration;

        const mx2 = calcMoveDuration(scene);
        duration_for_elems = duration_for_elems - mx2;

        let interact = calcInteractPosition(scene);
        if (interact !== undefined) interact = duration_for_elems - interact;


        // return { zoom, duration, start };

        return { zoom, duration, start, duration_for_elems, interact };

    },



    statuses: (zoom, duration) => {

        if (!zoom) return null;

        const wait = (zoom.zoom1 || zoom.zoom2) ? zoom.wait : 0;
        const t1 = zoom.zoom1 ? ZOOM_DURATION : 0;
        const t2 = zoom.zoom2 ? ZOOM_DURATION : 0;
        const t = duration - wait - t1 - t2;


        const v = {
            'waiting': { a: 0, b: wait },
            'zoom1': { a: wait, b: wait + t1, progress: true },
            'entered': { a: wait + t1, b: wait + t1 + t },
            'zoom2': { a: wait + t1 + t, b: wait + t1 + t + t2, progress: true },
        };

        if (wait == 0) delete v['waiting'];

        if (t1 == 0) delete v['zoom1'];
        if (t2 == 0) delete v['zoom2'];

        return v;



    }

}