import React, { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import { fnDetect } from "../firebase/functions.js";

import ElemBgImage from './ElemBgImage.js';

import { PropBgImage, PropFocus, PropArrow, PropTouch, PropText, PropButton, PropCursor } from "./Props.js";

import { drawCursorPath, computeCursor } from "../utils/cursor.js";
import { trig, getTextColor, tailwindColorToHex, randomFromList } from "../utils/index.js";

import { useClickOutside, useDrag } from "../hooks/index.js";

import { focus, cursor, get } from "./utils.js";

import Arrows from "./Arrows.js";
import Cursors from "./Cursors.js";

import { ask } from "../openai/index.js";

import Rewriting from "../ui/Rewriting.js";


import { ELEM_TRANSITION, ELEM_MIN_DURATION, FOCUS_INPUT_DELAY_CHAR, CURSOR_DURATION_1920, CURSOR_DURATION_MIN, CURSOR_CLICK, TOUCH_DURATION, TEXT_SIZES, CONTINUE_VARIANTS, TRANSITIONS_ARROW } from "../utils/consts.js";
import { OPENAI_PROMPTS } from "../openai/index.js";


import "../cache/cache-translate"

import emitter from "../emmiter/index.js";

import classNames from "classnames";




const useCanvasScale = (ref) => {
    const [canvasScale, setCanvasScale] = useState(1);

    useLayoutEffect(() => {
        if (!ref?.current) return;

        const v = ref.current.parentElement.parentElement.offsetWidth / ref.current.parentElement.parentElement.getBoundingClientRect().width;
        setCanvasScale(v);

    }, [ref]);

    return canvasScale;
}

const getHandleStyle = (canvasScale) => {
    const size = 15 * canvasScale;
    const border = 2 * canvasScale;

    return {
        'width': `${size}px`,
        'height': `${size}px`,
        'borderWidth': `${border}px`
    };
}


const Handle = ({ type, keepAspectRatio = false, rect, setRect }) => {

    let minW = 20;
    let minH = 20;

    const ratio = rect.width / rect.height;
    if (keepAspectRatio) minW = minH * ratio;

    const ref = useRef(null);

    const [isDragging, setIsDragging] = useState(false);
    const [dragInfo, setDragInfo] = useState({ rect: { left: 0, top: 0, width: 0, height: 0 }, x: 0, y: 0 });


    useDrag(isDragging,
        (x, y) => {

            let deltaX = Math.round((x - dragInfo.x) * canvasScale);
            let deltaY = Math.round((y - dragInfo.y) * canvasScale);

            // let deltaX = (x - dragInfo.x) * canvasScale;
            // let deltaY = (y - dragInfo.y) * canvasScale;


            let r = { ...dragInfo.rect };

            switch (type) {
                case 'lt':
                    if (keepAspectRatio) deltaY = Math.round(deltaX * 1 / ratio);

                    r.left = r.left + deltaX;
                    r.top = r.top + deltaY;
                    r.width = r.width - deltaX;
                    r.height = r.height - deltaY;

                    r.width = Math.max(r.width, minW);
                    r.height = Math.max(r.height, minH);


                    if (r.left + r.width > dragInfo.rect.left + dragInfo.rect.width) {
                        r.left = dragInfo.rect.left + dragInfo.rect.width - minW;
                        r.width = minW;
                    };

                    if (r.top + r.height > dragInfo.rect.top + dragInfo.rect.height) {
                        r.top = dragInfo.rect.top + dragInfo.rect.height - minH;
                        r.height = minH;
                    };

                    break;

                case 'rt':
                    if (keepAspectRatio) deltaY = Math.round(-deltaX * 1 / ratio);

                    r.top = r.top + deltaY;
                    r.width = r.width + deltaX;
                    r.height = r.height - deltaY;

                    r.width = Math.max(r.width, minW);
                    r.height = Math.max(r.height, minH);

                    if (r.top + r.height > dragInfo.rect.top + dragInfo.rect.height) {
                        r.top = dragInfo.rect.top + dragInfo.rect.height - minH;
                        r.height = minH;
                    };

                    break;

                case 'lb':
                    if (keepAspectRatio) deltaY = Math.round(-deltaX * 1 / ratio);

                    r.left = r.left + deltaX;
                    r.width = r.width - deltaX;
                    r.height = r.height + deltaY;

                    r.width = Math.max(r.width, minW);
                    r.height = Math.max(r.height, minH);


                    if (r.left + r.width > dragInfo.rect.left + dragInfo.rect.width) {
                        r.left = dragInfo.rect.left + dragInfo.rect.width - minW;
                        r.width = minW;
                    };

                    break;

                case 'rb':
                    if (keepAspectRatio) deltaY = Math.round(deltaX * 1 / ratio);

                    r.width = r.width + deltaX;
                    r.height = r.height + deltaY;

                    r.width = Math.max(r.width, minW);
                    r.height = Math.max(r.height, minH);

                    break;

                case 't':
                    if (keepAspectRatio) {
                        deltaX = Math.round(deltaY * ratio);
                        r.width = r.width - deltaX;

                        r.width = Math.max(r.width, minW);
                    };

                    r.top = r.top + deltaY;
                    r.height = r.height - deltaY;

                    r.height = Math.max(r.height, minH);

                    if (r.top + r.height > dragInfo.rect.top + dragInfo.rect.height) {
                        r.top = dragInfo.rect.top + dragInfo.rect.height - minH;
                        r.height = minH;
                    };

                    break;

                case 'r':
                    if (keepAspectRatio) {
                        deltaY = Math.round(deltaX * 1 / ratio);
                        r.height = r.height + deltaY;

                        r.height = Math.max(r.height, minH);
                    };

                    r.width = r.width + deltaX;
                    r.width = Math.max(r.width, minW);

                    break;

                case 'b':
                    if (keepAspectRatio) {
                        deltaX = Math.round(deltaY * ratio);
                        r.width = r.width + deltaX;

                        r.width = Math.max(r.width, minW);
                    };

                    r.height = r.height + deltaY;
                    r.height = Math.max(r.height, minH);

                    break;

                case 'l':
                    if (keepAspectRatio) {
                        deltaY = Math.round(deltaX * 1 / ratio);
                        r.height = r.height - deltaY;

                        r.height = Math.max(r.height, minH);
                    };

                    r.left = r.left + deltaX;
                    r.width = r.width - deltaX;

                    r.width = Math.max(r.width, minW);

                    if (r.left + r.width > dragInfo.rect.left + dragInfo.rect.width) {
                        r.left = dragInfo.rect.left + dragInfo.rect.width - minW;
                        r.width = minW;
                    };

                    break;
            }





            setRect(r);
        },
        () => {
            setIsDragging(false);
        }
    );


    const handleMouseDown = (e) => {
        e.stopPropagation();

        const x = e.clientX;
        const y = e.clientY;

        setIsDragging(true);
        setDragInfo({ rect, x, y });
    };


    const handleKeyDown = (e) => {
        if (isDragging && e.keyCode === 27) {
            e.stopPropagation();

            setIsDragging(false);
            setRect(dragInfo.rect);
        };
    };



    // const canvasScale = ref?.current ? ref.current.parentElement.parentElement.offsetWidth / ref.current.parentElement.parentElement.getBoundingClientRect().width : 1;


    const canvasScale = useCanvasScale(ref);

    const style = getHandleStyle(canvasScale);


    return (
        <>
            <div
                ref={ref}
                className={classNames('absolute z-20 w-5 h-5 bg-white border-2 border-blue-400 rounded-full !pointer-events-auto cursor-point focus:outline-none hover:!bg-black',
                    isDragging && '!bg-black',
                    type == 'lt' && 'left-0 top-0 -translate-x-1/2 -translate-y-1/2',
                    type == 'rt' && 'right-0 top-0 translate-x-1/2 -translate-y-1/2',
                    type == 'lb' && 'left-0 bottom-0 -translate-x-1/2 translate-y-1/2',
                    type == 'rb' && 'right-0 bottom-0 translate-x-1/2 translate-y-1/2',

                    type == 't' && 'left-1/2 top-0 -translate-x-1/2 -translate-y-1/2',
                    type == 'r' && 'right-0 top-1/2 translate-x-1/2 -translate-y-1/2',
                    type == 'b' && 'left-1/2 bottom-0 -translate-x-1/2 translate-y-1/2',
                    type == 'l' && 'left-0 top-1/2 -translate-x-1/2 -translate-y-1/2',

                    type == 'rotate' && 'left-0 bottom-0 -translate-x-1/2 translate-y-1/2',

                )}
                style={style}
                tabIndex={-1}
                onMouseDown={(e) => handleMouseDown(e)}
                onKeyDown={(e) => handleKeyDown(e)}>
            </div>

            {isDragging && <div className="absolute z-50 -left-[9999px] -top-[9999px] -right-[9999px] -bottom-[9999px] !pointer-events-auto cursor-point"></div>}

        </>
    );

};

const ElemEditableText = ({ className, line = false, autoWidth = false, text, setText, placeholder, oneClickEdit = false, readOnly = false }) => {

    const dispatch = useContext(DispatchContext);

    const [isFocusing, setIsFocusing] = useState(false);
    const [isEditing, setIsEditing] = useState(false);


    const [initialText, setInitialText] = useState('');

    const maxCharCount = 400;
    const ref = useRef(null);


    useEffect(() => dispatch({ type: 'runtime-editing-set', param: isEditing }), [isEditing]);


    useEffect(() => {
        if (ref && ref.current) {
            ref.current.style.height = '0px';
            const scrollHeight = ref.current.scrollHeight;

            ref.current.style.height = `${scrollHeight}px`;

            if (autoWidth) {
                ref.current.style.width = '0px';
                const scrollWidth = ref.current.scrollWidth;

                // ref.current.style.width = `${scrollWidth + 16}px`;
                ref.current.style.width = `${scrollWidth + 1}px`;
            }

        }
    }, [ref, text, isEditing]);


    const handleClick = (e) => {
        if (readOnly) return;

        if (e.detail == 2 || oneClickEdit) {
            e.stopPropagation();

            setInitialText(text);
            setIsEditing(true);
        };
    };

    const handleFocus = (e) => {
        const end = e.target.value.length;
        e.target.setSelectionRange(end, end);
    };

    const handleChange = (e) => {
        setText(e.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const handleKeyDown = (e) => {
        if (readOnly) return;

        if (isEditing && e.keyCode === 13 && line) {
            e.preventDefault();
        }

        if (isEditing && e.keyCode === 27) {
            e.stopPropagation();

            setText(initialText);
            setIsEditing(false);
        };
    };

    const handleContextMenu = (e) => {
        if (readOnly) return;

        // e.stopPropagation();
        if (oneClickEdit) {
            e.stopPropagation();
            setIsEditing(true);
        }
    }


    //flex-1 - only if I want to be vertically aligned with resizable

    return (
        <div
            className="flex flex-col"
            onMouseEnter={() => setIsFocusing(true)}
            onMouseLeave={() => setIsFocusing(false)}
            onContextMenu={(e) => handleContextMenu(e)}>

            {!isEditing && text &&
                <div
                    className={classNames('select-none', className)}
                    onClick={(e) => handleClick(e)}>
                    <span className="whitespace-pre-wrap break-words">{text}</span>
                </div>
            }

            {!isEditing && !text &&
                <div
                    className={classNames('select-none', className)}
                    onClick={(e) => handleClick(e)} >
                    <span className="font-thin italic opacity-70 whitespace-pre-wrap break-words">{placeholder || 'double click to edit'}</span>
                </div >
            }

            {
                isEditing &&
                <textarea
                    ref={ref}
                    className={classNames('w-full appearance-none resize-none focus:outline-none', className)}
                    maxLength={maxCharCount}
                    rows={1}
                    value={text}
                    autoFocus={true}
                    onFocus={(e) => handleFocus(e)}
                    onBlur={() => handleBlur()}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onMouseDown={(e) => e.stopPropagation()}
                    placeholder="">
                </textarea>
            }



        </div>
    );

};

const motionElemStatuses = (wait, t1, t, t2) => {

    return useMemo(() => {
        const v = {
            'waiting': { a: 0, b: wait },
            'entering': { a: wait, b: wait + t1 },
            'entered': { a: wait + t1, b: wait + t1 + t },
            'exiting': { a: wait + t1 + t, b: wait + t1 + t + t2 },
            'exited': { a: wait + t1 + t + t2, b: Infinity },
        };

        if (wait == 0) delete v['waiting'];
        if (t1 == 0) delete v['entering'];
        if (t2 == 0) delete v['exiting'];

        return v;
    }, [wait, t1, t, t2])


}

const motionElemStatus = (statuses, elapsed) => {

    const arr = Object.keys(statuses).reverse();

    const key = arr.find(key => statuses[key].a <= elapsed && elapsed < statuses[key].b);
    const progress = (key && statuses[key].progress) ? Math.round(((elapsed - statuses[key].a) / (statuses[key].b - statuses[key].a)) * 100) : 0;

    return [key, progress];

}

export const useElemMotion = (motion, statuses) => {

    const initialStatus = motion && statuses ? motionElemStatus(statuses, 0)[0] : 'entered';

    const [status, setStatus] = useState(initialStatus);
    const [progress, setProgress] = useState(0);

    const statusRef = useRef(initialStatus);
    const progressRef = useRef(0);

    const handleSceneChange = () => {

        setStatus(initialStatus);
        statusRef.current = initialStatus;

        setProgress(0);
        progressRef.current = 0;

    }


    const handleSceneProgress = ({ elapsed }) => {

        if (!statuses) return;

        const [s, p] = motionElemStatus(statuses, elapsed);


        //otherwise react will send the same event two times even it's identical
        if (statusRef.current != s) setStatus(s);
        if (progressRef.current != p) setProgress(p);

        statusRef.current = s;
        progressRef.current = p;

    }


    const [current, setCurrent] = useState(null);
    const v = JSON.stringify(statuses);

    useEffect(() => {
        if (!motion) return;
        if (!v) return;

        setCurrent(v);

        setStatus(initialStatus);

        emitter.on('scene.change', handleSceneChange);
        emitter.on('scene.progress', handleSceneProgress);

        return () => {

            emitter.off('scene.change', handleSceneChange);
            emitter.off('scene.progress', handleSceneProgress);

        }

    }, [motion, v]);


    return motion ? current != JSON.stringify(statuses) ? [initialStatus, 0] : [status, progress] : [undefined, 0];

}


const animateReverse = (v) => ['animate-zoom', 'outline', 'animate-rotate-x', 'animate-shake-x'].includes(v) ? 'animate-fade' : v;


const el = {
    viewport: (crop, zoom) => {
        const r = { ...crop };
        if (zoom.enabled) {
            r.left = r.left - zoom.x * 1 / zoom.value;
            r.top = r.top - zoom.y * 1 / zoom.value;

            r.width = r.width / zoom.value;
            r.height = r.height / zoom.value;
        }

        return r;
    },
    check: (r, viewport) => {
        const w = 'width' in r ? r.width : 0;
        const h = 'height' in r ? r.height : 0;

        r.left = Math.round(Math.min(Math.max(r.left, viewport.left - w), viewport.left + viewport.width));
        r.top = Math.round(Math.min(Math.max(r.top, viewport.top - h), viewport.top + viewport.height));

    },
    transform: (r, crop, zoom) => {
        r.left = r.left - crop.left;
        r.top = r.top - crop.top;

        if (zoom.enabled) {
            r.left = r.left * zoom.value + zoom.x;
            r.top = r.top * zoom.value + zoom.y;

            if ('width' in r) r.width = r.width * zoom.value;
            if ('height' in r) r.height = r.height * zoom.value;
        };

        // Object.keys(r).forEach(key => r[key] = Math.round(r[key]));

    },
    reverse: (r, crop, zoom) => {
        if (zoom.enabled) {
            r.left = (r.left - zoom.x) / zoom.value;
            r.top = (r.top - zoom.y) / zoom.value;

            if ('width' in r) r.width = r.width / zoom.value;
            if ('height' in r) r.height = r.height / zoom.value;

        };

        r.left = r.left + crop.left;
        r.top = r.top + crop.top;

        Object.keys(r).forEach(key => r[key] = Math.round(r[key]));
    },
    rect: (r) => ({ left: r.left, top: r.top, width: 'width' in r ? r.width : 1, height: 'height' in r ? r.height : 1 }),


}




const Elem = (props) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { scene, elem, runtime } = state;


    const ref = useRef(null);

    const [isDragging, setIsDragging] = useState(false);
    const [dragInfo, setDragInfo] = useState(null);


    const { crop } = props.parent;
    const { zoom } = props.motion ? props : props.parent;


    const options = [
        ...props.options,
        {
            name: 'Apply to all scenes',
            children: [
                {
                    name: 'Location',
                    action: () => dispatch({ type: 'applytoall', target: 'bounds' }),
                }
            ]

        },
    ];


    const { related } = props;

    const rect = { ...props.rect };
    const handles = runtime.editing || runtime.rewriting == props.name || props.motion ? [] : props.handles;



    const viewport = el.viewport(crop, zoom);

    if (related) {

        if (!rect.pristine) {

            el.check(rect, viewport);
            el.transform(rect, crop, zoom);

        }

    }
    else {

        //check

        // const w = 'width' in rect ? rect.width : 0;
        // const h = 'height' in rect ? rect.height : 0;

        //tood: ceva scamatorie ptr ca ref nu exista prima data
        const w = 'width' in rect ? rect.width : ref.current ? ref.current.offsetWidth : 0;
        const h = 'height' in rect ? rect.height : ref.current ? ref.current.offsetHeight : 0;

        rect.left = Math.min(Math.max(rect.left, -w), crop.width);
        rect.top = Math.min(Math.max(rect.top, -h), crop.height);

    }





    const setRect = (r) => {

        if ('width' in props.bounds == false) delete r.width;
        if ('height' in props.bounds == false) delete r.height;


        if (related) el.reverse(r, crop, zoom);

        dispatch({ type: 'elem-update', param: { bounds: { ...r } } })
    }


    useDrag(isDragging,
        (x, y) => {

            const canvasScale = ref.current.parentElement.getBoundingClientRect().width / ref.current.parentElement.offsetWidth;

            const deltaX = (x - dragInfo.x) * 1 / canvasScale;
            const deltaY = (y - dragInfo.y) * 1 / canvasScale;


            const { bounds } = dragInfo;

            let left = bounds.left + deltaX;
            let top = bounds.top + deltaY;


            if (related) {

                const r = { ...rect, left, top };
                delete r.pristine;

                // const r = { left, top };

                el.reverse(r, crop, zoom);
                el.check(r, viewport);

                dispatch({ type: 'elem-update', param: { bounds: { ...props.bounds, ...r } } });

            }
            else {

                const parentWidth = ref.current.parentElement.offsetWidth;
                const parentHeight = ref.current.parentElement.offsetHeight;
                const elemWidth = ref.current.offsetWidth;
                const elemHeight = ref.current.offsetHeight;


                left = Math.min(Math.max(left, -elemWidth), parentWidth);
                top = Math.min(Math.max(top, -elemHeight), parentHeight);


                // dispatch({ type: 'elem-update', param: { bounds: { ...props.bounds, left, top } } });
                dispatch({ type: 'elem-update', param: { bounds: { ...rect, left, top } } });

            };





        },
        () => {

            setIsDragging(false);

        });


    const handleMouseDown = (e) => {
        if (props.motion) return;

        e.stopPropagation();

        dispatch({ type: 'elem-set', elem: props });

        const x = e.clientX;
        const y = e.clientY;

        setIsDragging(true);
        setDragInfo({ x, y, bounds: { ...rect } });

    }

    const handleKeyDown = (e) => {
        if (props.motion) return;


        if (e.keyCode == 27 && isDragging) {

            setIsDragging(false);
            dispatch({ type: 'elem-update', param: { bounds: { ...dragInfo.bounds } } });

        }

        if (e.keyCode == 46 && !isDragging && !runtime.editing) {

            dispatch({ type: 'elem-update', param: { visible: false } });
            dispatch({ type: 'elem-set', elem: scene.elems.find(elem => elem.name == 'bgimage') });

        }

    }

    const handleContextMenu = (e) => {
        if (props.motion) return;

        e.stopPropagation();
        if (runtime.editing) return;

        e.preventDefault();
        // e.stopPropagation();

        props.contextmenu({ x: e.clientX, y: e.clientY, sender: null, options })
    }


    const style = Object.keys(props.bounds).filter(key => key != 'pristine').reduce((a, c) => { a[c] = `${rect[c]}px`; return a; }, {});



    const listHandles = handles.map(handle => <Handle key={handle} type={handle} rect={el.rect(rect)} setRect={setRect} />)


    return (
        <>
            {isDragging && <div className="absolute left-0 top-0 right-0 bottom-0 cursor-default" ></div>}
            <div
                ref={ref}
                className={classNames('absolute focus:outline-none', !props.bounds && 'left-0 top-0 right-0 bottom-0', props.animation)}
                style={style}
                tabIndex={-1}
                onMouseDown={(e) => handleMouseDown(e)}
                onKeyDown={(e) => handleKeyDown(e)}
                onContextMenu={(e) => handleContextMenu(e)}>

                {!props.motion && elem?.name == props.name &&
                    <div className="absolute left-0 top-0 right-0 bottom-0 pointer-events-none">
                        {listHandles}
                    </div>
                }

                {props.bounds && props.children}

            </div>
        </>

    );

};


const Caret = ({ blink, interval = 500 }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (!blink) return;

        const timer = setInterval(() => setIsVisible(prev => !prev), interval);
        return () => clearInterval(timer);
    }, [blink])

    return (<span className={classNames(blink && 'invisible', blink && isVisible && '!visible')}>|</span>)

}

const Typewriter = ({ text, delay = FOCUS_INPUT_DELAY_CHAR }) => {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        let timeout;

        if (currentIndex < text.length) {
            timeout = setTimeout(() => {
                setCurrentText(prevText => prevText + text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            }, delay);
        };

        return () => clearTimeout(timeout);
    }, [currentIndex, delay, text]);

    return (
        <>
            {currentText}
            <Caret blink={currentIndex >= text.length} />
        </>
    )
};

const TextInput = ({ className, input, interaction }) => {

    const [text, setText] = useState('');

    const check = () => { if (input.text.toLowerCase() == text.toLowerCase()) interaction.success(); else interaction.error(); }

    const change = (v) => {

        setText(v);

        // if (input.text.toLowerCase() == text.toLowerCase()) interaction.success();

        interaction.stopWiggling();

    }

    const handleFocus = (e) => {
        e.target.select();

    }

    const handleBlur = () => {

        check();

    }

    const handleKeyDown = (e) => { if ([9, 13].includes(e.keyCode)) check(); }

    return (
        <input className={classNames('appearance-none p-0 focus:outline-none placeholder:text-gray-300', className)}
            placeholder="type here"
            type={input.password ? 'password' : 'text'}
            maxLength="50"
            required={true}
            autoComplete="off"
            spellCheck={false}
            autoFocus={true}
            value={text}
            onFocus={(e) => handleFocus(e)}
            onChange={(e) => change(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={() => handleBlur()}
        />
    )
}


const FocusInput = ({ input, pause, motion, showme, interaction }) => {

    const text = input.password ? '*'.repeat(input.text.length) : input.text;


    const editZone = pause;

    //https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_containment/Container_queries

    const style = { 'containerType': 'size' };

    return (
        <div
            className={classNames('absolute left-0 top-0 right-0 bottom-0 p-1 truncate flex flex-row items-center', input.bg)}
            style={style}>

            {(!motion || (!editZone && motion && showme)) &&
                <p className={classNames('w-full text-[60cqh]', getTextColor(input.bg), input.align)}>
                    {!motion && text}
                    {!editZone && motion && showme && <Typewriter text={text} />}
                </p>
            }

            {editZone && motion && showme && <TextInput className={classNames('w-full h-full text-[60cqh]', getTextColor(input.bg), input.align)} input={input} interaction={interaction} />}

        </div>
    )

}

const Outline = ({ border }) => {

    const bg = `bg-${border.split('-').slice(1).join('-')}`;

    return (
        <>
            <div className={classNames('animate-outline-scale-width animate-fill-forwards absolute left-0 top-0 h-1 w-0', bg)}></div>
            <div className={classNames('animate-outline-scale-height animate-fill-forwards animate-delay-[120ms] absolute right-0 top-0 w-1 h-0', bg)}></div>
            <div className={classNames('animate-outline-scale-width animate-fill-forwards animate-delay-[240ms] absolute right-0 bottom-0 h-1 w-0', bg)}></div>
            <div className={classNames('animate-outline-scale-height animate-fill-forwards animate-delay-[360ms] absolute left-0 bottom-0 w-1 h-0', bg)}></div>
        </>
    )
}

const useAnimateWiggle = (count) => {

    const [animation, setAnimation] = useState(null);

    const interval = 2000;
    const wiggle = 'animate-focus-wiggle animate-twice !animate-duration-300';


    useEffect(() => {
        if (!count) return;

        const timer = setInterval(() => setAnimation(prev => prev ? null : wiggle), interval);
        return () => clearInterval(timer);
    }, [count])


    return animation;
}

const useAnimateError = (count) => {

    const [animation, setAnimation] = useState(null);

    const duration = 200;
    const error = 'animate-focus-error animate-once !animate-duration-200';


    useEffect(() => {
        if (!count) return;

        setAnimation(error);

        const timer = setTimeout(() => setAnimation(null), duration);
        return () => clearTimeout(timer);

    }, [count])


    return animation;
}


const ElemFocus = (props) => {

    const state = useContext(StateContext);
    // const dispatch = useContext(DispatchContext);

    const { file } = state;


    const { crop } = props.parent;
    const { zoom } = props.motion ? props : props.parent;


    const options = [];

    const handles = ['lt', 'rb'];
    const related = true;
    let rect = props.bounds.pristine ? { ...props.bounds, left: (crop.width - props.bounds.width) / 2, top: (crop.height - props.bounds.height) / 2 } : { ...props.bounds };


    // ----

    const ref = useRef(null);

    const [wiggleCount, setWiggleCount] = useState(0);
    const [errorCount, setErrorCount] = useState(0);

    const animateWiggle = useAnimateWiggle(wiggleCount);
    const animateError = useAnimateError(errorCount);


    const interaction = {

        error: () => {
            setErrorCount(prev => prev + 1);
            setWiggleCount(prev => prev + 1);//reset timer
        },
        success: () => {
            setWiggleCount(0);//stop

            emitter.emit('interact.continue');

        },
        stopWiggling: () => {
            if (wiggleCount) setWiggleCount(0);//stop
        }


    }



    // -----

    let opacity = props.cover.opacity;

    let t1 = props.transition ? ELEM_TRANSITION : 0;
    let t2 = props.transition ? ELEM_TRANSITION : 0;

    const prev = focus.prev(file, props.scene);
    const next = focus.next(file, props.scene);

    if (props.transition == 'move' && !props.bounds.pristine) {

        if (prev) {
            const point1 = { x: prev.bounds.left + prev.bounds.width / 2, y: prev.bounds.top + prev.bounds.height / 2 };
            const point2 = { x: props.bounds.left + props.bounds.width / 2, y: props.bounds.top + props.bounds.height / 2 };

            const dist = trig.distance(point1, point2);
            const criterion = { dist: 1980, duration: 5000 };

            t1 = Math.floor(criterion.duration * dist / criterion.dist);
            t1 = Math.min(t1, ELEM_MIN_DURATION);

        }

        if (next && next.transition == 'move' && !next.bounds.pristine) t2 = 0;
    }


    const t = props.duration - props.start - props.wait - t1 - t2;

    const statuses = motionElemStatuses(props.start + props.wait, t1, t, t2);

    if (props.input.text) {
        statuses['typing'] = { a: statuses['entered'].a + props.input.wait, b: statuses['entered'].b };
    }

    if (props.transition == 'move' && t1 && prev) {
        statuses['entering'].progress = true;
    }


    const [status, progress] = useElemMotion(props.motion, statuses);


    if (status != 'entered' && wiggleCount) setWiggleCount(0);
    // if (status == 'entered' && props.pause && props.paused && !wiggleCount) setWiggleCount(prev => prev + 1);
    if (status == 'entered' && props.pause && !wiggleCount) setWiggleCount(prev => prev + 1);


    const animation = {
        'waiting': props.transition != 'move' ? 'hidden' : prev ? null : 'hidden',
        'entering': props.transition != 'move' && props.transition != 'outline' ? `${props.transition} animate-duration-500` : null,
        'exiting': props.transition != 'move' ? `${animateReverse(props.transition)} animate-reverse animate-duration-500` : null,
        'exited': props.transition != 'move' ? 'hidden' : null,
    }

    const showInput = ['typing', 'exiting'].includes(status);



    if (status == 'waiting' && props.transition == 'move' && !props.bounds.pristine) {

        if (prev) {
            rect = prev.bounds;
            opacity = prev.cover.opacity;
        }

    }

    if (status == 'entering' && props.transition == 'move' && !props.bounds.pristine) {


        if (prev) {

            const r1 = prev.bounds;
            const r2 = props.bounds;


            const easeOutSine = (x) => Math.sin((x * Math.PI) / 2);
            // const easeOutCubic = (x) => 1 - Math.pow(1 - x, 3);


            const interpolate = (a, b, progress) => a + (b - a) * easeOutSine(progress);

            const interpolateRect = (r1, r2, progress) => ({ left: interpolate(r1.left, r2.left, progress), top: interpolate(r1.top, r2.top, progress), width: interpolate(r1.width, r2.width, progress), height: interpolate(r1.height, r2.height, progress) })

            rect = interpolateRect(r1, r2, progress / 100);

            opacity = interpolate(prev.cover.opacity, props.cover.opacity, progress / 100);


        }


    }



    const newProps = { ...props, handles, related, rect, options, animation: animation[status] };


    // const newProps = { ...props, handles, related, rect, options };



    const r = { ...rect };

    if (!r.pristine) {
        el.check(r, el.viewport(crop, zoom));
        el.transform(r, crop, zoom);
    }

    const style = { opacity };
    Object.keys(props.bounds).filter(key => key != 'pristine').forEach(key => style[key] = `${r[key]}px`);



    //interactivity

    // const clickZone = props.motion && props.pause && props.paused && !props.input.text && status == 'entered';
    const clickZone = props.motion && props.pause && !props.input.text && status == 'entered';

    const handleClick = () => {

        if (clickZone) interaction.success();

    }

    useClickOutside(clickZone, [ref], '[data-ignore="outside"]', () => interaction.error());


    const animateInteract = ['entered', 'typing'].includes(status) ? (animateError || animateWiggle) : null;




    return (
        <>
            {props.cover.enabled &&

                <div className="absolute left-0 top-0 right-0 bottom-0 pointer-events-none">
                    <div className={classNames('absolute left-0 top-0 right-0 bottom-0 overflow-clip',
                        // props.motion && ['waiting', 'exited'].includes(status) && 'hidden',

                        props.motion && status == 'waiting' && props.transition != 'move' && 'hidden',
                        props.motion && status == 'exited' && props.transition != 'move' && 'hidden',

                        props.motion && status == 'entering' && props.transition != 'move' && 'animate-fade animate-duration-500',
                        props.motion && status == 'exiting' && props.transition != 'move' && 'animate-fade animate-reverse animate-duration-500',

                        props.motion && status == 'entering' && props.transition == 'move' && !prev && 'animate-fade animate-duration-500',

                    )}>

                        <div
                            className={classNames('absolute outline outline-[9999px] outline-black',
                                !(animation[status] && animation[status].includes('animate-fade')) && animation[status],
                                animateInteract,
                                clickZone && 'active:scale-105')}
                            style={style}>

                        </div>

                    </div>

                </div>

            }

            <Elem {...newProps}>
                <div
                    ref={ref}
                    className={classNames('absolute left-0 top-0 right-0 bottom-0 border-4',
                        props.motion && status == 'entering' && props.transition == 'outline' && '!border-none',
                        props.border,
                        animateInteract,
                        clickZone && 'cursor-pointer hover:brightness-95 active:scale-105'
                    )}
                    onClick={() => handleClick()}>

                    {props.input.text && <FocusInput input={props.input} pause={props.pause} motion={props.motion} showme={showInput} interaction={interaction} />}

                    {props.motion && status == 'entering' && props.transition == 'outline' &&
                        <Outline border={props.border} />
                    }

                </div>
            </Elem >

        </>

    );

};


const Arrow = (props) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { elem } = state;

    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const [isDragging, setIsDragging] = useState(false);
    const [dragInfo, setDragInfo] = useState(null);


    useDrag(isDragging,
        (x, y) => {

            const scaleCanvas = ref2.current.parentElement.parentElement.getBoundingClientRect().width / ref2.current.parentElement.parentElement.offsetWidth;

            const { left, top } = ref2.current.parentElement.getBoundingClientRect();

            const angle = trig.angle({ x: left, y: top }, { x, y });

            const d = trig.distance({ x: left, y: top }, { x, y });
            const scale = Math.min(Math.max((d / props.data.diameter) * 1 / scaleCanvas, 0.5), 5);

            dispatch({ type: 'elem-update', param: { angle, scale } });

        },
        () => {
            setIsDragging(false);

        })

    const handleMouseDown = (e) => {
        e.stopPropagation();

        setIsDragging(true);
        setDragInfo({ angle: props.angle, scale: props.scale });

    }

    const handleKeyDown = (e) => {

        if (e.keyCode == 27 && isDragging) {
            setIsDragging(false);
            dispatch({ type: 'elem-update', param: { ...dragInfo } });

        }

    }


    const canvasScale = useCanvasScale(props.motion ? null : ref1);
    const styleHandle = getHandleStyle(canvasScale);


    const style1 = { 'transform': `scale(${props.scale}) rotate(${props.angle}deg)`, };
    const style2 = props.data ? { 'transform': `translate(${-(props.data.cx / props.data.width) * 100}%, ${-(props.data.cy / props.data.height) * 100}%)` } : null;

    const p = props.data ? trig.pointOnCircle(props.data.diameter * props.scale, props.angle) : null;
    // const style3 = p ? { left: `${p.x}px`, top: `${p.y}px` } : null;

    const style3 = p ? { ...styleHandle, left: `${p.x}px`, top: `${p.y}px` } : styleHandle;



    return (
        <>
            <div
                ref={ref1}
                className={classNames('absolute left-1/2 top-1/2 cursor-point pointer-events-none', props.motion && '!cursor-default')}>

                <div
                    className="origin-top-left"
                    style={style1}>

                    <div className={classNames('origin-top-left', props.animation)}>

                        <div
                            className={classNames('drop-shadow', !props.data && 'invisible')}
                            style={style2}>
                            {props.children}
                        </div>

                    </div>

                </div>


            </div>


            {!props.motion && elem?.name == props.name && props.data &&
                <>
                    <div
                        ref={ref2}
                        className={classNames('absolute bg-white border-2 border-blue-400 rounded-full cursor-point focus:outline-none hover:bg-black -translate-x-1/2 -translate-y-1/2', isDragging && '!bg-black')}
                        style={style3}
                        onMouseDown={(e) => handleMouseDown(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        tabIndex={-1}>
                    </div>

                    {isDragging && <div className="absolute z-50 -left-[9999px] -top-[9999px] -right-[9999px] -bottom-[9999px] cursor-point"></div>}

                </>
            }

        </>
    )
}

const ElemArrow = (props) => {

    // const dispatch = useContext(DispatchContext);

    const { crop } = props.parent;

    const options = [];

    const handles = [];
    const related = true;
    const rect = props.bounds.pristine ? { ...props.bounds, left: crop.width / 2, top: crop.height / 2 + 60 } : { ...props.bounds };


    // -----

    const t1 = props.transition ? ELEM_TRANSITION : 0;
    const t2 = props.transition ? ELEM_TRANSITION : 0;
    const t = props.duration - props.start - props.wait - t1 - t2;


    const statuses = motionElemStatuses(props.start + props.wait, t1, t, t2);

    const [status] = useElemMotion(props.motion, statuses);


    const animation = {
        'waiting': 'hidden',
        'entering': `${props.transition} animate-duration-500`,
        'entered': ``,
        'exiting': `${props.transition} animate-reverse animate-duration-500`,
        'exited': 'hidden',
    }

    if (!TRANSITIONS_ARROW.includes(props.transition)) {
        delete animation['entering'];
        delete animation['exiting'];

    }


    const specificAnimations = {
        'point': {
            'entering': 'animate-fade-left animate-duration-500',
            'exiting': 'animate-fade-left animate-reverse animate-duration-500',
        },
        'wag': {
            'entering': 'animate-wag animate-duration-500',
            'exiting': 'animate-fade-left animate-reverse animate-duration-500',
        },
        'shake': {
            'entering': 'animate-shake-x animate-duration-500',
            'exiting': 'animate-fade-left animate-reverse animate-duration-500',
        }
    }


    const animationForArrow = specificAnimations[props.transition] || {};


    const newProps = { ...props, handles, related, rect, options, animation: animation[status] };
    const newPropsForArrow = { ...props, handles, related, rect, options, animation: animationForArrow[status] };

    // const newProps = { ...props, handles, related, rect, options };


    const [data, setData] = useState({});

    const extract = (v) => { const clone = { ...data }; clone[props.style] = v; setData({ ...clone }); }

    const fill = tailwindColorToHex(props.bg);
    const Style = React.createElement(Arrows[props.style].comp, { key: props.style, fill, extract });

    return (
        <Elem {...newProps}>

            {/* <div className="absolute -left-2 -top-2 -right-2 -bottom-2 bg-green-300"></div> */}

            <Arrow {...newPropsForArrow} data={data[props.style]}>
                {Style}
            </Arrow>

        </Elem >
    );

};


const ElemText = (props) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, runtime } = state;

    // const { speak } = scene;
    const { speak } = props.scene;


    const [textUndo, setTextUndo] = useState(null);

    useEffect(() => { if (!props.motion) setTextUndo(null) }, [props.scene.id]);

    useEffect(() => { if (runtime.rewriting == props.name) setTextUndo(props.text); }, [runtime.rewriting]);


    const { crop } = props.parent;


    const askOpenAI = async (key) => {

        dispatch({ type: 'runtime-rewriting-set', param: props.name });

        const lang = await fnDetect(file.userId, get.textForDetect(file));
        const text = await ask(file.userId, props.text, lang?.name, key);

        dispatch({ type: 'runtime-rewriting-set', param: null });

        if (text) dispatch({ type: 'elem-update', param: { text } })

    }


    const options = [

        {
            name: 'Rewrite',
            children: [
                {
                    name: 'Undo',
                    action: () => dispatch({ type: 'elem-update', param: { text: textUndo } }),
                    disabled: !textUndo || textUndo == props.text,
                    delimiter: true,
                }
            ],
            disabled: !props.text,
            delimiter: true
        },

        {
            name: 'Copy from SPEAK',
            action: () => dispatch({ type: 'elem-update', param: { text: speak.text } }),
            disabled: !(speak.enabled && speak.text),
        },
        {
            name: 'Copy from SPEAK (all scenes)',
            action: () => dispatch({ type: 'applytoall', target: 'copy-from-speak', param: props.name }),
            disabled: !(speak.enabled && speak.text),
            delimiter: true
        },

    ];


    Object.keys(OPENAI_PROMPTS).forEach((key, index, arr) => options[0].children.push({ name: OPENAI_PROMPTS[key].caption, action: () => askOpenAI(key), delimiter: index == arr.length - 2 }))


    const handles = ['l', 'r'];
    const related = false;
    const rect = props.bounds.pristine ? { ...props.bounds, left: (crop.width * (1 - 0.6)) / 2, top: crop.height * 0.8, width: crop.width * 0.6 } : { ...props.bounds };



    // -----

    const t1 = props.transition ? ELEM_TRANSITION : 0;
    const t2 = props.transition ? ELEM_TRANSITION : 0;
    const t = props.duration - props.wait - t1 - t2;

    const statuses = motionElemStatuses(props.wait, t1, t, t2);

    const [status] = useElemMotion(props.motion, statuses);



    const animation = {
        'waiting': 'hidden',
        'entering': `${props.transition} animate-duration-500`,
        'exiting': `${animateReverse(props.transition)} animate-reverse animate-duration-500`,
        'exited': 'hidden',
    }


    const newProps = { ...props, handles, related, rect, options, animation: animation[status] };



    // const newProps = { ...props, handles, related, rect, options };




    return (
        <Elem {...newProps}>
            <ElemEditableText
                className={classNames('rounded-lg shadow-md', props.bg, getTextColor(props.bg), props.size, props.align, TEXT_SIZES[props.size].text, runtime.rewriting == props.name && '[&>*]:invisible')}
                text={props.text.translate(props.code)}
                setText={(v) => dispatch({ type: 'elem-update', param: { text: v } })}
                readOnly={props.motion} />

            {runtime.rewriting == props.name && <Rewriting className={TEXT_SIZES[props.size].text} color={getTextColor(props.bg)} size={props.size} />}
        </Elem>
    );

};



const Replay = ({ motion, size, color, click }) => {

    return (
        <div
            className={classNames(size, color, motion && 'cursor-pointer hover:scale-110 active:translate-y-px')}
            onClick={(e) => { e.stopPropagation(); click(); }}>
            &#8634;
        </div>
    )

};


const ElemButton = (props) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { elem, runtime } = state;

    const { crop } = props.parent;

    const options = [
        {
            name: 'Rewrite',
            action: () => dispatch({ type: 'elem-update', param: { caption: randomFromList(CONTINUE_VARIANTS, props.caption) } }),
            delimiter: true
        },
    ];

    const handles = [];
    const related = false;
    const rect = props.bounds.pristine ? { ...props.bounds, left: (crop.width * (1 - 0.6)) / 2, top: crop.height * 0.8 - 50 } : { ...props.bounds };



    // -----

    const t1 = props.transition ? ELEM_TRANSITION : 0;
    const t2 = props.transition ? ELEM_TRANSITION : 0;
    // const t = props.duration - props.start - t1 - t2;//teste

    const t = 100;//todo: atentie, analizeaza

    // props.wait 

    const wait = props.interact - t1 - t / 2;

    const statuses = motionElemStatuses(wait, t1, t, t2);


    // const statuses = motionElemStatuses(props.duration - t - t1 - t2, t1, t, t2);
    // const statuses = motionElemStatuses(props.interact - t - t1 - t2, t1, t, t2);

    const [status] = useElemMotion(props.motion, statuses);


    const animation = {
        'waiting': 'hidden',
        'entering': `${props.transition} animate-duration-500`,
        'exiting': `${props.transition} animate-reverse animate-duration-500`,
        'exited': 'hidden',
    }



    const newProps = { ...props, handles, related, rect, options, animation: animation[status] };



    const textColor = getTextColor(props.bg);
    const divideColor = `divide-${textColor.split('-')[1]}`;


    const handleContinue = () => {
        if (!props.motion) return;
        emitter.emit('interact.continue');
    }

    const handleReplayScene = () => {
        if (!props.motion) return;
        emitter.emit('interact.replay.scene');
    }


    return (
        <Elem {...newProps}>

            <div
                className={classNames('rounded shadow-md divide-x flex flex-row items-center space-x-2',
                    divideColor,
                    props.motion && 'cursor-pointer active:translate-y-px active:!shadow-none',
                    props.bg,
                    TEXT_SIZES[props.size].button,
                    !props.motion && elem?.name == props.name && !runtime.editing && 'outline outline-offset-2 outline-blue-400')}
                data-ignore="outside"
                onClick={() => handleContinue()}>

                {props.replay &&
                    <Replay motion={props.motion} size={props.size} color={textColor} click={handleReplayScene} />
                }

                <div className="px-3 flex flex-row items-center space-x-2">

                    <ElemEditableText
                        className={classNames('text-left whitespace-nowrap break-keep bg-transparent', textColor, props.size)}
                        line={true}
                        autoWidth={true}
                        text={props.caption.translate(props.code)}
                        setText={(v) => dispatch({ type: 'elem-update', param: { caption: v } })}
                        readOnly={props.motion} />

                    <div className={classNames(textColor, props.size)}>&#10140;</div>

                </div>

            </div>

        </Elem>
    );

};



const ElemTouch = (props) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { elem } = state;

    const ref = useRef(null);


    const { crop } = props.parent;

    const options = [];
    const handles = [];
    const related = true;
    const rect = props.bounds.pristine ? { ...props.bounds, left: crop.width / 2, top: crop.height / 2 - 60 } : { ...props.bounds };



    // -----

    const t1 = 0;
    const t2 = 0;
    // const t = props.duration - props.start - t1 - t2;
    const t = TOUCH_DURATION;

    // props.wait 

    const statuses = motionElemStatuses(props.duration, t1, t, t2);
    const [status] = useElemMotion(props.motion, statuses);


    const animation = {
        'waiting': 'hidden',
        'exited': 'hidden',
    }


    const newProps = { ...props, handles, related, rect, options, animation: animation[status] };




    const [isDragging, setIsDragging] = useState(false);
    const [dragInfo, setDragInfo] = useState(null);


    useDrag(isDragging,
        (x) => {

            const scaleCanvas = ref.current.parentElement.parentElement.getBoundingClientRect().width / ref.current.parentElement.parentElement.offsetWidth;

            const { left, width } = ref.current.getBoundingClientRect();
            const cx = left + width / 2;

            const distance = Math.max(x - cx, 0);
            const scale = Math.min(Math.max((distance / (diameter / 2)) * 1 / scaleCanvas, 0.5), 5);

            dispatch({ type: 'elem-update', param: { scale } });

        },
        () => {

            setIsDragging(false);

        })

    const handleMouseDown = (e) => {
        e.stopPropagation();

        setIsDragging(true);
        setDragInfo({ scale: props.scale });

    }

    const handleKeyDown = (e) => {

        if (e.keyCode == 27 && isDragging) {
            setIsDragging(false);
            dispatch({ type: 'elem-update', param: { ...dragInfo } });

        }

    }




    const border = props.bg.replace(/^.{2}/g, 'border').split('/')[0]; //opacity seems to have issues with safelist


    const diameter = 3 * 16;
    const style1 = { 'width': `${diameter * props.scale}px`, 'height': `${diameter * props.scale}px` };
    const style2 = { 'borderWidth': `${4 * props.scale}px` };

    const canvasScale = useCanvasScale(props.motion ? null : ref);
    const style3 = getHandleStyle(canvasScale);


    let animate = {
        container: 'animate-touch animate-duration-700',
        outer: 'animate-touch-outer animate-delay-700 animate-duration-1000 animate-fill-forwards',
        inner: 'animate-touch-inner animate-delay-700 animate-duration-1000 animate-fill-forwards',
    };

    if (!(props.motion && status == 'entered')) animate = {};



    return (
        <Elem {...newProps}>

            <div
                ref={ref}
                className={classNames('absolute left-0 top-0 -translate-x-1/2 -translate-y-1/2', animate.container)}
                style={style1}>

                <div className={classNames('absolute left-0 top-0 right-0 bottom-0 border-4 rounded-full', border, animate.outer)} style={style2}></div>
                <div className={classNames('absolute left-[15%] top-[15%] right-[15%] bottom-[15%] rounded-full', props.bg, animate.inner)}></div>


                {!props.motion && elem?.name == props.name &&
                    <>
                        <div
                            className={classNames('absolute top-1/2 right-0 bg-white border-2 border-blue-400 rounded-full cursor-point focus:outline-none hover:bg-black translate-x-1/2 -translate-y-1/2', isDragging && '!bg-black')}
                            style={style3}
                            onMouseDown={(e) => handleMouseDown(e)}
                            onKeyDown={(e) => handleKeyDown(e)}
                            tabIndex={-1}>
                        </div>

                        {isDragging && <div className="absolute z-50 -left-[9999px] -top-[9999px] -right-[9999px] -bottom-[9999px] cursor-point"></div>}

                    </>
                }
            </div>

        </Elem>
    );

};






const Cursor = (props) => {

    const style1 = { 'transform': `scale(${props.scale})`, };
    const style2 = props.data ? { 'transform': `translate(${-(props.data.cx / props.data.width) * 100}%, ${-(props.data.cy / props.data.height) * 100}%)` } : null;

    const style3 = { 'transform': `translate(-50%,-50%) scale(${props.scale})`, };

    return (
        <>
            {props.hili && <div className="absolute left-0 top-0 w-7 h-7 bg-yellow-300/50 rounded-full pointer-events-none" style={style3}></div>}

            <div className={classNames('absolute left-1/2 top-1/2 cursor-point', props.motion && '!cursor-default')}>
                <div
                    className="origin-top-left"
                    style={style1}>

                    <div
                        className={classNames('drop-shadow', !props.data && 'invisible')}
                        style={style2}>
                        {props.children}
                    </div>

                </div>

            </div>

        </>
    );

};

const ElemCursor = (props) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, scene, elem, runtime } = state;

    const { crop } = props.parent;
    const { zoom } = props.motion ? props : props.parent;

    const ref = useRef(0);

    const [index, setIndex] = useState(null);

    const [isDragging, setIsDragging] = useState(false);
    const [dragInfo, setDragInfo] = useState(null);


    const options1 = props.motion ? [] :
        [
            {
                name: 'Align to previous cursor',
                action: () => dispatch({ type: 'elem-update', param: { point1: cursor.prev(file, scene) } }),
                disabled: !cursor.prev(file, scene),
            }
        ];

    const options2 = props.motion ? [] :
        [
            {
                name: 'Align to next cursor',
                action: () => dispatch({ type: 'elem-update', param: { point2: cursor.next(file, scene) } }),
                disabled: !cursor.next(file, scene),
            }
        ];




    const viewport = el.viewport(crop, zoom);

    const point1 = props.point1.pristine ? { x: crop.width / 2, y: crop.height / 2 } : { ...props.point1 };
    const point2 = props.point2.pristine ? { x: crop.width / 2 + 100, y: crop.height / 2 - 100 } : { ...props.point2 };

    const transform = (point) => {
        point.x = point.x - crop.left;
        point.y = point.y - crop.top;

        if (zoom.enabled) {
            point.x = point.x * zoom.value + zoom.x;
            point.y = point.y * zoom.value + zoom.y;
        }
    }

    const reverse = (point) => {

        if (zoom.enabled) {
            point.x = (point.x - zoom.x) / zoom.value;
            point.y = (point.y - zoom.y) / zoom.value;
        };

        point.x = point.x + crop.left;
        point.y = point.y + crop.top;

        Object.keys(point).forEach(key => point[key] = Math.round(point[key]));

    }

    const check = (point) => {
        point.x = Math.round(Math.min(Math.max(point.x, viewport.left), viewport.left + viewport.width));
        point.y = Math.round(Math.min(Math.max(point.y, viewport.top), viewport.top + viewport.height));
    }


    if (!props.point1.pristine) {
        check(point1);
        transform(point1);
    }

    if (!props.point2.pristine) {
        check(point2);
        transform(point2);
    }



    useDrag(isDragging,
        (x, y) => {

            const canvasScale = ref.current.parentElement.getBoundingClientRect().width / ref.current.parentElement.offsetWidth;

            const deltaX = (x - dragInfo.x) * 1 / canvasScale;
            const deltaY = (y - dragInfo.y) * 1 / canvasScale;

            const { point } = dragInfo;

            const p = { x: point.x + deltaX, y: point.y + deltaY };
            reverse(p);
            check(p);

            const param = {};
            param[`point${dragInfo.index}`] = { x: p.x, y: p.y };

            dispatch({ type: 'elem-update', param });

        },
        () => {
            setIsDragging(false);
        });



    const handleMouseDown = (e, i) => {

        e.stopPropagation();

        setIndex(i);

        dispatch({ type: 'elem-set', elem: props });


        const x = e.clientX;
        const y = e.clientY;

        const point = i == 1 ? point1 : point2;

        setIsDragging(true);
        setDragInfo({ x, y, point, index: i });

    }

    const handleKeyDown = (e, i) => {

        if (e.keyCode == 27 && isDragging) {

            setIsDragging(false);

            const param = {};
            param[`point${dragInfo.index}`] = dragInfo.point;

            dispatch({ type: 'elem-update', param });

        }

        if (e.keyCode == 46 && !isDragging && !runtime.editing) {

            dispatch({ type: 'elem-update', param: { visible: false } });
            dispatch({ type: 'elem-set', elem: scene.elems.find(elem => elem.name == 'bgimage') });

        }

    }





    const [data, setData] = useState({});
    const extract = (v) => { const clone = { ...data }; clone[props.style] = v; setData({ ...clone }); }


    const blue = tailwindColorToHex('bg-blue-500');
    const black = tailwindColorToHex('bg-black');
    const red = tailwindColorToHex('bg-red-500');
    const fill = 'white';

    const Style1 = (i) => React.createElement(Cursors[props.style].comp, { key: props.style, stroke: !props.motion && elem?.name == props.name && index == i ? blue : black, fill, extract });


    const style1 = { left: `${point1.x}px`, top: `${point1.y}px` };
    const style2 = { left: `${point2.x}px`, top: `${point2.y}px` };


    // const style3 = { left: `${point2.x}px`, top: `${point2.y}px` };


    const draw = () => {
        if (props.motion) return;

        if (!ref.current) return;
        drawCursorPath(ref.current, point1, point2, props.path);
    }

    draw();
    useEffect(() => draw(), [ref]);



    const scale = zoom.enabled ? props.scale * zoom.value : props.scale;



    // -----

    const d = Math.max(Math.floor((CURSOR_DURATION_1920 * trig.distance(props.point1, props.point2)) / 1920), CURSOR_DURATION_MIN);


    const t1 = 0;
    const t2 = props.effect == 'click' ? CURSOR_CLICK : 0;
    // const t = props.duration - props.start - t1 - t2;
    const t = d - t1 - t2;

    // props.wait

    // const statuses = motionElemStatuses(props.duration - t - t2, t1, t, t2);
    const statuses = motionElemStatuses(props.duration, t1, t, t2);


    statuses['entered'].progress = true;


    const [status, progress] = useElemMotion(props.motion, statuses);



    const animation = {
        'exiting': 'animate-click animate-duration-200'
    }


    const point = {
        'waiting': point1,
        'entered': status == 'entered' ? computeCursor(point1, point2, crop.width, crop.height, props.path, progress / 100) : point1,
        'exiting': point2,
        'exited': point2,
    }

    const p = point[status];

    const Style2 = React.createElement(Cursors[props.style].comp, { key: props.style, stroke: status == 'exiting' ? red : black, fill, extract });


    const style3 = p ? { left: `${p.x}px`, top: `${p.y}px` } : null;




    return (
        <>

            {props.motion &&

                <div
                    className={classNames('absolute', animation[status])}
                    style={style3}>

                    {/* <div className="absolute -left-2 -top-2 -right-2 -bottom-2 bg-green-300"></div> */}

                    <Cursor motion={props.motion} scale={scale} hili={props.hili} data={data[props.style]}>
                        {Style2}
                    </Cursor>
                </div>

            }

            {!props.motion &&

                <>
                    <canvas
                        ref={ref}
                        className="absolute left-0 top-0 pointer-events-none"
                        width={crop.width}
                        height={crop.height}
                    />

                    {isDragging && <div className="absolute left-0 top-0 right-0 bottom-0 cursor-point" ></div>}

                    <div
                        className="absolute focus:outline-none"
                        style={style1}
                        tabIndex={-1}
                        onMouseDown={(e) => handleMouseDown(e, 1)}
                        onKeyDown={(e) => handleKeyDown(e, 1)}
                        onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); props.contextmenu({ x: e.clientX, y: e.clientY, sender: null, options: options1 }) }}>

                        {/* <div className="absolute -left-2 -top-2 -right-2 -bottom-2 bg-green-300"></div> */}

                        <Cursor motion={props.motion} scale={scale} hili={props.hili} data={data[props.style]}>
                            {Style1(1)}
                        </Cursor>
                    </div>

                    <div
                        className="absolute focus:outline-none"
                        style={style2}
                        tabIndex={-1}
                        onMouseDown={(e) => handleMouseDown(e, 2)}
                        onKeyDown={(e) => handleKeyDown(e, 2)}
                        onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); props.contextmenu({ x: e.clientX, y: e.clientY, sender: null, options: options2 }) }}>

                        {/* <div className="absolute -z-1 -left-2 -top-2 -right-2 -bottom-2 bg-green-300"></div> */}

                        <Cursor motion={props.motion} scale={scale} hili={props.hili} data={data[props.style]}>
                            {Style1(2)}
                        </Cursor>
                    </div>

                </>
            }




        </>
    );

};


export const ElemEditableStaticText = ({ className, motion, line = false, autoWidth = false, props }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { runtime } = state;


    const field = props.type == 'text' ? 'text' : 'caption';
    const text = props[field].translate(props.code);

    const setText = (v) => {
        if (props.type == 'text') dispatch({ type: 'elem-update', param: { text: v } });
        else dispatch({ type: 'elem-update', param: { caption: v } })

    }


    return (
        <>
            <ElemEditableText
                className={classNames('bg-transparent', className, line && 'whitespace-nowrap break-keep', props.size, props.align, runtime.rewriting == props.name && '[&>*]:invisible')}
                line={line}
                autoWidth={autoWidth}
                oneClickEdit={true}
                text={text}
                placeholder={props.placeholder}
                setText={(v) => setText(v)}
                readOnly={motion} />

            {runtime.rewriting == props.name && <Rewriting className={props.size} size={props.size} />}
        </>
    )

}

export const ElemStatic = ({ className, props, children }) => {

    // const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    // const {  elem, runtime } = state;


    const t1 = props.transition ? ELEM_TRANSITION : 0;
    const t2 = props.transition ? ELEM_TRANSITION : 0;


    // const t = props.duration - wait - t1 - t2;

    const t = 'wait' in props ? props.duration - props.wait - t1 - t2 : 100;
    const wait = 'wait' in props ? props.wait : props.interact - t1 - t / 2;


    const statuses = motionElemStatuses(wait, t1, t, t2);
    // const statuses = motionElemStatuses(props.duration - t - t1 - t2, t1, t, t2);


    const [status] = useElemMotion(props.motion, statuses);

    const animation = {
        'waiting': props.visible ? 'invisible' : 'hidden',
        'entering': `${props.transition} animate-duration-500`,
        'exiting': `${animateReverse(props.transition)} animate-reverse animate-duration-500`,
        'exited': props.visible ? 'invisible' : 'hidden',
    }

    const handleMouseDown = (e) => {
        if (props.motion) return;

        e.stopPropagation();

        dispatch({ type: 'elem-set', elem: props });


    }

    if (!props.visible) return;

    return (
        <div
            className={classNames('relative', className, animation[status])}
            onMouseDown={(e) => handleMouseDown(e)}>
            {children}
        </div>
    )

}






const Elems = {
    'bg-image': { comp: ElemBgImage, prop: PropBgImage },
    focus: { comp: ElemFocus, prop: PropFocus },
    arrow: { comp: ElemArrow, prop: PropArrow },
    text: { comp: ElemText, prop: PropText },
    button: { comp: ElemButton, prop: PropButton },
    touch: { comp: ElemTouch, prop: PropTouch },
    cursor: { comp: ElemCursor, prop: PropCursor },
}

export default Elems;

