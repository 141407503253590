import { useState } from "react";
import { useEffect } from 'react';
import { useRef } from 'react';


import { useClickOutside } from "../hooks";

import classNames from 'classnames';


const useSubmenu = (i) => {
  const [index, setIndex] = useState(null);

  useEffect(() => {

    const timeout = setTimeout(() => setIndex(i), 200);
    return () => clearTimeout(timeout);

  }, [i]);

  return index;
}


const ContextMenu = ({ x, y, sender, options, closeContextMenu }) => {

  const ref = useRef(null);

  const [index, setIndex] = useState(null);
  const submenu = useSubmenu(index);


  useClickOutside(true, [ref], null, () => closeContextMenu(false));

  const handleClick = (option) => {

    if (option.hidden) return;
    if (option.disabled) return;
    if (option.children) return;

    if (option.action) option.action(sender, option);

    closeContextMenu();
  }

  const handleMouseEnter = (parent, item, index) => {
    if (parent) return;

    if (item.children) setIndex(index); else setIndex(null);

  }

  const style = { left: `${x}px`, top: `${y}px`, };


  //todo: key must be analyzed

  const listItems = (parent, items) => items.map((item, index) =>
    <li
      className={classNames('context-menu-item', item.disabled && 'disabled', item.hidden && 'hidden', item.delimiter && 'delimiter')}
      key={item.tag ? item.tag : index}
      onClick={(e) => { e.stopPropagation(); handleClick(item); }}
      onMouseEnter={() => handleMouseEnter(parent, item, index)}
      onContextMenu={(e) => e.preventDefault()}>

      <span className="flex-1 text-nowrap">{item.name}</span>

      {item.children &&
        <>
          <svg
            className={classNames('ml-2 w-3 h-3', item.disabled && 'opacity-50')}
            width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1997 10.4919L13.2297 8.52188L10.0197 5.31188C9.33969 4.64188 8.17969 5.12188 8.17969 6.08188V12.3119V17.9219C8.17969 18.8819 9.33969 19.3619 10.0197 18.6819L15.1997 13.5019C16.0297 12.6819 16.0297 11.3219 15.1997 10.4919Z" fill="white" />
          </svg>

          <ul className={classNames('context-menu absolute z-10 right-0 top-0 translate-x-full hidden', submenu == index && 'animate-fade animate-duration-75 !block')}>
            {listItems(item, item.children)}
          </ul>
        </>
      }


    </li>
  );

  return (
    <ul
      ref={ref}
      className="context-menu fixed z-[9999]"
      style={style}>
      {listItems(null, options)}
    </ul>
  );

};

export default ContextMenu;
