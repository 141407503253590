import { useState } from "react";

import classNames from 'classnames';


export const Toggle = ({ name, disabled, isDown, setIsDown }) => {


    return (
        <div className={classNames('flex flex-row items-center space-x-2', disabled && 'opacity-30 pointer-events-none')}>
            <div
                className={classNames('relative w-9 h-4 bg-gray-400 shadow rounded-full cursor-pointer', isDown && 'bg-green-500')}
                onClick={() => setIsDown(!isDown)}>
                <span className={classNames('absolute top-0 bottom-0 w-4 bg-white border-2 rounded-full transition-transform duration-75', !isDown && 'left-0 border-gray-400', isDown && 'translate-x-5 border-green-500 ')}></span>
            </div>
            <div className={classNames('opacity-50 text-sm select-none', isDown && '!opacity-100')}>{name}</div>
        </div>
    )


}


export const ToggleSection = ({ name, disabled, isDown, setIsDown, children }) => {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!isDown);
        setIsDown(!isDown);
    }

    return (
        <div className={classNames('flex flex-col', disabled && 'opacity-25 pointer-events-none', children && isDown && 'p-2 mb-px')}>
            {!isDown &&
                <div
                    className={classNames('group cursor-pointer flex flex-row items-center space-x-2', children && 'py-2')}
                    onMouseDown={handleClick}>
                    <svg
                        className="scale-75"
                        width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line y1="6" x2="12" y2="6" stroke="black" strokeWidth="2" />
                        <line x1="6" y1="12" x2="6" stroke="black" strokeWidth="2" />
                    </svg>


                    <span className="text-xs uppercase select-none opacity-90 group-hover:!opacity-100">Add {name}</span>
                </div>
            }
            {isDown &&
                <>
                    <div
                        className="pr-2 cursor-pointer flex flex-row items-center"
                        onMouseDown={handleClick}>

                        <div className={classNames('flex-1', clicked && 'animate-fade-left animate-duration-200')}>
                            <span className="px-2 py-1 bg-black text-xs text-white uppercase rounded select-none">{name}</span>
                        </div>

                        <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line y1="1" x2="12" y2="1" stroke="black" strokeWidth="2" />
                        </svg>
                    </div>

                    {children &&
                        <div className="pl-1 py-4 flex flex-col">
                            {children}
                        </div>
                    }


                </>
            }
        </div >
    );

};


