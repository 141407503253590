import { useSyncExternalStore } from 'react';




const useOnlineStatus = () => {
    const isOnline = useSyncExternalStore(subscribe, getSnapshot);
    return isOnline;
}

const getSnapshot = () => {
    return navigator.onLine;
}

const subscribe = (callback) => {
    window.addEventListener('online', callback);
    window.addEventListener('offline', callback);
    return () => {
        window.removeEventListener('online', callback);
        window.removeEventListener('offline', callback);
    };
}

const OnlineStatus = () => {

    const isOnline = useOnlineStatus();

    if (isOnline) return;

    return (
        <div className="animate-mask-more animate-fill-forwards fixed left-0 top-0 right-0 bottom-0 bg-black z-[99]">

            <div className="fixed left-0 top-0 right-0 bottom-[10vh] z-[100] flex flex-col items-center justify-center">

                <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40.1194 31.0012C40.7293 31.6118 40.7287 32.6011 40.1181 33.2109L32.7751 40.5442L40.1181 47.8804C40.688 48.4496 40.7265 49.3493 40.2333 49.9632L40.1194 50.0901C39.5503 50.6599 38.6505 50.6984 38.0367 50.2052L37.9097 50.0914L30.5626 42.7529L23.2456 50.0635C22.635 50.6733 21.6457 50.6727 21.0359 50.0622C20.426 49.4516 20.4266 48.4623 21.0372 47.8525L28.3514 40.5454L21.0372 33.2389C20.4673 32.6697 20.4288 31.7699 20.922 31.1561L21.0359 31.0292C21.605 30.4593 22.5048 30.4208 23.1186 30.914L23.2456 31.0278L30.5626 38.3367L37.9097 30.9999C38.5203 30.3901 39.5096 30.3907 40.1194 31.0012ZM30.6305 10.6235C38.5518 10.6235 43.0454 15.8668 43.6989 22.1989L43.8989 22.1988C48.9966 22.1988 53.1291 26.3207 53.1291 31.4052C53.1291 36.4897 48.9966 40.6115 43.8989 40.6115L39.6487 40.6112C38.7875 40.6111 38.0893 39.9129 38.0893 39.0517C38.0893 38.2443 38.7029 37.5802 39.4892 37.5004L39.6487 37.4923L44.1343 37.4925C47.3769 37.4925 50.0055 34.8502 50.0055 31.5908C50.0055 28.3314 47.3769 25.6891 44.1343 25.6891L42.3585 25.6892C41.4194 25.6892 40.5992 24.949 40.5992 24.0057C40.5992 17.9761 35.9568 13.7425 30.6305 13.7425C25.3041 13.7425 20.6617 18.0525 20.6617 24.0057C20.6617 24.949 19.8415 25.6892 18.9024 25.6892L17.1266 25.6891C13.884 25.6891 11.2554 28.3314 11.2554 31.5908C11.2554 34.8502 13.884 37.4925 17.1266 37.4925L21.53 37.4923C22.3373 37.4923 23.0014 38.1059 23.0813 38.8922L23.0893 39.0517C23.0893 39.9129 22.3912 40.6111 21.53 40.6112L17.362 40.6115C12.2643 40.6115 8.13184 36.4897 8.13184 31.4052C8.13184 26.3207 12.2643 22.1988 17.3621 22.1988L17.562 22.1989C18.2193 15.8251 22.7091 10.6235 30.6305 10.6235Z" fill="white" />
                </svg>

                <h1 className="mt-2 text-2xl text-white">You appear to be offline</h1>
                <p className="mt-2 text-base text-gray-500">Check your internet connection</p>

            </div>

        </div>
    )

}

export default OnlineStatus;