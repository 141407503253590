
import colors from 'tailwindcss/colors';



export const getBase64 = (img, rect) => {
  if (!img) return;

  rect = rect || { left: 0, top: 0, width: img.naturalWidth, height: img.naturalHeight };

  const canvas = document.createElement('canvas');
  canvas.width = rect.width;
  canvas.height = rect.height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, rect.left, rect.top, rect.width, rect.height, 0, 0, canvas.width, canvas.height);

  return canvas.toDataURL();
};


export const objectsEqual = (object1, object2) => object1 && object2 ? Object.keys(object1).every((key) => object1[key] === object2[key]) : false;



export const trig = {
  distance: (point1, point2) => Math.sqrt((point2.x - point1.x) ** 2 + (point2.y - point1.y) ** 2),
  angle: (point1, point2) => {
    const deltaX = point2.x - point1.x;
    const deltaY = point2.y - point1.y;

    if (deltaY === 0) { return deltaX >= 0 ? 0 : 180; }

    const angleRad = Math.atan2(deltaY, deltaX);
    const angleDeg = (angleRad * 180) / Math.PI;

    return (angleDeg + 360) % 360;
  },
  pointOnCircle: (radius, angleDeg) => { const angleRad = angleDeg * Math.PI / 180; return { x: radius * Math.cos(angleRad), y: radius * Math.sin(angleRad) }; },
}



export const tailwindColorToHex = (c) => {

  const part = c.split('/')[0];

  const arr = part.split('-');
  const color = arr[1];
  const value = arr.length > 1 ? arr[2] : null;


  return value ? colors[color][value] : color;
}



export const getTextColor = (color) => {

  const hexcolor = tailwindColorToHex(color);
  if (['black', 'white'].includes(hexcolor)) return hexcolor == 'black' ? 'text-white' : 'text-black'

  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  return (yiq >= 150) ? 'text-black' : 'text-white';
}


export const randomFromList = (list, exclude) => list.filter(item => item != exclude).at(Math.floor(Math.random() * (list.length - 1)));



export const duplicateFilename = (filename, postfix = '(copy)') => filename.endsWith(postfix) ? filename : `${filename} ${postfix}`; 