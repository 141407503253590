import { useState } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';


import { check } from "./utils";
import { ZOOM_VALUES } from "../utils/consts";


import { startFrom } from "./utils";

import { ButtonWithModal } from "../ui/Buttons";

import emitter from "../emmiter";

import classNames from "classnames";



export const Pilot = ({ preferences, scenes, scene }) => {

    const index = scenes.findIndex(l => l.id == scene.id);

    const toggle = () => emitter.emit('toggle.pilot');

    const prev = () => {
        if (index == 0) return;
        emitter.emit('goto', scenes[index - 1]);

    }

    const next = () => {
        if (index == scenes.length - 1) return;
        emitter.emit('goto', scenes[index + 1]);
    }


    return (
        <div
            className="absolute z-40 top-0 left-0 right-0 opacity-90 select-none flex flex-col items-center"
            data-ignore="outside">


            <div
                className={classNames('relative px-1 pb-3 bg-slate-800 dark:bg-slate-700 rounded-bl-lg rounded-br-lg shadow-light flex flex-row items-center space-x-4', !preferences.pilot && 'cursor-pointer !opacity-90 hover:!opacity-100')}
                onClick={() => { if (!preferences.pilot) toggle(); }}>

                {!preferences.pilot &&
                    <div className="h-1 w-10">

                    </div>
                }

                {preferences.pilot &&
                    <>
                        <button
                            className={classNames('group p-2 flex flex-row items-center justify-center', index == 0 && 'opacity-20 pointer-events-none')}
                            onClick={() => prev()}>

                            <img
                                className="h-4 opacity-90 rotate-180 filter-white group-hover:!opacity-100 group-active:scale-125"
                                src={require("../assets/svg/scene-nav.svg").default} />

                        </button>

                        <span className="text-sm text-white select-none">{index + 1}/{scenes.length}</span>

                        <button
                            className={classNames('group p-2 flex flex-row items-center justify-center', index == scenes.length - 1 && 'opacity-20 pointer-events-none')}
                            onClick={() => next()}>

                            <img
                                className="h-4 opacity-90 filter-white group-hover:!opacity-100 group-active:scale-125"
                                src={require("../assets/svg/scene-nav.svg").default} />

                        </button>
                    </>
                }

                {preferences.pilot &&
                    <button
                        className="group absolute right-1/2 bottom-0 p-2 bg-white rounded-full shadow translate-x-1/2 translate-y-1/2 hover:!bg-blue-100"
                        onClick={() => toggle()}>
                        <img
                            className="h-2 rotate-[270deg] scale-150 opacity-80 filter-black transition-all group-hover:!opacity-100"
                            src={require("../assets/svg/small-arrow.svg").default} />
                    </button>
                }
            </div>

        </div>
    );

};

export const Toc = ({ preferences, scenes, scene, code }) => {

    const index = scenes.findIndex(l => l.id == scene.id);

    const toggle = () => emitter.emit('toggle.toc');
    const handleSelect = (i) => emitter.emit('goto', scenes[i]);


    const listScenes = scenes.map((scene, i) =>
        <li
            key={scene.id}
            className="group snap-start py-px font-light text-sm text-white cursor-pointer flex flex-row items-start space-x-0"
            onMouseDown={() => handleSelect(i)}>

            <img
                className={classNames('h-4 opacity-80 filter-white invisible', index == i && '!visible')}
                src={require("../assets/svg/toc-item.svg").default} />

            {/* {i}-{startFrom(file,i)} */}

            <span className={classNames('flex-1 first-letter:uppercase opacity-70 group-hover:!opacity-100', !scene.visible && 'line-through', index == i && 'font-semibold !opacity-100')}>
                {scene.name ? scene.name.translate(code) : `${'Scene'.translate(code)} ${i - startFrom(scenes, i)}`}
            </span>

        </li>
    );


    return (
        <>

            {!preferences.toc &&
                <button
                    className="absolute z-40 left-0 top-4 -rotate-90 origin-top-left translate-y-[6.1rem] px-4 py-1 bg-slate-800 dark:bg-slate-700 rounded-b-md opacity-80 shadow-light select-none hover:!opacity-100"
                    data-ignore="outside"
                    onClick={() => toggle()}>
                    <span className="text-sm text-white tracking-[0.1rem]">TOC</span>
                </button>
            }

            {preferences.toc &&
                <div
                    className="absolute z-40 left-0 top-12 pl-2 pr-5 py-3 bg-slate-800 dark:bg-slate-700 opacity-90 rounded-r-md select-none"
                    data-ignore="outside">

                    <ul className="min-w-28 max-w-[16rem] max-h-[50vh] mr-1 overflow-y-auto scroll-smooth snap-y">
                        {listScenes}
                    </ul>

                    <button
                        className="group absolute right-0 top-1/2 p-2 bg-white rounded-full shadow translate-x-1/2 -translate-y-1/2 hover:!bg-blue-100"
                        onClick={() => toggle()}>
                        <img
                            className="h-2 rotate-180 scale-150 opacity-80 filter-black transition-all group-hover:!opacity-100"
                            src={require("../assets/svg/small-arrow.svg").default} />
                    </button>

                </div>
            }

        </>
    );

};

export const Mode = ({ settings }) => {


    const toggle = () => emitter.emit('mode', settings.mode == 'dark' ? 'light' : 'dark');

    const light = require("../assets/svg/mode-light.svg").default;
    const dark = require("../assets/svg/mode-dark.svg").default;

    // https://tailwindcss.com/docs/dark-mode
    // console.log(window.matchMedia('(prefers-color-scheme: dark)').matches);

    return (
        <button
            className="group absolute right-2 top-2 p-1 z-40"
            data-ignore="outside"
            onClick={() => toggle()}>

            <img
                className={classNames('h-7 opacity-30 transition-all group-hover:!opacity-100', settings.mode == 'light' && 'filter-black', settings.mode == 'dark' && 'filter-white scale-75',)}
                src={settings.mode == 'light' ? light : dark} />

        </button>
    );

};




export const ZoomAndPan = () => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { scene, runtime } = state;
    const { crop, zoom } = scene.elems.find(elem => elem.name == 'bgimage');


    const togglePan = () => dispatch({ type: 'runtime-pan-toggle' })


    const toggleZoom = () => {
        if (runtime.pan && zoom.enabled) dispatch({ type: 'runtime-pan-toggle' });
        dispatch({ type: 'scene-bgimage-zoom', zoom: { ...zoom, enabled: !zoom.enabled } });
    }

    const zoomValueChange = (v) => {

        const index = ZOOM_VALUES.indexOf(zoom.value);

        let newIndex = index + v;
        newIndex = Math.max(newIndex, 0);
        newIndex = Math.min(newIndex, ZOOM_VALUES.length - 1);

        const newValue = ZOOM_VALUES[newIndex];
        if (zoom.value == newValue) return;

        const { newX, newY } = check.pan(zoom.x, zoom.y, crop, newValue);

        dispatch({ type: 'scene-bgimage-zoom', zoom: { ...zoom, value: newValue, x: newX, y: newY } });

        if (newValue == 1 && runtime.pan) dispatch({ type: 'runtime-pan-toggle' });

    }


    const handleKeyDown = (e) => {

        if (e.keyCode === 27 && runtime.pan) dispatch({ type: 'runtime-pan-toggle' });

    };

    return (
        <div
            className="absolute z-40 right-2 bottom-2 select-none flex flex-row items-center justify-end">

            {zoom.enabled && zoom.value != 1 &&
                <button
                    className={classNames('group mr-2 w-8 h-8 bg-slate-800 dark:bg-slate-700 rounded-md shadow focus:outline-none hover:bg-slate-600 flex flex-row items-center justify-center', runtime.pan && 'animate-wiggle animate-infinite !bg-red-500')}
                    autoFocus={true}
                    onMouseDown={() => togglePan()}
                    onKeyDown={(e) => handleKeyDown(e)}>
                    <svg
                        className={classNames(runtime.pan && 'scale-110')}
                        width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.8 9.6V12.8H10.4L8 16L5.6 12.8H7.2V9.6H8.8ZM12.8 5.6L16 8L12.8 10.4V8.8H9.6V7.2H12.8V5.6ZM3.2 5.6V7.2H6.4V8.8H3.2V10.4L0 8L3.2 5.6ZM8 0L10.4 3.2H8.8V6.4H7.2V3.2H5.6L8 0Z" fill="white" />
                    </svg>
                </button>
            }


            <div className="bg-slate-800 dark:bg-slate-700 rounded-md shadow flex flex-row items-center">
                <button
                    className={classNames('group rounded-l-md focus:outline-none hover:bg-slate-600', !zoom.enabled && 'invisible', zoom.value == ZOOM_VALUES.at(0) && 'opacity-20 pointer-events-none')}
                    onMouseDown={() => zoomValueChange(-1)}>
                    <svg
                        className="group-active:translate-y-px"
                        xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="white" fillOpacity="1" fillRule="evenodd" stroke="none" d="M21 16H10v-1h11v1z"></path>
                    </svg>
                </button>

                <button
                    className="relative group w-12 focus:outline-none flex flex-row items-center justify-center"
                    onMouseDown={toggleZoom}>
                    <span
                        className={classNames('text-xs text-gray-400 decoration-2 decoration-red-500 group-hover:text-gray-300', !zoom.enabled && 'line-through !text-gray-600')} >
                        {Math.round(zoom.value * 100)}%
                    </span>
                </button>

                <button
                    className={classNames('group rounded-r-md focus:outline-none hover:bg-slate-600', !zoom.enabled && 'invisible', zoom.value == ZOOM_VALUES.at(-1) && 'opacity-20 pointer-events-none')}
                    onMouseDown={() => zoomValueChange(+1)}>
                    <svg
                        className="group-active:translate-y-px"
                        xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="white" fillOpacity="1" fillRule="evenodd" stroke="none" d="M15 15v-5h1v5h5v1h-5v5h-1v-5h-5v-1h5z"></path>
                    </svg>
                </button>
            </div>


        </div>
    );

};




export const Lang = ({ lang, languages, code }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);


    //todo: nu a depistat lang ptr ca nu are texte, atunci nu arata nimic
    //atenite la source language in deepl

    const select = (v) => {
        if (v != code) emitter.emit('translate', v);
        setIsModalVisible(false);
    }


    const listLanguages = languages.map(l =>
        <li
            className={classNames('px-2 py-1.5 rounded cursor-pointer select-none hover:bg-gray-100 flex flex-row items-center space-x-1', (l.code == code || l.code == lang?.code && code == 'original') && 'bg-blue-200')}
            key={l.code}
            onClick={() => select(l.code == lang?.code ? 'original' : l.code)}>

            <span className={classNames('text-xs', l.code == lang?.code && 'font-bold')}>{l.name}</span>

        </li>
    )




    const caption = code && code != 'original' && languages.length ? languages.find(item => item.code == code).name : lang ? lang.name : 'Translate';

    return (

        <ButtonWithModal className="absolute right-12 top-3.5 z-[9999]" place="br" isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>

            <button
                className={classNames('px-4 py-1 bg-slate-800 opacity-70 rounded-full transition-opacity select-none hover:!opacity-100 hover:!bg-blue-500 flex flex-row items-center justify-center', isModalVisible && '!bg-blue-500 !opacity-100')}
                data-ignore="outside">
                <span className="text-white text-xs">
                    {caption}
                </span>
            </button>


            <ul className="p-2 bg-white rounded-md shadow-light grid grid-cols-3 gap-0 translate-y-1">
                {listLanguages}
            </ul>

        </ButtonWithModal>

    )

}
