
import { fnTextToSpeech } from "../firebase/functions";
import { dbTextToSpeechConvertTask } from "../firebase/database";


import { SPEAK_MIN_CHARS } from "../utils/consts";


//global
var tts = {
    // 'voice_id': { "salut": {audio: new Audio(), promise: new Promise((resolve, reject) =>  resolve()), status: 'ready'} },
};

//global
var la = undefined;


const get = (self, voice_id) => {
    if (!voice_id || self.length < SPEAK_MIN_CHARS) return undefined;
    return tts[voice_id]?.[self];
};

String.prototype.audio = function (voice_id) {

    const self = String(this);
    const item = get(self, voice_id);

    if (!item) return;

    return item.audio;

};

String.prototype.canPlay = function (voice_id) {

    const self = String(this);
    const item = get(self, voice_id);

    if (!item) return;

    return item.status == 'ready' && item.audio.currentTime < item.audio.duration;

};

String.prototype.isPlaying = function (voice_id) {

    const self = String(this);
    const item = get(self, voice_id);

    if (!item) return;
    if (item.status != 'ready') return;

    return item.audio.currentTime > 0
        && !item.audio.paused
        && !item.audio.ended
        && item.audio.readyState > 2;

};

String.prototype.duration = function (voice_id) {

    const self = String(this);
    const item = get(self, voice_id);

    if (!item) return 0;

    return item.status == 'ready' ? item.audio.duration : 0;

};


String.prototype.play = function (uid, voice_id, callback, restart = true) {

    const self = String(this);
    const item = get(self, voice_id);


    const play = (v) => {
        if (la && la != v.audio) stop();

        if (restart) v.audio.currentTime = 0;

        if (callback) callback();
        v.audio.play();
        la = v.audio;
    }


    if (!item) return new Promise(async (resolve, reject) => {

        await self.convert(uid, voice_id);
        const newItem = get(self, voice_id);
        play(newItem);

        resolve()

    });


    if (item.status == 'ready') return play(item);

    return new Promise(async (resolve, reject) => {

        try {
            await item.promise;
            play(item);

            resolve();

        } catch (err) {
            console.log(err);

            reject();
        }

    });


};


String.prototype.pause = function (voice_id) {

    const self = String(this);
    const item = get(self, voice_id);

    if (!item) return;
    if (item.status != 'ready') return;

    item.audio.pause();

};

String.prototype.stop = function (voice_id) {

    const self = String(this);
    const item = get(self, voice_id);

    if (!item) return;
    if (item.status != 'ready') return;

    item.audio.pause();
    item.audio.currentTime = 0

};




String.prototype.convert = function (uid, voice_id) {

    const self = String(this);
    if (!voice_id || self.length < SPEAK_MIN_CHARS) return undefined;

    if (tts[voice_id]?.[self]) return tts[voice_id]?.[self].promise;



    if (!(voice_id in tts)) tts[voice_id] = {};

    const data = {
        audio: new Audio(),
        promise: new Promise(async (resolve, reject) => {

            const res = await fnTextToSpeech(uid, voice_id, self);

            if (!res) {
                delete tts[voice_id][self];

                reject();
                return;
            };

            const item = tts[voice_id][self];

            item.status = 'loading';


            if (res.hash) {

                let unsubscribe = () => { };

                dbTextToSpeechConvertTask(res.hash,
                    (v) => unsubscribe = v,
                    (url) => {

                        item.audio.oncanplaythrough = () => { item.status = 'ready'; resolve(item.audio); };
                        item.audio.src = url;

                        unsubscribe();

                    })


            };

            if (res.url) {

                item.audio.oncanplaythrough = () => { item.status = 'ready'; resolve(item.audio); };
                item.audio.src = res.url;

            };


        }),
        status: 'converting'
    };

    tts[voice_id][self] = data;

    return data.promise;

};



export const pause = () => { if (la) { la.pause(); } };
export const stop = () => { if (la) { la.pause(); la.currentTime = 0; } };