import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useContext } from 'react';

import { Timestamp } from "firebase/firestore";

import { StateContext, DispatchContext } from "../context/";


import { useFiles, useFolders, useCheckInView } from "./hooks.js";


import { storageDeleteFolder } from "../firebase/storage.js";
import { dbDeleteFile, dbRenameFile, dbMoveFile, dbDeleteFileJobs, dbAddFolder, dbRenameFolder, dbDeleteFolder } from '../firebase/database.js';
import { fnCopyFile, fnCopyFolder } from "../firebase/functions.js";

import FilterOptions from './FilterOptions.js';

import SearchBox from "./SearchBox.js";

import Highlighted from "./Highlighted.js";

import CreateNewMovie from "./CreateNewMovie.js";

import { InputDialog, ConfirmDialog } from '../dialogs/';


import { add as cacheImgAdd } from "../cache/cache-img";

import { APP_NAME } from "../utils/consts.js";

import { duplicateFilename } from "../utils/index.js";

import ContextMenu from '../ui/ContextMenu.js';

import classNames from 'classnames';




const sortFiles = (files, filterField, filterDirection) => {

  const direction = filterDirection == 'asc' ? 1 : -1;

  const filter = {
    'name': (a, b) => direction * a.name.localeCompare(b.name),
    // 'created': (a, b) => a.created instanceof Timestamp && b.created instanceof Timestamp ? direction * (a.created.seconds - b.created.seconds) : 0,
    // 'modified': (a, b) => a.modified instanceof Timestamp && b.modified instanceof Timestamp ? direction * (a.modified.seconds - b.modified.seconds) : 0,
    'created': (a, b) => direction * (a.created.seconds - b.created.seconds),
    'modified': (a, b) => direction * (a.modified.seconds - b.modified.seconds),
  }

  if (filter[filterField]) return [...files].sort(filter[filterField]);

  return files;
}

//todo: files changes at every save, so you need to re-think a bit
export const useSortFiles = (files, filterField, filterDirection) => useMemo(() => sortFiles(files, filterField, filterDirection), [files, filterField, filterDirection])


const sortFolders = (folders) => [...folders].sort((a, b) => a.name.localeCompare(b.name));
export const useSortFolders = (folders) => useMemo(() => sortFolders(folders), [folders])







const Explorer = () => {

  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const { user, file, files, folder, folders, global } = state;

  const { filterField, filterDirection } = global;

  // const [filterField, setFilterField] = useState('created');
  // const [filterDirection, setFilterDirection] = useState('desc');


  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchText, setSearchText] = useState('');


  const [clipboard, setClipboard] = useState({ files: [], cut: false });

  const [selected, setSelected] = useState([]);


  const [isUpdating, setIsUpdating] = useState(false);

  const [showCreateNewMovie, setShowCreateNewMovie] = useState(false);


  const [inputDialogParams, setInputDialogParams] = useState(null);
  const [confirmDialogParams, setConfirmDialogParams] = useState(null);

  const [contextMenuParams, setContextMenuParams] = useState(null);


  const loading1 = useFiles(user.uid, dispatch);
  const loading2 = useFolders(user.uid, dispatch);
  const loading = loading1 || loading2;


  //todo: analizeaza asta, lista e sortata si filtrata
  // const refs = files.reduce((a, c) => { a[c.id] = React.createRef(); return a; }, {});
  // useCheckInView(refs, file);




  useEffect(() => {

    if (selected.length == 1) {

      const id = selected[0];

      const found = files.find(file => file.id == id);
      selectFile(found);
      document.title = found ? found.name : APP_NAME;

    }
    else {
      selectFile(null);
      document.title = APP_NAME;

    }

  }, [selected])




  const selectedFilesCount = selected.reduce((a, c) => a + (files.find(file => file.id == c) ? 1 : 0), 0);//todo: de optimizat


  const href = window.location.origin;
  const hrefWorkspace = `${href}/workspace/${user.uid}`;
  const hrefFolder = (sender) => `${href}/folder/${sender}`;

  const copyUrl = (url) => navigator.clipboard.writeText(url);
  const openUrl = (url) => window.open(url, '_blank');

  const createNewMovie = () => { setSelected([]); setShowCreateNewMovie(true); }


  const options = [
    {
      name: 'New',
      delimiter: true,
      children: [
        {
          name: 'Movie',
          action: () => createNewMovie(),
        },
        {
          name: 'Folder',
          action: () => addNewFolder(),
          hidden: folder
        },
      ]

    },
    {
      name: 'Paste',
      action: () => paste(),
      hidden: showSearchBox,
      disabled: !clipboard.files.some(id => files.find(file => file.id == id).folderId != folder?.id),
    },
    {
      name: 'Select all',
      action: () => selectAll(),
      delimiter: true,
    },
    {
      name: 'Share workspace',
      children: [
        {
          name: 'Open in a new tab',
          action: () => openUrl(hrefWorkspace),
        },
        {
          name: 'Copy link to clipboard',
          action: () => copyUrl(hrefWorkspace),
        }
      ]
    }
  ]



  const optionsFile = [
    {
      name: 'Reveal in folder',
      action: (sender) => revealFile(sender[0]),
      hidden: !showSearchBox || selectedFilesCount > 1,
      delimiter: true,
    },
    {
      name: 'Rename',
      action: (sender) => renameFile(sender[0]),
      hidden: selectedFilesCount > 1,
      delimiter: true
    },

    {
      name: 'Cut',
      action: (sender) => setClipboard({ files: [...sender], cut: true })
    },
    {
      name: 'Copy',
      action: (sender) => setClipboard({ files: [...sender], cut: false })
    },
    {
      name: 'Delete',
      action: (sender) => deleteFiles(sender),
      delimiter: true
    },
    {
      name: 'Duplicate',
      action: (sender) => duplicateFiles(sender),
      // hidden: selectedFilesCount > 1,
      delimiter: true
    },

  ];


  const optionsFolder = [
    {
      name: 'Rename',
      action: (sender) => renameFolder(sender),
      delimiter: true
    },
    {
      name: 'Delete',
      action: (sender) => deleteFolder(sender),
    },
    {
      name: 'Duplicate',
      action: (sender) => duplicateFolder(sender),
      delimiter: true
    },
    {
      name: 'Share folder',
      children: [
        {
          name: 'Open in a new tab',
          action: (sender) => openUrl(hrefFolder(sender)),
        },
        {
          name: 'Copy link to clipboard',
          action: (sender) => copyUrl(hrefFolder(sender)),
        }
      ]
    }

  ];





  //todo: as files changes, explorer renders a lot of times 
  // console.log('render explorer')


  // ─── Main Context Menu ───────────────────────────────────────────────

  const addNewFolder = async () => {

    setInputDialogParams({
      label: 'Type new folder name',
      value: '',
      button: 'Create',
      execute: async (v) => {

        const result = await dbAddFolder(user.uid, v);
        if (result) {
          dispatch({ type: 'folder-add', folder: result });
          setSelected([result.id]);
          setShowSearchBox(false);
        }

        return result;
      },
      close: () => setInputDialogParams(null)
    })

  };


  const copyFile = async (id, name = null, folderId = undefined) => new Promise(async (resolve, reject) => { const result = await fnCopyFile(id, name, folderId); resolve(result); });

  const paste = async () => {

    if (!clipboard.files.length) return;

    setIsUpdating(true);

    const { files, cut } = clipboard;

    if (cut) {

      const promises = files.map(id => new Promise(async (resolve, reject) => { const result = await dbMoveFile(id, folder?.id || null); resolve(result); }));
      const result = await Promise.all(promises);

      const success = result.every(v => v);

      if (success) {

        dispatch({ type: 'files-move', files, folderId: folder?.id });

        setClipboard({ files: [], cut: false });

        setSelected([...files]);
      }

    }
    else {

      const promises = files.map(id => copyFile(id, null, folder?.id || null));
      const result = await Promise.all(promises);

      const success = result.every(v => v);

      if (success) {

        dispatch({ type: 'files-add', files: result });

        const ids = result.map(({ id }) => id);
        setSelected([...ids]);

      }

    }


    setIsUpdating(false);

  }


  const selectAll = () => { const arr = files.filter(file => filter(file)).map(({ id }) => id); setSelected([...arr]); }



  // ─── File Context Menu Options ───────────────────────────────────────

  const revealFile = (sender) => {
    const file = files.find(file => file.id == sender);
    if (!file) return;

    const { folderId } = file;

    setShowSearchBox(false);

    const folder = folders.find(folder => folder.id == folderId);
    selectFolder(folder);

    setSelected([file.id]);

  }

  const renameFile = async (sender) => {
    if (!sender) return;

    const found = files.find((file) => file.id == sender);

    setInputDialogParams({
      label: 'Type new movie name',
      value: found.name,
      button: 'Rename',
      execute: async (v) => {

        const result = await dbRenameFile(sender, v);
        if (result) {
          dispatch({ type: 'file-rename', name: v });
          document.title = v;
        }

        return result;

      },
      close: () => setInputDialogParams(null)
    })

  };


  const deleteFile = async (id) => {

    const found = files.find((file) => file.id == id);

    const p1 = new Promise(async (resolve, reject) => { const result = await dbDeleteFile(id); resolve(result); })
    const p2 = new Promise(async (resolve, reject) => { const result = await dbDeleteFileJobs(user.uid, id); resolve(result); })
    const p3 = new Promise(async (resolve, reject) => { const result = await storageDeleteFolder(`users/${user.uid}/${id}/`); resolve(result); })


    return new Promise(async (resolve, reject) => {

      const arr = await Promise.all([p1, p2, p3]);
      const success = arr.every(v => v);

      if (success) dispatch({ type: 'file-delete', file: found });

      resolve(success);

    })


  }

  const deleteFiles = async (sender) => {
    if (!sender) return;

    const found = files.find((file) => file.id == sender[0]);
    const name = sender.length == 1 ? found.name : `${found.name} + ${sender.length - 1}`;


    setConfirmDialogParams({
      label: `Are you sure you want to delete '${name}'?`,
      warning: 'This action cannot be undone.',
      button: 'Yes, please delete it',
      execute: async () => {

        const promises = sender.map(id => deleteFile(id));
        const arr = await Promise.all(promises);

        const success = arr.every(v => v);

        if (success) {
          setSelected([]);
          setClipboard({ files: [], cut: false });

          dispatch({ type: 'clipboard-set', data: null });
        }

        return success;

      },
      close: () => setConfirmDialogParams(null)

    });


  };

  const duplicateFiles = async (sender) => {

    setIsUpdating(true);

    const promises = sender.map(id => copyFile(id, duplicateFilename(files.find(file => file.id == id).name)));
    const result = await Promise.all(promises);

    const success = result.every(v => v);

    if (success) {
      dispatch({ type: 'files-add', files: result });

      const ids = result.map(({ id }) => id);
      setSelected([...ids]);
    }

    setIsUpdating(false);

  }


  // ─── Folder Context Menu Options ─────────────────────────────────────

  const renameFolder = async (sender) => {
    if (!sender) return;

    const found = folders.find((folder) => folder.id == sender);

    setInputDialogParams({
      label: 'Type new folder name',
      value: found.name,
      button: 'Rename',
      execute: async (v) => {

        const result = await dbRenameFolder(sender, v);
        if (result) dispatch({ type: 'folder-rename', id: found.id, name: v });

        return result;

      },
      close: () => setInputDialogParams(null)
    })

  };

  const deleteFolder = async (sender) => {
    if (!sender) return;

    const found = folders.find((folder) => folder.id == sender);

    setConfirmDialogParams({
      label: `Are you sure you want to delete '${found.name}' folder?`,
      warning: 'This action cannot be undone.',
      button: 'Yes, please delete it',
      execute: async () => {

        const folderId = found.id;
        const arr = files.filter(file => file.folderId == folderId).map(({ id }) => id);

        const promises = arr.map(id => deleteFile(id));
        const result = await Promise.all(promises);

        const success = result.every(v => v) && await dbDeleteFolder(sender);

        if (success) {
          dispatch({ type: 'folder-delete', folder: found });

          setSelected([]);
          setClipboard({ files: [], cut: false });

          dispatch({ type: 'clipboard-set', data: null });
        }

        return success;

      },
      close: () => setConfirmDialogParams(null)

    });


  };


  const duplicateFolder = async (sender) => {

    const found = folders.find(folder => folder.id == sender);
    const newName = duplicateFilename(found.name);

    setIsUpdating(true);

    const result = await fnCopyFolder(sender, newName);
    if (result) {

      const { folder, files } = result;

      dispatch({ type: 'folder-add', folder });
      dispatch({ type: 'files-add', files });

      setSelected([folder.id]);

    }

    setIsUpdating(false);

  }


  // ─── Select ──────────────────────────────────────────────────────────


  const selectFile = (data) => {

    if (file?.id == data?.id) return;

    if (!data) {
      dispatch({ type: 'file-set', file: data });
      return;
    }

    const f = JSON.parse(JSON.stringify(data));

    const arr = f.content.scenes.filter(scene => scene.template == 'bg-image').map(scene => scene.elems.find(elem => elem.name == 'bgimage').url);
    cacheImgAdd(arr);

    dispatch({ type: 'file-set', file: f });
  }


  const selectFolder = (data) => {

    if (folder?.id == data?.id) return;

    dispatch({ type: 'folder-set', folder: data });
  }


  const selectItem = (e, type, data) => {

    e.stopPropagation();

    if (e.button != 0) return;


    switch (type) {
      case 'file':

        if (!e.ctrlKey) setSelected([data.id]);
        else {
          let arr = [...selected].filter(id => !folders.find(folder => folder.id == id));

          if (arr.includes(data.id)) arr = arr.filter(id => id != data.id);
          else arr.push(data.id);

          setSelected(arr);
        }


        break;

      case 'folder':

        if (!e.ctrlKey) {

          if (e.detail == 2) {
            selectFolder(data);
            setSelected([]);
          }
          else setSelected([data.id]);

        }

        break;

    }


  }

  const handleRootClick = (e) => {
    e.stopPropagation();

    if (e.detail == 2) {
      selectFolder(null);
      setSelected([folder.id]);
    }
    else setSelected([]);

  }

  // ─── Search Box ──────────────────────────────────────────────────────

  const openSearchBox = () => {

    setShowSearchBox(true);
    // setSelected([]);

  }

  const changeSearchBox = (v) => {

    setSearchText(v);
    setSelected([]);

  }

  const closeSearchBox = () => {

    setShowSearchBox(false);

    if (file) {
      const data = folders.find(folder => folder.id == file.folderId);
      selectFolder(data);
    }
    else {
      //multi-selection
      if (selected.length > 1) setSelected([]);
    }

    // selectFolder(null);
    // setSelected([]);

  }

  // ─── Context Menu ────────────────────────────────────────────────────


  const contextMenu = (e, type, data) => {

    e.preventDefault();
    e.stopPropagation();

    let sender;
    let options;

    switch (type) {
      case 'file':
        options = optionsFile;

        if (selected.includes(data.id)) {

          sender = [...selected].filter(id => files.find(file => file.id == id));

        }
        else {

          sender = [data.id];
          setSelected([data.id]);

        }

        break;
      case 'folder':
        options = optionsFolder;

        sender = data.id;
        setSelected([data.id]);

        break;

    }


    setContextMenuParams({ x: e.clientX, y: e.clientY, sender, options });

  }




  const countFiles = (folder) => files.reduce((a, c) => c.folderId == folder?.id ? a + 1 : a, 0);
  const filter = ({ name, folderId }) => showSearchBox ? !searchText || (searchText && name.toLowerCase().includes(searchText.toLowerCase())) : folderId == folder?.id;


  const list1 = useSortFolders(folders).map(folder => ({ type: 'folder', data: folder }))
  const list2 = useSortFiles(files, filterField, filterDirection).filter(file => filter(file)).map(file => ({ type: 'file', data: file }))


  const items = showSearchBox || folder ? list2 : [...list1, ...list2];


  const icon = { 'file': require("../assets/svg/file.svg").default, 'folder': require("../assets/svg/folder.svg").default };

  const listItems = items.map(({ type, data }) =>

    <li
      className={classNames('item snap-start flex flex-row items-center', selected.includes(data.id) && 'item-selected')}
      key={data.id}
      // ref={refs[f.id]}
      onMouseDown={(e) => selectItem(e, type, data)}
      onContextMenu={(e) => contextMenu(e, type, data)}>

      <img
        className={classNames('w-4 h-4 mr-2', type == 'file' && selected.includes(data.id) && 'filter-red')}
        src={icon[type]}
      />

      {type == 'folder' && <span className="flex-1">{data.name}</span>}
      {type == 'folder' && <span className="ml-2 px-1 !text-xs text-gray-500">{countFiles(data)}</span>}

      {type == 'file' && !showSearchBox && <span className="flex-1">{data.name}</span>}
      {type == 'file' && showSearchBox && <Highlighted text={data.name} highlight={searchText} />}

    </li>

  );



  return (
    <>
      <div
        className="group/explorer flex-1 flex flex-col"
        onContextMenu={(e) => { e.preventDefault(); setContextMenuParams({ x: e.clientX, y: e.clientY, sender: null, options }); }}>

        <button
          className="group mt-1.5 mb-6 mx-2 py-2 bg-yellow-400 rounded-md flex flex-row item-center justify-center"
          onClick={() => createNewMovie()}>
          <span className="text-xs group-active:translate-y-px">Create new movie</span>
        </button>


        <div className="relative px-2 mt-2 flex flex-row items-center">

          {(showSearchBox || !folder) &&
            <div className="flex-1 h-8 flex flex-row items-center"><span className="text-xs text-gray-400 uppercase">Movies</span></div>}

          {!showSearchBox && folder &&
            <div
              className="animate-fade-up animate-duration-100 flex-1 h-8 truncate text-clip select-none flex flex-row items-center">
              <span
                className="font-semibold text-xs text-gray-900 uppercase underline underline-offset-2 decoration decoration-transparent cursor-pointer hover:!decoration-red-500"
                onClick={() => renameFolder(folder.id)}>
                {folder.name}
              </span>
            </div>}


          <div className="ml-4 flex flex-row items-center space-x-1">
            {!showSearchBox &&
              <button
                className="group opacity-20 transition-opacity hover:opacity-100 flex flex-row items-center"
                onClick={() => openSearchBox()}>

                <img
                  className="h-5 group-active:translate-y-px"
                  src={require("../assets/svg/search.svg").default}
                />

              </button>
            }

            {/* <FilterOptions field={filterField} direction={filterDirection} onFieldChange={(v) => setFilterField(v)} onDirectionChange={(v) => setFilterDirection(v)} /> */}
            <FilterOptions field={filterField} direction={filterDirection} onFieldChange={(v) => dispatch({ type: 'global-update', param: { filterField: v } })} onDirectionChange={(v) => dispatch({ type: 'global-update', param: { filterDirection: v } })} />

          </div>

          {(isUpdating || loading) &&
            <div className="absolute left-0 right-0 h-px bottom-0 overflow-clip translate-y-1">
              <div className="animate-moving-line animate-duration-[1.3s] animate-infinite absolute bottom-0 w-8 h-full bg-blue-400"></div>
            </div>
          }

        </div>


        {/* search */}

        {showSearchBox && <SearchBox searchText={searchText} change={(v) => changeSearchBox(v)} close={closeSearchBox} />}


        {/* files */}

        <div
          className="flex-1 mt-8 mb-4 overflow-y-auto scroll-smooth snap-y"
          onMouseDown={() => { if (selected.length > 1) setSelected([]) }}
        // onMouseDown={() => setSelected([])}
        >
          <div className="h-0">
            <ul className="files group flex flex-col space-y-px">

              {!showSearchBox && folder &&
                <li
                  className={classNames('item snap-start flex flex-row items-center space-x-2',
                    !selected.length && 'item-selected'
                  )}
                  key="root"
                  onClick={(e) => handleRootClick(e)}
                  onContextMenu={(e) => { e.preventDefault() }}>

                  <span className="ml-6 origin-bottom-left scale-150">...</span>

                </li>
              }

              {listItems}

            </ul>
          </div>
        </div>



      </div >

      {/* new movie */}

      {showCreateNewMovie && <CreateNewMovie close={(v) => { setShowCreateNewMovie(false); setShowSearchBox(false); if (v) setSelected([v]) }} />}

      {/* dialogs */}

      {inputDialogParams && <InputDialog params={inputDialogParams} />}
      {confirmDialogParams && <ConfirmDialog params={confirmDialogParams} />}


      {/* context menu */}

      {contextMenuParams && <ContextMenu x={contextMenuParams.x} y={contextMenuParams.y} sender={contextMenuParams.sender} options={contextMenuParams.options} closeContextMenu={() => setContextMenuParams(null)} />}


      {(isUpdating || loading) && <div className="fixed left-0 top-0 right-0 bottom-0 z-[9999]"></div>}

    </>
  );

};

export default Explorer;
