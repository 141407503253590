
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';


import { fnSendEmail } from "../firebase/functions";

import { Dialog } from "../dialogs";

import classNames from "classnames";

const MIN_CHARS = 3;
const MAX_CHARS = 1500;

const PageWrite = ({ placeholder, prevent, sent }) => {

    const state = useContext(StateContext);

    const { user, subscription } = state;

    const [text, setText] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);

    const sendMessage = async () => {

        window.prevent = true;
        setIsUpdating(true);

        const { email, displayName } = user;

        const result = await fnSendEmail({ name: displayName, from: email, text, subscription });
        if (result) sent();

        setIsUpdating(false);
        window.prevent = false;

    }

    return (
        <>
            <textarea
                className="flex-1 w-full px-4 py-2 bg-gray-50 border border-transparent resize-none focus:outline-none focus:border-blue-200 placeholder:font-light placeholder:italic placeholder:text-gray-400"
                placeholder={placeholder}
                value={text}
                maxLength={MAX_CHARS}
                autoFocus={true}
                onChange={(e) => setText(e.target.value)}>

            </textarea>

            <div className="mt-2 flex flex-row items-center justify-end">
                <button
                    className={classNames('group px-4 py-2 bg-blue-500 rounded flex flex-row items-center justify-center space-x-2', text.length < MIN_CHARS && 'opacity-80 pointer-events-none')}
                    onClick={() => sendMessage()}>
                    {isUpdating && <span className="animate-spin w-2 h-2 bg-white"></span>}
                    <span className="text-sm text-white group-active:translate-y-px">Send</span>
                </button>
            </div>

            {isUpdating && <div className="fixed left-0 top-0 right-0 bottom-0 z-[9999]"></div>}
        </>
    )
}

const PageGetBackToYou = ({ back }) => {

    const state = useContext(StateContext);

    const { user } = state;

    return (
        <>
            <p className="mt-2">
                The message is on its way!
            </p>
            <p>
                Stay tuned for our email at {user.email}
            </p>

            <button className="self-start mt-4 group flex flex-row items-center justify-center" onClick={() => back()}><span className="text-blue-400 group-active:translate-y-px">Back</span></button>
        </>
    )

}

export const ContactFrame = ({ placeholder }) => {

    const [page, setPage] = useState('write');

    return (
        <div className="flex-1 flex flex-col">

            {page == 'write' && <PageWrite placeholder={placeholder} sent={() => setPage('get-back-to-you')} />}
            {page == 'get-back-to-you' && <PageGetBackToYou back={() => setPage('write')} />}

        </div>
    )

}


export const Contact = ({ placeholder, close }) => {

    return (
        <Dialog
            className="w-[36rem] h-[28rem] !p-2 select-none flex flex-col"
            close={close}>

            <ContactFrame placeholder={placeholder} />

        </Dialog>
    )

}



