
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

import { forwardRef, useImperativeHandle } from "react";

import { useClickOutside } from "../hooks";

import Tooltip from "./Tooltip";

import classNames from "classnames";




const useRefBoundingRect = (ref, isModalVisible, setBoundingRect) => {

    useEffect(() => {
        if (!ref?.current) return;

        const { left, top, width, height } = ref.current.getBoundingClientRect();
        setBoundingRect({ left, top, width, height })

    }, [ref, isModalVisible])

}

export const ButtonWithModal = ({ className, place, same = false, isModalVisible, setIsModalVisible, children }) => {

    const [buttonBoundingRect, setButtonBoundingRect] = useState(null);
    const [modalBoundingRect, SetModalBoundingRect] = useState(null);

    const refButton = useRef(null);
    const refModal = useRef(null);

    useRefBoundingRect(refButton, isModalVisible, setButtonBoundingRect);
    useRefBoundingRect(refModal, isModalVisible, SetModalBoundingRect);

    useClickOutside(isModalVisible, [refButton, refModal], null, () => setIsModalVisible(false));


    const handleMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setButtonBoundingRect(null);
        SetModalBoundingRect(null);
        setIsModalVisible(!isModalVisible);
    }


    const styleModal = () => {

        const m = 2;

        let left = -9999;
        let top = -9999;

        let w = null;

        if (buttonBoundingRect && modalBoundingRect) {

            w = same ? buttonBoundingRect.width : modalBoundingRect.width;

            switch (place) {
                case 'tl':
                    left = buttonBoundingRect.left;
                    top = buttonBoundingRect.top - modalBoundingRect.height - m;


                    break;

                case 'tr':
                    left = buttonBoundingRect.left + buttonBoundingRect.width - w;
                    top = buttonBoundingRect.top - modalBoundingRect.height - m;


                    break;

                case 'bl':
                    left = buttonBoundingRect.left;
                    top = buttonBoundingRect.top + buttonBoundingRect.height + m;



                    break;

                case 'br':
                    left = buttonBoundingRect.left + buttonBoundingRect.width - w;
                    top = buttonBoundingRect.top + buttonBoundingRect.height + m;


                    break;

                case 'r':
                    left = buttonBoundingRect.left + buttonBoundingRect.width + m;
                    top = buttonBoundingRect.top;


                    break;

            };

        }

        const v = {
            'left': `${left}px`,
            'top': `${top}px`,
        };

        if (w) v['width'] = `${w}px`;


        return v;

    }


    return (
        <>
            <div
                ref={refButton}
                className={className}
                onMouseDown={(e) => handleMouseDown(e)}>
                {children[0]}
            </div>
            {isModalVisible &&
                <div
                    ref={refModal}
                    className="fixed left-0 top-0 z-[9999] flex"
                    style={styleModal()}>
                    {children[1]}
                </div>
            }
        </>
    );

};


export const ButtonWithOptions = forwardRef(({ className, place, same = false, svg, disabled, tooltip, children }, ref) => {

    const [isModalVisible, setIsModalVisible] = useState(false);


    useImperativeHandle(ref, () => ({
        close: () => setIsModalVisible(false),
    }));

    const images = require.context('../assets/svg/', false);
    const icon = svg ? images(`./${svg}`) : null;


    return (

        <ButtonWithModal className={classNames('h-full', disabled && 'pointer-events-none')} place={place} same={same} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>

            <button
                className={classNames('group relative h-full px-3 hover:bg-slate-600 flex flex-row items-center space-x-2', disabled && 'opacity-20 pointer-events-none', isModalVisible && '!bg-slate-600')}>

                {icon &&
                    <img
                        className={classNames('opacity-80 transition-opacity group-hover:!opacity-100', className)}
                        src={icon} />
                }

                {!icon && children[0]}


                <svg
                    className={classNames('w-2 opacity-80 transition-opacity group-hover:!opacity-100 group-active:translate-y-px', isModalVisible && 'translate-y-px')}
                    width="34" height="19" viewBox="0 0 34 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.26777 1.27667C0.291441 2.25294 0.291441 3.83594 1.26777 4.81219L13.4982 17.0307C15.4512 18.9817 18.6157 18.9809 20.5677 17.0292L32.7935 4.80319C33.77 3.82694 33.77 2.24394 32.7935 1.26764C31.8172 0.291319 30.2342 0.291319 29.258 1.26764L18.794 11.7317C17.8177 12.7082 16.2347 12.7079 15.2585 11.7317L4.80329 1.27667C3.82699 0.300343 2.24407 0.300343 1.26777 1.27667Z" fill="white" />
                </svg>

                {tooltip && <Tooltip>{tooltip}</Tooltip>}

            </button>


            <div className={classNames('bg-slate-800 shadow', same && 'w-full')}>

                {icon ? children : children[1]}

            </div>

        </ButtonWithModal>

    );

});

export const ButtonFlatIcon = ({ classNameGroup, className, svg, disabled, tooltip, click, children }) => {

    const images = require.context('../assets/svg/', false);
    const icon = svg ? images(`./${svg}`) : null;

    const handleClick = () => { if (click) click(); }

    return (
        <button
            className={classNames('group relative h-full flex flex-row items-center justify-center', icon && 'px-3 hover:bg-slate-600', classNameGroup, disabled && 'opacity-20 pointer-events-none')}
            onClick={() => handleClick()}>

            {icon &&
                <img
                    className={classNames('opacity-90 transition-opacity group-hover:!opacity-100 group-active:translate-y-px', className)}
                    src={icon} />
            }

            {children}

            {tooltip && <Tooltip>{tooltip}</Tooltip>}

        </button>
    );

};


export const ButtonFlatIconToggle = ({ classNameGroup, className, svg, disabled, tooltip, isDown, setIsDown }) => {

    return (
        <ButtonFlatIcon
            classNameGroup={classNames('bg-slate-700 aspect-square', isDown && '!bg-blue-500', classNameGroup)}
            className={classNames(className, isDown && '!opacity-100 !translate-y-px')}
            svg={svg}
            disabled={disabled}
            tooltip={tooltip}
            click={() => setIsDown(!isDown)} />
    );

};
