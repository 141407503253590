import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import { ButtonFlatIcon } from "../ui/Buttons";

import Title from "../ui/Title";

import classNames from "classnames";



const ToolbarShare = ({ enabled, value, onBack }) => {

    const open = () => window.open(value, '_blank');

    return (
        <div className="toolbar">

            <ButtonFlatIcon className="h-4" svg="back.svg" click={onBack} />

            <span className="h-full w-px bg-slate-600"></span>

            <ButtonFlatIcon className="h-5" svg="share-open.svg" disabled={!enabled} tooltip="Open in a new tab" click={open} />

            <Title name="Share" />

        </div>
    );

};

export default ToolbarShare;