

import Compressor from 'compressorjs';


//https://github.com/fengyuanchen/compressorjs?tab=readme-ov-file


export const EXT = 'jpeg';//best in size
// export const EXT = 'webp';
// export const EXT = 'png';//keeps transparency, but can have huge size


const dimensions = async (file) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    await img.decode();
    const { naturalWidth, naturalHeight } = img;

    return { naturalWidth, naturalHeight };
}


export const compress = (file, q = 0.8) => new Promise((resolve, reject) => {

    new Compressor(file, {
        quality: q,
        maxWidth: 1920,
        maxHeight: 1920,
        minWidth: 150,
        minHeight: 150,

        mimeType: `image/${EXT}`,
        convertSize: Infinity,

        async success(data) {

            const name = data.name ? data.name.split('.').slice(0, -1).join('.') : '';
            const d = await dimensions(data);

            resolve({ name, ext: EXT, data, ...d });
        },
        error(err) {
            reject(err.message);
        },
    });


})
