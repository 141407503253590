

import classNames from "classnames";


const Info = ({ className, text }) => {

    return (
        <div className="relative group cursor-pointer">
            <svg
                className="w-5 h-5 opacity-70 pointer-events-none group-hover:!opacity-100"
                width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30 51.6C42.2606 51.6 52.2 41.6606 52.2 29.4C52.2 17.1392 42.2606 7.19995 30 7.19995C17.7393 7.19995 7.8 17.1392 7.8 29.4C7.8 41.6606 17.7393 51.6 30 51.6Z" stroke="black" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M32.2342 19.5602C32.2214 20.1416 31.98 20.6946 31.5622 21.099C31.1441 21.5034 30.5834 21.7266 30.0019 21.7201C29.5781 21.706 29.1679 21.5674 28.8223 21.3216C28.4765 21.0759 28.211 20.7337 28.0586 20.338C27.906 19.9423 27.8734 19.5104 27.9648 19.0962C28.056 18.6821 28.2672 18.3041 28.572 18.0092C28.8768 17.7143 29.2615 17.5156 29.6784 17.4379C30.0953 17.3602 30.5261 17.407 30.9166 17.5724C31.307 17.7378 31.6402 18.0144 31.8744 18.3679C32.1089 18.7214 32.2339 19.1361 32.2342 19.5602ZM28.2982 39.6723V26.7603C28.2917 26.5335 28.331 26.3076 28.4141 26.0967C28.4971 25.8857 28.6219 25.6937 28.7813 25.5324C28.9404 25.3709 29.1307 25.2432 29.3405 25.1573C29.5502 25.0714 29.7754 25.0287 30.0019 25.032C30.2256 25.0318 30.4471 25.0769 30.6528 25.1648C30.8585 25.2524 31.0445 25.3808 31.1993 25.5423C31.3541 25.7038 31.4743 25.8949 31.5533 26.1041C31.6322 26.3134 31.6678 26.5366 31.6579 26.7603V39.6723C31.6579 39.8928 31.6145 40.1112 31.5302 40.315C31.4458 40.5188 31.3219 40.704 31.1659 40.86C31.0099 41.016 30.8249 41.1397 30.6211 41.2241C30.4171 41.3086 30.1987 41.352 29.9782 41.352C29.7576 41.352 29.539 41.3086 29.3352 41.2241C29.1314 41.1397 28.9462 41.016 28.7902 40.86C28.6342 40.704 28.5103 40.5188 28.4261 40.315C28.3416 40.1112 28.2982 39.8928 28.2982 39.6723Z" fill="black" />
            </svg>

            <div className={classNames('absolute top-1/2 right-0 z-[9999] px-2 py-1 bg-yellow-400 text-sm text-nowrap opacity-0 rounded pointer-events-none transition-opacity delay-74 duration-200 shadow translate-x-[calc(100%+0.5rem)] -translate-y-1/2 group-hover:!opacity-100', className)}>
                {text}
            </div>

        </div>
    )

}

export default Info;