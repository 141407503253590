

const SearchBox = ({ searchText, change, close }) => {

    return (
        <div className="relative mt-4 px-2">

            <input
                className="w-full pl-2 pr-8 py-1 bg-gray-50 text-sm appearance-none focus:outline-none focus:bg-white focus:border-blue-500 placeholder:italic placeholder:text-gray-400"
                placeholder="search"
                type="text"
                maxLength="60"
                required={true}
                // autoComplete="off"
                spellCheck={false}
                autoFocus={true}
                value={searchText}
                onFocus={(e) => e.target.select()}
                onChange={(e) => change(e.target.value)}
            />

            <button className="absolute top-2 right-4 opacity-80 hover:opacity-100 transition-all ml-2"
                onClick={() => close()}>

                <img
                    className="h-3"
                    src={require("../assets/svg/x.svg").default}
                />

            </button>

        </div>
    );

}

export default SearchBox;
