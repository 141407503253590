import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";

import Platform from "../platform";

import Profile from '../profile';
import { Plan, Subscription } from '../subscription';

import { Contact } from "./Contact";
import About from "../about";

import { useClickOutside } from '../hooks';

import classNames from 'classnames';


const openTutorials = () => window.open('http://www.demo-builder.com/tutorials', '_blank');



const Shortcuts = () => {

    const state = useContext(StateContext);

    const { subscription } = state;

    const buttons = [
        {
            caption: 'Tutorials',
            action: () => openTutorials()
        },
        // {
        //     caption: 'Book a demo',
        //     action: () => openTutorials()
        // },
    ];

    const listButtons = buttons.map(({ caption, action }) =>
        <button
            className="h-10 pl-1 pr-2 pb-0.5 border border-transparent rounded hover:!border-blue-400 active:bg-neutral-50 flex flex-col items-start justify-end space-y-0.5"
            key={caption}
            onClick={() => action()}>

            <img
                className="h-3 filter-black"
                src={require("../assets/svg/share-open.svg").default}
            />

            <span className="text-xs">{caption}</span>
        </button>)


    if (subscription) return;

    return (
        <div className="flex-1 flex flex-row items-center justify-start space-x-1">
            {listButtons}
        </div>
    )
}


const Widget = () => {

    const state = useContext(StateContext);
    const { photoURL, displayName, email } = state.user;

    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const [isVisible, setIsVisible] = useState(false);
    const [showProfile, setShowProfile] = useState(false);

    const [showSubscription, setShowSubscription] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showAbout, setShowAbout] = useState(false);

    useClickOutside(isVisible, [ref1, ref2], null, () => setIsVisible(false));


    const logout = async () => {
        try {
            await signOut(auth);
        } catch (err) {
            console.error(err);
        }
    }

    const options = [
        {
            name: 'Profile',
            action: () => setShowProfile(true),
        },

        {
            name: 'Subscription',
            action: () => setShowSubscription(true),
            delimiter: true,
        },

        {
            name: 'Tutorials',
            action: () => openTutorials(),
        },

        {
            name: 'Write us',
            action: () => setShowContact(true),
        },
        {
            name: 'About',
            action: () => setShowAbout(true),
            delimiter: true,
        },

        {
            name: 'Sign out',
            action: logout,
        },
    ];


    const listOptions = options.map(({ name, delimiter, action }) =>
        <li
            className={classNames('pl-2 pr-10 py-0.5 cursor-default hover:bg-blue-200', delimiter && 'border-b border-b-gray-200')}
            key={name}
            onClick={() => { action(); setIsVisible(false); }}>

            <span className="text-sm text-nowrap">{name}</span>

        </li>
    )

    return (
        <>

            <div className="p-2 select-none flex flex-row items-center space-x-1">

                <div className="relative flex flex-row items-center justify-center">
                    <button
                        ref={ref1}
                        className={classNames('group w-10 h-10 bg-gray-300 border-2 border-transparent rounded-full overflow-hidden hover:!border-blue-400 flex flex-row items-center justify-center', isVisible && '!border-blue-400')}
                        onClick={() => setIsVisible(prev => !prev)}>

                        {photoURL &&
                            <img
                                className="h-10"
                                src={photoURL}
                            />
                        }

                        {!photoURL &&
                            <img
                                className={classNames('w-5 h-5 drop-shadow-md group-hover:filter-blue rounded-full', isVisible && '!filter-blue')}
                                src={require("../assets/svg/account.svg").default}
                            />
                        }

                    </button>
                    <ul
                        ref={ref2}
                        className={classNames('hidden absolute left-0 -top-1 p-1 bg-white rounded shadow -translate-y-full', isVisible && '!block')}>
                        {listOptions}
                    </ul>
                </div>

                <Plan />

                <Shortcuts />

                <Platform />

            </div>

            {showProfile && <Profile close={() => setShowProfile(false)} />}
            {showSubscription && <Subscription close={() => setShowSubscription(false)} />}
            {showContact && <Contact placeholder="Got a question? Write here." close={() => setShowContact(false)} />}
            {showAbout && <About close={() => setShowAbout(false)} />}
        </>
    )

}


export default Widget;