
import { useState } from "react";
import { useEffect } from "react";

import { dbGetFiles, dbGetFolders } from '../firebase/database.js';


export const useFiles = (uid, dispatch) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!uid) return;
        
        const load = async () => {
            const response = await dbGetFiles(uid);
            if (!response) return;

            dispatch({ type: 'files-set', files: response });

            setLoading(false);
        }

        load();

    }, [uid]);

    return loading;

}

export const useFolders = (uid, dispatch) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!uid) return;

        const load = async () => {
            const response = await dbGetFolders(uid);
            if (!response) return;

            dispatch({ type: 'folders-set', folders: response });

            setLoading(false);
        }

        load();

    }, [uid]);

    return loading;

}



export const useCheckInView = (refs, file) => {

    useEffect(() => {
        if (file && refs[file.id]?.current) {
            const rect = refs[file.id].current.getBoundingClientRect();
            const isInView = rect.top < window.innerHeight && rect.bottom >= 0;

            if (!isInView)
                refs[file.id].current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
        }

    }, [refs, file])

}
