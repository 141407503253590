import classNames from "classnames";

const Loading = ({ opaque = false }) => {

    return (
        <div className={classNames('absolute left-0 top-0 right-0 bottom-0 flex flex-row items-center justify-center', opaque && 'bg-white/90')}>
            <img
                alt=""
                className="h-7 animate-spin"
                src={require("../assets/svg/loading.svg").default}
            />
        </div>
    );

};

export default Loading;