

import { can } from "./editor/utils";


export const initialState = {
    isLoggedIn: false,
    user: null,

    session: null,

    plans: [],
    subscription: null,

    files: [],
    file: null,

    folders: [],
    folder: null,

    scene: null,
    elem: null,

    temp: null,//file to be saved

    clipboard: null,

    voices: [],
    languages: { source: [], target: [] },

    preferences: {
        pilot: false,
        toc: true,
        tta: 'maximized',


        voice_id: 'JBFqnCBsd6RMkjVDRZzb',

    },

    runtime: {
        editing: false,
        rewriting: null,


        pan: false,


    },


    global: {
        filterField: 'modified',
        filterDirection: 'desc',

    },


};

// https://react.dev/learn/updating-arrays-in-state
// https://github.com/immerjs/use-immer

export const reducer = (draft, action) => {
    switch (action.type) {

        case 'login':
            // console.log('User is signed in', action.user);

            draft.isLoggedIn = true;
            draft.user = action.user;

            break;

        case 'session':
            // console.log('User session', action.session);

            draft.session = action.session;

            break;

        case 'logout':
            // console.log('User is signed out');

            draft.isLoggedIn = false;
            draft.user = null;

            draft.session = null;

            draft.files = [];
            draft.file = null;

            draft.folders = [];
            draft.folder = null;

            draft.scene = null;
            draft.elem = null;

            draft.clipboard = null;

            break;


        // ─── Plans ───────────────────────────────────────────────────

        case 'plans-set':
            // console.log('plans set', action.plans);

            draft.plans = action.plans;

            break;

        // ─── Subscription ────────────────────────────────────────────

        case 'subscription-set':
            // console.log('subscription set', action.subscription);

            draft.subscription = action.subscription;

            break;

        // ─── Clipboard ───────────────────────────────────────────────

        case 'clipboard-set':
            // console.log('set clipboard', action.data);

            draft.clipboard = action.data;

            break;

        // ─── Files ───────────────────────────────────────────────────

        case 'files-add':
            // console.log('adding files', action.files);

            draft.file = null;
            draft.scene = null;
            draft.elem = null;

            draft.files.splice(0, 0, ...action.files);

            break;

        case 'files-set':
            // console.log('changing files', action.files);
            draft.files = action.files;


            break;

        case 'files-move':
            // console.log('moving files', action.files, action.folderId);

            action.folderId = action.folderId || null;

            draft.files = draft.files.map(file => action.files.includes(file.id) ? { ...file, folderId: action.folderId } : file);

            break;

        // ─── File ────────────────────────────────────────────────────

        case 'file-add':
            // console.log('adding file', action.file);

            draft.file = action.file;
            draft.scene = null;
            draft.elem = null;

            draft.runtime.pan = false;

            draft.files.splice(0, 0, action.file);

            break;

        case 'file-set':
            // console.log('changing file', action.file);

            draft.file = action.file;
            draft.scene = null;
            draft.elem = null;

            draft.runtime.pan = false;

            break;

        case 'file-rename':
            // console.log('rename file', action.name);

            //saves in db by Explorer

            draft.file.name = action.name;
            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            break;

        case 'file-delete':
            // console.log('delete file', action.file);

            //saves in db by Explorer

            draft.files = draft.files.filter(file => file.id != action.file.id);

            draft.file = null;
            draft.scene = null;
            draft.elem = null;


            break;

        case 'file-settings-update':
            // console.log('file settings update', action.param);

            Object.keys(action.param).forEach(key => draft.file.settings[key] = action.param[key]);

            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break;

        // ─── Folders ─────────────────────────────────────────────────

        case 'folders-set':
            // console.log('changing folders', action.folders);

            draft.folders = action.folders;
            draft.folder = null;

            break;

        // ─── Folder ──────────────────────────────────────────────────

        case 'folder-add':
            // console.log('adding folder', action.folder);

            // draft.folder = action.folder;
            draft.folder = null;

            draft.folders.splice(0, 0, action.folder);

            break;

        case 'folder-set':
            // console.log('changing folder', action.folder);

            draft.folder = action.folder;

            break;

        case 'folder-rename':
            // console.log('rename folder', action.id, action.name);

            //saves in db by Explorer

            if (draft.folder && draft.folder.id == action.id) draft.folder.name = action.name;

            draft.folders.find(folder => folder.id == action.id).name = action.name;

            break;

        case 'folder-delete':
            // console.log('delete folder', action.folder);

            //saves in db by Explorer

            draft.folder = null;

            draft.folders = draft.folders.filter(folder => folder.id != action.folder.id);

            break;

        // ─── Scenes ──────────────────────────────────────────────────

        case 'scenes-toggle':
            // console.log('toggling scenes', action.id);

            const sceneToToggle = draft.file.content.scenes.find(scene => scene.id == action.id);
            sceneToToggle.visible = !sceneToToggle.visible;

            if (sceneToToggle.selected)
                draft.file.content.scenes.forEach(scene => { if (scene.selected) scene.visible = sceneToToggle.visible });


            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break

        case 'scenes-select':
            // console.log('selecting scenes', action.ids);

            draft.file.content.scenes = draft.file.content.scenes.map(scene => ({ ...scene, selected: action.ids.includes(scene.id) }));

            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break

        case 'scenes-unselect':
            // console.log('unselecting scenes');

            draft.file.content.scenes = draft.file.content.scenes.map(scene => ({ ...scene, selected: false }));

            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break

        case 'scenes-delete':
            // console.log('deleting scenes', action.ids);

            draft.file.content.scenes = draft.file.content.scenes.filter(scene => !action.ids.includes(scene.id));

            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break

        case 'scenes-order':
            // console.log('ordering scenes', action.ids);

            draft.file.content.scenes = action.ids.map(id => draft.file.content.scenes.find(scene => scene.id == id));

            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break

        case 'scenes-insert':
            // console.log('insert scenes', action.scenes);

            draft.scene = null;

            draft.file.content.scenes = draft.file.content.scenes.map(scene => ({ ...scene, selected: false }));
            action.scenes = action.scenes.map(({ scene, index }) => ({ scene: { ...scene, selected: true }, index }));

            if (!action.scenes.every(({ index }) => index == undefined)) action.scenes.reverse();

            action.scenes.forEach(item => {
                if (item.index == undefined) draft.file.content.scenes.push(item.scene);
                else draft.file.content.scenes.splice(item.index, 0, item.scene);
            }
            );


            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break

        case 'scenes-bgimage-url':
            // console.log('updating scenes bgimage url', action.items);

            if (draft.scene)
                draft.scene.elems.find(elem => elem.name == 'bgimage').url = action.items.find(({ id }) => draft.scene.id == id).url;

            action.items = action.items.map(({ id, url }) => ({ bgimage: draft.file.content.scenes.find(scene => scene.id == id).elems.find(elem => elem.name == 'bgimage'), url }))
            action.items.forEach(({ bgimage, url }) => bgimage.url = url);



            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break

        // ─── Scene ───────────────────────────────────────────────────

        case 'scene-set':
            // console.log('changing scene', action.scene);

            draft.scene = action.scene;
            draft.elem = action.scene ? action.scene.template == 'bg-image' ? action.scene.elems.find(elem => elem.name == 'bgimage') : null : null;


            break

        case 'scene-toggle':
            // console.log('toggling scene');

            draft.scene.visible = !draft.scene.visible;

            draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.id == draft.scene.id ? draft.scene : scene);
            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break

        case 'scene-rename':
            // console.log('renaming scene', action.id, action.name);

            if (action.id) {
                const sceneToRename = draft.file.content.scenes.find(scene => scene.id == action.id);
                sceneToRename.name = action.name;
            }
            else {
                draft.scene.name = action.name;
                draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.id == draft.scene.id ? draft.scene : scene);
            }

            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break

        case 'scene-delete':
            // console.log('scene delete');

            const sceneToDeleteIndex = draft.file.content.scenes.findIndex(scene => scene.id == draft.scene.id);
            const nextSceneSelected = sceneToDeleteIndex < draft.file.content.scenes.length - 1 ? sceneToDeleteIndex + 1 : sceneToDeleteIndex - 1;

            draft.scene = nextSceneSelected > -1 ? JSON.parse(JSON.stringify(draft.file.content.scenes[nextSceneSelected])) : null;
            draft.file.content.scenes.splice(sceneToDeleteIndex, 1);

            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break


        case 'scene-layout':
            // console.log('changing scene layout', action.layout);

            draft.scene.layout = action.layout;

            draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.id == draft.scene.id ? draft.scene : scene);
            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break

        case 'scene-layout-accent':
            // console.log('changing scene layout accent', action.accent);

            draft.scene.accent = action.accent;

            draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.id == draft.scene.id ? draft.scene : scene);
            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break

        case 'scene-wait':
            // console.log('changing scene wait', action.wait);

            draft.scene.wait = action.wait;

            draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.id == draft.scene.id ? draft.scene : scene);
            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break



        case 'scene-elem-toggle':
            // console.log('scene-elem-toggle', action.name);


            const elToToggle = draft.scene.elems.find(elem => elem.name == action.name);
            elToToggle.visible = !elToToggle.visible;

            if (elToToggle.visible) {
                // draft.elem = JSON.parse(JSON.stringify(elToToggle));
            }
            else {
                if (draft.elem && draft.elem.name == elToToggle.name) draft.elem = null;
                draft.runtime.editing = false;
            };




            draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.id == draft.scene.id ? draft.scene : scene);
            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break;

        case 'scene-elems-visible':
            // console.log('scene-elems-visible', action.names);

            draft.scene.elems = draft.scene.elems.map(elem => ({ ...elem, visible: action.names.includes(elem.name) }));

            draft.elem = null;


            draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.id == draft.scene.id ? draft.scene : scene);
            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break;



        case 'scene-bgimage-url':
            // console.log('updating scene bgimage', action.name, action.naturalWidth, action.naturalHeight, action.url);

            draft.scene.name = action.name;

            const bgImageToUpdateImg = draft.scene.elems.find(elem => elem.name == 'bgimage');
            bgImageToUpdateImg.naturalWidth = action.naturalWidth;
            bgImageToUpdateImg.naturalHeight = action.naturalHeight;
            bgImageToUpdateImg.crop = { left: 0, top: 0, width: action.naturalWidth, height: action.naturalHeight };//reset
            bgImageToUpdateImg.zoom = { ...bgImageToUpdateImg.zoom, value: 1, x: 0, y: 0 };//reset
            bgImageToUpdateImg.url = action.url;


            draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.id == draft.scene.id ? draft.scene : scene);
            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;


            break

        case 'scene-bgimage-crop':
            // console.log('updating scene bgimage crop', action.crop);

            const bgImageToUpdateCrop = draft.scene.elems.find(elem => elem.name == 'bgimage');
            bgImageToUpdateCrop.crop = action.crop;
            bgImageToUpdateCrop.zoom = { ...bgImageToUpdateCrop.zoom, value: 1, x: 0, y: 0 };//reset


            draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.id == draft.scene.id ? draft.scene : scene);
            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;


            break

        case 'scene-bgimage-zoom':
            // console.log('updating scene bgimage zoom', action.zoom);

            if (draft.elem && draft.elem.name == 'bgimage') draft.elem.zoom = action.zoom;

            const bgImageToUpdateZoom = draft.scene.elems.find(elem => elem.name == 'bgimage');
            bgImageToUpdateZoom.zoom = action.zoom;


            draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.id == draft.scene.id ? draft.scene : scene);
            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;


            break

        // ─── Speak ───────────────────────────────────────────────────

        case 'scene-speak-update':
            // console.log('scene speak update', action.param);

            Object.keys(action.param).forEach(key => draft.scene.speak[key] = action.param[key]);


            draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.id == draft.scene.id ? draft.scene : scene);
            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;


            break;

        // ─── Elem ────────────────────────────────────────────────────

        case 'elem-set':
            // console.log('setting elem', action.elem);


            //action.elem may contain additional data, such as motion
            draft.elem = action.elem && draft.scene.elems.find(elem => elem.name == action.elem.name);


            break

        case 'elem-update':
            // console.log('elem update', action.param);


            // const elem = draft.scene.elems.find(elem => elem.name == draft.elem.name);

            if (action.param.bounds?.pristine) {

                delete draft.elem.bounds.pristine;
                // delete elem.bounds.pristine;

                delete action.param.bounds.pristine;


            }

            Object.keys(action.param).forEach(key => draft.elem[key] = action.param[key]);
            // Object.keys(action.param).forEach(key => elem[key] = action.param[key]);


            draft.scene.elems = draft.scene.elems.map(elem => elem.name == draft.elem.name ? draft.elem : elem);


            draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.id == draft.scene.id ? draft.scene : scene);
            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;

            break

        // ─── ApplyToAll ──────────────────────────────────────────────

        case 'applytoall':
            // console.log('apply to all', action.target, action.param);


            switch (action.target) {
                case 'layout':

                    if (draft.scene.accent)
                        draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.template == draft.scene.template ? { ...scene, layout: draft.scene.layout, accent: draft.scene.accent } : scene);
                    else
                        draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.template == draft.scene.template ? { ...scene, layout: draft.scene.layout } : scene);

                    break;

                case 'wait':

                    draft.file.content.scenes = draft.file.content.scenes.map(scene => scene.template == draft.scene.template ? { ...scene, wait: draft.scene.wait } : scene);

                    break;

                case 'crop':

                    const bgImageCrop = draft.scene.elems.find(elem => elem.name == 'bgimage');

                    draft.file.content.scenes
                        .filter(scene => scene.id != draft.scene.id && scene.template == draft.scene.template)
                        .map(scene => scene.elems.find(elem => elem.name == 'bgimage'))
                        .filter(bgimage => can.crop(bgimage, bgImageCrop))
                        .forEach(bgimage => { bgimage.crop = { ...bgImageCrop.crop }; bgimage.zoom = { ...bgimage.zoom, value: 1, x: 0, y: 0 }; });


                    break;

                case 'zoom':

                    // console.log('action.param: ', action.param);

                    const sceneZoomIndex = draft.file.content.scenes.findIndex(scene => scene.id == draft.scene.id);
                    const bgImageZoom = draft.scene.elems.find(elem => elem.name == 'bgimage');

                    draft.file.content.scenes
                        .filter((scene, index) => scene.id != draft.scene.id && scene.template == draft.scene.template
                            && (action.param == undefined ? true : action.param == 'prev' ? index < sceneZoomIndex : action.param == 'next' ? index > sceneZoomIndex : false))
                        .map(scene => scene.elems.find(elem => elem.name == 'bgimage'))
                        .filter(bgimage => can.zoom(bgimage, bgImageZoom))
                        .forEach(bgimage => { bgimage.zoom = { ...bgImageZoom.zoom } });




                    break;

                case 'bounds':

                    const bgImageBounds = draft.scene.elems.find(elem => elem.name == 'bgimage');

                    draft.file.content.scenes
                        .filter(scene => scene.template == draft.scene.template)
                        .map(scene => ({ ...scene, bgimage: scene.elems.find(elem => elem.name == 'bgimage') }))
                        .filter(scene => can.bounds(scene.bgimage, bgImageBounds))
                        .map(scene => scene.elems.find(elem => elem.type == draft.elem.type))
                        .forEach(elem => elem.bounds = draft.elem.bounds);


                    break;

                case 'elem':

                    draft.file.content.scenes
                        .filter(scene => scene.template == draft.scene.template)
                        .map(scene => scene.elems.find(elem => elem.type == draft.elem.type))
                        .forEach(elem => Object.keys(action.param).forEach(key => elem[key] = action.param[key]));

                    break;

                case 'elems':

                    draft.file.content.scenes
                        .filter(scene => scene.template == draft.scene.template)
                        .forEach(scene => draft.scene.elems.forEach(elem => scene.elems.find(el => el.name == elem.name).visible = elem.visible));

                    break;


                case 'copy-from-speak':

                    draft.elem.text = draft.scene.speak.text;

                    draft.scene.elems.find(elem => elem.name == action.param).text = draft.scene.speak.text;

                    draft.file.content.scenes
                        .filter(scene => scene.template == draft.scene.template)
                        .filter(scene => scene.speak.enabled)
                        .forEach(scene => scene.elems.find(elem => elem.name == action.param).text = scene.speak.text);

                    break;


                case 'speak-voice_id':

                    draft.file.content.scenes
                        .forEach(scene => scene.speak.voice_id = action.param);

                    break;

                case 'speak-copy-from':

                    draft.scene.speak.text = draft.scene.elems.find(elem => elem.name == action.param).text;

                    draft.file.content.scenes
                        .filter(scene => scene.template == draft.scene.template)
                        .forEach(scene => scene.speak.text = scene.elems.find(elem => elem.name == action.param).text);

                    break;


            }





            draft.files = draft.files.map(file => file.id == draft.file.id ? draft.file : file);

            draft.temp = draft.file;


            break;

        // ─── TextToSpeech ────────────────────────────────────────────

        case 'voices-set':
            draft.voices = action.voices;
            break;

        // ─── Translate ───────────────────────────────────────────────

        case 'languages-set':
            draft.languages = action.languages;
            break;

        // ─── Preferences ─────────────────────────────────────────────

        case 'preferences-pilot-toggle':
            draft.preferences.pilot = !draft.preferences.pilot;
            break;

        case 'preferences-toc-toggle':
            draft.preferences.toc = !draft.preferences.toc;
            break;

        case 'preferences-tta-toggle':
            draft.preferences.tta = draft.preferences.tta == 'maximized' ? 'minimized' : 'maximized';
            break;

        case 'preferences-voice_id-set':
            draft.preferences.voice_id = action.param;
            break;

        // ─── Runtime ─────────────────────────────────────────────────

        case 'runtime-editing-set':
            draft.runtime.editing = action.param;
            break;

        case 'runtime-rewriting-set':
            draft.runtime.rewriting = action.param;
            break;

        case 'runtime-pan-toggle':
            draft.runtime.pan = !draft.runtime.pan;
            break;


        // ─── Global ──────────────────────────────────────────────────


        case 'global-update':
            // console.log('global update', action.param);

            Object.keys(action.param).forEach(key => draft.global[key] = action.param[key]);

            break;



    }
}

