import React, { useMemo } from "react";

import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useContext } from 'react';



import { StateContext, DispatchContext } from '../context/';


import Templates from '../templates/';
import Elems from './Elems.js';

import { useElemMotion } from "./Elems.js";

import { Play } from "./Controller.js";


import compute from "./compute.js";

import { Pilot, Toc, Mode, ZoomAndPan, Lang } from './Additions.js';

import EditTextToAudio from './EditTextToAudio.js';

import ListWithOptions from "../ui/ListWithOptions.js";


import ContextMenu from '../ui/ContextMenu.js';

import emitter from "../emmiter/index.js";


import classNames from "classnames";


// const getSceneStatuses = (scene, scenes, duration) => {

//     return useMemo(() => {

//         const t2 = 500;

//         const v = {
//             'entering': { a: 0, b: duration - t2-100 },
//             'exiting': { a: duration - t2, b: Infinity },
//         };


//         return v;


//     }, [scene, scenes, duration])

// }

const useBgImageZoom = (motion, zoom, duration) => {


    const statuses = useMemo(() => compute.statuses(zoom, duration), [zoom, duration]);

    const [status, progress] = useElemMotion(motion, statuses);


    if (!zoom) return null;

    if (!motion || !status) return zoom.zoom;

    const easeOutSine = (x) => Math.sin((x * Math.PI) / 2);
    // const easeOutCubic = (x) => 1 - Math.pow(1 - x, 3);


    const interpolate = (a, b, progress) => a + (b - a) * easeOutSine(progress);
    const interpolateZoom = (z1, z2, progress) => ({ value: interpolate(z1.value, z2.value, progress), x: interpolate(z1.x, z2.x, progress), y: interpolate(z1.y, z2.y, progress) })


    const animate = {
        'waiting': zoom.zoom1 ? zoom.zoom1 : zoom.zoom,
        'entered': zoom.zoom,
        'zoom1': zoom.zoom1 ? interpolateZoom(zoom.zoom1, zoom.zoom, progress / 100) : null,
        'zoom2': zoom.zoom2 ? interpolateZoom(zoom.zoom, zoom.zoom2, progress / 100) : null,
    }


    const z = { enabled: true, ...animate[status] };

    return z;
}


export const EditCanvas = ({ preferences, settings, scenes, scene, motion, paused, ring, pilot, toc, lang, languages, translate, mode, code }) => {


    const state = useContext(StateContext);
    const { file, runtime } = state;



    const { zoom, start, duration, duration_for_elems, interact } = compute.calc(file, scene, code);


    // const [status] = useElemMotion(motion, getSceneStatuses(scene, scenes, duration));
    // console.log('status: ', status);


    const bgImageZoom = useBgImageZoom(motion, zoom, duration);



    const [contextMenuParams, setContextMenuParams] = useState(null);

    const contextmenu = (params) => setContextMenuParams(params);




    const Template = Templates[scene.template];
    const layout = Template.layouts.find(layout => layout.name == scene.layout);



    const contentElem = (elem) =>
        React.createElement(Elems[elem.type].comp, {
            key: elem.name,
            ...elem,
            parent: scene.elems.find(e => e.name == elem.parent),
            motion,

            zoom: bgImageZoom,//todo: atentie, poate il duci in parinte mai sus, acoloe  locul lui
            start: 0,//todo: atentie
            duration: duration_for_elems,//todo: atentie
            interact,//atentie

            paused,//atentie

            code,//atentie

            scene,//atentie, daca trimiti scena, apoi nu mai trimite is si speak, imi prebiue la focus sa calculez prev

            contextmenu
        });



    // const clone = scene.elems.toSorted((a, b) => a.type == 'cursor' ? -1 : 1);    
    const clone = scene.elems.filter(elem => window.rec ? elem.type != 'button' : true);




    const parents = clone.filter(elem => !elem.parent);
    const adopts = clone.filter(elem => elem.parent && layout.adopt?.includes(elem.name)).map(elem => ({ ...elem, bounds: null }));

    const root = [...parents, ...adopts]; //used by template
    const children = clone.filter(elem => !root.find(item => item.name == elem.name)).map(elem => ({ ...elem, content: contentElem(elem) }));

    const arr = root.map(elem => ({ ...elem, children: children.filter(child => child.parent == elem.name) })).map(elem => ({ ...elem, content: contentElem(elem) }));





    // const elems = arr.reduce((a, c) => { a[c.name] = { ...c }; return a; }, {});

    const elems = arr.reduce((a, c) => {
        a[c.name] = {
            ...c,

            motion,//atentie
            duration: duration_for_elems,//atentie
            interact,//atentie
            scene,//atentie
            code,
            contextmenu//atentie
        };
        return a;
    }, {});


    const scene1 = motion && scenes.find(scene => scene.template == 'bg-image' && scene.visible);
    // const scene2 = motion && scenes.findLast(scene => scene.template == 'bg-image' && scene.visible);//todo:nu e ok, trebuie cu sttaus de sus

    // const status = motion ? scene1 && scene1.id == scene.id ? 'first' : scene2 && scene2.id == scene.id ? 'last' : undefined : undefined;
    const status = motion && !ring && scene1 && scene1.id == scene.id ? 'first' : undefined;



    //todo: analyze key -> it could fire the useDimensions in ElemBgImage
    //key: scene.id -> flick
    const contentTemplate = React.createElement(layout.comp, {
        // key: scene.id,
        key: layout.name,
        motion,
        elems,
        status, //atentie
        scenes,//atentie
        scene,//atentie
        // code,
    })



    return (

        <>
            <div className={classNames('flex-1 flex flex-col', settings.mode == 'dark' && 'dark')}>
                <div
                    className={classNames('flex-1 relative overflow-clip flex flex-col',
                        motion && ring && 'outline outline-1 outline-offset-[-1px] outline-red-500',
                        !window.rec && 'bg-pattern-light dark:bg-pattern-dark',
                        window.rec && 'bg-[#f7f7f7] dark:bg-slate-800')}
                    onContextMenu={(e) => e.preventDefault()}>


                    {contentTemplate}



                    {pilot && <Pilot preferences={preferences} scenes={scenes} scene={scene} />}
                    {toc && <Toc preferences={preferences} scenes={scenes} scene={scene} code={code} />}

                    {translate && <Lang lang={lang} languages={languages} code={code} />}


                    {mode && <Mode settings={settings} />}



                    {!motion && elems.bgimage && !elems.bgimage.url.includes('placeholder.svg') && <ZoomAndPan />}




                </div>

            </div>

            {contextMenuParams && <ContextMenu x={contextMenuParams.x} y={contextMenuParams.y} sender={contextMenuParams.sender} options={contextMenuParams.options} closeContextMenu={() => setContextMenuParams(null)} />}

            {/* mask to prevent user interaction */}
            {runtime.rewriting && <div className="fixed z-[9999] left-0 top-0 right-0 bottom-0" onContextMenu={(e) => e.preventDefault()}></div>}

        </>


    );

};







const EditElems = () => {


    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { voices, scene, elem } = state;


    const voice = voices.find(({ voice_id }) => voice_id == scene.speak.voice_id);
    const gender = voice ? voice.labels?.gender : 'male';

    const img = {
        'bg-image': require("../assets/svg/el-image.svg").default,

        focus: require("../assets/svg/el-focus.svg").default,
        arrow: require("../assets/svg/el-arrow.svg").default,
        text: require("../assets/svg/el-text.svg").default,
        button: require("../assets/svg/el-button.svg").default,
        cursor: require("../assets/svg/el-cursor.svg").default,
        touch: require("../assets/svg/el-touch.svg").default,

        speak: gender == 'male' ? require("../assets/svg/speak-male.svg").default : require("../assets/svg/speak-female.svg").default,

    }


    const toggle = (name) => dispatch({ type: 'scene-elem-toggle', name });



    const list = scene.elems.filter(({ name }) => name != 'bgimage').map(elem => ({ ...elem, toggle }));
    const speak = { name: 'speak', visible: scene.speak.enabled, type: 'speak', desc: 'Speak', toggle: () => dispatch({ type: 'scene-speak-update', param: { enabled: !scene.speak.enabled } }) };

    const listElems = [...list, speak].map(({ name, visible, type, desc, toggle }) =>
        <li
            key={name}
            className={classNames('w-full aspect-square bg-gray-50 border border-gray-200 select-none cursor-pointer hover:bg-gray-50 flex flex-col', visible && '!border-blue-500')}
            onMouseDown={(e) => { e.preventDefault(); toggle(name); }}>

            <div className="flex-1 flex flex-row items-center justify-center">
                <img className={classNames('h-5 filter-black', visible && '!filter-blue translate-y-px')}
                    src={img[type]} />
            </div>

            <div className={classNames('py-1 text-xs text-center opacity-50', visible && 'text-black !opacity-100', name == elem?.name && '!text-blue-500')}>
                {desc}
            </div>

        </li>
    );


    return (
        <ul className="mb-8 grid grid-cols-3 gap-0.5 justify-between">
            {listElems}
        </ul>
    );

};

const LayoutAccents = ({ layout }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { scene } = state;
    const { accents } = layout;

    const accent = scene.accent && accents.includes(scene.accent) ? scene.accent : accents[0];

    const [initialValue, setInitialValue] = useState(accent);

    const handleMouseEnter = (v) => dispatch({ type: 'scene-layout-accent', accent: v });
    const handleMouseLeave = () => dispatch({ type: 'scene-layout-accent', accent: initialValue });

    const listAccents = accents.map(color =>
        <li
            className={classNames('p-px border border-transparent', accent == color && scene.layout == layout.name && '!border-slate-800')}
            key={color}
            onMouseEnter={() => handleMouseEnter(color)}
            onMouseLeave={() => handleMouseLeave()}>

            <span className={classNames('block w-4 h-4', color)}></span>

        </li>
    )

    return (
        <ul className="flex flex-row items-center">
            {listAccents}
        </ul>
    )
}

const EditLayout = () => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { scene } = state;

    const l = Templates[scene.template].layouts.find(layout => layout.name == scene.layout);

    const options = [
        {
            name: `Apply theme to all '${Templates[scene.template].title}' scenes`,
            action: () => dispatch({ type: 'applytoall', target: 'layout' }),
        },

    ];

    const list = Templates[scene.template].layouts.map(layout => ({ value: layout.name, caption: layout.title, comp: layout.accents ? React.createElement(LayoutAccents, { key: layout.name, layout }) : null }));

    return (
        <ListWithOptions svg="list-layouts.svg" value={l.name} list={list} options={options} preview={true} change={((v) => dispatch({ type: 'scene-layout', layout: v }))} />
    )

};

const EditWait = () => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { scene } = state;

    const min = 0;
    const max = 10000;


    const options = [
        {
            name: `Apply duration to all '${Templates[scene.template].title}' scenes`,
            action: () => dispatch({ type: 'applytoall', target: 'wait' }),
        },

    ];


    const friendly = (v) => `+${v}s`;



    const range = [...Array(max / 1000 - min / 1000 + 1).keys()].map(i => i + min / 1000);

    const list = range.map(value => ({ value, caption: friendly(value) }));

    return (
        <ListWithOptions svg="list-duration.svg" value={scene.wait / 1000} list={list} options={options} change={((v) => dispatch({ type: 'scene-wait', wait: v * 1000 }))} />
    )

};




const EditProps = () => {


    return (
        <div className="relative bg-gray-100 ml-2 p-2 w-[16.5rem] overflow-x-clip flex flex-col" >

            <EditElems />

            <EditLayout />
            <EditWait />


            {/* todo: vezi cu snap ca la explorer */}

            {/* magic h-0 */}
            {/* 
            <div className="flex-1 overflow-y-auto scroll-smooth">
                <div className="flex flex-col h-0">
      
                </div>
            </div> 
            */}


        </div >
    );

};

const EditPlay = ({ status, duration, play, stop }) => {

    return (
        <div className="relative h-12 py-2 flex flex-row items-center" >

            <Play status={status} duration={duration} play={play} stop={stop} />

        </div >
    );

};

export const Controller = ({ motion }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, scene, preferences } = state;

    const scenes = file.content.scenes;
    const settings = file.settings;

    // const [paused, setPaused] = useState(false);


    const handleTogglePilot = () => {
        dispatch({ type: 'preferences-pilot-toggle' });
    }

    const handleToggleToc = () => {
        dispatch({ type: 'preferences-toc-toggle' });
    }

    const handleMode = (mode) => {
        dispatch({ type: 'file-settings-update', param: { mode } });
    }

    const handleGoTo = (scene) => {
        dispatch({ type: 'scene-set', scene });
    }



    //todo: mai implementezi? daca nu, scoate afara
    // const handlePaused = ({ paused }) => {

    //     if (paused) cacheAudioPause();
    //     setPaused(paused);

    // }



    useEffect(() => {

        emitter.on('toggle.pilot', handleTogglePilot);
        emitter.on('toggle.toc', handleToggleToc);
        emitter.on('mode', handleMode);

        emitter.on('goto', handleGoTo);

        // emitter.on('paused', handlePaused);

        return () => {

            emitter.off('toggle.pilot', handleTogglePilot);
            emitter.off('toggle.toc', handleToggleToc);
            emitter.off('mode', handleMode);

            emitter.off('goto', handleGoTo);

            // emitter.off('paused', handlePaused);

        }

    }, []);



    return (<EditCanvas preferences={preferences} settings={settings} scenes={scenes} scene={scene} motion={motion} paused={false} ring={true} pilot={true} toc={true} mode={true} />)

}


export const EditScene = () => {

    const state = useContext(StateContext);
    const { file, scene } = state;


    const [motion, setMotion] = useState(false);


    const { duration } = compute.calc(file, scene);



    return (
        <div className="flex-1 flex flex-row">

            <div className="flex-1 flex flex-col">

                <Controller motion={motion} />

                {!scene.speak.enabled && <EditPlay status={motion ? 'playing' : null} duration={duration} play={() => setMotion(true)} stop={() => setMotion(false)} />}

                {scene.speak.enabled && <EditTextToAudio status={motion ? 'playing' : null} duration={duration} play={() => setMotion(true)} stop={() => setMotion(false)} />}

            </div>

            <EditProps />

        </div >
    );

};

