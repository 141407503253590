import { useState } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import { useCredits, useViews } from "../hooks/subscribe";

import ToolbarShare from "./ToolbarShare";

import { Toggle } from "../ui/Toggles";

import Info from "../ui/Info";

import Tooltip from "../ui/Tooltip";


import classNames from "classnames";





const Views = ({ enabled, toggle }) => {

    const state = useContext(StateContext);

    const { file } = state;

    const views = useViews(file.id);

    const open = require("../assets/svg/eye.svg").default;
    const closed = require("../assets/svg/eye-no.svg").default;


    return (
        <div className="h-8 mt-4 px-1 py-2 flex flex-row items-center">

            <button
                className="relative group flex flex-row items-center justify-center"
                onClick={() => toggle()}>

                <img
                    className="h-4 group-hover:filter-blue"
                    src={enabled ? open : closed}
                />


                <Tooltip>Toggle sharing</Tooltip>
            </button>

            {views != undefined &&

                <div className="animate-fade relative group ml-4 text-sm text-gray-500 select-none cursor-default">
                    <span>{views}</span>

                    <Tooltip>Views</Tooltip>
                </div>
            }


        </div>
    );

};

const Box = ({ enabled, text, tooltip }) => {

    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        if (!isCopied) return;

        const timer = setTimeout(() => setIsCopied(false), 500);
        return () => clearTimeout(timer);

    }, [isCopied])

    const copy = () => {
        navigator.clipboard.writeText(text);
        setIsCopied(true);
    }



    return (
        <div className="mt-2 p-px bg-slate-200 flex flex-row">

            <div className={classNames('w-[20rem] px-2 py-1 bg-neutral-50 active:select-all flex flex-row', !enabled && 'text-gray-300 line-through !decoration-black pointer-events-none')}>
                <span className="text-sm text-gray-700 truncate text-clip">{text}</span>
            </div>

            <button
                className={classNames('relative group px-3 rounded hover:bg-slate-300 flex flex-row items-center justify-center', isCopied && 'pointer-events-none', !enabled && 'opacity-20 pointer-events-none')}
                onClick={() => copy()}>

                {!isCopied &&
                    <svg
                        className="w-4 h-4 group-active:translate-y-px"
                        width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 12.5001C17.5291 12.5021 16.234 12.5334 15.2301 13.045C14.2892 13.5243 13.5243 14.2892 13.045 15.2301C12.5 16.2996 12.5 17.6997 12.5 20.5V44.5C12.5 47.3002 12.5 48.7005 13.045 49.77C13.5243 50.7108 14.2892 51.4757 15.2301 51.955C16.2996 52.5 17.6997 52.5 20.5 52.5H39.5C42.3003 52.5 43.7005 52.5 44.77 51.955C45.7108 51.4757 46.4757 50.7108 46.955 49.77C47.5 48.7005 47.5 47.3002 47.5 44.5V20.5C47.5 17.6997 47.5 16.2996 46.955 15.2301C46.4757 14.2892 45.7108 13.5243 44.77 13.045C43.766 12.5334 42.471 12.5021 40 12.5001M20 12.5001V17.5H40V12.5001M20 12.5001V11.7678C20 10.6359 20.4496 9.55035 21.25 8.75C22.0504 7.94965 23.1359 7.5 24.2678 7.5H35.7323C36.864 7.5 37.9497 7.94965 38.75 8.75C39.5503 9.55035 40 10.6359 40 11.7678V12.5001" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                }

                {isCopied &&
                    <svg
                        className="animate-ping animate-once animate-duration-500 w-4 h-4"
                        width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5 30C5 16.1929 16.1929 5 30 5C43.807 5 55 16.1929 55 30C55 43.807 43.807 55 30 55C16.1929 55 5 43.807 5 30ZM39.2677 23.2322C40.244 24.2085 40.244 25.7915 39.2677 26.7677L30.0607 35.9748C28.6465 37.389 26.3535 37.389 24.9392 35.9748L20.7322 31.7677C19.7559 30.7915 19.7559 29.2085 20.7322 28.2323C21.7085 27.256 23.2915 27.256 24.2678 28.2323L27.5 31.4645L35.7323 23.2322C36.7085 22.2559 38.2915 22.2559 39.2677 23.2322Z" fill="#35A31A" />
                    </svg>
                }


                <Tooltip>{tooltip}</Tooltip>

            </button>

        </div>
    );

};

const Share = ({ onBack }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { user, file, subscription } = state;

    const { share } = file.settings;

    const credits = useCredits(user.uid, subscription?.metadata);

    const href = window.location.origin;
    const url = `${href}/play/${file.id}`;

    const iframe = `<iframe src="${url}" style="width:100%; height:100%; border:none;"></iframe>`;

    return (
        <>
            <ToolbarShare enabled={share.enabled} value={url} onBack={onBack} />

            <div className="flex-1 m-4 flex flex-col items-start">

                <Views enabled={share.enabled} toggle={() => dispatch({ type: 'file-settings-update', param: { share: { ...share, enabled: !share.enabled } } })} />

                <h1 className="mt-8 font-light text-sm">Share via public link</h1>
                <Box enabled={share.enabled} text={url} tooltip="Copy link to clipboard" />

                {credits['views'] != undefined && credits['views'] <= 0 &&
                    <p className="mt-2 text-sm text-red-500">
                        The user cannot access the video as there are no remaining 'views' credits.<br />Please check your subscription status.
                    </p>
                }

                {share.enabled &&
                    <>
                        <h1 className="mt-8 font-light text-sm">Embed in a web page</h1>
                        <Box enabled={share.enabled} text={iframe} tooltip="Copy code to clipboard" />
                    </>
                }

                {share.enabled &&
                    <>
                        <div className="mt-12 my-2 w-[20rem] h-px border-t border-dashed border-gray-300"></div>

                        <h1 className="font-light text-sm">Include</h1>

                        <div className="mt-4 flex flex-col space-y-2.5">
                            <Toggle key="pilot" name="Pilot" isDown={share.pilot} setIsDown={(v) => dispatch({ type: 'file-settings-update', param: { share: { ...share, pilot: v } } })} />
                            <Toggle key="toc" name="Table of contents" isDown={share.toc} setIsDown={(v) => dispatch({ type: 'file-settings-update', param: { share: { ...share, toc: v } } })} />

                            <div className="flex flex-row items-center space-x-6">
                                <Toggle key="translate" name="Auto-Translate" isDown={share.translate} setIsDown={(v) => dispatch({ type: 'file-settings-update', param: { share: { ...share, translate: v } } })} />
                                <Info className="!w-[20rem] !text-wrap" text="The video is automatically translated based on the user's browser settings. Additionally, the user has the option to choose the language." />
                            </div>

                        </div>
                    </>
                }

            </div>
        </>
    );

};

export default Share;
