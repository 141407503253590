import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';


import { Dialog } from "../dialogs";
import { ToggleSection } from '../ui/Toggles.js'

import DropZone from "../ui/DropZone";

import { uniqueId, actions } from "../editor/actions.js";

import { storageUploadFiles } from '../firebase/storage.js';
import { dbAddFile } from '../firebase/database.js';

import { RecoderFrame } from "../recorder/index.js";

import { addScenesFromCaptures } from "../recorder/index.js";

import classNames from "classnames";



const Card = ({ title, desc, selected, onSelect, children }) => {


    return (
        <div
            className={classNames('w-[calc(33%-0.5rem)] p-0 bg-white border-2 border-transparent rounded shadow-light hover:bg-neutral-50 flex flex-col', selected && '!border-red-500')}
            onMouseDown={onSelect}>

            <div className="flex-1 scale-50 flex flex-row items-center justify-center">{children}</div>
            <h1 className="py-1 font-semibold text-sm text-center">{title}</h1>
            <p className="min-h-[4rem] px-2 text-xs text-pretty text-gray-400">{desc}</p>

            <div className="flex p-2 flex-row justify-end">
                {!selected &&
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10" cy="10" r="10" fill="black" fillOpacity="0.1" />
                        <circle cx="9.75" cy="9.75" r="6.25" fill="white" />
                    </svg>



                }

                {selected &&
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10" cy="10" r="10" fill="#E94A47" fillOpacity="0.25" />
                        <circle cx="10" cy="10" r="6.25" fill="#E94A47" />
                        <path d="M9.0625 11.7456L13.2165 7.59153C13.3386 7.46949 13.5364 7.46949 13.6585 7.59153C13.7805 7.71357 13.7805 7.91143 13.6585 8.03347L9.28347 12.4085C9.16143 12.5305 8.96357 12.5305 8.84153 12.4085L6.96653 10.5335C6.84449 10.4114 6.84449 10.2136 6.96653 10.0915C7.08857 9.96949 7.28643 9.96949 7.40847 10.0915L9.0625 11.7456Z" fill="white" />
                    </svg>
                }


            </div>


        </div>
    );

};

const Page = ({ title, subtitle, children }) => {

    return (
        <div className="flex-1 flex flex-col">

            <h1 className="font-semibold text-pretty">{title}</h1>
            <p className="mt-1 text-xs text-gray-600">{subtitle}</p>

            {children}

        </div >
    );

};

const PageChoose = ({ option, setOption }) => {



    return (
        <Page title="How do you want to start?" subtitle="Kindly pick an option from below.">


            <div className="flex-1 py-8 flex flex-row justify-between">

                <Card key="blank" title="Blank" desc="Start by creating a blank movie. You can record your screen or import images later on" selected={option == 'blank'} onSelect={() => setOption('blank')}>
                    <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="11.6673" y1="48.4368" x2="48.4368" y2="11.6673" stroke="black" strokeWidth="3" />
                        <line x1="24.5668" y1="48.2653" x2="47.9013" y2="24.9307" stroke="black" strokeWidth="3" />
                        <line x1="11.8388" y1="35.5373" x2="35.1733" y2="12.2028" stroke="black" strokeWidth="3" />
                        <line x1="36.8804" y1="48.6795" x2="48.1941" y2="37.3658" stroke="black" strokeWidth="3" />
                        <line x1="11.4246" y1="23.2236" x2="22.7383" y2="11.9099" stroke="black" strokeWidth="3" />
                    </svg>

                </Card>

                <Card key="record" title="Record" desc="Start by recording the screen. Capture the steps you want to show in your demonstration." selected={option == 'record'} onSelect={() => setOption('record')}>
                    <svg width="53" height="47" viewBox="0 0 53 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="2" y="2" width="49" height="32" rx="6" stroke="black" strokeWidth="4" />
                        <line x1="8" y1="45.5" x2="43" y2="45.5" stroke="black" strokeWidth="3" />
                    </svg>

                </Card>

                <Card key="import" title="Import" desc="Start by importing images from your computer." selected={option == 'import'} onSelect={() => setOption('import')}>
                    <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.8876 28.2392C31.8416 28.1804 31.7828 28.1328 31.7156 28.1001C31.6485 28.0674 31.5747 28.0504 31.5 28.0504C31.4253 28.0504 31.3516 28.0674 31.2845 28.1001C31.2173 28.1328 31.1585 28.1804 31.1124 28.2392L24.2218 36.9571C24.165 37.0296 24.1298 37.1167 24.1201 37.2083C24.1104 37.3 24.1268 37.3925 24.1672 37.4753C24.2076 37.558 24.2705 37.6278 24.3487 37.6765C24.4269 37.7252 24.5173 37.751 24.6094 37.7508H29.156V52.664C29.156 52.9347 29.3775 53.1562 29.6482 53.1562H33.3396C33.6103 53.1562 33.8318 52.9347 33.8318 52.664V37.7569H38.3907C38.8029 37.7569 39.0305 37.2832 38.7783 36.9633L31.8876 28.2392Z" fill="black" />
                        <path d="M49.9201 22.5606C47.1023 15.1286 39.9226 9.84375 31.5123 9.84375C23.1021 9.84375 15.9223 15.1225 13.1045 22.5545C7.83193 23.9388 3.9375 28.7437 3.9375 34.4531C3.9375 41.2515 9.44385 46.7578 16.236 46.7578H18.7031C18.9738 46.7578 19.1953 46.5363 19.1953 46.2656V42.5742C19.1953 42.3035 18.9738 42.082 18.7031 42.082H16.236C14.1627 42.082 12.2124 41.2576 10.7604 39.7626C9.31465 38.2737 8.54561 36.2681 8.61328 34.1886C8.66865 32.5644 9.22236 31.0386 10.2252 29.7527C11.2526 28.4423 12.6923 27.4887 14.2919 27.0642L16.6236 26.4551L17.4788 24.2033C18.0079 22.8006 18.7462 21.4901 19.6752 20.3027C20.5923 19.1258 21.6787 18.0913 22.899 17.2327C25.4276 15.4547 28.4054 14.5134 31.5123 14.5134C34.6192 14.5134 37.597 15.4547 40.1256 17.2327C41.3499 18.094 42.4327 19.1276 43.3494 20.3027C44.2784 21.4901 45.0167 22.8067 45.5458 24.2033L46.3948 26.4489L48.7204 27.0642C52.055 27.9624 54.3867 30.9955 54.3867 34.4531C54.3867 36.4895 53.5931 38.4091 52.1534 39.8487C51.4474 40.5589 50.6075 41.1219 49.6824 41.5053C48.7573 41.8886 47.7653 42.0847 46.764 42.082H44.2969C44.0262 42.082 43.8047 42.3035 43.8047 42.5742V46.2656C43.8047 46.5363 44.0262 46.7578 44.2969 46.7578H46.764C53.5561 46.7578 59.0625 41.2515 59.0625 34.4531C59.0625 28.7499 55.1804 23.9511 49.9201 22.5606Z" fill="black" />
                    </svg>

                </Card>

            </div>


        </Page>
    );

};


const PageRecord = ({ back, next, refRecorder }) => {


    return (
        <Page title="Let's record the screen" subtitle="Capture the steps you want to show in your demonstration.">

            <RecoderFrame cancel={back} done={(name, captures) => next({ name, captures })} ref={refRecorder} />

        </Page>
    );

};


const PageImport = ({ next }) => {

    const max = 20;
    const canPaste = false;
    const text = "Drag your images here to craft new scenes.";

    const onDropFiles = (files) => {
        const name = files.length > 1 ? `${files[0].name} + ${files.length - 1}` : files[0].name;
        next({ name, files });
    }

    return (
        <Page title="Let's import images" subtitle="Create new scenes by dropping your images here.">


            <div className="flex-1 py-4 flex flex-col">

                <div className="group flex-1 relative bg-pattern-diagonal">
                    <DropZone max={max} canPaste={canPaste} execute={onDropFiles} >
                        <img
                            className="h-8 opacity-60 transition-all filter-black group-hover:!opacity-100"
                            src={require("../assets/svg/image-upload.svg").default}
                        />
                        <p className="mt-4 text-xs">{text}</p>
                        {canPaste &&
                            <>
                                <p className="text-sm">or</p>
                                <p>CTRL-V</p>
                            </>
                        }

                    </DropZone>
                </div>

            </div>

        </Page>
    );

};

const PageEnterName = ({ name, setName, intro, setIntro, outro, setOutro, next }) => {

    const handleKeyDown = (e) => {
        if (e.keyCode == 13) {
            next();
        }
    }

    return (
        <Page title="Almost ready" subtitle="Let's come up with a friendly name for your upcoming movie.">

            <input className="appearance-none w-[40%] mt-6 px-0 py-1 font-light bg-white text-sm border-b border-gray-200 transition-colors focus:outline-none focus:border-blue-500 placeholder:text-gray-300"
                placeholder="untitled"
                type="text"
                maxLength="60"
                required={true}
                // autoComplete="off"
                spellCheck={false}
                autoFocus={true}
                value={name}
                onFocus={(e) => e.target.select()}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={handleKeyDown} />


            <h1 className="mt-8 font-light text-sm">Include</h1>

            <div className="mt-4 w-40 flex flex-col space-y-3">
                <ToggleSection key="intro" name="Intro" isDown={intro} setIsDown={setIntro} />
                <ToggleSection key="outro" name="Outro" isDown={outro} setIsDown={setOutro} />
            </div>

        </Page>
    );

};



const CreateNewMovie = ({ close }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { user, folder, preferences } = state;

    const initialParams = { name: '', intro: false, outro: false };

    const [step, setStep] = useState(1);
    const [option, setOption] = useState('blank');
    const [params, setParams] = useState(initialParams);


    const [isCreating, setIsCreating] = useState(false);

    const refRecorder = useRef(null);

    const handleClose = () => {

        if (step == 2 && option == 'record') return;
        if (step == 3 && option == 'record') return

        if (isCreating) return;

        close();
    }



    const create = async () => {

        const name = params.name || 'untitled';

        const doc = await dbAddFile(user.uid, name, folder?.id);
        if (!doc) return;

        dispatch({ type: 'file-add', file: doc });

        document.title = name;

        return doc.id;
    }

    const createBlankMovie = async () => {

        actions.addSceneBlank(preferences, dispatch);

        return true;

    }

    const createMovieFromRecording = async (fileId) => {

        return await addScenesFromCaptures(preferences, dispatch, user.uid, fileId, params.captures, undefined);

    }

    const createMovieFromImages = async (fileId) => {

        const arr = params.files.map(f => { const id = uniqueId(); return { ...f, id, path: `users/${user.uid}/${fileId}/${id}.${f.ext}` } });

        const result = await storageUploadFiles(arr);
        if (!result) return;

        const fs = result.map(({ name, id, naturalWidth, naturalHeight, url }) => ({ name, id, naturalWidth, naturalHeight, url }));
        actions.addScenesFromImages(preferences, dispatch, undefined, fs);


        return true;

    }

    const handleNext = () => setStep(prev => prev == 1 ? option == 'blank' ? 3 : 2 : 3)


    const handleBack = () => {

        if (step == 2 && option == 'record') refRecorder.current.close();

        setParams(initialParams);
        setStep(1);
    }

    const handleCreate = async () => {

        setIsCreating(true);

        // await new Promise(r => setTimeout(r, 5300));

        const id = await create();
        if (!id) { setIsCreating(false); return; }

        let result;

        switch (option) {
            case 'blank':
                result = await createBlankMovie();
                break;
            case 'record':
                result = await createMovieFromRecording(id);
                break;
            case 'import':
                result = await createMovieFromImages(id);
                break;

        };

        if (params.intro) actions.addSceneFromTemplate(preferences, dispatch, undefined, 'intro');
        if (params.outro) actions.addSceneFromTemplate(preferences, dispatch, undefined, 'outro');


        dispatch({ type: 'scenes-unselect' });


        setIsCreating(false);

        if (result) close(id);


    }

    return (

        <Dialog
            className="w-[40rem] h-[28rem] !p-8 select-none"
            close={handleClose}>


            {step == 1 && <PageChoose option={option} setOption={setOption} />}
            {step == 2 && option == 'record' && <PageRecord back={() => handleBack()} next={({ name, captures }) => { setParams(prev => ({ ...prev, name, captures })); handleNext(); }} refRecorder={refRecorder} />}
            {step == 2 && option == 'import' && <PageImport next={({ name, files }) => { setParams(prev => ({ ...prev, name, files })); handleNext(); }} />}

            {step == 3 && <PageEnterName
                name={params.name}
                setName={(v) => setParams(prev => ({ ...prev, name: v }))}

                intro={params.intro}
                setIntro={(v) => setParams(prev => ({ ...prev, intro: v }))}

                outro={params.outro}
                setOutro={(v) => setParams(prev => ({ ...prev, outro: v }))}

                next={handleCreate} />}



            <div className="flex flex-row items-center justify-end">
                {[2, 3].includes(step) &&
                    <button
                        className="mr-2 px-4 py-2 bg-gray-400 opacity-90 text-white rounded shadow-light hover:!opacity-100 active:translate-y-px"
                        onClick={() => handleBack()}>

                        <svg
                            className="rotate-180"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.2929 13H7.5C7.22386 13 7 12.7761 7 12.5C7 12.2239 7.22386 12 7.5 12H17.2929L14.1464 8.85355C13.9512 8.65829 13.9512 8.34171 14.1464 8.14645C14.3417 7.95118 14.6583 7.95118 14.8536 8.14645L18.8536 12.1464C19.0488 12.3417 19.0488 12.6583 18.8536 12.8536L14.8536 16.8536C14.6583 17.0488 14.3417 17.0488 14.1464 16.8536C13.9512 16.6583 13.9512 16.3417 14.1464 16.1464L17.2929 13Z" fill="white" />
                        </svg>
                    </button>}


                {step == 1 &&
                    <button
                        className="px-4 py-2 bg-red-500 opacity-90 text-white rounded shadow-light flex flex-row items-center space-x-1 hover:!opacity-100 active:translate-y-px"
                        onClick={() => handleNext()}>
                        <span>Next</span>

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.2929 13H7.5C7.22386 13 7 12.7761 7 12.5C7 12.2239 7.22386 12 7.5 12H17.2929L14.1464 8.85355C13.9512 8.65829 13.9512 8.34171 14.1464 8.14645C14.3417 7.95118 14.6583 7.95118 14.8536 8.14645L18.8536 12.1464C19.0488 12.3417 19.0488 12.6583 18.8536 12.8536L14.8536 16.8536C14.6583 17.0488 14.3417 17.0488 14.1464 16.8536C13.9512 16.6583 13.9512 16.3417 14.1464 16.1464L17.2929 13Z" fill="white" />
                        </svg>
                    </button>}

                {step == 3 &&
                    <button
                        className="px-4 py-2 bg-red-500 opacity-90 text-white rounded shadow-light flex flex-row items-center space-x-2 hover:!opacity-100 active:translate-y-px"
                        onClick={() => handleCreate()}>
                        {isCreating && <span className="animate-spin w-2 h-2 bg-white"></span>}
                        <span>Create</span>

                    </button>}

            </div>


            {/* do not allow click  */}
            {isCreating && <div className="absolute z-50 left-0 top-0 right-0 bottom-0"></div>}

        </Dialog>

    );

};

export default CreateNewMovie;
