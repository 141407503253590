import { useEffect, useState } from "react";


import { auth, googleProvider } from "../config/firebase";

import {
    signInWithPopup,
    isSignInWithEmailLink,
    signInWithEmailLink,
    sendSignInLinkToEmail,
} from "firebase/auth";



import classNames from "classnames";



const GoogleProvider = () => {

    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
        } catch (err) {
            console.error(err);
        }
    };


    return (
        <button
            className="group w-full px-2 py-2 bg-white rounded shadow flex flex-row items-center space-x-2"
            onClick={() => signInWithGoogle()}>
            <img
                className="w-4 h-4"
                src={require("../assets/svg/login-google.svg").default} />

            <span className="text-sm group-active:translate-y-px">Continue with Google</span>
        </button>
    )

}


const Page1 = ({ description, submit }) => {

    const [email, setEmail] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const [isUpdating, setIsUpdating] = useState(false);

    const isValidEmail = (v) => v.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    const handleClick = async () => {
        if (!isValidEmail(email)) return;

        setIsUpdating(true);

        const result = await submit(email);

        setIsUpdating(false);
    }

    const handleKeyDown = (e) => { if (e.keyCode == 13) handleClick(); }

    const valid = isValidEmail(email);

    return (
        <>
            <p className="mt-8 text-sm text-gray-700">{description}</p>

            <div className={classNames('mt-12 bg-white border border-gray-200 rounded flex flex-row items-center space-x-2', !email.length && isFocused && '!border-blue-300', email.length && !valid && '!border-red-300', email.length && valid && '!border-green-500')}>

                <div className="bg-slate-800 p-2 rounded-l flex flex-row items-center justify-center">
                    <img
                        className="w-4 h-4 filter-white"
                        src={require("../assets/svg/login-email.svg").default} />

                </div>

                <input
                    className="appearance-none flex-1 text-sm focus:outline-none placeholder:font-light placeholder:text-gray-400"
                    placeholder="yours@example.com"
                    type="email"
                    maxLength="60"
                    required={true}
                    autoComplete="email"
                    spellCheck={false}
                    // autoFocus={true}
                    value={email}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                />

            </div>


            <button
                className={classNames('group w-full mt-4 py-2 bg-slate-700 rounded flex flex-row items-center justify-center space-x-2', !valid && 'pointer-events-none')}
                onClick={() => handleClick()}>

                {isUpdating && <span className="animate-spin w-2 h-2 bg-white"></span>}
                <span className={classNames('text-sm text-white opacity-80 group-active:translate-y-px', valid && '!opacity-100')}>Continue</span>
            </button>

            {isUpdating && <div className="fixed left-0 top-0 right-0 bottom-0 z-[9999]"></div>}

        </>
    )

}

const Page2 = ({ back }) => {

    return (
        <>
            <p className="text-sm">Your login link awaits in your inbox! Simply give it a click to get started.</p>
            <button
                className="group mt-8 flex flex-row items-center justify-center"
                onClick={() => back()}>
                <span className="text-sm text-blue-500 group-active:translate-y-px">Back</span>
            </button>
        </>
    )

}


const Passwordless = ({ alone }) => {

    const [index, setIndex] = useState(0);


    const sendLink = async (email) => {

        const actionCodeSettings = {
            url: window.location.href,
            handleCodeInApp: true,//must be true
        };

        try {
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            window.localStorage.setItem('emailForSignIn', email);

            return true;

        } catch (err) {
            console.log(err);

        }

        return false;

    }

    const handleSubmit = async (email) => {

        const result = await sendLink(email);

        if (result) {
            setIndex(1);
            alone(true);
        };

        return result;

    }

    const handleBack = () => {
        setIndex(0);
        alone(false);
    }


    return index == 0 ? <Page1 description="Type your email for passwordless login, and expect to receive a link via email." submit={(email) => handleSubmit(email)} /> : <Page2 back={handleBack} />;

}





const EmailLink = ({ email }) => {

    const [error, setError] = useState(null);

    const checkLink = async (email) => {
        setError(null);

        try {
            const result = await signInWithEmailLink(auth, email, window.location.href);

            window.localStorage.removeItem('emailForSignIn');
            history.replaceState({}, document.title, '/');

            return true;

        } catch (err) {
            console.log(err);
            setError('Oops! Something went wrong.');

        }

        return false;

    }

    const handleSubmit = async (email) => {

        const result = await checkLink(email);

        return result;

    }


    useEffect(() => { if (email) checkLink(email); }, []);


    return (
        <>
            {!email && <Page1 description="Please provide your email for confirmation." submit={(email) => handleSubmit(email)} />}
            {email && !error && <div className="flex flex-row items-center justify-center"><span className="animate-spin w-2 h-2 bg-black"></span></div>}

            {error && <div className="mt-8 flex flex-row items-center justify-center"><span className="text-sm text-red-500">{error}</span></div>}
        </>
    )

}



const Login = () => {

    const [passwordlessWantsAlone, setPasswordlessWantsAlone] = useState(false);

    const hasEmailLink = isSignInWithEmailLink(auth, window.location.href);
    const email = window.localStorage.getItem('emailForSignIn');

    return (
        <div className="w-full h-full flex flex-col items-center">

            <div className="relative mt-[20vh] w-72">

                <div className="mb-16 flex flex-row items-center justify-center">
                    <a href="https://www.demo-builder.com">
                        <img
                            className="w-36"
                            src={require("../assets/svg/logo.svg").default} />
                    </a>
                </div>


                {hasEmailLink && <EmailLink email={email} />}

                {!hasEmailLink && !passwordlessWantsAlone &&
                    <>

                        <GoogleProvider />

                        <div className="relative mt-8 flex">
                            <div className="w-full h-px bg-gray-300"></div>
                            <div className="absolute left-1/2 top-1/2 px-2 text-sm bg-[#f7f7f7] -translate-x-1/2 -translate-y-1/2">or</div>
                            <div></div>
                        </div>

                    </>

                }

                {!hasEmailLink && <Passwordless alone={(v) => setPasswordlessWantsAlone(v)} />}


            </div>


        </div>
    );
};


export default Login;