import { useEffect } from "react";

import { auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";

import { APP_NAME } from "../utils/consts";


// https://github.com/machadop1407/react-firebase-course/blob/main/src/components/auth.js
// https://firebase.google.com/docs/auth/web/anonymous-auth?authuser=0#web-modular-api_1



export const useAuthStateChanged = (dispatch) => {

  useEffect(() => {

    onAuthStateChanged(auth, async (u) => {
      if (u) {

        const { uid, displayName, email, photoURL } = u;

        dispatch({ type: 'login', user: { uid, displayName, email, photoURL } });

        history.replaceState({}, document.title, '/');


      } else {

        dispatch({ type: 'logout' });

        document.title = APP_NAME;

      }
    });


  }, []);

};



