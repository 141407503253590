
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import { Dialog } from "../dialogs";

import { fnGoodbye } from "../firebase/functions";

import classNames from "classnames";


const PageProfile = ({ change }) => {

    const state = useContext(StateContext);
    const { photoURL, displayName, email } = state.user;


    return (
        <>
            <div className="flex-1 flex flex-col">
                <h1 className="font-semibold text-center">Profile</h1>

                {displayName &&
                    <div className="mt-4">
                        <h1 className="font-light">Name</h1>
                        <p>{displayName}</p>
                    </div>
                }

                <div className="mt-4">
                    <h1 className="font-light">Email</h1>
                    <p>{email}</p>
                </div>

            </div>

            <button
                className="mt-4 group px-2 py-2 bg-red-500 rounded flex flex-row items-center justify-center space-x-2"
                onClick={() => change('delete-account')}>
                <span className="text-sm text-white group-active:translate-y-px">Delete account</span>
            </button>


        </>
    )
}



const PageDeleteAccount = ({ change }) => {

    const word = 'delete';

    const [text, setText] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);

    const confirmDelete = async () => {

        window.prevent = true;
        setIsUpdating(true);

        const result = await fnGoodbye();
        if (result) location.reload();

        setIsUpdating(false);
        window.prevent = false;

    }

    return (

        <>

            <div className="flex-1 flex flex-col">
                <h1 className="font-semibold text-center">Delete account</h1>
                <p className="mt-4">This action is irreversible. All associated data and subscriptions will be permanently erased upon account deletion.</p>

                <p className="mt-8">To confirm deletion, type '{word}' below.</p>

                <input
                    className="appearance-none mt-4 w-[50%] px-1 py-1 bg-white text-sm text-red-500 border-b-2 border-gray-200 focus:outline-none focus:border-blue-500 placeholder:italic placeholder:text-gray-400"
                    placeholder=""
                    type="text"
                    maxLength="50"
                    required={true}
                    // autoComplete="off"
                    spellCheck={false}
                    autoFocus={true}
                    value={text}
                    onChange={(e) => setText(e.target.value)} />


            </div>

            <div className="grid grid-cols-2 gap-1">

                <button
                    className="mt-4 group px-2 py-2 bg-gray-500 rounded flex flex-row items-center justify-center space-x-2"
                    onClick={() => change('profile')}>
                    <span className="text-sm text-white group-active:translate-y-px">Cancel</span>
                </button>

                <button
                    className={classNames('mt-4 group px-2 py-2 bg-red-500 rounded flex flex-row items-center justify-center space-x-2', text != word && 'opacity-60 pointer-events-none')}
                    onClick={() => confirmDelete()}>
                    {isUpdating && <span className="animate-spin w-2 h-2 bg-white"></span>}
                    <span className="text-sm text-white group-active:translate-y-px">Confirm</span>
                </button>

            </div>

            {isUpdating && <div className="fixed left-0 top-0 right-0 bottom-0 z-[9999]"></div>}
        </>

    )
}


const Profile = ({ close }) => {

    const [page, setPage] = useState('profile');


    return (
        <Dialog
            className="w-[26rem] h-[28rem] !p-8 select-none flex flex-col"
            close={close}>

            {page == 'profile' && <PageProfile change={(v) => setPage(v)} />}
            {page == 'delete-account' && <PageDeleteAccount change={(v) => setPage(v)} />}

        </Dialog>
    )

}


export default Profile;