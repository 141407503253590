import { useState } from "react";
import { useEffect } from 'react';
import { useRef } from 'react';

import { ButtonWithModal } from "./Buttons";
import MoreOptions from "./MoreOptions";

import classNames from 'classnames';



const ListWithOptions = ({ svg, className, value, list, options, preview, change }) => {

    const images = require.context('../assets/svg/', false);
    const icon = svg ? images(`./${svg}`) : null;


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [initialValue, setInitialValue] = useState(null);


    useEffect(() => {
        if (isModalVisible) setInitialValue(value)
        else setInitialValue(null);
    }, [isModalVisible])

    const handleClick = (v) => {
        change(v);
        setIsModalVisible(false);
    };

    const handleMouseEnter = (v) => {
        if (preview) change(v);
    };

    const handleMouseLeave = () => {
        if (preview) change(initialValue);
    };


    const caption = list.find(item => item.value == value).caption;

    const listList = list.map(({ value, caption, comp }) =>
        <li
            key={value}
            className={classNames('px-2 py-1 cursor-pointer hover:bg-blue-50 flex flex-row items-center', value == initialValue && '!bg-blue-200')}
            onClick={() => handleClick(value)}
            onMouseEnter={() => handleMouseEnter(value)}
            onMouseLeave={() => handleMouseLeave()}>

            <span className="flex-1 text-sm">{caption}</span>

            {comp}
        </li>
    );


    return (
        <div className={classNames('relative mt-2 bg-gray-50 transition-colors rounded hover:bg-gray-200', isModalVisible && '!bg-gray-200')}>

            <ButtonWithModal className="pr-6" place="bl" same={true} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>
                <button className="group w-full pl-2 pr-1 py-2 flex flex-row items-center space-x-2">

                    {icon &&
                        <img
                            className={classNames('opacity-80 transition-opacity group-hover:!opacity-100', className)}
                            src={icon} />
                    }


                    <span
                        className={classNames('flex-1 text-sm text-left text-nowrap opacity-70 transition-opacity overflow-hidden group-hover:!opacity-100', isModalVisible && '!opacity-100')}>
                        {caption}
                    </span>

                    <svg
                        className={classNames(isModalVisible && 'translate-y-px')}
                        width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.25 0.5H12.5V2.25H0.25V0.5Z" fill="#2E3436" />
                        <path d="M0.25 4.875H12.5V6.625H0.25V4.875Z" fill="#2E3436" />
                        <path d="M0.25 9.25H12.5V11H0.25V9.25Z" fill="#2E3436" />
                    </svg>

                </button>

                <ul className="max-h-[23rem] w-full p-1 bg-white overflow-y-auto shadow-light">
                    {listList}
                </ul>

            </ButtonWithModal>

            <MoreOptions className="!absolute right-0 top-0 bottom-0 w-6" light={true} options={options} />


        </div>
    );

};


export default ListWithOptions;