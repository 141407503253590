import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import Templates from '../templates/';

import Elems from "./Elems";

import { PropStatic } from "./Props";

import MoreOptions from "../ui/MoreOptions";

import { ButtonFlatIcon } from "../ui/Buttons";
import { InputDialog, ConfirmDialog } from '../dialogs/';

import { actions } from "./actions";

import { startFrom } from "./utils";

import classNames from "classnames";



const SceneElemsHelper = () => {
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { scene } = state;

    const isEqual = (a, b) => JSON.stringify(a.sort()) === JSON.stringify(b.sort());

    const helper = [
        {
            title: 'Follow me',
            names: ['focus1'],
        },
        {
            title: 'Show & Tell',
            names: ['text1', 'arrow1', 'cursor1'],
        },
    ]

    const select = (names) => dispatch({ type: 'scene-elems-visible', names: [...names, 'bgimage'] });
    const match = (names) => isEqual([...names, 'bgimage'], scene.elems.filter(elem => elem.visible).map(({ name }) => name));

    const listHelper = helper.map(({ title, names }) =>
        <span
            className={classNames('text-[0.7rem] uppercase text-white opacity-20 transition-opacity cursor-pointer hover:opacity-100', match(names) && '!opacity-100')}
            key={title}
            onClick={() => select(names)}>

            {title}

        </span>)

    return (
        <div className="h-full flex flex-row items-center justify-center space-x-2">

            {listHelper}

        </div>
    );

};



const ToolbarScene = ({ onBack }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, scene, elem, preferences } = state;

    const [inputDialogParams, setInputDialogParams] = useState(null);
    const [confirmDialogParams, setConfirmDialogParams] = useState(null);


    const index = file.content.scenes.findIndex(l => l.id == scene.id);

    const renameScene = () => {

        setInputDialogParams({
            label: 'Type new scene name',
            value: scene.name,
            allowEmpty: true,
            button: 'Rename',
            execute: async (v) => {

                if (typeof v != 'string') return true;//can rename to blank

                dispatch({ type: 'scene-rename', name: v });

                return true;

            },
            close: () => setInputDialogParams(null)
        })

    }

    const deleteScene = () => {

        setConfirmDialogParams({
            // sender: sender,
            label: `Are you sure you want to delete ${scene.name ? '\'' + scene.name + '\'' : 'this scene'}?`,
            button: 'Yes, please delete it',
            execute: async () => {

                dispatch({ type: 'scene-delete' });

                return true;
            },
            close: () => setConfirmDialogParams(null)

        });

    }

    const addSceneBlank = () => {
        const newScene = actions.addSceneBlank(preferences, dispatch, index + 1);

        dispatch({ type: 'scene-set', scene: JSON.parse(JSON.stringify(newScene)) });

    }

    const addSceneFromTemplate = (option) => {
        const newScene = actions.addSceneFromTemplate(preferences, dispatch, index + 1, option.tag);

        dispatch({ type: 'scene-set', scene: JSON.parse(JSON.stringify(newScene)) });
    }

    const duplicateScene = () => {

        const copyScene = JSON.parse(JSON.stringify(scene));

        let delta = 1;

        if (copyScene.template == 'bg-image') {
            copyScene.speak.text = '';

            const elems = copyScene.elems.filter(elem => ['cursor1', 'touch1'].includes(elem.name));

            const some = elems.some(elem => elem.visible);
            if (some) delta = 0;

            elems.forEach(elem => elem.visible = false);
        }

        // const newScene = actions.duplicateScene(dispatch, index + 1, copyScene);
        const newScene = actions.duplicateScene(dispatch, index + delta, copyScene);

        dispatch({ type: 'scene-set', scene: JSON.parse(JSON.stringify(newScene)) });

    }


    const options = [
        {
            name: scene.visible ? 'Hide' : 'Show',
            action: () => dispatch({ type: 'scene-toggle' }),
            delimiter: true,
        },
        {
            name: 'Rename',
            action: () => renameScene(),
        },
        {
            name: 'Delete',
            action: () => deleteScene(),
        },
        {
            name: 'Duplicate',
            action: () => duplicateScene(),
            delimiter: true,
        },
        {
            name: 'Insert scene',
            children: [
                {
                    name: 'Blank',
                    action: () => addSceneBlank(),
                    delimiter: true
                },
            ],
        },

    ];

    const T = Object.keys(Templates).filter(key => key != 'bg-image');

    T.forEach(key => options[4].children.push({ name: Templates[key].title, tag: key, action: (option) => addSceneFromTemplate(option) }))



    const adopt = scene.template == 'bg-image' ? Templates['bg-image'].layouts.find(({ name }) => name == scene.layout).adopt : undefined;

    const props = elem ? React.createElement(scene.template == 'bg-image' && (!adopt || !adopt.includes(elem.name)) ? Elems[elem.type].prop : PropStatic, {
        key: elem.name,
        ...elem,
    }) : null

    // const props = elem ? React.createElement(Elems[elem.type].prop, {
    //     key: elem.name,
    //     ...elem,    
    // }) : null



    if (!scene) { onBack(); return; };//delete, then there are no scenes


    return (
        <>
            <div className="toolbar">

                <div className="h-full pr-0 border-r border-slate-600 flex flex-row items-center space-x-1">

                    <ButtonFlatIcon className="h-4" svg="back.svg" click={onBack} />

                    <div className="w-32 h-full ml-1">

                        <div className="relative h-full inline-block">
                            <div
                                className={classNames('h-full max-w-28 opacity-80 cursor-pointer flex flex-row items-center',
                                    !scene.visible && '!opacity-20',
                                    scene.visible && 'underline underline-offset-4 decoration decoration-transparent hover:!opacity-100 hover:!decoration-red-500')}
                                onClick={() => renameScene()}>

                                <span className="text-sm text-white first-letter:uppercase truncate"> {scene.name ? scene.name : index - startFrom(file.content.scenes, index)}</span>

                            </div>

                            {!scene.visible && <div className="absolute left-0 right-0 top-1/2 h-px bg-red-500 pointer-events-none"></div>}
                        </div>

                    </div>

                    <ButtonFlatIcon svg="duplicate.svg" tooltip="Smart duplicate scene" click={duplicateScene} />


                    <MoreOptions options={options} />

                </div>

                <div className="flex-1 h-full flex flex-row items-center">
                    {elem && props}
                </div>

                <div className="ml-2 w-[16.5rem] h-full">
                    {scene.template == 'bg-image' && <SceneElemsHelper />}
                </div>


            </div>

            {/* dialogs */}

            {inputDialogParams && <InputDialog params={inputDialogParams} />}
            {confirmDialogParams && <ConfirmDialog params={confirmDialogParams} />}
        </>
    );

};



export default ToolbarScene;
