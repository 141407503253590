

import { usePlatformVersion } from "../hooks/subscribe";


import classNames from "classnames";




//collection 'platform' must exist
//Create 'platform' collection, with key 'version' and field 'latest'

//To update platform, must do two things:
//1. update const variable below
//2. update firebase > collection platform > session > latest with the same name as const



export const RUNNING_VERSION = 'Leonidas-M5';


const Platform = () => {

    const version = usePlatformVersion(RUNNING_VERSION);
    // console.log('version from server: ', version);

    const handleClick = () => {
        location.reload();
    }

    if (version == RUNNING_VERSION) return;

    return (
        <button
            className="relative group !flex flex-row items-center justify-center'"
            onClick={() => handleClick()}>

            <img
                className="animate-pulse w-6 h-6 filter-black group-hover:!animate-none group-active:translate-y-px"
                src={require("../assets/svg/platform-update.svg").default} />


            <span className="absolute left-1/2 top-0 z-[9999] px-2 py-1 bg-yellow-400 opacity-0 rounded pointer-events-none transition-opacity delay-74 duration-200 shadow -translate-x-1/2 -translate-y-[calc(100%+0.3rem)] group-hover:!opacity-100"><span className="text-sm text-nowrap">Platform update available. Refresh.</span></span>

        </button>
    )

}

export default Platform;