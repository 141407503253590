import { useState } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import Templates from '../templates/';

import { Record } from "../recorder/index.js";
import { UploadImages } from "./Upload.js";

import { ButtonFlatIcon, ButtonWithModal } from '../ui/Buttons.js';

import { actions } from './actions.js';


import classNames from "classnames";

const ToolbarThumbnails = ({ onPlay, onShare, onExport }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, preferences } = state;

    const { share } = file.settings;

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [showRecord, setShowRecord] = useState(false);
    const [showUploadImages, setShowUploadImages] = useState(false);



    const isEmpty = !file.content.scenes.length;

    const addSceneBlank = () => {
        actions.addSceneBlank(preferences, dispatch);
        setIsModalVisible(false);
    }

    const record = () => {

        setShowRecord(true);
        setIsModalVisible(false);

    }

    const importImages = () => {

        setShowUploadImages(true);
        setIsModalVisible(false);

    }

    const addSceneFromTemplate = (key) => {
        actions.addSceneFromTemplate(preferences, dispatch, undefined, key);
        setIsModalVisible(false);
    }

    const T = Object.keys(Templates).filter(key => key != 'bg-image');

    const listTemplates = T.map(key =>
        <li
            key={key}
            className="context-menu-item"
            onClick={() => addSceneFromTemplate(key)}>
            <span>{Templates[key].title}</span>
        </li>
    );

    return (
        <>
            <div className="toolbar">

                <ButtonWithModal className="h-full" place="bl" isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>

                    <ButtonFlatIcon classNameGroup={classNames(isModalVisible && '!bg-slate-600')} className={classNames('h-4', isModalVisible && 'translate-y-px')} svg="thumbnails-plus.svg" tooltip="Add new scene" />

                    <ul className="context-menu">

                        <li
                            className="context-menu-item"
                            onClick={addSceneBlank}>
                            <span>Blank</span>
                        </li>
                        <li
                            className="context-menu-item"
                            onClick={record}>
                            <span>Record</span>
                        </li>
                        <li
                            className="context-menu-item delimiter"
                            onClick={importImages}>
                            <span>Import</span>
                        </li>


                        {listTemplates}

                    </ul>

                </ButtonWithModal>

                <ButtonFlatIcon className="h-4" svg="thumbnails-play.svg" disabled={isEmpty} tooltip="Preview" click={onPlay} />

                <ButtonFlatIcon classNameGroup="ml-8" disabled={isEmpty} click={onShare}>
                    <span
                        className={classNames('px-4 py-1 bg-blue-500 text-xs text-white uppercase rounded-full hover:!bg-blue-600 active:translate-y-px', !share.enabled && '!text-gray-400 line-through !decoration-red-500')}>Share</span>
                </ButtonFlatIcon>

                <ButtonFlatIcon classNameGroup="ml-3" disabled={isEmpty} tooltip="Export" click={onExport}>
                    <span className="group p-1 border-2 border-transparent rounded-full hover:!border-blue-500 active:translate-y-px">
                        <svg
                            className="w-5 h-5 transition-transform group-hover:scale-110"
                            width="39" height="30" viewBox="0 0 39 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M34.8037 7.43299C34.3755 6.79279 33.7043 6.3485 32.9376 6.19783C32.1709 6.04716 31.3715 6.20246 30.7152 6.62957L23.4712 11.3509L7.87446 9.93958L4.79055 12.6294L17.3034 16.7306L11.1356 22.1102L3.10426 20.5352L1.79524 22.7495L10.7583 27.8018L34.0363 11.3991C34.6608 10.9587 35.0897 10.2995 35.2328 9.55983C35.3759 8.82021 35.222 8.05785 34.8037 7.43299Z" fill="white" />
                        </svg>
                    </span>
                </ButtonFlatIcon>


            </div >

            {showRecord && <Record close={() => setShowRecord(false)} />}
            {showUploadImages && <UploadImages close={() => setShowUploadImages(false)} />}
        </>
    );

};

export default ToolbarThumbnails;