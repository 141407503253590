import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

import { ElemStatic, ElemEditableStaticText } from "../editor/Elems";

import { getTextColor } from "../utils";
import { ACCENTS_SUBTITLE, ACCENTS_GLOW, ACCENTS_MOBILE, circleGradient } from "../utils/consts";

import emitter from "../emmiter";

import classNames from "classnames";



const useRefObserveDimensions = (ref) => {
    const [dimensions, setDimensions] = useState(null);
    useEffect(() => {
        if (!ref?.current) return;

        const handleResize = () => {
            if (ref?.current) {
                const { left, top, width, height } = ref.current.getBoundingClientRect();
                setDimensions({ left, top, width, height });
            }
        };

        handleResize();

        const resizeObserver = new ResizeObserver(() => { handleResize(); });
        resizeObserver.observe(ref.current);

        return () => {
            resizeObserver.disconnect();
        };

    }, [ref])

    return dimensions;
};

const useRefOutfit = (ref, bgimage, t, b, l, r) => {

    const dimensions = useRefObserveDimensions(ref);

    const m = 4;
    const min = 0.1;
    const max = 1;
    const scale = dimensions ? Math.min(Math.max(Math.min((dimensions.width - m) / bgimage.crop.width, (dimensions.height - m) / bgimage.crop.height), min), max) : 1;


    const style = () => {
        if (!dimensions) return null;

        const rem = 16;

        const mt = t * rem;
        const mb = b * rem;
        const ml = l * rem;
        const mr = r * rem;

        return {
            'left': `${(dimensions.width - bgimage.crop.width * scale) / 2 - ml}px`,
            'top': `${(dimensions.height - bgimage.crop.height * scale) / 2 - mt}px`,
            'width': `${bgimage.crop.width * scale + ml + mr}px`,
            'height': `${bgimage.crop.height * scale + mt + mb}px`,

        }
    }

    return style();
}


const calcMockupScale = (dimensions1, dimensions2, dimensions3, tw, th, margin, min, max) => {

    let lScale = 1;

    if (dimensions1 && dimensions2 && dimensions3) {
        lScale = Math.min((dimensions1.width - margin) / dimensions2.width, (dimensions1.height - margin) / dimensions2.height);

        if (tw && th) {
            const ld = Math.min(dimensions3.width / tw, dimensions3.height / th);
            if (1 / ld < lScale) lScale = 1 / ld;
        };

        lScale = Math.max(lScale, min);
        lScale = Math.min(lScale, max);

    };


    return lScale;
};

const LayoutMockUp = ({ motion, elems, status, children }) => {

    const refContainer = useRef(null);
    const dimensionsContainer = useRefObserveDimensions(refContainer);

    const naturalDimensionsMockup = { width: Number(children.props.width), height: Number(children.props.height) };//svg

    const m = 16 * 1.5;
    const display = children.props.children.find(l => l.props['data-target'] == 'display');
    const naturalDimensionsMockupDisplay = { x: Number(display.props.x) + m / 2, y: Number(display.props.y) + m / 2, width: Number(display.props.width) - m, height: Number(display.props.height) - m };


    const scaleMockup = calcMockupScale(dimensionsContainer, naturalDimensionsMockup, naturalDimensionsMockupDisplay, elems?.bgimage.crop.width, elems?.bgimage.crop.height, 16 * 8, 0.01, 10);



    const styleMockup = () => {
        if (!(dimensionsContainer)) return;

        return {
            'left': `${(dimensionsContainer.width - naturalDimensionsMockup.width * scaleMockup) / 2}px`,
            'top': `${(dimensionsContainer.height - naturalDimensionsMockup.height * scaleMockup) / 2}px`,
            'width': `${naturalDimensionsMockup.width * scaleMockup}px`,
            'height': `${naturalDimensionsMockup.height * scaleMockup}px`,
        }
    }

    const styleBgImage = () => {
        if (!dimensionsContainer) return;

        return {
            'left': `${(dimensionsContainer.width - naturalDimensionsMockup.width * scaleMockup) / 2 + naturalDimensionsMockupDisplay.x * scaleMockup}px`,
            'top': `${(dimensionsContainer.height - naturalDimensionsMockup.height * scaleMockup) / 2 + naturalDimensionsMockupDisplay.y * scaleMockup}px`,
            'width': `${naturalDimensionsMockupDisplay.width * scaleMockup}px`,
            'height': `${naturalDimensionsMockupDisplay.height * scaleMockup}px`,
        }

    }





    return (
        <div
            ref={refContainer}
            className={classNames('relative flex-1', status == 'first' && 'animate-fade-up animate-duration-500')}>

            {dimensionsContainer &&

                <>
                    <div
                        className="absolute"
                        style={styleMockup()}>
                        {children}
                    </div>

                    <div
                        className="absolute"
                        style={styleBgImage()}>
                        {elems.bgimage.content}
                    </div>
                </>

            }

        </div>
    )

}



export const Layout100 = ({ motion, elems, status }) => {

    //todo: responsive + see other layouts
    //https://tailwindcss.com/docs/responsive-design
    //tailwind is mobile first
    //e.g. add to first div md:m-[5rem]


    return (
        <div className={classNames('absolute left-6 top-6 right-6 bottom-6 shadow-md-bg', status == 'first' && 'animate-fade animate-duration-500')}>
            {elems.bgimage.content}
        </div>
    )
}

export const Layout101 = ({ motion, elems, status, scene }) => {

    const ref = useRef(null);

    const accent = scene.accent && ACCENTS_SUBTITLE.includes(scene.accent) ? scene.accent : ACCENTS_SUBTITLE[0];

    const style = useRefOutfit(ref, elems.bgimage, 0, 0, 0, 0);

    const handleContinue = () => {
        if (!motion) return;
        emitter.emit('interact.continue');
    }


    return (

        <>
            <div className={classNames('absolute left-0 right-0 bottom-0 h-1/2 bg-slate-900', status == 'first' && 'animate-fade-up animate-duration-500')}>
                <div className={classNames('absolute left-0 right-0 h-2', accent)}></div>
            </div>

            <div
                ref={ref}
                className={classNames('absolute left-12 top-12 right-12 bottom-[10rem]', status == 'first' && 'animate-fade-down animate-duration-500 animate-delay-200')}>

                {style &&

                    <div
                        className="absolute shadow-lg"
                        style={style}>


                        <div className="absolute left-1/2 bottom-0 z-50 w-[70vw] -translate-x-1/2 translate-y-[calc(100%+2rem)] flex flex-col items-center">

                            <ElemStatic className="w-full" props={elems.text1}>

                                <ElemEditableStaticText className="text-white" motion={motion} line={false} props={elems.text1} />

                            </ElemStatic>

                            {elems.button1 &&
                                <ElemStatic props={elems.button1}>

                                    <div
                                        className={classNames('group inline-block mt-8 px-6 py-2 border border-orange-500 ', motion && 'cursor-pointer')}
                                        data-ignore="outside"
                                        onClick={() => handleContinue()}>
                                        <ElemEditableStaticText className={classNames('text-white', motion && 'group-active:translate-y-px')} motion={motion} line={true} autoWidth={true} props={elems.button1} />
                                    </div>

                                </ElemStatic>
                            }

                        </div>


                    </div>

                }

                {elems.bgimage.content}

            </div>
        </>


    )
}

export const Layout102 = ({ motion, elems, status, scene }) => {

    const ref = useRef(null);

    const accent = scene.accent && ACCENTS_GLOW.includes(scene.accent) ? scene.accent : ACCENTS_GLOW[0];

    const style = useRefOutfit(ref, elems.bgimage, 0.1, 0.1, 0.1, 0.1);

    return (
        <div
            ref={ref}
            className={classNames('absolute left-12 top-12 right-12 bottom-12', status == 'first' && 'animate-fade-up animate-duration-500')}>

            {style &&
                <div
                    className={classNames('absolute blur-3xl rounded-xl', accent, motion && 'animate-tilt animate-infinite absolute')}
                    style={style}>
                </div>
            }

            {elems.bgimage.content}

        </div>
    )
}

export const Layout103 = ({ motion, elems, status }) => {

    const ref = useRef(null);

    const style = useRefOutfit(ref, elems.bgimage, 1.5, 1.5, 1.5, 1.5);

    return (
        <div
            ref={ref}
            className={classNames('absolute left-12 top-24 right-12 bottom-48', status == 'first' && 'animate-fade-up animate-duration-500')}>

            {style &&

                <div
                    className="absolute"
                    style={style}>

                    <div className="hidden absolute left-0 right-0 bottom-2 h-28 p-4 bg-gray-200 shadow dark:shadow-white translate-y-full lg:flex flex-row items-center justify-end">
                        <img
                            className="h-4 filter-neutral drop-shadow"
                            src={require("../assets/svg/logo.svg").default} />
                    </div>
                    <div className="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-br from-neutral-100 to-neutral-200 border-2 border-zinc-200 rounded-md shadow-sm shadow-gray-400"></div>

                </div>

            }

            {elems.bgimage.content}

        </div>
    )
}


export const Layout200 = ({ motion, elems, status }) => {

    const ref = useRef(null);

    const style = useRefOutfit(ref, elems.bgimage, 5.5, 0.5, 0.5, 0.5);


    // gradient generator
    // https://hypercolor.dev/generator



    return (
        <div
            ref={ref}
            className={classNames('absolute left-12 top-36 right-12 bottom-12', status == 'first' && 'animate-fade-up')}>

            {style &&

                <div
                    className="absolute shadow-lg flex flex-col"
                    style={style}>

                    <div className="bg-[#dfe1e5] rounded-tl-md rounded-tr-md flex flex-row">
                        <div className="px-4 bg-[#dfe1e5] rounded-tl-md rounded-br-md flex flex-row items-center space-x-2">
                            <span className="w-3 h-3 bg-red-400 rounded-full"></span>
                            <span className="w-3 h-3 bg-yellow-200 rounded-full"></span>
                            <span className="w-3 h-3 bg-green-300 rounded-full"></span>
                        </div>

                        <div className="bg-[#dfe1e5]">
                            <div className="h-2"></div>
                            <div className="pl-3 h-8 w-[5rem] sm:w-[10rem] bg-white rounded-tl-md rounded-tr-md flex flex-row items-center">
                                <div className="w-3/4 h-1/4 bg-gradient-to-r from-neutral-100 to-neutral-200 rounded-full">
                                </div>
                            </div>
                        </div>

                        <div className="flex-1 bg-[#dfe1e5] rounded-bl-md rounded-tr-md"></div>
                    </div>


                    <div className="flex-1 bg-white rounded-bl-md rounded-br-md">
                        <div className="px-4 py-3 border-b border-b-neutral-120 flex flex-row items-center space-x-5">
                            <img
                                className="h-3 filter-black opacity-20"
                                src={require("../assets/svg/browser-back.svg").default}
                            />
                            <img
                                className="h-3 filter-black opacity-20 rotate-180"
                                src={require("../assets/svg/browser-back.svg").default}
                            />
                            <img
                                className="h-4 filter-black opacity-20"
                                src={require("../assets/svg/browser-refresh.svg").default}
                            />

                            <div className="flex-1 h-3 bg-gradient-to-r from-neutral-100 to-neutral-200 rounded-full"></div>
                            <div className="w-3 h-3 bg-neutral-200 rounded-full"></div>
                        </div>

                    </div>

                </div>

            }

            {elems.bgimage.content}

        </div>
    )
}


export const Layout300 = ({ motion, elems, status }) => {

    return (
        <LayoutMockUp motion={motion} elems={elems} status={status}>
            <svg
                className="w-full h-full drop-shadow-lg"
                width="801" height="674" viewBox="0 0 801 674" fill="none" xmlns="http://www.w3.org/2000/svg">

                <path d="M0.396011 468.377H801V537.389C801 543.147 796.333 547.814 790.576 547.814H10.8205C5.06322 547.814 0.396011 543.147 0.396011 537.389V468.377Z" fill="#ECEDEF" />
                <path d="M0 7.29718C0 3.26707 3.26706 0 7.29717 0H793.307C797.337 0 800.604 3.26706 800.604 7.29717V468.378H0V7.29718Z" fill="#F5F5F5" />
                <path d="M19.4807 18.9077H781.519V449.202H19.4807V18.9077Z" fill="#BEBFC3" />

                <rect
                    data-target="display"
                    x="21.1996" y="20.6266" width="758.957" height="427.041"
                    fill="#BCBCBC" />

                <rect x="305.388" y="547.751" width="189.65" height="118.03" fill="#E1E1E1" />
                <rect x="305.388" y="665.208" width="189.65" height="8.02146" fill="#ECEDEF" />
            </svg>
        </LayoutMockUp>
    )
}


export const Layout400 = ({ motion, elems, status }) => {

    return (
        <LayoutMockUp motion={motion} elems={elems} status={status}>
            <svg
                className="w-full h-full drop-shadow-lg"
                width="711" height="382" viewBox="0 0 711 382" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="83.0702" width="544.941" height="367.408" rx="26" fill="#F5F5F5" />
                <rect x="85.4437" y="2.37343" width="539.719" height="362.186" rx="23" fill="#ECEDEF" />

                <rect
                    data-target="display"
                    x="109.653" y="26.1078" width="491.301" height="307.597"
                    fill="#BCBCBC" />

                <path d="M0 365.56C0 365.007 0.447715 364.56 1 364.56H709.606C710.159 364.56 710.606 365.007 710.606 365.56V366.933H0V365.56Z" fill="#F5F5F5" />
                <path d="M0 366.933H710.606C676.799 376.808 646.856 378.964 588.137 381.174H124.843C73.5879 379.769 46.2516 376.616 0 366.933Z" fill="#ECEDEF" />
                <path d="M295.255 364.56H414.402V365.832C414.402 367.489 413.059 368.832 411.402 368.832H298.255C296.598 368.832 295.255 367.489 295.255 365.832V364.56Z" fill="#D0D0D0" />
                <path d="M409.655 364.56H414.402V365.832C414.402 367.489 413.059 368.832 411.402 368.832H409.655V364.56Z" fill="#E2E2E2" />
                <path d="M300.002 364.56H295.255V365.832C295.255 367.489 296.598 368.832 298.255 368.832H300.002V364.56Z" fill="#E2E2E2" />
            </svg>
        </LayoutMockUp>
    )
}


export const Layout500 = ({ motion, elems, status, scene }) => {

    const ref = useRef(null);

    const accent = scene.accent && ACCENTS_MOBILE.includes(scene.accent) ? scene.accent : ACCENTS_MOBILE[0];

    const textColor = getTextColor(accent);
    const gradient = circleGradient(accent);

    const style = useRefOutfit(ref, elems.bgimage, 0.8, 0.8, 0.8, 0.8);

    const handleContinue = () => {
        if (!motion) return;
        emitter.emit('interact.continue');
    }

    //https://tailwindcomponents.com/gradient-generator/


    return (
        <div
            ref={ref}
            className={classNames('absolute left-12 top-[6rem] right-12 bottom-[10rem]', status == 'first' && 'animate-fade-up')}>

            <div
                className={classNames('absolute left-1/2 top-1/2 h-3/4 aspect-square bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] shadow-2xl rounded-full -translate-x-1/2 -translate-y-1/2', gradient, elems.bgimage.crop.width > elems.bgimage.crop.height && 'hidden')}>
            </div>


            {style &&
                <>
                    <div
                        className="absolute bg-gradient-to-br from-zinc-600 via-zinc-700 to-zinc-900 rounded-xl shadow-lg"
                        style={style}>

                        <div className="absolute left-1/2 bottom-0 z-50 w-[70vw] -translate-x-1/2 translate-y-[calc(100%+2rem)] flex flex-col items-center">

                            <ElemStatic className="w-full" props={elems.text1}>

                                <ElemEditableStaticText className="dark:text-white" motion={motion} line={false} props={elems.text1} />

                            </ElemStatic>

                            {elems.button1 &&
                                <ElemStatic props={elems.button1}>

                                    <div
                                        className={classNames('group inline-block mt-6 px-6 py-2 rounded shadow', accent, motion && 'cursor-pointer')}
                                        data-ignore="outside"
                                        onClick={() => handleContinue()}>
                                        <ElemEditableStaticText className={classNames(textColor, motion && 'group-active:translate-y-px')} motion={motion} line={true} autoWidth={true} props={elems.button1} />
                                    </div>

                                </ElemStatic>
                            }

                        </div>
                    </div>

                </>

            }


            {elems.bgimage.content}

        </div>
    )


}


