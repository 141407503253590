

import { ButtonFlatIcon } from "../ui/Buttons";
import Title from "../ui/Title";

const ToolbarPreview = ({ loading, onBack, onReplay, onEdit }) => {

    return (
        <div className="toolbar">


            <ButtonFlatIcon className="h-4" svg="back.svg" click={onBack} />

            <span className="h-full w-px bg-slate-600"></span>

            <ButtonFlatIcon className="h-4" svg="preview-replay.svg" disabled={loading} tooltip="Replay" click={onReplay} />
            <ButtonFlatIcon className="h-4" svg="preview-edit.svg" disabled={loading} tooltip="Edit current scene" click={onEdit} />

            <Title name="Preview" />


        </div>
    );

};

export default ToolbarPreview;