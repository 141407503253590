
import { Bezier } from "bezier-js";

import { trig } from ".";

// https://pomax.github.io/bezierjs/

const getQuadraticAngle = (t, sx, sy, cp1x, cp1y, ex, ey) => {
    let dx = 2 * (1 - t) * (cp1x - sx) + 2 * t * (ex - cp1x);
    let dy = 2 * (1 - t) * (cp1y - sy) + 2 * t * (ey - cp1y);
    return -Math.atan2(dx, dy) + 0.5 * Math.PI;
}

const getMiddlePoint = (point1, point2, width, height, path) => {


    if (!path) return point2;

    const d = trig.distance(point2, point1) / 3;
    const angle = trig.angle(point2, point1);

    const v = 25 * Math.sign(point2.x - point1.x);

    let c = trig.pointOnCircle(d, angle + v);

    if (point2.x + c.x < 0) c = trig.pointOnCircle(d, angle - v);
    else if (point2.y + c.y < 0) c = trig.pointOnCircle(d, angle - v);
    else if (point2.x + c.x > width) c = trig.pointOnCircle(d, angle - v);
    else if (point2.y + c.y > height) c = trig.pointOnCircle(d, angle - v);

    c.x = point2.x + c.x;
    c.y = point2.y + c.y;


    return c;
}

export const drawCursorPath = (canvas, point1, point2, path) => {

    const ctx = canvas.getContext('2d');

    const c = getMiddlePoint(point1, point2, canvas.width, canvas.height, path);



    ctx.clearRect(0, 0, canvas.width, canvas.height);



    // draw path 

    ctx.strokeStyle = 'black';
    ctx.lineWidth = 3;
    ctx.fillStyle = 'white';

    ctx.beginPath();
    ctx.moveTo(point1.x, point1.y);
    ctx.quadraticCurveTo(c.x, c.y, point2.x, point2.y);
    ctx.stroke();


    // draw arrow

    const m = 6;

    const curve = new Bezier(point1.x, point1.y, c.x, c.y, point2.x, point2.y);

    const l = curve.length();

    if (l < 2 * m) return;

    const coord = curve.get(0.8);
    const angle = getQuadraticAngle(0.8, point1.x, point1.y, c.x, c.y, point2.x, point2.y);


    ctx.beginPath();
    ctx.translate(coord.x, coord.y);
    ctx.rotate(angle);
    ctx.moveTo(-m, -m);
    ctx.lineTo(m, 0);
    ctx.lineTo(-m, m);
    ctx.closePath();

    ctx.stroke();
    ctx.fill();

    // ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.resetTransform();


}


export const computeCursor = (point1, point2, w, h, path, progress) => {

    const c = getMiddlePoint(point1, point2, w, h, path);
    const curve = new Bezier(point1.x, point1.y, c.x, c.y, point2.x, point2.y);

    const coord = curve.get(progress);

    return coord;

    // return { x: Math.floor(coord.x), y: Math.floor(coord.y) };

}