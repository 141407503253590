import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";


export const useRefObserveDimensions = (ref) => {
    const [dimensions, setDimensions] = useState(null);
    useEffect(() => {
        if (!ref?.current) return;

        const handleResize = () => {
            if (ref?.current) {
                const { left, top, width, height } = ref.current.getBoundingClientRect();
                setDimensions({ left, top, width, height });
            }
        };

        handleResize();

        const resizeObserver = new ResizeObserver(() => { handleResize(); });
        resizeObserver.observe(ref.current);

        return () => {
            resizeObserver.disconnect();
        };

    }, [ref])

    return dimensions;
};


export const useRefBoundingRect = (ref, dep = []) => {
    const [dimensions, setDimensions] = useState(null);

    useEffect(() => {
        if (!ref?.current) return;

        const { left, top, width, height } = ref.current.getBoundingClientRect();
        setDimensions({ left, top, width, height })

    }, [ref, ...dep])

    return dimensions;
}

export const useClickOutside = (condition, refs = [], selectors, click) => {

    useEffect(() => {
        if (!condition) return;

        const handleClickOutside = (e) => {
            const inside = refs.some(ref => ref.current && ref.current.contains(e.target));

            // const ignore = e.target.closest('[data-ignore="outside"]');
            const ignore = selectors ? e.target.closest(selectors) : false; 

            if (!inside && !ignore) {
                // e.preventDefault();
                // e.stopPropagation();
                click();
            };

        };

        document.addEventListener('mousedown', handleClickOutside, true);
        document.addEventListener('wheel', handleClickOutside, true);
        document.addEventListener('contextmenu', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
            document.removeEventListener('wheel', handleClickOutside, true);
            document.removeEventListener('contextmenu', handleClickOutside, true);
        };
    }, [condition]);


}


export const useDrag = (isDragging, move, up, cleanup, condition) => {

    useEffect(() => {
        const handleMouseMove = (e) => {
            e.stopPropagation();
            move(e.clientX, e.clientY);
        };

        const handleMouseUp = (e) => {
            e.stopPropagation();
            up(e.clientX, e.clientY);
        };

        if (!isDragging) return;

        if (condition === false) {
            // console.log('drag cannot start, condition not satisfied');
            return;
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            if (cleanup) cleanup();

            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

    }, [isDragging]);

}


