import { useEffect } from 'react';

import { useDropzone } from 'react-dropzone';


import { compress } from '../utils/compress';


import classNames from "classnames";


const usePaste = (canPaste, execute) => {

    useEffect(() => {
        if (!canPaste) return;

        const handlePaste = (event) => {
            const clipboardItems = event.clipboardData.items;
            const items = [].slice.call(clipboardItems).filter((item) => {
                return item.type.indexOf('image') !== -1;
            });
            if (items.length === 0) {
                return;
            }

            const item = items[0];
            const blob = item.getAsFile();

            execute(blob);

        };
        document.addEventListener('paste', handlePaste, true);
        return () => {
            document.removeEventListener('paste', handlePaste, true);
        };
    }, [canPaste]);

}


const DropZone = ({ max = 1, noClick = false, canPaste = true, execute, children }) => {

    const onDropAccepted = async (files) => {
        // console.log('files: ', files);

        const promises = files.map(file => compress(file));

        try {
            const arr = await Promise.all(promises);
            execute(arr);
        }
        catch (err) {
            console.log(err);
        }
    }

    // https://react-dropzone.js.org/

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDropAccepted,
        maxFiles: max,
        multiple: max > 1,
        noClick,
        accept: {
            'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.webp']

        }
    });



    usePaste(canPaste, (blob) => onDropAccepted([blob]));



    return (
        <div {...getRootProps()}
            className={classNames('absolute left-0 top-0 right-0 bottom-0 text-gray-500 text-sm text-center focus:outline-none flex flex-col items-center justify-center', isDragActive && 'border-2 border-green-300', !noClick && 'cursor-pointer')}>
            <input {...getInputProps()} />

            {children}

            {isDragActive}

        </div>
    );

};

export default DropZone;