
import { useRef, useState } from "react";
import { useReducer } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { useImmerReducer } from 'use-immer';

import { BrowserRouter, Routes, Route } from "react-router-dom";


import { StateContext, DispatchContext } from './context/';
import { initialState, reducer } from './reducer.js';

import { useAuthStateChanged } from './auth/';

import { dbUpdateFile } from './firebase/database.js';
import { fnGetVoices, fnGetLanguages } from './firebase/functions.js';


import { usePlans, useSubscription } from "./hooks/subscribe.js";

import Layout from "./layout/index.js";
import Player from "./player/";

import { Folder, Workspace } from "./view/index.js";




const useVoices = (dispatch) => {

  useEffect(() => {

    const load = async () => {
      const response = await fnGetVoices();
      if (!response) return;

      dispatch({ type: 'voices-set', voices: response });
    }

    load();

  }, []);

}

const useLanguages = (dispatch) => {

  useEffect(() => {

    const load = async () => {
      const response = await fnGetLanguages();
      if (!response) return;

      dispatch({ type: 'languages-set', languages: response });
    }

    load();

  }, []);

}

const useSave = (temp) => {
  const [debounced, setDebounced] = useState(null);

  const last = useRef(null);

  const DEBOUNCE_TIMEOUT = 1000;


  const save = async (file) => {

    const clone = JSON.parse(JSON.stringify(file));

    delete clone.selected;
    clone.content.scenes.forEach(scene => delete scene.selected);

    const l = JSON.stringify(clone);

    if (last.current == l) return;

    await dbUpdateFile(file.id, file.settings, file.content);

    last.current = l;

    // console.log('file saved', file.name);
  }

  useEffect(() => {
    if (!temp) return;

    if (!last.current || JSON.parse(last.current).id != temp.id) {
      save(temp);
      return;
    }

    const timeout = setTimeout(() => setDebounced(temp), DEBOUNCE_TIMEOUT);
    return () => clearTimeout(timeout);

  }, [temp]);



  useEffect(() => { if (!debounced) return; save(debounced); }, [debounced]);

}


const Hero = () => {

  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const { user, temp } = state;

  useAuthStateChanged(dispatch);

  useVoices(dispatch);
  useLanguages(dispatch);

  useSave(temp);


  usePlans(dispatch);
  useSubscription(user?.uid, dispatch);

  return <Layout />
}




function App() {
  const [state, dispatch] = useImmerReducer(reducer, initialState);


  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/thankyou" element={<Hero />} />

            <Route path="/play/:id" element={<Player />} />
            
            <Route path="/folder/:id" element={<Folder />} />
            <Route path="/workspace/:id" element={<Workspace />} />

          </Routes>
        </BrowserRouter>

      </StateContext.Provider>
    </DispatchContext.Provider>
  )

}

export default App;
