import { useState } from "react";
import { useEffect } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import { useCredits } from "../hooks/subscribe";

import { fnCreatePortalLink, fnStopPendingCancellation } from "../firebase/functions";

import Manage from "./Manage";

import Info from "../ui/Info";

import { Dialog } from "../dialogs"

import { prettify, k, audioCharsDuration } from "./utils";

import moment from "moment";


import classNames from "classnames"


// using firebase extension
//https://console.firebase.google.com/u/0/project/demobuilder-d24ed/extensions/instances/firestore-stripe-payments?tab=usage



export const Plan = () => {

    const state = useContext(StateContext);

    const { plans, subscription } = state;

    const product = plans.find(({ id }) => id == subscription?.product);

    if (!product) return;

    return (
        <div className="flex-1 pl-2 text-sm opacity-50">
            {product ? product.name : ''}
        </div>
    )

}


const Row = ({ className, caption, a, classNameA, actionA, isUpdatingA, b, classNameB, actionB, isUpdatingB, col3, children }) => {

    const handleClickA = () => {
        if (isUpdatingA) return;
        if (actionA) actionA();
    }
    const handleClickB = () => {
        if (isUpdatingB) return;
        if (actionB) actionB();
    }

    return (
        <div className={classNames('py-0.5 border-b border-b-gray-100 grid grid-cols-2', className)}>
            <div className="p-1 flex flex-row items-center"><span className="text-sm">{caption}</span></div>

            <div className={classNames(col3 && 'grid grid-cols-2 gap-1')}>

                <div className="h-full flex flex-row items-center space-x-2">
                    <div
                        className={classNames('group p-1 flex flex-row items-center justify-start space-x-2', classNameA, actionA && 'cursor-pointer', actionA && isUpdatingA && 'pointer-events-none')}
                        onClick={() => handleClickA()}>
                        {isUpdatingA && <span className="animate-spin w-2 h-2 bg-white"></span>}
                        <span className={classNames('font-light text-sm text-nowrap', actionA && '!text-white group-active:translate-y-px')}>{a}</span>
                    </div>

                    {children && <div>{children}</div>}
                </div>

                <div
                    className={classNames('group p-1 flex flex-row items-center justify-end space-x-2', classNameB, actionB && 'cursor-pointer', actionB && isUpdatingB && 'pointer-events-none')}
                    onClick={() => handleClickB()}>
                    {isUpdatingB && <span className="animate-spin w-2 h-2 bg-white"></span>}
                    <span className={classNames('text-sm text-gray-500', actionB && '!text-white group-active:translate-y-px')}>{b}</span>
                </div>

            </div>

        </div>
    )

}



const Status = () => {

    const state = useContext(StateContext);

    const { user, plans, subscription } = state;

    const [isUpdating, setIsUpdating] = useState(false);

    const credits = useCredits(user.uid, subscription?.metadata);

    const product = plans.find(({ id }) => id == subscription?.product);

    const stopPendingCancellation = async () => {

        window.prevent = true;
        setIsUpdating(true);

        await fnStopPendingCancellation(subscription.id);

        setIsUpdating(false);
        window.prevent = false;

    }


    const diff = (v) => {
        const today = moment(new Date());
        const target = moment(v);
        return moment(target).diff(today, 'days');
    }

    const nextInvoiceIn = subscription ? `${diff(subscription.current_period_end.toDate())} days` : null;
    const nextInvoice = subscription ? `$${Math.round((subscription.unit_amount) / 100)} US` : null;


    const metadata = subscription ? subscription.metadata : null;

    const pretty = prettify(credits);

    const listCredits = Object.keys(pretty).map(key =>
        <Row key={key}
            className={classNames(credits[key] != undefined && credits[key] <= 0 && 'bg-red-200')}
            caption={pretty[key].caption}
            a={pretty[key].value}
            b={metadata ? metadata[key] ? k(metadata[key]) : 'unlimited' : null}
            col3={subscription}>
            {credits[key] != undefined && key == 'tts' && <Info text={audioCharsDuration(credits[key])} />}
        </Row>
    );

    return (
        <>
            <div className="flex-1 mx-2 flex flex-col">

                <Row caption="Subscribed to" a={product ? product.name : '-'} col3={subscription} />

                {subscription && <Row caption="Subscription status" a={subscription.status} classNameA={classNames(subscription.status == 'active' && 'bg-green-100 rounded !justify-center')} col3={subscription} />}

                {subscription && !subscription.cancel_at_period_end && <Row caption="Next invoice in" a={nextInvoiceIn} col3={subscription} />}
                {subscription && !subscription.cancel_at_period_end && <Row caption="Next invoice" a={nextInvoice} col3={subscription} />}


                {subscription && subscription.cancel_at_period_end && <Row caption="Will be canceled in" a={nextInvoiceIn} classNameA="" b="Stop" classNameB="bg-red-500 rounded !justify-center" actionB={stopPendingCancellation} isUpdatingB={isUpdating} col3={subscription} />}



                <div className="mt-8"></div>

                <Row a="Remaining" classNameA="opacity-50" b={subscription ? `Total(${subscription.interval})` : null} classNameB="opacity-50" col3={subscription} />


                {listCredits}



            </div>

            {isUpdating && <div className="fixed left-0 top-0 right-0 bottom-0 z-[9999]"></div>}
        </>
    )


}

const CustomerPortal = () => {

    const state = useContext(StateContext);

    const { subscription } = state;


    const [isUpdating, setIsUpdating] = useState(false);


    const redirectToCustomerPortal = async () => {

        window.prevent = true;
        setIsUpdating(true);

        const url = await fnCreatePortalLink();

        if (url) window.location.assign(url);

        setIsUpdating(false);
        window.prevent = false;

    }


    if (!subscription) return;


    return (
        <>
            <button
                className={classNames('group mt-2 mb-5 flex flex-row items-center space-x-2', isUpdating && 'pointer-events-none')}
                onClick={() => redirectToCustomerPortal()}>

                {!isUpdating &&
                    <svg
                        className="w-2 h-2"
                        width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.5978 28.0356L27.5978 3.03564M27.5978 3.03564H5.0978M27.5978 3.03564V25.5356" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                }

                {isUpdating && <span className="animate-spin w-2 h-2 bg-black"></span>}

                <p className="text-sm group-hover:text-blue-500">Manage subscription + invoices</p>

            </button>

            {isUpdating && <div className="fixed left-0 top-0 right-0 bottom-0 z-[9999]"></div>}
        </>
    )

}


export const Subscription = ({ close }) => {

    const state = useContext(StateContext);

    const { subscription } = state;

    const [showManage, setShowManage] = useState(false);


    return (
        <>
            <Dialog
                className="w-[28rem] h-[36rem] !py-8 !px-10 select-none flex flex-col"
                close={close}>

                <Status />
                <CustomerPortal />

                <button
                    className="group px-4 py-2 bg-blue-500 rounded flex flex-row items-center justify-center"
                    onClick={() => setShowManage(true)}>
                    <span className="text-sm text-white group-active:translate-y-px">{subscription ? 'Change plan' : 'Subscribe'}</span>
                </button>

            </Dialog>

            {showManage && <Manage close={() => setShowManage(false)} />}

        </>
    )

}

