import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useRef } from 'react';
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import Templates from '../templates/';

import { storageUploadFile } from "../firebase/storage.js";

import { UploadImages } from "./Upload.js";

import { InputDialog, ConfirmDialog } from '../dialogs/';

import ContextMenu from '../ui/ContextMenu.js';

import { actions } from './actions.js';

import { useDrag } from "../hooks/index.js";

import { startFrom } from "./utils.js";

import { trig } from "../utils/index.js";

import { uniqueId } from "./actions.js";

import { Record } from "../recorder/index.js";



import classNames from 'classnames';


const useRefObserveDimensions = (ref, isDragging, index) => {
    
    const [dimensions, setDimensions] = useState(null);

    useEffect(() => {
        if (!ref?.current) return;


        const update = () => {
            if (ref?.current && !isDragging) {
                const { left, top, width, height } = ref.current.getBoundingClientRect();
                setDimensions({ left, top, width, height });
            }
        }

        update();

        const handleResize = () => update();

        const resizeObserver = new ResizeObserver(() => { handleResize(); });
        resizeObserver.observe(ref.current.parentElement);

        const handleScroll = () => update();
        document.addEventListener('scroll', handleScroll, true);

        return () => {
            resizeObserver.disconnect();

            document.removeEventListener('scroll', handleScroll, true);
        };

    }, [ref, isDragging, index])

    return dimensions;
};


const Elements = ({ scene }) => {

    const state = useContext(StateContext);

    const { voices } = state;

    const voice = voices.find(({ voice_id }) => voice_id == scene.speak.voice_id);
    const gender = voice ? voice.labels?.gender : 'male';

    const img = {

        focus: require("../assets/svg/el-focus.svg").default,
        arrow: require("../assets/svg/el-arrow.svg").default,
        text: require("../assets/svg/el-text.svg").default,
        button: require("../assets/svg/el-button.svg").default,
        cursor: require("../assets/svg/el-cursor.svg").default,
        touch: require("../assets/svg/el-touch.svg").default,

        speak: gender == 'male' ? require("../assets/svg/speak-male.svg").default : require("../assets/svg/speak-female.svg").default,

    }

    let list = scene.elems.filter(elem => elem.name != 'bgimage' && elem.visible).map(elem => ({ name: elem.name, type: elem.type, interactive: elem.type == 'button' || (elem.type == 'focus' && elem.pause) }));

    if (scene.speak.enabled && scene.speak.text) list = [{ name: 'speak', type: 'speak', interactive: false }, ...list];

    const listElems = list.map(({ name, type, interactive }) =>
        <div
            className="p-1 bg-gray-200 rounded"
            key={name}>
            <img
                className={classNames('w-3 h-3', interactive ? 'filter-red' : 'filter-neutral')}
                src={img[type]}
            />
        </div>
    )


    return (
        <div className="px-1.5 py-1 flex flex-row items-center space-x-0.5">
            {listElems}
        </div>
    )

}



const Thumb = ({ scene, startFrom, index, onToggle, dragInfo, isDragging, onStartDrag, onDragging, onEndDrag, onSelect, onEdit, onContextMenu }) => {

    const ref = useRef(null);
    const dimensions = useRefObserveDimensions(ref, isDragging, index);

    const [isMouseDown, setIsMouseDown] = useState(false);
    const [mouseDownInfo, setMouseDownInfo] = useState({ x: -1, y: -1 });


    useDrag(isMouseDown,
        (x, y) => {
            const delta = 5;
            const d = trig.distance({ ...mouseDownInfo }, { x, y });

            if (d > delta) {
                onStartDrag();
                setIsMouseDown(false);
            };
        },
        () => {
            setIsMouseDown(false);
            setMouseDownInfo({ x: -1, y: -1 });
        });


    useDrag(isDragging,
        (x, y) => {
            onDragging(x, y, x - mouseDownInfo.x, y - mouseDownInfo.y);

        },
        (x, y) => {
            onEndDrag(x, y);

        }, () => {
            setMouseDownInfo({ x: -1, y: -1 });//can use Esc key to exit the dragging state
        },
        mouseDownInfo.x > -1 && mouseDownInfo.y > -1
    );


    const handleMouseDown = (e) => {

        e.stopPropagation();

        onSelect(e);

        if (e.ctrlKey) return;

        setIsMouseDown(true);
        setMouseDownInfo({ x: e.clientX, y: e.clientY });

    };


    const handleClick = (e) => {
        if (e.detail == 2) onEdit();
    };



    const style = () => {
        if (!dimensions) return;
        if (!isDragging) return;

        return {
            left: `${dimensions.left + dragInfo.deltaX}px`,
            top: `${dimensions.top + dragInfo.deltaY}px`,
        };

    };



    const src = Templates[scene.template].thumb || scene.elems.find(elem => elem.name == 'bgimage')?.url;

    const text = scene.speak.enabled && scene.speak.text ? scene.speak.text : scene.elems.find(({ visible, type }) => type == 'text' && visible)?.text;


    return (

        <>
            {isDragging &&
                <div
                    className="thumb"
                    data-index={-1}>
                </div>

            }

            <div
                ref={ref}
                className={classNames('thumb group relative bg-zinc-100 shadow-light select-none hover:!bg-zinc-50 flex flex-col', scene.selected && '!bg-zinc-50 ring ring-blue-400', isDragging && 'thumb-dragging')}
                style={style()}
                data-index={isDragging ? -1 : index}
                onMouseDown={(e) => handleMouseDown(e)}
                onClick={(e) => handleClick(e)}
                onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); onContextMenu(e, index) }}>

                {!isDragging && dragInfo.hoveredIndex == index && dragInfo.insert == 'before' &&
                    <div className="absolute left-0 top-1/4 bottom-1/4 w-1 bg-red-500 rounded -translate-x-3"></div>
                }

                {!isDragging && dragInfo.hoveredIndex == index && dragInfo.insert == 'after' &&
                    <div className="absolute right-0 top-1/4 bottom-1/4 w-1 bg-red-500 rounded translate-x-3"></div>
                }


                <div className="flex-1 bg-gray-400 flex flex-row items-center justify-center">

                    {!scene.visible &&
                        <img
                            className="w-8 opacity-30 filter-white"
                            src={require("../assets/svg/x.svg").default} />
                    }

                    {scene.visible &&
                        <img
                            className={classNames('h-[5.5rem] w-auto', scene.template == 'bg-image' && 'backdrop-contrast-75 shadow')}
                            src={src}
                        />
                    }

                </div>

                <div className="h-[5.5rem] flex flex-col">

                    {scene.visible &&
                        <>
                            <Elements scene={scene} />
                            <div className="px-1.5 text-xs text-gray-700 line-clamp-2">{text}</div>
                        </>
                    }

                    <div className="mt-auto pl-1.5 pr-2 py-1 flex flex-row items-center space-x-2">

                        <div className={classNames('flex-1 font-semibold text-xs text-black first-letter:uppercase truncate text-clip', !scene.visible && 'opacity-80 line-through decoration decoration-black')}>
                            {scene.name ? scene.name : index - startFrom}
                        </div>

                        <button
                            onMouseDown={(e) => { e.stopPropagation(); onToggle(); }}
                            onClick={(e) => e.stopPropagation()}>
                            <img
                                className={classNames('h-4', !scene.visible && 'opacity-20')}
                                src={require("../assets/svg/eye.svg").default}
                            />
                        </button>

                    </div>

                </div>

            </div>
        </>

    );

};

const SelectRect = ({ isSelecting, selectInfo, onSelecting, onEndSelection }) => {

    const left = Math.min(selectInfo.point1.x, selectInfo.point2.x);
    const top = Math.min(selectInfo.point1.y, selectInfo.point2.y);
    const width = Math.abs(selectInfo.point1.x - selectInfo.point2.x);
    const height = Math.abs(selectInfo.point1.y - selectInfo.point2.y);

    useDrag(isSelecting,
        (x, y) => {
            x = Math.max(x, selectInfo.boundingRect.left);
            y = Math.max(y, selectInfo.boundingRect.top);
            x = Math.min(x, selectInfo.boundingRect.right);
            y = Math.min(y, selectInfo.boundingRect.bottom);

            //todo: check when it has scroll

            onSelecting(x, y);
        },
        () => {
            onEndSelection();
        }
    );


    const style = {
        left: `${left - selectInfo.boundingRect.left}px`,
        top: `${top - selectInfo.boundingRect.top}px`,
        width: `${width}px`,
        height: `${height}px`,
    }

    return (
        <div
            className={classNames('absolute bg-blue-200 border-2 bg-opacity-30 border-blue-300 pointer-events-none', width < 3 && height < 3 && 'invisible')}
            style={style}>
        </div>
    );

};

const Thumbnails = ({ onEditScene }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { user, files, file, clipboard, preferences } = state;


    const [isSelecting, setIsSelecting] = useState(false);
    const [selectInfo, setSelectInfo] = useState({ point1: { x: 0, y: 0 }, point2: { x: 0, y: 0 }, boundingRect: { left: 0, top: 0, right: 0, bottom: 0 } });

    const [isSceneDragging, setIsSceneDragging] = useState(false);
    const [dragSceneInfo, setSceneDragInfo] = useState({ deltaX: 0, deltaY: 0, hoveredIndex: -1, insert: '' });

    const [isUpdating, setIsUpdating] = useState(false);

    const [recordParams, setRecordParams] = useState(null);
    const [uploadImagesParams, setUploadImagesParams] = useState(null);

    const [inputDialogParams, setInputDialogParams] = useState(null);
    const [confirmDialogParams, setConfirmDialogParams] = useState(null);

    const [contextMenuParams, setContextMenuParams] = useState(null);

    const ref = useRef(null);

    const selectedCount = file.content.scenes.reduce((a, c) => a + (c.selected ? 1 : 0), 0);


    const optionsCanvas = [
        {
            name: 'Record',
            action: () => recordScreen(),
        },
        {
            name: 'Import',
            action: () => addScenesFromImages(),
        },
        {
            name: 'Add scene',
            children: [
                {
                    name: 'Blank',
                    action: (sender) => addSceneBlank(sender),
                    delimiter: true
                },
            ],

            delimiter: true,
        },
        {
            name: 'Paste',
            action: () => paste(),
            disabled: !clipboard,
        },
        {
            name: 'Select All',
            action: () => selectAll()
        }
    ];

    const optionsThumbs = [
        {
            name: 'Edit',
            action: (sender) => editThumb(sender),
            hidden: selectedCount > 1,
        },
        {
            name: 'Rename',
            action: (sender) => renameThumb(sender),
            hidden: selectedCount > 1,
            delimiter: true,
        },
        {
            name: 'Insert scene',
            hidden: selectedCount > 1,
            children: [
                {
                    name: 'Record',
                    action: (sender) => recordScreen(sender),
                },
                {
                    name: 'Import',
                    action: (sender) => addScenesFromImages(sender),
                    delimiter: true
                },
                {
                    name: 'Blank',
                    action: (sender) => addSceneBlank(sender),
                    delimiter: true
                },
            ],

            delimiter: true,
        },
        {
            name: 'Copy',
            action: (sender) => copyThumbs(sender),
        },
        {
            name: 'Delete',
            action: (sender) => deleteThumbs(sender),
            delimiter: true
        },
        {
            name: 'Duplicate',
            action: (sender) => duplicateThumbs(sender)
        }
    ];


    // add templates to menus

    const T = Object.keys(Templates).filter(key => key != 'bg-image');

    T.forEach(key => optionsCanvas[2].children.push({ name: Templates[key].title, tag: key, action: (sender, option) => addSceneFromTemplate(sender, option) }))
    T.forEach(key => optionsThumbs[2].children.push({ name: Templates[key].title, tag: key, action: (sender, option) => addSceneFromTemplate(sender, option) }))


    //general

    const selectAll = () => {
        const arr = file.content.scenes.map(scene => scene.id);
        dispatch({ type: 'scenes-select', ids: arr });
    };

    const unselectAll = () => {
        dispatch({ type: 'scenes-select', ids: [] });
    };



    const recordScreen = (sender) => {
        const index = sender ? sender[0] + 1 : undefined;
        setRecordParams({ index });
    };


    const addScenesFromImages = (sender) => {
        const index = sender ? sender[0] + 1 : undefined;
        setUploadImagesParams({ index });
    };

    const addSceneBlank = (sender) => {
        const index = sender ? sender[0] + 1 : undefined;

        actions.addSceneBlank(preferences, dispatch, index);
    };

    const addSceneFromTemplate = (sender, option) => {
        const index = sender ? sender[0] + 1 : undefined;
        actions.addSceneFromTemplate(preferences, dispatch, index, option.tag);
    };

    const paste = async () => {
        if (!clipboard) return;

        setIsUpdating(true);

        const { fileId, scenes } = clipboard;
        const fileSrc = files.find(file => file.id == fileId);

        if (!fileSrc) {
            setIsUpdating(false);
            return;
        }

        const newScenes = fileSrc.content.scenes.filter(scene => scenes.includes(scene.id)).map(scene => JSON.parse(JSON.stringify(scene)));
        if (!newScenes.length) {
            setIsUpdating(false);
            return;
        }


        //need to regenerate id as I can paste into the same movie
        newScenes.forEach(scene => scene.id = uniqueId());


        const EXT = '.jpeg';

        const elems = newScenes.filter(({ template }) => template == 'bg-image').map(({ id, elems }) => ({ id, bgImage: elems.find(elem => elem.name == 'bgimage') })).filter(({ bgImage }) => !bgImage.url.includes('placeholder.svg'));
        const p = elems.reduce((a, c) => { a[c.bgImage.url] = { bgImage: c.bgImage, path: `users/${user.uid}/${file.id}/${c.id}${EXT}` }; return a; }, {});


        const getBytes = async (url) => new Promise(async (resolve, reject) => {

            try {
                const response = await fetch(url);
                const blob = await response.blob();

                resolve(blob);

            } catch (err) {
                console.log(err);
                resolve(null);
            }

        })


        const download = async () => {

            const promises = Object.keys(p).map(url => getBytes(url));
            const result = await Promise.all(promises);

            const success = result.every(v => v);

            if (success) Object.keys(p).forEach((key, i) => p[key].blob = result[i]);

            return success;
        }

        const upload = async () => {

            const promises = Object.keys(p).map(key => new Promise(async (resolve, reject) => { const url = await storageUploadFile(p[key].path, p[key].blob); resolve(url); }));
            const result = await Promise.all(promises);

            const success = result.every(v => v);

            if (success) Object.keys(p).forEach((key, i) => p[key].newUrl = result[i]);

            return success;
        }

        if (!await download()) {
            setIsUpdating(false);
            return;
        }

        if (!await upload()) {
            setIsUpdating(false);
            return;
        }


        Object.keys(p).forEach(key => p[key].bgImage.url = p[key].newUrl);

        dispatch({ type: 'scenes-insert', scenes: newScenes.map(scene => ({ scene, index: undefined })) });

        setIsUpdating(false);

    };

    // thumbs menu

    const editThumb = (sender) => {
        onEditScene(file.content.scenes[sender[0]]);
    };

    const renameThumb = (sender) => {
        const scene = file.content.scenes[sender[0]];

        setInputDialogParams({
            label: 'Type new scene name',
            value: scene.name,
            allowEmpty: true,
            button: 'Rename',
            execute: async (v) => {

                if (typeof v != 'string') return true;//can rename to blank

                dispatch({ type: 'scene-rename', id: scene.id, name: v });

                return true;
            },
            close: () => setInputDialogParams(null)
        })

    };


    const copyThumbs = (sender) => {

        const arr = sender.map(index => file.content.scenes[index].id);
        dispatch({ type: 'clipboard-set', data: { fileId: file.id, scenes: arr } });

    };

    const deleteThumbs = (sender) => {

        // const names = sender.map(index => file.content.scenes[index].name);


        setConfirmDialogParams({
            // sender: sender,
            label: `Are you sure you want to delete selected scenes?`,
            button: 'Yes, please delete them',
            execute: async () => {

                const arr = sender.map(index => file.content.scenes[index].id);
                dispatch({ type: 'scenes-delete', ids: arr });

                if (clipboard && clipboard.fileId == file.id) dispatch({ type: 'clipboard-set', data: null });

                return true;

            },
            close: () => setConfirmDialogParams(null)

        });


    };

    const duplicateThumbs = (sender) => {
        const arr = sender.map(index => ({ index: index + 1, scene: file.content.scenes[index] }));
        actions.duplicateScenes(dispatch, arr)
    };




    // thumb events

    const handleSceneToggle = (scene) => {
        dispatch({ type: 'scenes-toggle', id: scene.id });
    };

    const getDragHoveredScene = (x, y) => {
        for (let i = 0; i < ref.current.children.length; i++) {
            const index = ref.current.children[i].getAttribute('data-index');

            if (index == -1) continue;
            if (file.content.scenes[index].selected) continue;

            const { left, top, right, bottom } = ref.current.children[i].getBoundingClientRect();

            if (left <= x && x <= right && top <= y && y <= bottom) {
                return { hoveredIndex: index, insert: x < left + (right - left) / 2 ? 'before' : 'after' }
            }

        }

        return { hoveredIndex: -1, insert: '' };
    }

    const handleSceneStartDrag = () => {
        setIsSceneDragging(true);
        setSceneDragInfo({ deltaX: 0, deltaY: 0, hoveredIndex: -1, insert: '' });
    }

    const handleSceneDragging = (x, y, deltaX, deltaY) => {
        const { hoveredIndex, insert } = getDragHoveredScene(x, y);
        setSceneDragInfo({ deltaX, deltaY, hoveredIndex, insert });
    }

    const handleSceneEndDrag = (x, y) => {

        const { hoveredIndex, insert } = getDragHoveredScene(x, y);

        if (hoveredIndex > -1) {

            let arrSel = [];
            let arr1 = [];
            let arr2 = [];

            file.content.scenes.forEach((scene, index) => {
                if (scene.selected) {
                    arrSel.push(scene.id);
                }
                else {
                    if (index < hoveredIndex) arr1.push(scene.id);
                    if (index > hoveredIndex) arr2.push(scene.id);

                }
            });

            let arr = [];

            const hoveredId = file.content.scenes[hoveredIndex].id;

            if (insert == 'before') arr = [...arr1, ...arrSel, hoveredId, ...arr2];
            else arr = [...arr1, hoveredId, ...arrSel, ...arr2];


            dispatch({ type: 'scenes-order', ids: arr });

        };


        setIsSceneDragging(false);
        setSceneDragInfo({ deltaX: 0, deltaY: 0, hoveredIndex: -1, insert: '' });

    }



    const handleSceneSelect = (e, scene) => {

        let arr = file.content.scenes.filter(scene => scene.selected).map(scene => scene.id);

        if (!e.ctrlKey) {
            if (!(scene.selected && selectedCount > 1)) arr = [];
        }

        const index = arr.findIndex(id => id == scene.id);

        if (e.ctrlKey) {
            if (scene.selected) {
                arr = arr.filter(id => id != scene.id);
            }
            else {
                if (index == -1) arr.push(scene.id);
            }

        }
        else {
            if (index == -1) arr.push(scene.id);
        }



        dispatch({ type: 'scenes-select', ids: arr });
    }

    const handleEditScene = (scene) => {
        // if (selectedCount == 1) //leave in comment, dbl-click should work even in multi-select
        onEditScene(scene);
    }


    //canvas events

    const onSelecting = (x, y) => {

        const intersectRect = (r1, r2) => {
            return !(r2.left > r1.right ||
                r2.right < r1.left ||
                r2.top > r1.bottom ||
                r2.bottom < r1.top);
        };

        let arr = [];

        const r1 = {
            left: Math.min(selectInfo.point1.x, x),
            top: Math.min(selectInfo.point1.y, y),
            right: Math.max(selectInfo.point1.x, x),
            bottom: Math.max(selectInfo.point1.y, y)
        };

        for (let i = 0; i < ref.current.children.length; i++) {
            const childRect = ref.current.children[i].getBoundingClientRect();

            const r2 = {
                left: childRect.left,
                top: childRect.top,
                right: childRect.right,
                bottom: childRect.bottom
            };


            if (intersectRect(r1, r2)) {
                const index = ref.current.children[i].getAttribute('data-index');
                arr.push(file.content.scenes[index].id);
            }

        };


        dispatch({ type: 'scenes-select', ids: arr });

        setSelectInfo({ ...selectInfo, point2: { x, y } })
    }

    const onEndSelection = () => {
        setIsSelecting(false);
    }

    const handleMouseDown = (e) => {
        const { left, top, right, bottom } = ref.current.getBoundingClientRect();

        unselectAll();

        setIsSelecting(true);
        setSelectInfo({ point1: { x: e.pageX, y: e.pageY }, point2: { x: e.pageX, y: e.pageY }, boundingRect: { left, top, right, bottom } })

    };


    const handleKeyDown = (e) => {

        if (e.keyCode === 27 && isSceneDragging) {
            setIsSceneDragging(false);
            setSceneDragInfo({ deltaX: 0, deltaY: 0, hoveredIndex: -1, insert: '' });
        };

        if (e.keyCode === 46 && !isSceneDragging) {
            const list = file.content.scenes.map((scene, index) => scene.selected ? index : -1).filter(index => index > -1);
            if (list.length) deleteThumbs(list);
        };

    };



    const handleThumbContextMenu = (index, x, y) => {
        let scene = file.content.scenes[index];

        if (scene.selected) {
            const list = file.content.scenes.map((scene, index) => scene.selected ? index : -1).filter(index => index > -1);
            setContextMenuParams({ x: x, y: y, sender: list, options: optionsThumbs })
        }
        else {
            dispatch({ type: 'scenes-select', ids: [scene.id] });
            setContextMenuParams({ x: x, y: y, sender: [index], options: optionsThumbs })
        }

    };




    //list scenes 

    const listScenes = file.content.scenes.map((scene, index) =>
        <Thumb
            key={scene.id}
            scene={scene}

            startFrom={startFrom(file.content.scenes, index)}
            index={index}

            onToggle={() => handleSceneToggle(scene)}

            isDragging={scene.selected && isSceneDragging}

            dragInfo={dragSceneInfo}

            onStartDrag={() => handleSceneStartDrag()}
            onDragging={(x, y, deltaX, deltaY) => handleSceneDragging(x, y, deltaX, deltaY)}
            onEndDrag={(x, y) => handleSceneEndDrag(x, y)}

            onSelect={(e) => handleSceneSelect(e, scene)}
            onEdit={() => handleEditScene(scene)}
            onContextMenu={(e) => handleThumbContextMenu(index, e.clientX, e.clientY)} />
    );

    return (
        <>
            <div
                className="flex-1 relative bg-grid select-none overflow-y-auto focus:outline-none flex flex-col"
                tabIndex={-1}
                onKeyDown={(e) => handleKeyDown(e)}
                onMouseDown={(e) => handleMouseDown(e)}
                onContextMenu={(e) => { e.preventDefault(); setContextMenuParams({ x: e.clientX, y: e.clientY, sender: null, options: optionsCanvas }) }}>

                {isUpdating &&
                    <div className="absolute left-0 right-0 h-px top-0 overflow-clip">
                        <div className="animate-moving-line animate-duration-[4s] animate-infinite absolute bottom-0 w-1/4 h-full bg-blue-400"></div>
                    </div>
                }

                <div
                    ref={ref}
                    className="flex-1 relative p-4 flex flex-row flex-wrap content-start">
                    {listScenes}
                </div>

                {isSelecting && <SelectRect isSelecting={isSelecting} selectInfo={selectInfo} onSelecting={(x, y) => onSelecting(x, y)} onEndSelection={onEndSelection} />}

            </div>

            {recordParams && <Record index={recordParams.index} close={() => setRecordParams(null)} />}
            {uploadImagesParams && <UploadImages index={uploadImagesParams.index} close={() => setUploadImagesParams(null)} />}

            {inputDialogParams && <InputDialog params={inputDialogParams} />}
            {confirmDialogParams && <ConfirmDialog params={confirmDialogParams} />}

            {isUpdating && <div className="fixed left-0 top-0 right-0 bottom-0 z-[9999]"></div>}

            {contextMenuParams && <ContextMenu x={contextMenuParams.x} y={contextMenuParams.y} sender={contextMenuParams.sender} options={contextMenuParams.options} closeContextMenu={() => setContextMenuParams(null)} />}
        </>

    );

};



export default Thumbnails;