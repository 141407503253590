import { useState } from "react";
import { useEffect } from 'react';
import { useRef } from 'react';


import { useClickOutside } from "../hooks";


import classNames from "classnames";


const FilterOptionsMenu = ({ refButton, field, direction, onFieldChange, onDirectionChange, closeFilterOptionsMenu }) => {

    const ref = useRef(null);

    useClickOutside(true, [refButton, ref], null, () => closeFilterOptionsMenu());

    return (
        <ul ref={ref}
            className="absolute z-[999] left-0 top-5 filter-options">
            <li className="filter-options-item"
                onClick={() => onFieldChange('name')}>
                <img
                    className={classNames('h-3', field != 'name' && 'invisible')}
                    src={require("../assets/svg/check-mark.svg").default}
                />
                <span>Name</span>
            </li>
            <li className="filter-options-item"
                onClick={() => onFieldChange('modified')}>
                <img
                    className={classNames('h-3', field != 'modified' && 'invisible')}
                    src={require("../assets/svg/check-mark.svg").default}
                />
                <span>Modified</span>
            </li>
            <li className="filter-options-item border-b border-gray-200"
                onClick={() => onFieldChange('created')}>
                <img
                    className={classNames('h-3', field != 'created' && 'invisible')}
                    src={require("../assets/svg/check-mark.svg").default}
                />
                <span>Created</span>
            </li>
            <li className="filter-options-item"
                onClick={() => onDirectionChange('asc')}>
                <img
                    className={classNames('h-3', direction != 'asc' && 'invisible')}
                    src={require("../assets/svg/check-mark.svg").default}
                />
                <span>Asc</span>
            </li>
            <li className="filter-options-item"
                onClick={() => onDirectionChange('desc')}>
                <img
                    className={classNames('h-3', direction != 'desc' && 'invisible')}
                    src={require("../assets/svg/check-mark.svg").default}
                />
                <span>Desc</span>
            </li>

        </ul>
    );

};


const FilterOptions = ({ field, direction, onFieldChange, onDirectionChange }) => {

    const [showMenu, setShowMenu] = useState(false);

    const ref = useRef(null);


    const handleFieldChange = (v) => {
        onFieldChange(v);
        setShowMenu(false);
    }

    const handleDirectionChange = (v) => {
        onDirectionChange(v);
        setShowMenu(false);
    }

    return (
        <div className="relative ml-2 flex items-center justify-center">

            <button
                ref={ref}
                className={classNames('opacity-30 hover:opacity-100 transition-opacity select-none', showMenu && '!opacity-100')}
                onClick={() => setShowMenu(prev => !prev)}>

                {direction == 'asc' &&
                    <img
                        className="h-5"
                        src={require("../assets/svg/sort-down.svg").default}
                    />
                }

                {direction == 'desc' &&
                    <img
                        className="h-5"
                        src={require("../assets/svg/sort-up.svg").default}
                    />
                }

            </button>

            {showMenu && <FilterOptionsMenu refButton={ref} field={field} direction={direction} onFieldChange={(v) => handleFieldChange(v)} onDirectionChange={(v) => handleDirectionChange(v)} closeFilterOptionsMenu={() => setShowMenu(false)} />}
        </div>

    );

};

export default FilterOptions;
