import { useState } from "react";
import { useEffect } from "react";

import { useParams } from "react-router-dom";

import { fnGetView } from "../firebase/functions";

import Player from "./Player";

import classNames from "classnames";

const Section = ({ folder }) => {

    const { name } = folder;

    return (
        <>
            {!name && <div className="my-12 w-full h-px border-t border-dashed border-t-gray-200"></div>}
            {name && <h1 className="mt-12 mb-4 font-semibold text-lg capitalize">{name}</h1>}
        </>

    )

}

const File = ({ file, bookmark, open }) => {

    const { id, name, description } = file;


    return (
        <div
            className="relative mb-2 px-4 py-2 bg-white rounded select-none cursor-pointer hover:!bg-blue-50 flex flex-row items-center"
            onClick={() => open(id)}>

            {bookmark &&
                <div className="absolute -left-5 top-0">
                    <img
                        className="w-4 h-4 filter-neutral"
                        src={require("../assets/svg/bookmark.svg").default}
                    />
                </div>
            }

            <img
                className="self-start mt-1 mr-3 w-4 h-4 filter-neutral"
                src={require("../assets/svg/file.svg").default}
            />


            <div className="flex-1 flex flex-col space-y-1">
                <h1 className="capitalize">{name}</h1>
                <p className="text-sm line-clamp-1 opacity-80">{description}</p>
            </div>

            <svg
                className="ml-12 w-4 h-4 filter-neutral"
                width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.5 15.5L37.5 30.5L22.5 45.5" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>


        </div>
    )

}

const Content = ({ view }) => {

    const { title, items } = view;

    const [url, setUrl] = useState(null);
    const [lastSeen, setLastSeen] = useState(null);
    const [closing, setClosing] = useState(false);


    const handleOpen = (id) => {

        const href = window.location.origin;
        const v = `${href}/play/${id}`;

        setUrl(v);
        setClosing(false);

        setLastSeen(id);

    }



    const list = items.map((item) => item.type == 'folder' ? <Section key={item.id} folder={item} /> : <File key={item.id} file={item} bookmark={item.id == lastSeen} open={(v) => handleOpen(v)} />)

    return (
        <>
            {title &&
                <div className="bg-slate-800 flex flex-col items-center">
                    <div className="my-20 lg:w-[800px]">
                        <h1 className="font-semibold text-xl text-white capitalize"> {title}</h1>

                    </div>

                </div>
            }

            <div className={classNames('flex-1 overflow-y-auto flex flex-col items-center', url && 'animate-slide-left-out animate-fill-forwards !overflow-y-hidden', closing && 'animate-slide-right-in animate-fill-forwards')}>

                <div className="[&>:first-child]:!mt-0 py-12 lg:w-[800px] flex flex-col">
                    {list}
                </div>

            </div>

            {url && <Player url={url} closing={() => setClosing(true)} close={() => setUrl(null)} />}

        </>
    )

}


const Loading = () => {

    const fill = 'black';

    return (
        <div className="absolute left-0 top-0 right-0 bottom-0 z-[9999] flex flex-col items-center">

            <div className="mt-[30vh] flex flex-col items-center">

                <svg
                    className="animate-fade mt-6 w-14 h-14"
                    width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke={fill}>
                    <g fill="none" fillRule="evenodd" strokeWidth="2">
                        <circle cx="22" cy="22" r="1">
                            <animate attributeName="r"
                                begin="0s" dur="1.8s"
                                values="1; 20"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.165, 0.84, 0.44, 1"
                                repeatCount="indefinite" />
                            <animate attributeName="stroke-opacity"
                                begin="0s" dur="1.8s"
                                values="1; 0"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.3, 0.61, 0.355, 1"
                                repeatCount="indefinite" />
                        </circle>
                        <circle cx="22" cy="22" r="1">
                            <animate attributeName="r"
                                begin="-0.9s" dur="1.8s"
                                values="1; 20"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.165, 0.84, 0.44, 1"
                                repeatCount="indefinite" />
                            <animate attributeName="stroke-opacity"
                                begin="-0.9s" dur="1.8s"
                                values="1; 0"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.3, 0.61, 0.355, 1"
                                repeatCount="indefinite" />
                        </circle>
                    </g>
                </svg>

            </div>

        </div>
    )
}

const useView = (type, param) => {

    const [view, setView] = useState(undefined);

    useEffect(() => {

        const load = async () => {

            const result = await fnGetView(type, param);

            setView(result);

        }

        load();

    }, [type, param])


    return view;

}

export const Folder = () => {
    const { id } = useParams();

    const view = useView('folder', id);

    return (
        <div className="relative w-screen h-screen bg-[#f7f7f7] flex flex-col">
            {view === undefined && <Loading />}
            {view === null && <div>Uh oh!</div>}
            {view && <Content view={view} />}
        </div>
    );

}

export const Workspace = () => {
    const { id } = useParams();

    const view = useView('workspace', id);

    return (
        <div className="relative w-screen h-screen bg-[#f7f7f7] flex flex-col">
            {view === undefined && <Loading />}
            {view === null && <div>Uh oh!</div>}
            {view && <Content view={view} />}
        </div>
    );

}