

import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useContext } from 'react';

import { StateContext, DispatchContext } from '../context/';

import { fnRec } from "../firebase/functions";
import { dbAddJob } from "../firebase/database";

import { ButtonFlatIcon, ButtonWithOptions, ButtonWithModal } from "../ui/Buttons";

import Slider from "../ui/Slider";

import { loadLang } from "./Loader";

import { get } from "./utils";


import { MAX_JOB_DURATION, MAX_CONCURRENT_JOBS } from "../utils/consts";

import Title from "../ui/Title";

import classNames from "classnames";


const useLang = (file) => {

    const [lang, setLang] = useState(null);

    useEffect(() => {

        const load = async () => {
            const result = await loadLang(file);
            setLang(result);
        }

        load()

    }, [file]);

    return lang;
}

const Export = ({ credits, jobs, lang, execute }) => {

    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    const { file, languages } = state;

    const { settings } = file;
    const { target } = languages;

    const [isModalVisible, setIsModalVisible] = useState(false);


    const resolutions = [
        {
            w: 640,
            h: 480
        },
        {
            w: 1280,
            h: 720
        },
        {
            w: 1920,
            h: 1080
        },
    ];


    const index = resolutions.findIndex(resolution => resolution.h == settings.export.h || resolution.h == settings.export.w);
    const orientation = resolutions[index].h == settings.export.h ? 'horizontal' : 'vertical';

    const changeResolution = (i) => {

        const size = orientation == 'horizontal' ? resolutions[i] : { w: resolutions[i].h, h: resolutions[i].w };
        const { w, h } = size;

        dispatch({ type: 'file-settings-update', param: { export: { ...settings.export, w, h } } })

    }

    const changeOrientation = (v) => {

        const size = v == 'horizontal' ? resolutions[index] : { w: resolutions[index].h, h: resolutions[index].w };
        const { w, h } = size;

        dispatch({ type: 'file-settings-update', param: { export: { ...settings.export, w, h } } })

    }

    const changeCode = (code) => {
        setIsModalVisible(false);
        dispatch({ type: 'file-settings-update', param: { export: { ...settings.export, code } } })
    }



    const listResolutions = resolutions.map(({ h }, i) =>
        <span
            className={classNames('text-[0.7rem] text-white opacity-30', index == i && '!opacity-100')}
            key={h}>

            {h}p

        </span>
    )

    const list = [...target, { code: '', name: 'NONE' }];

    const listLanguages = list.map(({ code, name }) =>
        <li
            className={classNames('px-2 py-1.5 rounded select-none cursor-pointer hover:bg-slate-600', code == settings.export.code && '!bg-blue-500', code == lang?.code && 'opacity-30 pointer-events-none')}
            key={code.length ? code : name}
            onClick={() => changeCode(code)}>

            <span className={classNames('text-xs text-white', !code && settings.export.code && '!text-orange-500')}>{name}</span>

        </li>
    )

    const selected = list.find(({ code }) => code == settings.export.code)?.name;

    const duration = get.duration(file);
    const count = jobs.filter(({ status }) => !['error', 'ready'].includes(status)).length;

    const msg = credits['export'] != undefined && credits['export'] <= 0 ? 'You cannot export the video as there are no remaining \'export\' credits. Please check the subscription status.' : count >= MAX_CONCURRENT_JOBS ? 'Kindly wait until the ongoing jobs have been completed.' : duration > MAX_JOB_DURATION ? `Sorry, there is a ${Math.floor(MAX_JOB_DURATION / (1000 * 60))}-minute limit for export. Your movie exceeds this limit.` : null;

    return (
        <div className={classNames(msg && 'w-48 p-2', !msg && 'w-48 h-60 p-2 flex flex-col')}>

            {msg &&
                <p className="text-sm text-white">{msg}</p>
            }

            {!msg &&
                <>
                    <Slider className="w-full !h-8" min={0} max={resolutions.length - 1} value={index} setValue={(v) => changeResolution(v)} />

                    <div className="px-1 flex flex-row justify-between">
                        {listResolutions}
                    </div>

                    <div className="mt-5 px-1 flex flex-row items-center space-x-2">
                        <div className={classNames('w-5 h-3 border border-white opacity-40 cursor-pointer', orientation == 'horizontal' && '!opacity-100')} onClick={() => changeOrientation('horizontal')}></div>
                        <div className={classNames('w-3 h-5 border border-white opacity-40 cursor-pointer', orientation == 'vertical' && '!opacity-100')} onClick={() => changeOrientation('vertical')}></div>
                    </div>


                    <div className={classNames('mt-10 px-1 flex flex-row items-center', !target.length && 'invisible')}>
                        <div className="mr-1 text-xs text-white">Translate :</div>

                        <ButtonWithModal className="mr-2" place="r" isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>
                            <button className={classNames('group max-w-24 px-2 py-1 truncate hover:bg-slate-700', isModalVisible && '!bg-slate-600')}>
                                <span className={classNames('text-xs text-white opacity-80 group-hover:!opacity-100', isModalVisible && '!opacity-100')}>
                                    {selected}
                                </span>
                            </button>

                            <div className="p-2 bg-slate-800 shadow shadow-slate-600">
                                <ul className="grid grid-cols-3">
                                    {listLanguages}
                                </ul>
                            </div>


                        </ButtonWithModal>

                    </div>


                    <button
                        className="group mt-auto py-2 text-xs text-white border border-slate-700 rounded hover:bg-slate-700 flex flex-row items-center justify-center"
                        onClick={() => execute(settings.export)}>
                        <span className="group-active:translate-y-px">Export</span>
                    </button>
                </>
            }

        </div >
    )


}


const ToolbarExport = ({ credits, jobs, onBack }) => {

    const state = useContext(StateContext);
    // const dispatch = useContext(DispatchContext);

    const { user, file } = state;

    const ref = useRef(null);

    const lang = useLang(file);


    const handleExport = async (job) => {

        ref.current.close();

        let result;

        //todo: blokez in UI, insa daca trece de UI si nu are credits, poate adauga un job aiurea pe care nu o sa l proceseze niciodata ptr ca donditia se afla in rec de pe cloud func

        result = await dbAddJob(user.uid, file.id, job);
        if (!result) return;

        result = await fnRec(result);

    }



    return (
        <div className="toolbar">

            <ButtonFlatIcon className="h-4" svg="back.svg" click={onBack} />

            <span className="h-full w-px bg-slate-600"></span>

            <ButtonWithOptions place="bl" svg="export.svg" className="h-4" tooltip="Add export job" ref={ref}>

                <Export credits={credits} jobs={jobs} lang={lang} execute={handleExport} />

            </ButtonWithOptions>

            <Title name="Export"/>

        </div>
    );

};

export default ToolbarExport;